<template>
    <div>
        <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow">
                    <v-col cols="4" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li>Notification Group Rule</li>
                        </ul>
                    </v-col>
                    <v-col cols="8"></v-col>
                </v-row>
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>
    
        <edc-data-grid :dataList="tableList" @onNew="onNew" @onDelete="onDelete" @onSave="onSave" @onCancel="onCancel" @onEdit="onEdit"></edc-data-grid>

        <loading-panel :loader="loader"></loading-panel>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    </div>
</template>
<script>
import { get as getToServer, deleteFromServer, post as postToServer } from '@/methods/serverCall.js';
import { GET_ALL_EXECUTION_RULE, GET_ALL_PUBLISHESD_PROCESS_DEF_LIST,DELETE_EXECUTION_RULE_MAPPING,
     GET_ALL_EXECUTION_RULE_MAPPING, ADD_EXECUTION_RULE_MAPPING, UPDATE_EXECUTION_RULE_MAPPING} from '@/data/url_constants.js'
import {CLIENT_SIDE, SERVER_SIDE} from '@/data/macros.js'
import config from '../../config.json'
import environmentList from '../../methods/EnvironmentList.js'
import _ from 'lodash'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

export default {
    name:'ProcessExecutionRule',
        
        data: function () {
        return {
               tableList: {
                headers: [
                    { text: 'Notification Group', value: 'notification_group_name', width:"50%", option:[], 'item_text':'name', 'item_value':'name', title: 'Notification Groups', useAutoComplete:true},
                    { text: 'Tag List', value: 'tag_name', width:"50%", option:[], 'item_text':'tag_name', 'item_value':'tag_name', title: 'Tag List', useAutoComplete:true},
                ], 
                actions: [{'text':'edit','key':"id", selectType:"single", role:true, index: 1},
                        {'text':'new','key':"id", 'enabled':this.Environment, role:true, index: 2},
                        {'text':"save","key":"is_row_editable",selectType:"single", index: 3},
                        {'text':"cancel","key":"is_row_editable",selectType:"single", index: 4},
                        {'text':'delete','key':"id", selectType:"single", role:true, index: 5}],//if we need conditional action in row then provide key
                sorting_type: CLIENT_SIDE,
                filterType: CLIENT_SIDE,
                paginationType: CLIENT_SIDE,
                total_count: 0,
                is_row_edit:true,
                rows: [],
                selected_rows: [],
                syncHeaderScroll:false,
                hideExport:true,
                hideShowColumns:true,
                hideFilter:true,
                is_grid_inline_edit:true,
                itemkey:'id'
            },
            snackbar:false,
            snackbartext:'',
            loader:false,            
            colorValue:'success',
            snackbartimeout: Snackbar_Default_Timeout,
        }},
        mounted(){
            this.getAllRule();
            // this.getEnvironmentList();
            this.getAllTags();
            this.getAllGroups();
        },
        methods:{
            getAllRule(){
                let url =  config.NOTIFICATION_GROUP_URL + '/get_all_ticket_notification_group_rule';
                let inputJson={
                    'client_id': parseInt(this.$session.get('client_id'))
                }
                postToServer(this, url, inputJson).then(response  => {
                        this.tableList.rows = response ;
                    }).catch(error => {
                        this.tableList.rows =[];
                    if(error){
                        // this.snackbar = true;
                        // this.colorValue = 'error';
                        // this.snackbartext = error;
                        }
                        else {
                        }
                });
            },
            getAllTags(){
                let url =  config.EDC_TAG_SERVICE_URL + '/get_all_tags';
                let inputJson={
                    'client_id':this.$session.get('client_id'),
                    'product_name': 'TICKET_MANAGEMENT'
                }
                postToServer(this, url, inputJson).then(response  => {
                        this.tableList.headers[1].option = response;
                    }).catch(error => {
                        this.tableList.rows =[];
                    if(error){
                        // this.snackbar = true;
                        // this.colorValue = 'error';
                        // this.snackbartext = error;
                        }
                        else {
                        }
                });
            },
            getEnvironmentList(){
                let _this = this;
                environmentList(this).then(response=>{
                    if(response){
                        response.unshift({"id": 'ENV0', "name": "ANY"});
                        this.tableList.headers[0].option = response;
                    }
                },response=>{
                    this.ShowErrorMessage=true
                    this.ErrorMessage=response
                })
            },
            getAllGroups(){
            let _this = this;
            var client_id = this.$session.get('client_id')
            var request_data = {'client_id': client_id,
                    'product_name': "TICKET_MANAGEMENT"}
            postToServer(_this, config.NOTIFICATION_GROUP_URL + '/get_all_notification_group', request_data).then(response => {
                this.tableList.headers[0].option = response;
                _this.loader = false;
            }).catch(error_response => {
                _this.loader = false;
                if (error_response) {
                    // this.loader = null
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = error_response;
                } else {
                    // this.snackbar = true
                    // this.colorValue = 'error'
                    // this.snackbartext = SERVER_ERROR;
                }
            });
            },
            onNew(){
                if(false){
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = 'Please Add Notification group First';
                } else {
                    let obj = _.find(this.tableList.rows,["is_row_editable",true])
                    if(obj)
                    {
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = 'First fillup the open row';
                        return
                    }

                    this.tableList.rows.unshift({
                        'notification_group_name':'',
                        'tag_name':'',
                        'is_row_editable' : true,
                    });
                }
            },
            onDelete(record){  
                let url = config.NOTIFICATION_GROUP_URL+ '/remove_ticket_notification_group_rule';
                let inputJson = {id: record.id, 'client_id': this.$session.get('client_id')}
                postToServer(this, url, inputJson).then(response  => {
                        this.snackbar = true
                        this.colorValue = 'success'
                        this.snackbartext = response;
                        this.getAllRule();
                        // this.getRuleList();
                    }).catch(error => {
                        this.getAllRule();
                    if(error){
                        this.snackbar = true;
                        this.colorValue = 'error';
                        this.snackbartext = error;
                        }
                        else {
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = SERVER_ERROR;
                        }
                });  
            },
            onSave(record){
            let tagObj = _.find(this.tableList.headers[1].option, ['tag_name', record.tag_name]);
            let groupObj = _.find(this.tableList.headers[0].option, ['name', record.notification_group_name]);
            if(tagObj && groupObj){
                let inputJson = {'client_id': parseInt(this.$session.get('client_id')),
                                'tag_id':tagObj.id, 
                                'tag_name':tagObj.tag_name,
                                'notification_group_id':groupObj.id,
                                'notification_group_name':groupObj.name,
                                // 'product_name': 'TICKET_MANAGEMENT'
                            };
                if(record.id)
                    inputJson["id"] =record.id
                console.log(inputJson)
                let url =  config.NOTIFICATION_GROUP_URL + '/manage_ticket_notification_group_rule';
                postToServer(this, url, inputJson).then(response  => {
                        this.snackbar = true
                        this.colorValue = 'success'
                        this.snackbartext = response;
                        this.getAllRule();
                    }).catch(error => {
                        // this.getRuleList();
                    if(error){
                        this.snackbar = true;
                        this.colorValue = 'error';
                        this.snackbartext = error;
                        }
                        else {
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = SERVER_ERROR;
                        }
                });  
            }
            },
            onCancel(){
                this.getAllRule()
            },
        }   
}
</script>
