<template>
    <v-container class="EDC-Container" style="padding-top:1% !important;">
        <v-row no-gutters class="EDC-Row">
            <v-col cols="12" class="EDC-Col EDC-ColsSpacing">
                <v-list v-for="(item, index) in ticket_list_final" :key="item.ticket_data.ticket_id">
                    <v-card :color="getTileColor(index)">
                        <v-row no-gutters class="EDC-Row EDC-RowsSpacing">
                            <v-col cols="2" class="EDC-Col EDC-ColsSpacing" align="left">
                                <a><v-list-item-title @click="redirectToTicketDetails(item.ticket_data)" style="padding-left:8px !important;" class="override-font"><b>{{item.ticket_data.title}}-{{item.ticket_data.ticket_id}}</b></v-list-item-title></a>
                            </v-col>
                            <v-col cols="2" class="EDC-Col EDC-ColsSpacing" align="left">
                                <v-list-item-title>
                                    <v-chip :color="getColor(item.ticket_data.status?item.ticket_data.status:'')" :text-color="item.ticket_data.status == 'Closed'? 'black' : 'white'" small dark class="override-font">
                                        {{item.ticket_data.status}}
                                    </v-chip>
                                </v-list-item-title>
                            </v-col>
                            <v-col cols="2" class="EDC-Col EDC-ColsSpacing" align="left">
                                <v-list-item-title style="padding-left:8px !important;" class="override-font">Priority- {{item.ticket_data.priority_name}}</v-list-item-title>
                            </v-col>
                            <v-col cols="2" class="EDC-Col EDC-ColsSpacing" align="left">
                                <v-list-item-title style="padding-left:8px !important;" class="override-font">Queue- {{item.ticket_data.queue_name}}</v-list-item-title>
                            </v-col>
                            <v-col cols="2" class="EDC-Col EDC-ColsSpacing" align="left">
                                <v-list-item-title style="padding-left:8px !important;" class="override-font">Created By- {{item.ticket_data.created_by}}</v-list-item-title>
                            </v-col>
                            <v-col cols="2" class="EDC-Col EDC-ColsSpacing" align="left">
                                <v-list-item-title class="override-font">Created Date Time- {{item.ticket_data.created_date_time}}</v-list-item-title>
                            </v-col>
                            <!-- <v-col cols="2" class="EDC-Col EDC-ColsSpacing" align="left">                                
                                <v-list-item-title class="override-font">{{item.tz_action_date_time}}</v-list-item-title>
                            </v-col> -->
                        </v-row>
                        <v-row no-gutters class="EDC-Row EDC-RowsSpacing" v-if="!search_word">
                            <v-col cols="12" class="EDC-Col EDC-ColsSpacing" align="left">
                                <v-list-item-title>Description: {{item.ticket_data.description}} </v-list-item-title>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="EDC-Row EDC-RowsSpacing" v-if="item.highlight">
                            <v-col cols="12" class="EDC-Col EDC-ColsSpacing" align="left">
                                <a><v-list-item-title v-for="(key_1, value_1) in Object.entries(item.highlight)" v-bind:key="value_1" @click="redirectToTicketDetails(item.ticket_data, key_1)" style="padding-left:8px !important;" class="override-font" v-html="returnBold(key_1)"></v-list-item-title></a>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-list>
            </v-col>
        </v-row>
        <v-container style="max-height:50px;padding:0px;">
			<v-row no-gutters dense xs12>
				<v-col cols="4" class="EDC-DashboardCol">
					<v-row no-gutters>
						<v-col cols="8" style="margin-left:5%;">
							<v-select :items="perPageArray" hide-details v-model="perPage" style="width:50px;padding:0px;" @input="onPerPageChange"></v-select>
							<span style="position: absolute;left:60px;margin-top:-22px;" class="EDCTableFooter">Rows</span>
						</v-col>
						<v-col cols="3"></v-col>
					</v-row>
				</v-col>
				<v-col cols="4" class="EDC-DashboardCol" align-self="center">
					<span class="EDCTableFooter">
					{{ (page - 1) * perPage + 1 }} - {{ (page - 1) * perPage + ticket_list_final.length }} of {{ total_rows }}</span>
				</v-col>
				<v-col cols="4" class="EDC-DashboardCol" justify="end" align="end" align-self="end">
                    <v-pagination style="justify-content:flex-end !important;float:right;" v-model="page" :length="pageCount" :total-visible="pageNumberTotalVisible" @input="onPageChange"></v-pagination>
				</v-col>
			</v-row>
		</v-container>
        <loading-panel :loader="loader"></loading-panel>
    </v-container>
</template>
<script>
var moment = require("moment-timezone")
import config from '../../config.json';
import {post as postToServer} from './../../methods/serverCall.js';
import {Snackbar_Default_Timeout, GRID_STATUS_COLOR_CODE, Ticket_management} from '@/constants/constants.js';
import {manageSearchDisplay} from '@/methods/special.js'
import{CLIENT_SIDE,SERVER_SIDE} from '@/data/macros.js';
export default{
    data(){
        return{
            ticket_list_final:[],
            ticket_data:[],
            boldString:'',
            field_list:[],
            search_word:'',
            loader:false,
            rowsToDisplay:[],
            total_rows:0,
            page: 1,
            perPage: this.$route.params.ticket_list.result.length,
            perPageArray:[5,10,15,20,25,50,100],
            pageCount: 0,
            pageNumberTotalVisible:6,
            data_to_highlight:[],
            dateFormat:this.$session.get('UI_date_format'),
            timeZoneFormat:this.$session.get('time_zone_preference'),
        }
    },
    watch:{
        '$store.state.searchTicketResult':{
            handler(newValue){
                this.dateFormat=this.$session.get('UI_date_format'),
                this.timeZoneFormat=this.$session.get('time_zone_preference')
                this.ticket_list_final=[]
                this.field_list = newValue.field_list
                this.search_word = newValue.search_keyword
                this.perPage = manageSearchDisplay()
                this.total_rows = newValue.ticket_list.total
                this.pagenationInitiation(this.perPage, this.total_rows);
                if(newValue.ticket_list.result && newValue.ticket_list.result.length>0){
                    var ticket_list_temp = newValue.ticket_list.result
                    this.ticketListInitiation(ticket_list_temp);
                }
            }
        }
    },
    mounted(){
        // this.word_searched = this.$route.params.keyword
        this.field_list = this.$route.params.field_list
        this.search_word = this.$route.params.search_keyword
        this.perPage = manageSearchDisplay()
        this.total_rows = this.$route.params.ticket_list.total
        this.pagenationInitiation(this.perPage, this.total_rows);
        if(this.$route.params.ticket_list.result && this.$route.params.ticket_list.result.length>0){
            var ticket_list_temp = this.$route.params.ticket_list.result
            this.ticketListInitiation(ticket_list_temp);
        }
    },
    methods:{
        getTileColor(index){
            if(index % 2 != 0)
                return '#F5F5F5'
            else
                return 'white'
        },
        returnBold(key_1){
            var key = key_1[0]
            var text = key_1[1][0]
            return `${key}: ${text}`
        },
        getColor(value){
            return GRID_STATUS_COLOR_CODE[value.toLowerCase()]
        },
        redirectToTicketDetails(record, key_1){
            var test_key = key_1
            this.$router.push({name:'TicketDetailsForDv', params: record})
        },
        onPerPageChange(number){
            this.perPage=number
            this.searchForTickets(this.field_list, this.search_word)
        },
        onPageChange(number){
            this.page=number
            this.searchForTickets(this.field_list, this.search_word)
        },
        pagenationInitiation(perpage, total_rows){
            this.perPage = perpage
            this.total_rows = total_rows
            this.pageCount = parseInt(Math.ceil(this.total_rows/this.perPage))
        },
        searchForTickets(field_list, search_word){
            this.ticket_list_final=[]
            let search_data = {"page":this.page,
            "page_size": this.perPage,
            "search_keyword": search_word}
            search_data["filter_list"] = field_list 
            this.loader=true
            var search_url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + '/search_data_from_es'
            postToServer(this, search_url, search_data).then(response => {
                this.loader=false
                if(response && response.result.length>0){
                    this.ticketListInitiation(response.result)
                    this.pagenationInitiation(this.perPage, response.total)
                }
            }).catch(error_response => {
                this.loader=false
                console.log(error_response)
            });
        },
        ticketListInitiation(ticketListArry){
            _.forEach(ticketListArry,(element) => {
                var tkt_dataList = {}
                var date_time = element["ticket_data"]["created_date_time"]
                date_time = date_time.split("T")
                element["ticket_data"]["created_date_time"] = moment(date_time[0],"YYYY-MM-DD").format('ll') +' at '+moment(date_time[0]+' '+date_time[1],"YYYY-MM-DD"+" HH:mm:ss").tz(this.timeZoneFormat).format('LT') +' '+moment().tz(this.timeZoneFormat).format('z')
                tkt_dataList["ticket_data"] = element["ticket_data"]
                if(element["highlight"]){
                    let keys = _.keys(element["highlight"])
                    for(let i=0;i<keys.length;i++){
                        if(keys[i].includes('ticket_data.')){
                            var newKey = keys[i].replace('ticket_data.','')
                            newKey = newKey.charAt(0).toUpperCase()+newKey.slice(1)
                            element['highlight'][newKey] = element['highlight'][keys[i]]
                            delete element['highlight'][keys[i]]
                        }
                        else if(keys[i].includes('workflow_data.')){
                            // var newKey = keys[i].replace('workflow_data.','')
                            var newKey = keys[i]
                            newKey = newKey.split(".")
                            var finalKey = ""
                            for(var x=0;x<newKey.length;x++){
                                if(newKey[x].includes("_")){
                                    var final_key_word = ''
                                    var temp_key = newKey[x].split("_")
                                    temp_key.forEach(function(element){
                                        final_key_word=final_key_word+element+" "
                                    })
                                    finalKey = finalKey+final_key_word+" -> "
                                }
                                else{
                                    finalKey=finalKey+newKey[x]+" -> "
                                }
                            }
                            finalKey=finalKey.slice(0,finalKey.length-4)
                            // newKey = "workflow -> step name -> form name -> field name"
                            element['highlight'][finalKey] = element['highlight'][keys[i]]
                            delete element['highlight'][keys[i]]
                        }
                    }
                }
                // console.log(tkt_dataList)
                tkt_dataList["highlight"] = element['highlight']
                this.ticket_list_final.push(tkt_dataList)
            });
        },
    }
}
</script>
<style scoped>
.override-font{
  font-size: 12px !important;
}
.EDCTableFooter {
	font-size: 12px !important;
}
</style>