<template>
  <div
    id="app"
    style="background:white"
  >
    <!-- <button @click.prevent="focusEditor">Focus Editor</button> -->
    <vue-editor  id="editor2"
      v-model="obj_description"
      placeholder="Description"
      @focus="onFocus"
      :disabled="no_toolbarbox_readonly"
      :editor-toolbar="customToolbar"
      :editorOptions="editorOptions"
    />
    <!-- <vue-editor id="editor1"
      v-model="obj_comment"
      placeholder="Comment"
      :editor-toolbar="customToolbar"
    /> -->
  </div>
</template>

 <script>
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor
  },
  props:['vueEditorObj','disabled'],

  data() {
    return {
      obj_description: "",
      in_focus:false,
      no_toolbarbox_readonly:false,
      editorOptions : {
          modules: {
          toolbar: false,
        }
      },
      customToolbar:  [
                [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
                // [{ 'size': ['small', false, 'large', 'huge'] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
                // [{ 'header': 1 }, { 'header': 2 }],
                ['blockquote'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
                [{ 'script': 'sub'}, { 'script': 'super' }],
                [{ 'indent': '-1'}, { 'indent': '+1' }],
                [{ 'color': [] }, { 'background': [] }],
                ['link', 'image'],
                [{ 'direction': 'rtl' }],
                // ['clean'],
              ]
    };
  },
  mounted() {
    this.obj_description = this.vueEditorObj.description
    this.vueEditorObj = {}
    this.vueEditorObj['description'] = _.cloneDeep(this.obj_description)
    // this.$refs.editor1.quill.focus();
  },
  methods: {
    focusEditor() {
      this.$refs.editor1.quill.focus();
    },
    onFocus(){
        this.$emit('onFocus');
      //   this.$nextTick(() => {
      //     editorOptions = {
      //     modules: {
      //     toolbar: true,
      //   }
      // }
      // });
    },
   
  },
  watch: {
  //   commentEditorObj(newvalue,oldvalue){
  //     this.obj_comment = ''
  //     debugger;
  //     if(newvalue && newvalue.description)
  //       this.obj_comment = this.commentEditorObj.description
  //   },
  //   obj_comment(newvalue){
  //       this.$emit('CommentChange',newvalue);
  //   },
  //   in_focus(newvalue){
  //     this.in_focus = true
  //   }
  // }
  //THis watch solves text overlaping when its disabled that is it shows Description on top of text box value
  disabled:{
      handler(newValue){
        var _this = this
        setTimeout(function () {
          _this.no_toolbarbox_readonly = newValue
            }, 1000);
      }
    },
  vueEditorObj(newvalue,oldvalue){
      this.obj_description = ''
      debugger;
      if(newvalue && newvalue.description)
        this.obj_description = this.vueEditorObj.description
    },
    obj_description(newvalue){
        this.$emit('DescriptionChange',newvalue);
    }
  }
};

</script>
<style scoped>
  .ql-editor{
      min-height: 150px !important;
    }
    
#editor2{
      min-height: 225px !important;
      max-height: 225px !important;
    }
</style>