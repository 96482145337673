<template>
  <div
    id="app"
    style="background:white"
  >
    <vue-editor id="editor1" ref="editor1"
      v-model="obj_description"
      placeholder="Description"
      @focus="onFocus"
      @blur="onBlur"
      :editor-toolbar="customToolbar"
    />
  </div>
</template>

 <script>
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor
  },
  props:['vueEditorObj'],

  data() {
    return {
      obj_description: "",
      customToolbar:  [
                [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
                // [{ 'size': ['small', false, 'large', 'huge'] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
                // [{ 'header': 1 }, { 'header': 2 }],
                ['blockquote'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
                [{ 'script': 'sub'}, { 'script': 'super' }],
                [{ 'indent': '-1'}, { 'indent': '+1' }],
                [{ 'color': [] }, { 'background': [] }],
                ['link', 'image'],
                [{ 'direction': 'rtl' }],
                // ['clean'],
              ]
    };
  },
  methods: {
   onBlur(){
     debugger
        this.$emit('onBlur');
    },
   onFocus(){
     debugger
     document.getElementById("editor1").blur()
   }
  },
  mounted() {
    this.obj_description = this.vueEditorObj.description
    this.vueEditorObj = {}
    document.getElementById("editor1").click()
    this.vueEditorObj['description'] = _.cloneDeep(this.obj_description)
    // this.$refs.editor1.quill.focus();
  },
  watch: {
    vueEditorObj(newvalue,oldvalue){
      this.obj_description = ''
      var _this =this
      // setTimeout(function () {
      //           _this.$refs.editor1.quill.focus();
      //       }, 500);
      // debugger;
      if(newvalue && newvalue.description)
        this.obj_description = this.vueEditorObj.description
    },
    obj_description(newvalue){
        this.$emit('DescriptionChange',newvalue);
    }
  }
};
</script>