<template>
    <div>
        <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow">
                    <v-col cols="4" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li>Queue List</li>
                        </ul>
                    </v-col>
                    <v-col cols="8"></v-col>
                </v-row>
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>

        <edc-data-grid :dataList="tableList" @onNew="addQueue" @onEdit="onEdit" @onDelete="onDelete" @ondblClick="onEdit"></edc-data-grid>

        <loading-panel :loader="loader"></loading-panel>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    </div>
</template>

<script>
import moment from 'moment'
import config from './../../config.json'
import {
    get as getToServer, post as postToServer
} from '../../methods/serverCall.js';
import {
    SERVER_ERROR
} from '../../data/client_message.js'
import {
    CLIENT_SIDE,
    SERVER_SIDE
} from '../../data/macros.js'
import _ from 'lodash'
// import dummyData from './tableData.js'
// import data from './metaData.js'
import {
    TAG_SERVICE, QUEUE_MANAGEMENT_SERVICE,
    CREATE_ACTION, UPDATE_ACTION, DELETE_ACTION, CREATE_QUEUE, READ_QUEUE, DELETE_QUEUE, UPDATE_QUEUE, MAP_QUEUE_AND_TAG, MAP_QUEUE_AND_USER_GROUP, REMOVE_QUEUE_AND_TAG_MAPPING, REMOVE_QUEUE_AND_USER_GROUP_MAPPING
} from '../../data/macros.js'
import {GET_ALL_QUEUES, DELETE_QUEUE_URL} from '../../data/url_constants.js'
import {getUserRole, getTicketRole} from './../../methods/GetUserRole.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import {getActionByGroup} from './../../methods/TicketActionsAndQueueList.js'

export default {
    name: 'QueueList',
    
    data: function () {
        return {
            userRole:{},
            loader: false,
            snackbar: false,
            snackbartext: '',
            colorValue: 'success',
            snackbartimeout: Snackbar_Default_Timeout,
            actions_by_group:[],
            tableList: {
                headers: [{
                        text: 'Queue Name',
                        value: 'queue_name',
                        width: "20%",
                        title: 'Ticket Queue Name'
                    },
                    {
                        text: 'Tags',
                        value: 'tag_name',
                        width: "20%",
                        title: 'Mapped Ticket Tags'
                    },
                    {
                        text: 'User Groups',
                        value: 'group_name',
                        width: "20%",
                        title: 'Mapped Ticket Groups'
                    },
                    {
                        text: 'Created By',
                        value: 'created_by',
                        width: "20%",
                        title: 'Queue Created By'
                    },
                    {
                        text: 'Created Date',
                        value: 'tz_created_date',
                        width: "20%",
                        title: 'Queue Created Date',
                        dataType:'date'
                    },
                    {
                        text: 'Created Time',
                        value: 'tz_created_time',
                        width: "20%",
                        title: 'Queue Created Time',
                        dataType:'time',
                        hideInlineFilter: true
                    }
                    // ,
                    // {
                    //     text: 'Users',
                    //     value: 'manage_users',
                    //     width: "10%",
                    //     title: 'Double click to Add/Remove users',
                    // },
                ],
                actions:[],
                sorting_type: CLIENT_SIDE,
                filterType: CLIENT_SIDE,
                paginationType: CLIENT_SIDE,
                total_count: 0,
                rows: [],
                selected_rows: [],
                hideExport:true,
                hideShowColumns:true,
                hideFilter:true,
                syncHeaderScroll:false,
            },
            valid: true,
        }
    },
    mounted() {
        this.userRole = this.$session.get('user_role_mapping');
        this.getQueueList();
        getActionByGroup(this) 
        this.tableList.actions = [{
                            'text': 'new',
                            'key': "id",
                            selectType:"single",
                            role:this.userRole.is_superadmin || getTicketRole(this.actions_by_group,QUEUE_MANAGEMENT_SERVICE,CREATE_QUEUE),
                            index:1,
                        },{
                            'text': 'edit',
                            'key': "id",
                            selectType:"single",
                            role:this.userRole.is_superadmin || getUserRole(this.userRole,TAG_SERVICE, READ_QUEUE),
                            index:2
                        },
                        {
                            'text': 'delete',
                            'key': "id",
                            selectType:"single",
                            role:this.userRole.is_superadmin || getUserRole(this.userRole,TAG_SERVICE, DELETE_QUEUE),
                            index:3
                        }
                ]//if we need conditional action in row then provide key
    },
    methods: {
        addQueue(){
            let _this = this;
            _this.$router.push('/ticketqueuemanagement');
        
        },
        onDelete:function(record){
            let _this = this;
            _this.deleteQueue(record.id);
        },
        onEdit:function(record){
            let _this = this;   
            this.$router.push({ name: 'ticketQueueManagement', params: { queue_id: record.id, type:'edit' }})
        },
        getQueueList() {
            let _this = this;
            _this.loader = true;
            _this.tableList.rows = []
            var request_data = {
                'client_id': this.$session.get('client_id'),
                'product_name': window.sessionStorage.getItem('product')}
            postToServer(_this, config.EDC_QUEUE_MANAGEMENT_URL + GET_ALL_QUEUES, request_data).then(response => {
                _this.loader = false;
                if (response) {
                    _this.tableList.rows = response;
                } else {
                    _this.tableList.rows = [];
                }
                response.map(function(obj){
                    obj.manage_users = 'Manage Users'
                })
            }).catch(error_response => {
                _this.loader = false;
                 _this.tableList.rows = [];
                if (error_response) {
                    this.loader = null
                    /* this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = error_response; */
                } else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                }
            });
        },
        deleteQueue(id){
            let _this = this;
            var deleteData = {"queue_id": id, 'client_id': this.$session.get('client_id')}
            var url = config.EDC_QUEUE_MANAGEMENT_URL + DELETE_QUEUE_URL
            postToServer(_this, url, deleteData).then(response => {
                _this.getQueueList()
          }, response => {
            this.loader = null
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = response;
          }).catch(e => {
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = SERVER_ERROR;
          })
        }
    }
}
</script>
<style scoped>

</style>