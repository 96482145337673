export const CLIENT_SIDE = 'client_side';
export const SERVER_SIDE = 'server_side';
export const PER_PAGE = 10;
export const PER_PAGE_ARRAY = [10,20, 50, 'all'];
export const COLOR_CODE = '#323232';
export const BTN_COLOR = 'indigo';
export const CHECKBOX_COLOR='black';

// service id
export const DATASOURCE_SERVICE_ID = 'datasource'
export const ENVIRONMENT_SERVICE_ID = 'environment_setting_service'
export const PUBLISH_SERVICE_ID = 'publisher_service'
export const REPOSITORY_SERVICE_ID = 'repository_service'
export const USER_PROVISIONING_SERVICE_ID = 'user_provisioning'
export const POLICY_SERVICE = 'policy_service'
export const SCHEDULER_SERVICE = 'scheduler'
export const ACCOUNT_MANAGEMENT_SERVICE = 'account_management'
export const SECURITY_SERVICE = 'security_role_service'
export const JOB_MANAGEMENT_SERVICE = 'engine_service'
export const JOB_CALENDAR_SERVICE = 'job_calendar'
export const JOB_PLAN_SERVICE_ID = 'job_plan_service'
export const TAG_SERVICE = 'tag_service'
export const TICKET_MANAGEMENT_SERVICE = 'ticket_management_service'
export const QUEUE_MANAGEMENT_SERVICE = 'queue_management'

//actions
export const CREATE_ACTION = 'create'
export const UPDATE_ACTION = 'update'
export const DELETE_ACTION = 'delete'
export const READ_ACTION = 'read'
export const PUBLISH_ACTION = 'publish'
export const UNPUBLISH_ACTION = 'unpublish'
export const SCHEDULE_ACTION = 'schedule'
export const EXECUTE_ACTION ='execute'

//Actions for Ticket System
export const CREATE_TICKET = 'create_ticket'
export const CHANGE_DESCRIPTION = 'change_description'
export const CREATE_TICKET_ACTION = 'create_action'
export const READ_TICKET_ACTION = 'read_action'
export const CREATE_ATTACHMENT = 'create_attachment'
export const CREATE_COMMENT = 'create_comment'
export const DELETE_ATTACHMENT = 'delete_attachment'
export const DELETE_TICKET = 'delete_ticket'
export const READ_ATTACHMENT = 'read_attachment'
export const READ_COMMENT = 'read_comment'
export const READ_TICKET = 'read_ticket'
export const UPDATE_TICKET_ACTION = 'update_action'
export const DELETE_TICKET_ACTION = 'delete_action'
export const UPDATE_CONFIGURATION = 'update_configuration'
export const READ_CONFIGURATION = 'read_configuration'
export const READ_ID_CONFIGURATION = 'read_id_configuration'
export const UPDATE_ID_CONFIGURATION = 'update_id_configuration'
export const READ_PRIORITY = 'read_priority'
export const DELETE_PRIORITY = 'delete_priority'
export const UPDATE_PRIORITY = 'update_priority'
export const CHANGE_TICKET_STATUS = 'change_status'
export const CHANGE_TICKET_QUEUE = 'assigne_queue'
export const CHANGE_TICKET_ASSIGNEE = 'change_assignee'

//Actions for Queue Service
export const CREATE_QUEUE = 'create'
export const READ_QUEUE = 'read'
export const DELETE_QUEUE = 'delete'
export const UPDATE_QUEUE = 'update'
export const MAP_QUEUE_AND_TAG = 'map_queue_and_tag'
export const MAP_QUEUE_AND_USER_GROUP = '"map_queue_and_user_group'
export const REMOVE_QUEUE_AND_TAG_MAPPING = 'remove_queue_and_tag_mapping'
export const REMOVE_QUEUE_AND_USER_GROUP_MAPPING = 'remove_queue_and_user_group_mapping'

//Actions for Security Service
export const CREATE_ROLE = 'create'
export const READ_ROLE = 'read'
export const DELETE_ROLE = 'delete'
export const UPDATE_ROLE = 'update'

export const EDC_GRID_PROPS = {
      "filter": [],
      "filter_data":[],
      "sort": [],
      "page": 1,
      "page_size": 10
    }