<template>
	<v-container class="EDC-Row">
		<v-row class="EDC-Row">
			<v-col class="EDC-Col" cols="12">
				<v-select :hide-details="hideDetails" :dense="propDense" :items="itemList" 
				:item-text="itemText" :item-value="itemValue" :return-object="returnObject" :label="labelText" :multiple="selectMultiple" :chips="showAsChips" @input="emitInputChange($event)" v-model="selectModel"></v-select>
			</v-col>
		</v-row>
	</v-container>
</template>
<style scoped>

</style>
<script>
	export default {
		name:'EdcAutoComplete',
		props:{
			hideDetails:{
				type:Boolean,
				default:true
			},
			propDense:{
				type:Boolean,
				default:true
			},
			itemList:{
				type:Array,
				default:()=>{
					return []
				}
			},
			itemText:{
				type:String,
				default:'text'
			},
			itemValue:{
				type:String,
				default:'value'
			},
			returnObject:{
				type:Boolean,
				default:false
			},
			labelText:{
				type:String,
				default:'Select'
			},
			vModel:{

			},
			selectMultiple:{
				type:Boolean,
				default:false
			},
			showAsChips:{
				type:Boolean,
				default:false
			}
		},
		data(){
			return{
				selectModel:'',
			}
		},
		watch:{
			itemList:{
				handler(newvalue){

				}
			},
			vModel:{
				handler(newvalue){
					this.selectModel = _.cloneDeep(this.vModel)
				}
			}
		},
		mounted(){
			this.selectModel = _.cloneDeep(this.selectValue)
		},
		methods:{
			emitInputChange(event){
				this.$emit('input',event)
			}
		}
	}
</script>