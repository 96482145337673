/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ticket_add': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" style="-inkscape-stroke:none" d="M3.736.217a.528.528 0 00-.374.152L.369 3.362a.534.534 0 000 .748l.701.703.188-.187a.326.326 0 01.466 0 .326.326 0 010 .466l-.187.188.703.701a.534.534 0 00.748 0l2.993-2.993a.536.536 0 000-.75l-.702-.7-.187.187a.326.326 0 01-.467 0 .326.326 0 010-.467l.188-.187L4.11.369a.53.53 0 00-.375-.152zm0 .526l.405.406c-.147.318-.152.69.109.951.26.26.633.256.951.11l.406.404-2.994 2.994-.404-.406c.147-.318.151-.691-.11-.952-.26-.26-.633-.256-.95-.11l-.407-.403L3.736.743zm-.374 1.496l-.186.187.748.748.187-.186-.75-.75zm-.842.843l-.186.186.748.748.186-.186-.748-.748z" color="#000"/>'
  }
})
