<template>
  <div>
      <loading-panel :loader="loader"></loading-panel>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>Workflow Workspace Mapping</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <edc-data-grid :dataList="tableList" @onNew="onNew" @onDelete="onDelete" @onSave="onSave" @onCancel="onCancel"  @onValueUpdate="onValueUpdate"></edc-data-grid>
    
    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>
<script>
import { post as postToServer } from '../../methods/serverCall.js';
import { get as getToServer } from '../../methods/serverCall.js';
import config from '../../config.json'
import { SERVER_ERROR } from '../../data/client_message.js'
import { GET_ALL_QUEUES, GET_ALL_WORKFLOW_OBJECTS, GET_USER_ENV_ROLE_MAPPING, GET_ALL_ROLES, GET_ENVIRONMENTAL_ROLES, MANAGE_USER_ROLE_MAPPING, DELETE_USER_ROLE_MAPPING, GET_ROLES_BY_TYPE, MANAGE_ENV_USER_ROLE_MAPPING, ADD_ENV_USER_ROLE_MAPPING } from '../../data/url_constants.js'
import {CLIENT_SIDE, SERVER_SIDE} from '../../data/macros.js'
import {SECURITY_SERVICE,CREATE_ACTION,READ_ACTION} from "../../data/macros.js"
import _ from 'lodash'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import {mapActions} from 'vuex'
export default{
    data(){
        return{
            snackbar:false,
            snackbartext:'',
            colorValue:'error',
            loader: false,
            snackbartimeout: Snackbar_Default_Timeout,
            loading: false,
            workspaceList:[],
            workflowList:[],
            mappedWorkflowList:[],
            envIpJson: {
                "filter": [],
                "sort": [{
                    "column_name": "",
                    "type": ""
                }],
                "page": 1,
                "page_size": 5
            },
            tableList:{
                headers: [
                {text: 'Work Flow', value: 'workflow_name',title: 'Workflow Name', option:[], 'item_text':'workflow_name', 'item_value':'workflow_name', useAutoComplete:true},
                { text: 'Work Space', value: 'workspace_name', title: 'Workspace Name', option:[], 'item_text':'name', 'item_value':'workspace_name', useAutoComplete:true},
                
                ], 
                actions: [],//if we need conditional action in row then provide key
                sorting_type: CLIENT_SIDE,
                filterType: CLIENT_SIDE,
                paginationType: CLIENT_SIDE,
                total_count: 0,
                is_row_editable:true,
                rows: [],
                selected_rows: [],
                syncHeaderScroll:false,
                disableDefaltSorting:true,
                hideExport:true,
                hideShowColumns:true,
                hideFilter:true,
                itemkey:'id',
                is_grid_inline_edit:true
            },
        }
    },

    mounted(){
        this.tableList.actions = [
      {'text':'edit','key':"id", selectType:"single", role:true, index:1},
      {'text':'new','key':"id", 'enabled':this.Environment, role:true, index:2},    
      {'text':"save","key":"is_row_editable",selectType:"single", index:3},
      {'text':"cancel","key":"is_row_editable",selectType:"single", index:4},
      {'text':'delete','key':"id", selectType:"single", role:true, index:5}];
    if(this.tableList.paginationType == CLIENT_SIDE)
      this.envIpJson.page_size="*";
    
    this.GetWorkflowList();
    this.GetWorkspaceList();
    this.GetWorkspaceMappingList();
    },

    methods: {
        
        GetWorkflowList() {
            this.tableList.rows = []
			var user_id = this.$session.get('user_id')
            var client_id = this.$session.get('client_id')
			var get_workflow_list_url = config.IDE_API_URL + GET_ALL_WORKFLOW_OBJECTS
            var data = {"client_id": client_id, "object_type_code": "WF", 'product_name': window.sessionStorage.getItem('product')}
            postToServer(this, get_workflow_list_url, data).then(Response => {
                if(Response){
                this.workflowList = Response;
                this.tableList.headers[0].option = Response;
                }
            });
        },

        GetWorkspaceList() {
            let _this = this;
            _this.loader = true;
            _this.tableList.rows = []
            var request_data = {
                'client_id': this.$session.get('client_id'),
                'product_name': window.sessionStorage.getItem('product')}
            postToServer(_this, config.WORKSPACE_URL + "/get_all_workspaces", request_data).then(response => {
                _this.loader = false;
                if (response) {
                    _this.workspaceList = response;
                    _this.tableList.headers[1].option = response;
                } else {
                    _this.tableList.rows = [];
                }
               
            }).catch(error_response => {
                _this.loader = false;
                 _this.tableList.rows = [];
                if (error_response) {
                    this.loader = null
                    /* this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = error_response; */
                } else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                }
            });
        },

        GetWorkspaceMappingList(){
            var _this = this
            _this.loader = true
            var data_to_send = {"client_id": _this.$session.get('client_id'),
                                "product_name": window.sessionStorage.getItem('product')}
            postToServer(_this, config.WORKFLOW_PRODUCT_URL+"/get_all_wf_ws_mapping", data_to_send).then(response =>{
                if(response){
                    _this.loader = false
                    this.tableList.rows = response;
                    this.mappedWorkflowList = response;
                    this.mappedWorkflowList.map(function(obj){
                        obj.isselected = false
                    })
                }
                else{
                    _this.loader = false
                }
            }).catch(error_response => {
                _this.tableList.rows = []
            });
        },

        onNew() {
            let obj = _.find(this.tableList.rows,["is_row_editable",true])
            if(obj)
            {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = 'First fiilup the open row';
                return
            }
            this.tableList.rows.unshift({
                'workflow_name':'',                
                'workspace_name':'',
                'is_row_editable': true,
            });
        },

        onCancel() {
            this.GetWorkspaceMappingList();
        },

        onSave(record) {
            if(!record.workspace_name || !record.workflow_name){
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = 'All fields are required';
                return false
            }
            record.id ? this.updateMapping(record) : this.saveMapping(record);            
        },

        saveMapping(record) {
            let workspace_object = _.find(this.workspaceList,['name',record.workspace_name])
            let workflow_object = _.find(this.workflowList,['workflow_name',record.workflow_name])
            let userData = {
                'client_id':this.$session.get('client_id'),
                'workspace_id':workspace_object.id,
                'workspace_name':workspace_object.name,
                'workflow_id':workflow_object.object_id,
                'workflow_name':workflow_object.workflow_name,
            }
            var url = config.WORKFLOW_PRODUCT_URL + '/add_wf_ws_mapping'
            postToServer(this, url, userData).then(Response => {
                this.snackbar = true
                this.colorValue = 'success'
                this.snackbartext = "Mapping added successfully";
                this.GetWorkspaceMappingList();
            }).catch(error_response => {
                if (error_response) {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
                }else{
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
                }
            });
        },

        updateMapping(record) {  
            let workspace_object = _.find(this.workspaceList,['name',record.workspace_name])
            let workflow_object = _.find(this.workflowList,['workflow_name',record.workflow_name])
            var userData = {
                'id': record.id,
                'client_id':this.$session.get('client_id'),
                'workspace_id':workspace_object.id,
                'workspace_name':workspace_object.name,
                'workflow_id':workflow_object.object_id,
                'workflow_name':workflow_object.workflow_name,
            }
            var url = config.WORKFLOW_PRODUCT_URL + '/update_wf_ws_mapping'
            postToServer(this, url, userData).then(Response => {
                this.snackbar = true
                this.colorValue = 'success'
                this.snackbartext = "Mapping updated successfully";
                this.GetWorkspaceMappingList();
            }).catch(error_response => {
                if (error_response) {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
                }else{
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
                }
            });
        },

        onDelete(record) {
            var _this = this
            let inputJson = {
                'client_id':this.$session.get('client_id'),
                'id': record.id
            }
            let url = config.WORKFLOW_PRODUCT_URL + '/delete_wf_ws_mapping'            
            postToServer(this, url, inputJson).then(response  => {
                _this.GetWorkspaceMappingList();
                this.loader = false
                this.snackbar = true
                this.colorValue = 'success'
                this.snackbartext = 'Workspace mapping deleted successfully';
            }).catch(error_response => {
                this.GetWorkspaceMappingList();
                if(error_response){
                this.loader = false
                this.snackbar = true;
                this.colorValue = 'error';
                this.snackbartext = error_response;
                } else {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = SERVER_ERROR;
                }
            });               
        },
    }
}
</script>