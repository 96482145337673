/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'delete_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M2.646.264v.53H.53v.529H5.82v-.53H3.705V.264H2.646zM1.06 1.852v3.705a.265.265 0 00.263.264h3.706a.265.265 0 00.263-.264V1.852h-.53v3.44H1.588v-3.44h-.53zm1.058.53v2.38h.53v-2.38h-.53zm1.588 0v2.38h.527v-2.38h-.527z" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
