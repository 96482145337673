/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Job_Execute': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<g _stroke="#000"><circle pid="0" cx="32.01" cy="31.949" r="24.712" _fill="none" stroke-width="2"/><path pid="1" d="M41 32l-7.222 4.17-7.223 4.17V23.66l7.223 4.17z" stroke-width="9.556" stroke-linejoin="round"/></g>'
  }
})
