<template>
	<v-container class="EDC-Row">
		<v-row class="EDC-Row">
			<v-col class="EDC-Col" cols="12">
				<!-- TO DO: We have to written this as a for loop-->
				<v-checkbox v-if="returnType === 'custom'" class="customCheckbox" :hide-details="hideDetails" :dense="propDense" v-model="checkModel" :label="labelText" :titleText="titleText"  :true-value="returnOnTrue" :false-value="returnOnFalse" @change="selectionChange"></v-checkbox>
				<v-checkbox v-else class="customCheckbox" :hide-details="hideDetails" :dense="propDense" v-model="checkModel" :value="checkValue" :label="labelText"  :titleText="titleText" @change="selectionChange"></v-checkbox>
			</v-col>
		</v-row>
	</v-container>
</template>
<style scoped>
	div[aria-required=true].v-input .v-label::after {
	  content: " *";
	  color: red;
	}
	.customCheckbox >>> label{
    	top: 4px !important;
	}
</style>
<script>
	export default {
		name:'EdcCheckbox',
		props:{
			returnType:{
				type:String,
				default:"text"
			},
			autoComplete:{
				type:String,
				default:"off"
			},
			hideDetails:{
				type:Boolean,
				default:false
			},
			propDense:{
				type:Boolean,
				default:true
			},
			vModel:{
				type:String,
				default:'',
			},
			returnOnTrue:{

			},
			returnOnFalse:{

			},
			labelText:{
				type:String,
				default:'Text'
			},
			fieldUniqueId:{
				type:String,
				default:''
			},
			isRequired:{
				type:Boolean,
				default:true
			},
			formValidate:{
				type:Boolean,
				default:true
			},
			titleText:{
				type:String,
				default:''
			},
			checkModel:{

			},
			checkValue:{

			},
		},
		data(){
			return{

			}
		},
		watch:{
			vModel:{
				handler(newvalue){
					// avoid infinite looping
					if(newvalue!=this.checkModel)
						this.checkModel = newvalue
				}
			},
			returnType:{

			},
			returnOnTrue:{

			},
			returnOnFalse:{

			}
		},
		mounted(){
			this.checkModel = this.vModel
		},
		methods:{
			selectionChange(event){
				this.$emit('change',event,this.fieldUniqueId)
			}
		}
	}
</script>
