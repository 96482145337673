<template>
  <div id="adduser">
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="4" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>
                <router-link to="/WorkSpacesList">Workspace List</router-link>
              </li>
              <li>Manage Workspace</li>
            </ul>
          </v-col>
          <v-col cols="8"></v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <v-container>
      <v-form v-model="valid" ref="create_workspace_form">
        <v-card elevation-1> 
          <v-container class="EDC-Container">
            <v-row no-gutters align="center" class="EDC-Row" style="padding-top:10px !important">
              <v-col cols="12">
                <v-row no-gutters align="center" class="EDC-Row EDC-RowsSpacing">
                  <v-col cols="6" class="EDC-Col EDC-ColsSpacing">
                    <v-text-field v-model="workspace_name" label="Workspace Name" :rules="workspaceRules" required dense></v-text-field>
                  </v-col>
                  <v-col cols="6" class="EDC-Col EDC-ColsSpacing">
                    <v-text-field v-model="description" label="Description" :rules="descriptionRules" required dense></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row no-gutters class="EDC-Row EDC-RowsSpacing" justify="end">
              <vc-button itemText="Save" @click.native="submit"></vc-button>
              <!-- <v-btn outline @click="submit" :color="outlineColor" :loading="loading" :disabled="loading" @click.native="loader='loading'" style="border-radius:10px">Save</v-btn> -->
              <vc-button itemText="Cancel" @click.native="onCancel"></vc-button>
              <!-- <v-btn outline @click="onCancel" :color="outlineColor" style="margin-right: 0px;border-radius:10px">Cancel</v-btn> -->
            </v-row>

          </v-container>
        </v-card>
      </v-form>
    </v-container>
    <loading-panel :loader="loader"></loading-panel>
    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
    
  </div>
</template>

<style scoped>
.v-text-field.v-text-field--solo .v-input__control{
  min-height: 20px;
}
#adduser .v-input--checkbox, label {
  margin-top:2%;
}
.EDC-UIComponent{
    margin: 0px !important;
    padding: 0px !important;
}
.customCheckbox >>> label{
  top: 5px !important;
}
</style>

<script>
import config from "../../config.json"
import Breadcrumb from "../Breadcrumbs.vue"
import { post as postToServer } from './../../methods/serverCall.js';
import {SERVER_ERROR} from '../../data/client_message.js';
import { ADD_USER, GET_USER, MANAGE_WORKSPACE, UPDATE_USER,GET_DATASOURCE_LIST, UPDATE_WORKSPACE_DATA, GET_WORKSPACE_DATA, GET_ALL_SMTP_SERVER, GET_ALL_DATABASE_SERVER,GET_ALL_APPLICATION_SERVER, GET_ALL_CLOUD_SERVER} from '../../data/url_constants.js'
import {COLOR_CODE, BTN_COLOR} from '@/data/macros.js'
import {
  DATE_TIME_FORMAT
} from '@/data/manage_account_constants.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
    
export default {
  data () {
      return {
        date_formatitems: DATE_TIME_FORMAT,
        outlineColor:BTN_COLOR,
        colorCode:COLOR_CODE,
        valid: false,
        snackbar:false,
        snackbartext:'',
        colorValue:'error',
        snackbartimeout: Snackbar_Default_Timeout,
        loader: null,
        loading: false,
        show1: false,
        show2: false,
        workspace_name:'',
        description:'',
        changedResourceType:"database",
        resourceTypes:['application','cloud','smtp','database','cloud'],
        resourceNameList:[],
        showEdit:[],
        resourceList:[],
        resource_list:[],
        currentValue:[],
        selectedResourceType:'',
        selectedResource:'',
        // valid: true,
        first_name: "",
        last_name: "",
        email: "",
        pincode: "",
        addressLin1: "",
        addressLin2:"",
        date_format: "",
        show_enable_button:true,
        show_password_control:true,
        adressRules:[
          v => !!v || 'Address is required'
        ],
        workspaceRules:[
          v => !!v || 'Workspace Name is required'
        ],
        descriptionRules:[
          v => !!v || 'Description is required'
        ],
        is_enabled: true,
        verification_status: true,
        resourceRules: [
          v => !!v || 'Resource Name is required'
        ],
        pwdRules: [
          v => !!v || 'Password is required',
          v => v.length >= 8 || 'Min 8 characters',
        ],
        confirmPwdRules: [
          v => !!v || 'Confirm Password is required',
          (v) => v == this.password || 'Password and Confirm Password must match'
        ],
        dateFormatRules: [v => !!v || 'Date Format is required.'],
        // date_format: '',
        logintype:false,
        ldap_login_name:'',
        dnloading:false,
      }
      
    },
    mounted () {
       this.GetDataSource()
    },
    methods: {
      onCancel(){
      this.$router.push("/WorkSpacesList")
      },
      submit () {
        if (this.$refs.create_workspace_form.validate()) {
        if(this.$route.params.workspaceid && this.$route.params.type == 'edit'){
              this.updateWorkspace()
        } else {
          this.addWorkspace()
        }
        }
        // this.addWorkspace()
      },
      GetDataSource(){
      if(this.$route.params.workspaceid){
        var data ={
          id: this.$route.params.workspaceid
        }
        this.loader = true;
        postToServer(this, config.WORKSPACE_URL + "/get_workspace_by_id", data).then(response => {
                var data = response
                let _this =this
                if(_this.$route.params.workspaceid)
                  {
                     _this.workspace_name=_this.$route.params.type == 'copy' ? 'Copy_of_'+data[0].name :data[0].name
                     _this.description=data[0].description
                    
            
                  }  
                _this.loader = false;
           }).catch(error_response => {
            if(error_response){
              _this.snackbar = true
              _this.colorValue = 'error'
              _this.snackbartext = error_response;
              _this.loader = false;
            }
            else {
              _this.snackbar = true
              _this.colorValue = 'error'
              _this.snackbartext = SERVER_ERROR;
              _this.loader = false;
            }
        })
      }
      },
      updateWorkspace(){
          let _this = this; 
          _this.loader=true;
        let form_data={
          client_id:this.$session.get('client_id'),
          name:this.workspace_name,
          description:this.description,
          id:this.$route.params.workspaceid
        }
        postToServer(this, config.WORKSPACE_URL + MANAGE_WORKSPACE, form_data).then(workspaceResponse  => {
                this.dnloading = false
                _this.loader=false;
                _this.snackbar = true
                _this.colorValue = 'success'
                _this.$router.push("/WorkSpacesList")
                 _this.snackbartext = workspaceResponse;
                
            }).catch(error_response => {
               _this.loader=false;
              _this.dnloading = false
              _this.snackbar = true
              _this.colorValue = 'error'
              _this.snackbartext = error_response
      });
      },
        addWorkspace(){
          let _this = this;
        let form_data={
          client_id:this.$session.get('client_id'),
          name:this.workspace_name,
          description:this.description
        } 
        _this.loader=true;
        postToServer(this, config.WORKSPACE_URL + MANAGE_WORKSPACE, form_data).then(workspaceResponse  => {
                this.dnloading = false
                 if(_this.$session.get('workspace_list'))
	                {
                  _this.WorkspaceList = _this.$session.get('workspace_list')
                   _this.WorkspaceList.push(form_data)
                   _this.$session.set('workspace_list', _this.WorkspaceList)
                  }
                _this.loader=false;
                _this.snackbar = true
                _this.colorValue = 'success'
                  _this.snackbartext = workspaceResponse;
                 _this.$router.push("/WorkSpacesList")
            }).catch(error_response => {
               _this.loader=false;
              _this.dnloading = false
              _this.snackbar = true
              _this.colorValue = 'error'
              _this.snackbartext = error_response
      });
      },
    }
  }
</script>