/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'New': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M32 5c-2.216 0-4 1.784-4 4v19H9c-2.216 0-4 1.784-4 4s1.784 4 4 4h19v19c0 2.216 1.784 4 4 4s4-1.784 4-4V36h19c2.216 0 4-1.784 4-4s-1.784-4-4-4H36V9c0-2.216-1.784-4-4-4z"/>'
  }
})
