export function manageScreenDisplay(){
	var window_height = window.innerHeight
	var gridpage_size = 5
	if(window_height < 500)
		gridpage_size = 5
  else if(window_height <600){
      gridpage_size = 10
  }
  else if(window_height <700){
      gridpage_size = 15
  }

  else if(window_height <800){
      gridpage_size = 20
  }
  else {
  	gridpage_size = 25
  }
	// this.$session.set('gridpagesize',gridpage_size)
  // that.envIpJson.page_size = gridpage_size
	return 10
}

export function manageSearchDisplay(){
	var window_height = window.innerHeight
	var gridpage_size = 5
	if(window_height <700){
      gridpage_size = 5
  }
  else if(window_height <800){
      gridpage_size = 10
  }
  else if(window_height <900){
      gridpage_size = 15
  }
  else {
  	gridpage_size = 20
  }
	// this.$session.set('gridpagesize',gridpage_size)
  // that.envIpJson.page_size = gridpage_size
	return gridpage_size
}