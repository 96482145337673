/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'archive_dispose_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<g color="#000" font-weight="400" font-family="sans-serif"><path pid="0" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M.53.53v1.588h5.29V.53zm.529.529H5.29v.53H1.06zM.794 2.38l.022 2.973c.003.29.225.558.53.558h1.829v-.529h-1.83c.012 0 0 .006 0-.03l-.022-2.972zM4.277 2.91v.264h-.792v.53h2.38v-.53H5.07V2.91h-.793zm-.572 1.102v1.545a.265.265 0 00.264.264h1.41a.265.265 0 00.266-.264V4.012h-.53v1.28h-.883v-1.28h-.527zm.838.09v.926h.263v-.926h-.263z" overflow="visible"/></g>'
  }
})
