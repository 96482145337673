/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Archive': {
    width: 80,
    height: 80,
    viewBox: '0 0 80 80',
    data: '<rect pid="0" width="16" height="4" x="32" y="35" rx="1.067" ry="1.6" _fill="#fff"/><g _fill="none" _stroke="#000"><path pid="1" d="M19 9c-3.799 0-6.857 4.08-6.857 9.145V25h56v-6.855c0-5.066-3.058-9.145-6.857-9.145zM64 31H16v25.904C16 60.28 18.621 63 21.878 63h36.244C61.38 63 64 60.28 64 56.904z" stroke-width="2"/><path pid="2" d="M34 37h12" stroke-width="4" stroke-linecap="round"/></g>'
  }
})
