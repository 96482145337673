<template>
  <div>
      <loading-panel :loader="loader"></loading-panel>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>Queue Workflow Mapping</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>
    <v-container>
        <v-card elevation-1>
            <v-row class="EDC-Row" no-gutters>
                <v-tabs v-model="activeTab" non-linear @change="tabChange">
                    <v-tab class="EDC-Tabs"   key="tab1">
                        <span >Ticket Management Mapping</span>
                    </v-tab>
                    <v-tab class="EDC-Tabs" key="tab2">
                        <span >Dataviewer Mapping</span>
                    </v-tab>
                    <v-tab-item>
                        <edc-data-grid :dataList="tableList" @onNew="onNew" @onDelete="onDelete" @onSave="onSave" @onCancel="onCancel"></edc-data-grid>
                    </v-tab-item>
                    <v-tab-item>
                        <edc-data-grid :dataList="dataviewerTableList" @onNew="onNew" @onDelete="onDelete" @onSave="onSave" @onEdit="onEdit" @onCancel="onCancel"  @onValueUpdate="onValueUpdate" @performTableSearch="performTableSearch"></edc-data-grid>
                    </v-tab-item>
                </v-tabs>
            </v-row>
        </v-card>
    </v-container>
    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>
<script>
import { post as postToServer } from '../../methods/serverCall.js';
import { get as getToServer } from '../../methods/serverCall.js';
import config from '../../config.json'
import { SERVER_ERROR } from '../../data/client_message.js'
import {
		getTableList,
		getTableColumns,
    getTablesByDVEnv,
	} from "../../methods/agent_api_call.js";
import { GET_ALL_QUEUES, GET_ALL_WORKFLOW_OBJECTS, GET_USER_ENV_ROLE_MAPPING, GET_ALL_ROLES, GET_ENVIRONMENTAL_ROLES, MANAGE_USER_ROLE_MAPPING, DELETE_USER_ROLE_MAPPING, GET_ROLES_BY_TYPE, MANAGE_ENV_USER_ROLE_MAPPING, ADD_ENV_USER_ROLE_MAPPING } from '../../data/url_constants.js'
import {CLIENT_SIDE, SERVER_SIDE} from '../../data/macros.js'
import {SECURITY_SERVICE,CREATE_ACTION,READ_ACTION} from "../../data/macros.js"
import _ from 'lodash'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import {mapActions} from 'vuex'
export default{
    data(){
        return{
            snackbar:false,
            activeTab: 0,
            snackbartext:'',
            colorValue:'error',
            loader: false,
            snackbartimeout: Snackbar_Default_Timeout,
            ticket_source:"Ticket",
            loading: false,
            queueList:[],
            source_code: '',
            source_type: '',
            workflowList:[],
            workspaceList:[],
            businessViewList:[],
            businessObjectList:[],
            gridViewList:[],
            tableListMapping:[],
            dvEnvList:[],
            dsList:[],
            mappedWorkflowList:[],
            tkt_mgmt_resources: [],
            dataviewer_resources: [],
            envIpJson: {
                "filter": [],
                "sort": [{
                    "column_name": "",
                    "type": ""
                }],
                "page": 1,
                "page_size": 5
            },
            tableList:{
                headers: [
                {text: 'Name', value: 'option_name', option:[],title: 'Name', 'item_text':'option_name', 'item_value':'option_name',useTextField:true},
                { text: 'Queue', value: 'queue_name', title: 'Queue Name', option:[], 'item_text':'queue_name', 'item_value':'queue_name', useAutoComplete:true},
                {text: 'Work Flow', value: 'workflow_name',title: 'Workflow Name', option:[], 'item_text':'workflow_name', 'item_value':'workflow_name', useAutoComplete:true},
                {text: 'Workspace', value: 'workspace_name',title: 'Workspace Name', option:[], 'item_text':'name', 'item_value':'workspace_name', useAutoComplete:true},
                
                ], 
                actions: [],//if we need conditional action in row then provide key
                sorting_type: CLIENT_SIDE,
                filterType: CLIENT_SIDE,
                paginationType: CLIENT_SIDE,
                total_count: 0,
                is_row_editable:true,
                rows: [],
                selected_rows: [],
                syncHeaderScroll:false,
                disableDefaltSorting:true,
                hideExport:true,
                hideShowColumns:true,
                hideFilter:true,
                itemkey:'id',
                is_grid_inline_edit:true
            },
            dataviewerTableList:{
                headers: [
                {text: 'Environment', value: 'env_name', option:[],title: 'Environment', 'item_text':'env_name', 'item_value':'env_name',useAutoComplete:true},
                {text: 'Object Type', value: 'source_name', option:[], title: 'Object Type', 'item_text':'source_name', 'item_value':'source_name', useAutoComplete:true, isRoleMapping:true},
                {text: 'Datasource', value: 'datasource_name', option:[], title: 'Datasource', 'item_text':'datasource_name', 'item_value':'datasource_name', useAutoComplete:true, isRoleMapping:true, disabledOnNoitem:true},
                {text: 'Object Name', value: 'object_name', option:[], title: 'Object Name', 'item_text':'object_name', 'item_value':'object_name', isTableLoader:true},
                { text: 'Queue', value: 'queue_name', title: 'Queue Name', option:[], 'item_text':'queue_name', 'item_value':'queue_name', useAutoComplete:true},
                {text: 'Work Flow', value: 'workflow_name',title: 'Workflow Name', option:[], 'item_text':'workflow_name', 'item_value':'workflow_name', useAutoComplete:true},
                {text: 'Workspace', value: 'workspace_name',title: 'Workspace Name', option:[], 'item_text':'name', 'item_value':'workspace_name', useAutoComplete:true},
                
                ], 
                actions: [],//if we need conditional action in row then provide key
                sorting_type: CLIENT_SIDE,
                filterType: CLIENT_SIDE,
                paginationType: CLIENT_SIDE,
                total_count: 0,
                is_row_editable:true,
                rows: [],
                selected_rows: [],
                syncHeaderScroll:false,
                disableDefaltSorting:true,
                hideExport:true,
                hideShowColumns:true,
                hideFilter:true,
                itemkey:'id',
                tableLoading:false,
                is_grid_inline_edit:true
            },
        }
    },

    mounted(){
        this.tableList.actions = [
      {'text':'edit','key':"id", selectType:"single", role:true, index:1},
      {'text':'new','key':"id", 'enabled':this.Environment, role:true, index:2},    
      {'text':"save","key":"is_row_editable",selectType:"single", index:3},
      {'text':"cancel","key":"is_row_editable",selectType:"single", index:4},
      {'text':'delete','key':"id", selectType:"single", role:true, index:5}];
    if(this.tableList.paginationType == CLIENT_SIDE)
      this.envIpJson.page_size="*";

        this.dataviewerTableList.actions = [
      {'text':'edit','key':"id", selectType:"single", role:true, index:1},
      {'text':'new','key':"id", 'enabled':this.Environment, role:true, index:2},    
      {'text':"save","key":"is_row_editable",selectType:"single", index:3},
      {'text':"cancel","key":"is_row_editable",selectType:"single", index:4},
      {'text':'delete','key':"id", selectType:"single", role:true, index:5}];
    if(this.dataviewerTableList.paginationType == CLIENT_SIDE)
      this.envIpJson.page_size="*";
    
    this.GetWorkflowList();
    this.GetQueueList();
    this.GetWorkspaceList();
    this.GetWorkflowMappingList();
    this.GetDataviewerEnv();
    },

    methods: {
        ...mapActions(['setworkflowQueueMappingChange']),
        GetDataviewerEnv(){
            let data_to_for_env = {"client_id": this.$session.get('client_id')}
            postToServer(this, config.ENVIRONMENT_API_URL + '/get_environment_data_for_dv' , data_to_for_env).then(envResponse=>{
                if(envResponse){
                    envResponse.result.unshift({"env_id": 0, "env_name": "Any"})
                    this.dataviewerTableList.headers[0].option = envResponse.result
                    this.dvEnvList = envResponse.result
                }
            }).catch(envResponse => {
                this.dataviewerTableList.headers[0].option = []
            });
        },
        GetDataviewerResources(){
            var _this = this
            var data_for_resource_type = {"source_type": "dataviewer"}
            postToServer(_this, config.EDC_TICKET_MANAGEMENT_SERVICE_URL+"/get_ticket_source", data_for_resource_type).then(response =>{
                if(response){
                    _this.dataviewer_resources = response
                    this.dataviewerTableList.headers[1].option = this.dataviewer_resources
                }
                else{
                    _this.dataviewer_resources = []
                    this.dataviewerTableList.headers[1].option = []
                }
            }).catch(error_response => {
                _this.dataviewer_resources = []
                this.dataviewerTableList.headers[1].option = []
            });
        },
        tabChange(){
            if(this.activeTab === 1)
                this.getDataviewerMapping()
            else
                this.GetWorkflowMappingList()
        },
        getDataviewerMapping(){
            var _this = this
            _this.loader = true
            this.GetDataviewerResources()
            var data_to_send = {"client_id": _this.$session.get('client_id'),
                                "product_name": window.sessionStorage.getItem('product'),
                                "source_type":"dataviewer"}
            postToServer(_this, config.EDC_QUEUE_MANAGEMENT_URL+"/get_all_queue_workflow_form_rule", data_to_send).then(response =>{
                if(response){
                    _this.loader = false
                    _.forEach(response,(element) => {
                        element.source_name=_.find(this.dataviewer_resources,['source_code',element.source_code]).source_name
                    })
                    this.dataviewerTableList.rows = response;
                    this.mappedWorkflowList = response;
                    this.mappedWorkflowList.map(function(obj){
                        obj.isselected = false
                    })
                }
                else{
                    _this.loader = false
                    this.dataviewerTableList.rows = []
                }
            }).catch(error_response => {
                 _this.loader = false
                this.dataviewerTableList.rows = []
            });
        },
        GetWorkflowList() {
			var user_id = this.$session.get('user_id')
            var client_id = this.$session.get('client_id')
			var get_workflow_list_url = config.IDE_API_URL + GET_ALL_WORKFLOW_OBJECTS
            var data = {"client_id": client_id, "object_type_code": "WF", 'product_name': window.sessionStorage.getItem('product')}
            postToServer(this, get_workflow_list_url, data).then(Response => {
                if(Response){
                // Response.push({"workflow_name": "None"})
                this.workflowList = Response;
                this.tableList.headers[2].option = Response;
                this.dataviewerTableList.headers[5].option = Response;
                }
            });
        },
        GetWorkspaceList() {
            let _this = this;
            _this.loader = true;
            _this.tableList.rows = []
            var request_data = {
                'client_id': this.$session.get('client_id'),
                'product_name': window.sessionStorage.getItem('product')}
            postToServer(_this, config.WORKSPACE_URL + "/get_all_workspaces", request_data).then(response => {
                _this.loader = false;
                if (response) {
                    // response.push({"name": "None"})
                    _this.workspaceList = response;
                    _this.tableList.headers[3].option = response;
                    _this.dataviewerTableList.headers[6].option = response;
                } else {
                    _this.tableList.rows = [];
                }
               
            }).catch(error_response => {
                _this.loader = false;
                 _this.tableList.rows = [];
                if (error_response) {
                    this.loader = null
                    /* this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = error_response; */
                } else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                }
            });
        },
        GetQueueList() {
            let _this = this;
            _this.loader = true;
            _this.tableList.rows = []
            var request_data = {
                'client_id': this.$session.get('client_id'),
                'product_name': window.sessionStorage.getItem('product')}
            postToServer(_this, config.EDC_QUEUE_MANAGEMENT_URL + GET_ALL_QUEUES, request_data).then(response => {
                _this.loader = false;
                if (response) {
                    _this.queueList = response;
                    _this.tableList.headers[1].option = response;
                    _this.dataviewerTableList.headers[4].option = response;
                } else {
                    _this.tableList.rows = [];
                }
               
            }).catch(error_response => {
                _this.loader = false;
                 _this.tableList.rows = [];
                if (error_response) {
                    this.loader = null
                    /* this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = error_response; */
                } else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                }
            });
        },

        GetWorkflowMappingList(){
            var _this = this
            _this.loader = true
            // _this.GetTicketMgmtResources()
            var data_to_send = {"client_id": _this.$session.get('client_id'),
                                "product_name": window.sessionStorage.getItem('product'),
                                "source_type":"ticket_management"}
            postToServer(_this, config.EDC_QUEUE_MANAGEMENT_URL+"/get_all_queue_workflow_form_rule", data_to_send).then(response =>{
                if(response){
                    _this.loader = false
                    var tkt_mgmt_sources = _this.tkt_mgmt_resources
                    var final_list_tkt_mgmt = []
                    this.tableList.rows = response;
                    this.mappedWorkflowList = final_list_tkt_mgmt;
                    this.mappedWorkflowList.map(function(obj){
                        obj.isselected = false
                    })
                }
                else{
                    _this.loader = false
                    this.tableList.rows = []
                }
            }).catch(error_response => {
                 _this.loader = false
                this.tableList.rows = []
            });
        },

        onNew() {
            if(this.activeTab === 0){
                let obj = _.find(this.tableList.rows,["is_row_editable",true])
                if(obj)
                {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = 'First fiilup the open row';
                    return
                }
                this.tableList.rows.unshift({
                    'option_name':'',             
                    'queue_name':'',
                    'is_row_editable': true,
                });
            }
            else{
                let obj_dv_tbl = _.find(this.dataviewerTableList.rows,["is_row_editable",true])
                if(obj_dv_tbl)
                {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = 'First fiilup the open row';
                    return
                }
                this.dataviewerTableList.rows.unshift({
                    'source_name': '',             
                    'queue_name':'',
                    'is_row_editable': true,
                });
            }   
        },

        onCancel() {
            if(this.activeTab === 0)
                this.GetWorkflowMappingList();
            else
                this.getDataviewerMapping();
        },

        onSave(record) {
            if(!record.queue_name){
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = 'All fields are required';
                return false
            }
            record.id ? this.updateMapping(record) : this.saveMapping(record);            
        },

        saveMapping(record) {
            let source_info = {}
            let userData = {}
            let queue_object = _.find(this.queueList,['queue_name',record.queue_name])
            var  workspace_object = _.find(this.workspaceList,['name',record.workspace_name])
            if(!workspace_object)
                workspace_object = {"name": '', "id": ''}
            var workflow_object = _.find(this.workflowList,['workflow_name',record.workflow_name])
            if(!workflow_object)
                workflow_object={"workflow_name": '', "object_id": ''}
            if(this.activeTab === 0){
                this.source_code = "TKT_MGMT"
                this.source_type = "ticket_management"
                userData = {
                    'client_id':this.$session.get('client_id'),
                    'queue_id':queue_object.id,
                    'queue_name':queue_object.queue_name,
                    'workspace_id':workspace_object.id,
                    'workspace_name':workspace_object.name,
                    'workflow_id':workflow_object.object_id,
                    'workflow_name':workflow_object.workflow_name,
                    'option_name': record.option_name,
                    'source_code': this.source_code,
                    'source_type': this.source_type
                }
            }
            else if(this.activeTab === 1){
                if(_.find(this.dataviewer_resources,['source_name',record.source_name])){
                    source_info = _.find(this.dataviewer_resources,['source_name',record.source_name])
                    this.source_code = source_info.source_code
                    this.source_type = source_info.source_type
                    userData = {
                        'client_id':this.$session.get('client_id'),
                        'queue_id':queue_object.id,
                        'queue_name':queue_object.queue_name,
                        'workspace_id':workspace_object.id,
                        'workspace_name':workspace_object.name,
                        'workflow_id':workflow_object.object_id,
                        'workflow_name':workflow_object.workflow_name,
                        'object_name': record.object_name,
                        'source_code': this.source_code,
                        'source_type': this.source_type,
                        'env_name': record.env_name,
                    }
                    userData["env_id"] = _.find(this.dvEnvList,["env_name", record.env_name]).id
                    if(this.source_code === "T"){
                        userData["datasource_name"] = record.datasource_name
                        userData["datasource_id"] = _.find(this.dsList,["datasource_name", record.datasource_name]).datasource_id
                        userData["object_id"] = record.object_name.split(" ")[0]
                    }
                    else if(this.source_code === "BO"){
                        userData["object_id"] = _.find(this.businessObjectList,["object_name", record.object_name]).object_id
                    }
                    else if(this.source_code === "BV"){
                        userData["object_id"] = _.find(this.businessViewList,["object_name", record.object_name]).object_id
                    }
                    else if(this.source_code === "GV"){
                        userData["object_id"] = _.find(this.gridViewList,["object_name", record.object_name]).object_id
                    }
                    else if(this.source_code === "ANY"){
                        userData["object_id"] = "0"
                    }
                }
            }
            var url = config.EDC_QUEUE_MANAGEMENT_URL + '/add_queue_workflow_form_rule'
            postToServer(this, url, userData).then(Response => {
                this.setworkflowQueueMappingChange({"saved":true})
                this.snackbar = true
                this.colorValue = 'success'
                this.snackbartext = "Mapping added successfully";
                if(this.activeTab === 0)
                    this.GetWorkflowMappingList();
                else
                    this.getDataviewerMapping();
            }).catch(error_response => {
                if (error_response) {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
                }else{
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
                }
            });
        },

        updateMapping(record) { 
            let source_info = {}
            let userData_update = {}
            let queue_object = _.find(this.queueList,['queue_name',record.queue_name])
            var  workspace_object = _.find(this.workspaceList,['name',record.workspace_name])
            if(!workspace_object)
                workspace_object = {"name": '', "id": ''}
            var workflow_object = _.find(this.workflowList,['workflow_name',record.workflow_name])
            if(!workflow_object)
                workflow_object={"workflow_name": '', "object_id": ''}
            if(this.activeTab === 0){
                this.source_code = "TKT_MGMT"
                this.source_type = "ticket_management"
                userData_update = {
                    'id': record.id,
                    'client_id':this.$session.get('client_id'),
                    'workspace_id':workspace_object.id,
                    'workspace_name':workspace_object.name,
                    'queue_id':queue_object.id,
                    'queue_name':queue_object.queue_name,
                    'workflow_id':workflow_object.object_id,
                    'workflow_name':workflow_object.workflow_name,
                    'option_name': record.option_name,
                    'source_code': this.source_code,
                    'source_type': this.source_type
                }
            }
            else if(this.activeTab === 1){
                if(_.find(this.dataviewer_resources,['source_name',record.source_name])){
                    source_info = _.find(this.dataviewer_resources,['source_name',record.source_name])
                    this.source_code = source_info.source_code
                    this.source_type = source_info.source_type
                    userData_update = {
                        'id': record.id,
                        'client_id':this.$session.get('client_id'),
                        'queue_id':queue_object.id,
                        'queue_name':queue_object.queue_name,
                        'workspace_id':workspace_object.id,
                        'workspace_name':workspace_object.name,
                        'workflow_id':workflow_object.object_id,
                        'workflow_name':workflow_object.workflow_name,
                        'object_name': record.object_name,
                        'source_code': this.source_code,
                        'source_type': this.source_type,
                        'env_name': record.env_name,
                    }
                    userData_update["env_id"] = _.find(this.dvEnvList,["env_name", record.env_name]).id
                    if(this.source_code === "T"){
                        userData_update["datasource_name"] = record.datasource_name
                        userData_update["datasource_id"] = _.find(this.dsList,["datasource_name", record.datasource_name]).datasource_id
                        userData_update["object_id"] = record.object_name.split(" ")[0]
                    }
                    else if(this.source_code === "BO"){
                        userData_update["object_id"] = _.find(this.businessObjectList,["object_name", record.object_name]).object_id
                    }
                    else if(this.source_code === "BV"){
                        userData_update["object_id"] = _.find(this.businessViewList,["object_name", record.object_name]).object_id
                    }
                    else if(this.source_code === "GV"){
                        userData_update["object_id"] = _.find(this.gridViewList,["object_name", record.object_name]).object_id
                    }
                }
            }
            
            var url = config.EDC_QUEUE_MANAGEMENT_URL + '/update_queue_workflow_form_rule'
            postToServer(this, url, userData_update).then(Response => {
                this.setworkflowQueueMappingChange({"updated":true})
                this.snackbar = true
                this.colorValue = 'success'
                this.snackbartext = "Mapping updated successfully";
                if(this.activeTab === 0)
                    this.GetWorkflowMappingList();
                else
                    this.getDataviewerMapping();
            }).catch(error_response => {
                if (error_response) {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
                }else{
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
                }
            });
        },
        onValueUpdate(record){
            if(this.activeTab===1){
                var env_id = _.find(this.dvEnvList,["env_name", record.env_name]).id
                if(record.source_name!="Table"){
                    record.datasource_name=null
                }
                if(record.datasource_name){
                    let request_data = {'env_id':env_id,"description_needed":true,
                                "check_in_description":false,
                                "client_id": this.$session.get('client_id')}
                    if(record.object_name){
                        request_data['search_text']=record.object_name.split(" ")[0]
                    }
                    let tableUrl = config.AGENT_API_URL + '/get_tables_by_dataviewer_env';
                    postToServer(this, tableUrl, request_data).then(tableResponse => {
                        var tbl_data =_.filter(tableResponse.available_table_list, { 'datasource_name': record.datasource_name})[0];
                        _.forEach(tbl_data.table_list,(obj=>{
							if(obj.description){
								obj.object_name = obj.title+' '+obj.description
							}
							else{
								obj.object_name = obj.title
							}
                        }))
                        tbl_data.table_list.unshift({"object_id":"0","object_name":"Any"})
                        this.dataviewerTableList.headers[3].option = tbl_data.table_list
                        this.tableListMapping.push(tbl_data.table_list)
                    }).catch(errorResponse => {
                        this.dataviewerTableList.headers[3].option = []
                    });
                }
                if(record.source_name==="Table"){
                    this.fetchDatasourcesForEnv(record);
                }
                else if(record.source_name==="Business Object"){
                    this.dataviewerTableList.headers[2].option = []
                    var t_data = {'env_id': env_id, 
                                  'client_id': this.$session.get('client_id')}
                    postToServer(this, config.PUBLISHER_URL +'/published_objects_in_env_for_dv', t_data, false).then(async response => {
                    var data =_.filter(response.result, { 'object_type': 'BO'});
                    data.unshift({"object_id":"0","object_name":"Any"})
                    this.dataviewerTableList.headers[3].option = data;
                    this.businessObjectList = data
                    this.businessViewList = []
                    this.gridViewList = []
                    }).catch(ProcessDocError => {
                    });
                }
                else if(record.source_name==="Business View"){
                    this.dataviewerTableList.headers[2].option = []
                    var t_data = {'env_id': env_id, 
                                  'client_id': this.$session.get('client_id')}
                    postToServer(this, config.PUBLISHER_URL +'/published_objects_in_env_for_dv', t_data, false).then(async response => {
                    var data =_.filter(response.result, { 'object_type': 'BV'});
                    data.unshift({"object_id":"0","object_name":"Any"})
                    this.dataviewerTableList.headers[3].option = data;
                    this.businessViewList = data
                    this.businessObjectList = []
                    this.gridViewList = []
                    }).catch(ProcessDocError => {
                    });
                }
                else if(record.source_name==="Grid View"){
                    this.dataviewerTableList.headers[2].option = []
                    var t_data = {'env_id': env_id, 
                                  'client_id': this.$session.get('client_id')}
                    postToServer(this, config.PUBLISHER_URL +'/published_objects_in_env_for_dv', t_data, false).then(async response => {
                    var data =_.filter(response.result, { 'object_type': 'GV'});
                    data.unshift({"object_id":"0","object_name":"Any"})
                    this.dataviewerTableList.headers[3].option = data;
                    this.gridViewList = data
                    this.businessObjectList = []
                    this.businessViewList = []
                    }).catch(ProcessDocError => {
                    });
                }
                else{
                    this.dataviewerTableList.headers[2].option = []
                }
            }
        },
        performTableSearch(event){
            var _this = this
            let obj = _.find(this.dataviewerTableList.rows,["is_row_editable",true])
            if(obj.source_name != "Table")
                return
            if(!obj.datasource_name)
                return
            var env_id = _.find(this.dvEnvList,["env_name", obj.env_name]).id
            let request_data = {'env_id':env_id,"description_needed":true,
                                "check_in_description":true,
                                "client_id": this.$session.get('client_id')}
            request_data['search_text'] = event
            _this.dataviewerTableList.tableLoading = true
            let tableUrl = config.AGENT_API_URL + '/get_tables_by_dataviewer_env';
            postToServer(this, tableUrl, request_data).then(tableResponse => {
                _this.dataviewerTableList.tableLoading = false
                var tbl_data =_.filter(tableResponse.available_table_list, { 'datasource_name': obj.datasource_name})[0];
                _.forEach(tbl_data.table_list,(obj=>{
                    obj.object_name = obj.description?obj.title+' '+obj.description:obj.title
                }))
                tbl_data.table_list.unshift({"object_id": "0", "object_name": "Any"})
                this.dataviewerTableList.headers[3].option = tbl_data.table_list
                this.tableListMapping.push(tbl_data.table_list)
            }).catch(errorResponse => {
                _this.dataviewerTableList.tableLoading = false
                this.dataviewerTableList.headers[3].option = []
            });
        },
        fetchDatasourcesForEnv(record){
            if(record.env_name === "Any"){
                this.dsList.unshift({"datasource_id":0,"datasource_name":"Any"})
                this.dataviewerTableList.headers[2].option.unshift({"datasource_id":0,"datasource_name":"Any"})
                return
            }
            var env_dv_id = _.find(this.dvEnvList,["env_name", record.env_name]).id
            getToServer(this, config.ENVIRONMENT_API_URL + '/get_ds_id_name_using_env_id/'+env_dv_id).then(dsResponse=>{
                if(dsResponse){
                    this.dataviewerTableList.headers[2].option = dsResponse
                    this.dsList = dsResponse
                }
                else{
                    this.dataviewerTableList.headers[2].option = []
                }
            }).catch(error_response => {
                this.dataviewerTableList.headers[2].option = []
            })
        },
        onDelete(record) {
            var _this = this
            let inputJson = {
                'client_id':this.$session.get('client_id'),
                'id': record.id
            }
            let url = config.EDC_QUEUE_MANAGEMENT_URL + '/delete_queue_workflow_form_rule'            
            postToServer(this, url, inputJson).then(response  => {
                this.setworkflowQueueMappingChange({"deleted":true})
                if(this.activeTab === 1)
                    this.getDataviewerMapping()
                else
                    _this.GetWorkflowMappingList();
                this.loader = false
                this.snackbar = true
                this.colorValue = 'success'
                this.snackbartext = 'Queue Workflow mapping deleted successfully';
            }).catch(error_response => {
                this.GetWorkflowMappingList();
                if(error_response){
                this.loader = false
                this.snackbar = true;
                this.colorValue = 'error';
                this.snackbartext = error_response;
                } else {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = SERVER_ERROR;
                }
            });               
        },
        onEdit(reord){}
    }
}
</script>