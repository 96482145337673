<template>
	<v-container class="EDC-Row">
		<v-row class="EDC-Row">
			<v-col class="EDC-Col" cols="12">
				<edc-text-field v-if="eachField.controlToDisplay === 'textbox'" :fieldType="eachField.fieldType" :vModel="eachField.value" :key="eachField.fieldId" :id="eachField.domId" :labelText="eachField.label" :fieldUniqueId="eachField.fieldId" @onBlur="mapFieldValue" :isRequired="isRequiredField(eachField)" :isSpaceAllowed="eachField.validationDetails && eachField.validationDetails.clientSide && eachField.validationDetails.clientSide.allowSpace" :lengthValidation="eachField.validationDetails && eachField.validationDetails.clientSide && eachField.validationDetails.clientSide.restrictedLength" :titleText="eachField.description" :isEmail="isEmailField(eachField)" :readOnly="eachField.readonly" :maskFormat="getMaskFormat(eachField)"> 
				</edc-text-field>
				<edc-text-area v-if="eachField.controlToDisplay === 'textarea'" :fieldType="eachField.fieldType" :vModel="eachField.value" :key="eachField.fieldId" :id="eachField.domId" :labelText="eachField.label" :fieldUniqueId="eachField.fieldId" @onBlur="mapFieldValue" :isRequired="isRequiredField(eachField)" :isSpaceAllowed="eachField.validationDetails && eachField.validationDetails.clientSide && eachField.validationDetails.clientSide.allowSpace" :lengthValidation="eachField.validationDetails && eachField.validationDetails.clientSide && eachField.validationDetails.clientSide.restrictedLength" :titleText="eachField.description" :isEmail="isEmailField(eachField)" :readOnly="eachField.readonly"> 
				</edc-text-area>
				<edc-auto-complete v-if="eachField.controlToDisplay === 'dropdown'" :key="eachField.fieldId" :vModel="eachField.value" :itemText="eachField.prePropValue.itemText" :itemValue="eachField.prePropValue.itemValue" :itemList="eachField.prePropValue.itemList" :isRequired="isRequiredField(eachField)" :id="eachField.domId" :labelText="eachField.label" @input="mapFieldValue" :fieldUniqueId="eachField.fieldId" :readOnly="eachField.readonly"></edc-auto-complete>
				<edc-checkbox v-if="eachField.controlToDisplay === 'checkbox'" :key="eachField.fieldId" :returnType="eachField.returnType" :returnOnTrue="eachField.prePropValue.returnOnTrue" :returnOnFalse="eachField.prePropValue.returnOnFalse" :labelText="eachField.label" @change="mapFieldValue" :fieldUniqueId="eachField.fieldId" :vModel="eachField.prePropValue.defaultValue"></edc-checkbox>
				<edc-radio v-if="eachField.controlToDisplay === 'radio'" :key="eachField.fieldId" :itemText="eachField.prePropValue.itemText" :itemValue="eachField.prePropValue.itemValue" :itemList="eachField.prePropValue.itemList" :id="eachField.domId" :vModel="eachField.prePropValue.defaultValue" :fieldUniqueId="eachField.fieldId"></edc-radio>
			</v-col>
		</v-row>
	</v-container>
</template>
<style scoped>
	
</style>
<script>
	export default {
		name:'EdcSubForm',
		props:{
			fieldObj:{
				type:Object,
				default:()=>{
					return {}
				}
			}
		},
		data(){
			return{
				eachField:{}
			}
		},
		watch:{
			fieldObj:{
				handler(newValue){
					this.eachField = _.cloneDeep(newValue)
				},
				deep:true
			}
		},
		mounted(){
			this.eachField = _.cloneDeep(this.fieldObj)
		},
		methods:{
			mapFieldValue(val1,val2,val3,val4,val5,val6){
				this.$emit('onAction',val1,val2,val3,val4,val5,val6)
			},
			isRequiredField(eachField){
				// console.log(eachField.fieldId)
				// console.log('is required field?' ,!_.isEmpty(eachField.validationDetails) && !_.isEmpty(eachField.validationDetails.clientSide) &&  eachField.validationDetails.clientSide.required)
				return !_.isEmpty(eachField.validationDetails) && !_.isEmpty(eachField.validationDetails.clientSide) &&  eachField.validationDetails.clientSide.required
			},
			isEmailField(eachField){
				return !_.isEmpty(eachField.validationDetails) && !_.isEmpty(eachField.validationDetails.clientSide) &&  eachField.validationDetails.clientSide.email
			},
			getMaskFormat(eachField){
				if(_.isEmpty(eachField.validationDetails) || _.isEmpty(eachField.validationDetails.clientSide) || !eachField.validationDetails.clientSide.maskFormat)
					return ""
				else
					return eachField.validationDetails.clientSide.maskFormat
			}
		}
	}
</script>