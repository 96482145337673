<template>
  <div>
      <loading-panel :loader="loader"></loading-panel>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>Notification Group User Group Mapping</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <edc-data-grid :dataList="tableList" @onNew="onNew" @onDelete="onDelete" @onSave="onSave" @onCancel="onCancel" @onValueUpdate="onValueUpdate"></edc-data-grid>
    
    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>
<script>
import { post as postToServer } from './../../methods/serverCall.js';
import { get as getToServer } from './../../methods/serverCall.js';
import config from '../../config.json'
import { SERVER_ERROR } from '../../data/client_message.js'
import { GET_ALL_USERS_LIST, GET_USER_ROLE_MAPPING, GET_USER_ENV_ROLE_MAPPING, GET_ALL_ROLES, GET_ENVIRONMENTAL_ROLES, MANAGE_USER_ROLE_MAPPING, DELETE_USER_ROLE_MAPPING, GET_ROLES_BY_TYPE, MANAGE_ENV_USER_ROLE_MAPPING, ADD_ENV_USER_ROLE_MAPPING } from '../../data/url_constants.js'
import {CLIENT_SIDE, SERVER_SIDE} from '../../data/macros.js'
import {SECURITY_SERVICE,CREATE_ACTION,READ_ACTION} from "./../../data/macros.js"
import _ from 'lodash'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
export default{
    data(){
        return{
            snackbar:false,
            snackbartext:'',
            colorValue:'error',
            loader: "loading",
            snackbartimeout: Snackbar_Default_Timeout,
            loading: false,
            userGroupList:[],
            notificationGroupList:[],
            mappedGroupList:[],
            envIpJson: {
                "filter": [],
                "sort": [{
                    "column_name": "",
                    "type": ""
                }],
                "page": 1,
                "page_size": 5
            },
            tableList:{
                headers: [
                {text: 'User Group', value: 'user_group_name', width:"50%", option:[], 'item_text':'group_name', 'item_value':'group_name', useAutoComplete:true},
                { text: 'Notification Group', value: 'name', width: '50%', title: 'Notification Group Name', option:[], 'item_text':'name', 'item_value':'name', useAutoComplete:true, isRoleMapping:true}
                ], 
                actions: [],//if we need conditional action in row then provide key
                sorting_type: CLIENT_SIDE,
                filterType: CLIENT_SIDE,
                paginationType: CLIENT_SIDE,
                total_count: 0,
                is_row_editable:true,
                rows: [],
                selected_rows: [],
                syncHeaderScroll:false,
                disableDefaltSorting:true,
                hideExport:true,
                hideShowColumns:true,
                hideFilter:true,
                itemkey:'map_id',
                is_grid_inline_edit:true
            },
        }
    },

    mounted(){
        this.tableList.actions = [
      {'text':'edit','key':"id", selectType:"single", role:true, index:1},
      {'text':'new','key':"id", 'enabled':this.Environment, role:true, index:2},    
      {'text':"save","key":"is_row_editable",selectType:"single", index:3},
      {'text':"cancel","key":"is_row_editable",selectType:"single", index:4},
      {'text':'delete','key':"id", selectType:"single", role:true, index:5}];
    if(this.tableList.paginationType == CLIENT_SIDE)
      this.envIpJson.page_size="*";
    
    this.GetNotificationGroupList();
    this.GetAllUserGroups();
    this.GetMappedGroupList();
    },

    methods: {
        GetNotificationGroupList() {
            let data = {
                'client_id':this.$session.get('client_id'),
                'product_name': "TICKET_MANAGEMENT"}
                debugger
            var url =  config.NOTIFICATION_GROUP_URL + '/get_all_notification_groups'
            postToServer(this, url, data).then(Response => {
                if(Response){
                this.notificationGroupList = Response;
                this.tableList.headers[1].option = Response;
                }
            });
        },

        GetAllUserGroups() {
            let _this = this;
            _this.loader = true;
            _this.tableList.rows = []
            var request_data = {
                'client_id': this.$session.get('client_id'),
                'product_name': window.sessionStorage.getItem('product')}
            postToServer(_this, config.USER_PROVISION_URL + '/get_all_group_details', request_data).then(response => {
                _this.loader = false;
                if (response) {
                    _this.userGroupList = response;
                    _this.tableList.headers[0].option = response;
                } else {
                    _this.tableList.rows = [];
                }
               
            }).catch(error_response => {
                _this.loader = false;
                 _this.tableList.rows = [];
                if (error_response) {
                    this.loader = null
                    /* this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = error_response; */
                } else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                }
            });
        },

        GetMappedGroupList(){
            var _this = this
            _this.loader = true
            var data_to_send = {"client_id": _this.$session.get('client_id'),
                                "product_name": window.sessionStorage.getItem('product')}
            postToServer(_this, config.NOTIFICATION_GROUP_URL+"/get_not_group_usr_group_mapping", data_to_send).then(response =>{
                if(response){
                    _this.loader = false
                    this.tableList.rows = response;
                    this.mappedGroupList = response;
                    mappedGroupList.map(function(obj){
                        obj.isselected = false
                    })
                }
                else{
                    _this.loader = false
                }
            }).catch(error_response => {
            });
        },

        onNew() {
            let obj = _.find(this.tableList.rows,["is_row_editable",true])
            if(obj)
            {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = 'First fiilup the open row';
                return
            }
            this.tableList.rows.unshift({
                'user_group_name':'',
                'name':'',
                'is_row_editable': true,
            });
        },

        onCancel() {
            this.GetMappedGroupList();
        },

        onSave(record) {
            if(!record.user_group_name || !record.name){
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = 'All fields are required';
                return false
            }
            record.map_id ? this.updateMapping(record) : this.saveMapping(record);            
        },

        saveMapping(record) {
            let userData = {
                'client_id':this.$session.get('client_id'),
                'group_id':_.find(this.notificationGroupList,['name',record.name]).id,
                'user_group_id':_.find(this.userGroupList,['group_name',record.user_group_name]).id,
                'user_group_name':record.user_group_name,
            }
            var url = config.NOTIFICATION_GROUP_URL + '/add_not_group_user_group_mapping'
            postToServer(this, url, userData).then(Response => {
                this.snackbar = true
                this.colorValue = 'success'
                this.snackbartext = "Mapping added successfully";
                this.GetNotificationGroupList();
                this.GetMappedGroupList();
            }).catch(error_response => {
                if (error_response) {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
                }else{
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
                }
            });
        },

        updateMapping(record) {  
            var userData = {
                'id': record.map_id,
                'client_id':this.$session.get('client_id'),
                'group_id':_.find(this.notificationGroupList,['name',record.name]).id,
                'user_group_id':_.find(this.userList,['group_name',record.user_group_name]).id,
                'user_group_name':record.user_name
            }
            var url =  config.NOTIFICATION_GROUP_URL + '/update_not_group_user_group_mapping'
            postToServer(this, url, userData).then(Response => {
                this.snackbar = true
                this.colorValue = 'success'
                this.snackbartext = "Mapping updated successfully";
                this.GetNotificationGroupList();
                this.GetMappedGroupList();
            }).catch(error_response => {
                if (error_response) {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
                }else{
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
                }
            });
        },

        onDelete(record) {
            var _this = this
            let inputJson = {
                'client_id':this.$session.get('client_id'),
                'id': record.map_id
            }
            let url = config.NOTIFICATION_GROUP_URL + '/delete_not_group_user_group_mapping'            
            postToServer(this, url, inputJson).then(response  => {
                _this.GetMappedGroupList();
                this.snackbar = true
                this.colorValue = 'success'
                this.snackbartext = 'User Role deleted successfully';
            }).catch(error_response => {
                this.GetMappedGroupList();
                if(error_response){
                this.snackbar = true;
                this.colorValue = 'error';
                this.snackbartext = error_response;
                } else {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = SERVER_ERROR;
                }
            });               
        },
    }
}
</script>