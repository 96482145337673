<template>
	<v-container class="EDC-Container">
		<v-row class="EDC-Row">
			<v-dialog v-model="showTicket" persistent width="400" style="overflow:hidden !important;">
				<v-col cols="12" class="EDC-Col">
	        <v-card elevation-1>
	        	<v-row no-gutters class="EDC-Row">
	            <v-toolbar dark dense absolute class="EDC-Toolbar" style="width:100%; padding-bottom:10px;border-radius:0px !important;">
                <v-toolbar-title class="EDC-ToolbarTitle">New Ticket</v-toolbar-title>
                <!-- <v-spacer></v-spacer> -->
                <!-- <v-spacer v-for="index in 24" :key="index"></v-spacer> -->
                <!-- <svgicon  class="svg-icon-grid svg-fill-toolbar" name="cancel_v2"  :original="true" @click="closePanel" title="Close"></svgicon> -->
              </v-toolbar>
            </v-row>
            <v-row class="EDC-Row">
            	<v-col cols="12" class="EDC-Col">
            		<edc-ticket :isForDV="true" :applicationRedirectKey="applicationRedirectKey" :dataviewerDetails="tkt_data" @onSuccess="manageCreateTicket">
            			
            		</edc-ticket>
            	</v-col>
            </v-row>
	        </v-card>
	      </v-col>
			</v-dialog>
		</v-row>
		<loading-panel :loader="loader"></loading-panel>
		<v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
	</v-container>
</template>
<script>
import config from '../../config.json';
import {Snackbar_Default_Timeout, GRID_STATUS_COLOR_CODE, Ticket_management} from '@/constants/constants.js'
import {
  post as postToServer
} from './../../methods/serverCall.js';

import ticketComponent from './revisedAddNewTicket.vue'
export default {
	name:"dataviewerLanding",
	components:{
		'edc-ticket':ticketComponent
	},
	data(){
			return {
				tkt_data: '',
				snackbar:false,
				snackbartext:'',
				colorValue:'error',
				snackbartimeout: Snackbar_Default_Timeout,
				loader:false,
				applicationRedirectKey:this.$route.query.application_redirect_key,
				showTicket:false
			}
		},
		computed:{

		},
		mounted(){
			// alert("Welcome to the dataviewer landing page.")
			this.fetchTktDetails();
		},
		methods:{
			fetchTktDetails(){
				let _this = this
				var data_to_send = {"application_redirect_key": this.$route.query.application_redirect_key, "archivist_redirect_key":this.$route.query.application_redirect_key}
				var cache_url = config.EDC_CACHE_SERVICE_URL + "/fetch_dataviewer_redirect_key"
				_this.loader = true
				postToServer(_this, cache_url, data_to_send).then(response=>{
					if (response){
						_this.tkt_data = response
						var url=response.url
						var url_type='POST'
						// let params = {'edcurl': this.$route.query.application_redirect_key, 'postData': response.postData, 'url_type': url_type, "title": "Dataviewer Ticket", "description": "Ticket for Table",
						// "resource_type": "dataviewer_service"}
						// this.$router.push({name:'TicketDetailsNew','params':params})
						_this.showTicket = true
						// let queryString = "?edcurl="+url+"&url_type="+url_type
						// window.location.href = '/ticketdetailsnew'+queryString
						// alert("Fetched Ticket Deatils.")
						_this.loader = false
					}
					else{
						return
					}
				}).catch(error_response=>{
					_this.loader = false
					this.snackbar = true
					this.colorValue = 'error'
					this.snackbartext = error_response;
				})
				// call to cache service to fetch json details.
				// after successfully fetching details call fetchDatasourceDetailsForEnv() and pass response object as a parameter 
			},
			manageCreateTicket(ticketDetails){
      			this.$router.push({"name":"TicketStatus"})
    		}
		}
}
</script>