/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'job_details_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M3.18.268A2.915 2.915 0 00.27 3.18a2.914 2.914 0 002.91 2.91 2.914 2.914 0 002.91-2.91A2.916 2.916 0 003.18.268zm0 .53A2.378 2.378 0 015.56 3.18a2.376 2.376 0 01-2.38 2.38A2.376 2.376 0 01.799 3.18 2.378 2.378 0 013.179.797zm-.005.525a.53.53 0 00-.53.53.53.53 0 00.53.528.53.53 0 00.53-.529.53.53 0 00-.53-.53zm-.529 1.226v2.62h1.06v-2.62h-.03a.53.53 0 01-.501.361.53.53 0 01-.501-.36h-.028z" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
