/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Menu_Create_Ticket': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" style="-inkscape-stroke:none" d="M32 2C15.479 2 2 15.479 2 32s13.479 30 30 30 30-13.479 30-30S48.521 2 32 2zm0 8c12.198 0 22 9.802 22 22s-9.802 22-22 22a21.94 21.94 0 01-22-22c0-12.198 9.802-22 22-22zm0 3.998a4 4 0 00-4 4V28H18a4 4 0 00-4 4 4 4 0 004 4h10v10a4 4 0 004 4 4 4 0 004-4V36h10a4 4 0 004-4 4 4 0 00-4-4H36V17.998a4 4 0 00-4-4z"/>'
  }
})
