<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>Notification Group Mapping</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <edc-data-grid :dataList="tableList" @onNew="onNew" @onDelete="onDelete" @onSave="onSave" @onCancel="onCancel" @onValueUpdate="onValueUpdate"></edc-data-grid>
    
    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>

<script>
import { post as postToServer } from './../../methods/serverCall.js';
import { get as getToServer } from './../../methods/serverCall.js';
import config from '../../config.json'
import { SERVER_ERROR } from '../../data/client_message.js'
import { GET_ALL_USERS_LIST, GET_USER_ROLE_MAPPING, GET_USER_ENV_ROLE_MAPPING, GET_ALL_ROLES, GET_ENVIRONMENTAL_ROLES, MANAGE_USER_ROLE_MAPPING, DELETE_USER_ROLE_MAPPING, GET_ROLES_BY_TYPE, MANAGE_ENV_USER_ROLE_MAPPING, ADD_ENV_USER_ROLE_MAPPING } from '../../data/url_constants.js'
import {CLIENT_SIDE, SERVER_SIDE} from '../../data/macros.js'
import {SECURITY_SERVICE,CREATE_ACTION,READ_ACTION} from "./../../data/macros.js"
import _ from 'lodash'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

export default {
  data() {
    return {
      snackbar:false,
      snackbartext:'',
      colorValue:'error',
      loader: "loading",
      snackbartimeout: Snackbar_Default_Timeout,
      loading: false,
      userList:[],
      notificationGroupList:[],
      mappedGroupList:[],
      environmentalRoleList:[],
      envIpJson: {
        "filter": [],
        "sort": [{
            "column_name": "",
            "type": ""
        }],
        "page": 1,
        "page_size": 5
      },
      tableList:{
        headers: [
          {text: 'User', value: 'user_name', width:"50%", option:[], 'item_text':'full_name', 'item_value':'full_name', useAutoComplete:true},
          { text: 'Notification Group', value: 'name', width: '50%', title: 'Notification Group Name', option:[], 'item_text':'name', 'item_value':'name', useAutoComplete:true, isRoleMapping:true}
          // {text: 'Role', value: 'role', width:"25%", option:[], 'item_text':'role', 'item_value':'role', useAutoComplete:true},
          // {text: 'Environment', value: 'env_name', width:"25%", option:[], 'item_text':'name', 'item_value':'name', useAutoComplete:true},
        ], 
        actions: [],//if we need conditional action in row then provide key
        sorting_type: CLIENT_SIDE,
        filterType: CLIENT_SIDE,
        paginationType: CLIENT_SIDE,
        total_count: 0,
        is_row_editable:true,
        rows: [],
        selected_rows: [],
        syncHeaderScroll:false,
        disableDefaltSorting:true,
        hideExport:true,
        hideShowColumns:true,
        hideFilter:true,
        itemkey:'map_id',
        is_grid_inline_edit:true
      },
    }
  },

  mounted() {
    this.tableList.headers[1].option = [{"role_type":"administrator"}, {"role_type":"environmental"}]
    this.tableList.actions = [
      {'text':'edit','key':"id", selectType:"single", role:true, index:1},
      {'text':'new','key':"id", 'enabled':this.Environment, role:true, index:2},    
      {'text':"save","key":"is_row_editable",selectType:"single", index:3},
      {'text':"cancel","key":"is_row_editable",selectType:"single", index:4},
      {'text':'delete','key':"id", selectType:"single", role:true, index:5}];
    if(this.tableList.paginationType == CLIENT_SIDE)
      this.envIpJson.page_size="*";
    this.GetMappedGroupList();
    this.GetNotificationGroupList();
    this.GetUserList();
    // this.GetEnvironmentalRoleList();
    // this.GetEnvironmentList();
  },

  methods: {
    GetNotificationGroupList() {
      let data = {
        'client_id':this.$session.get('client_id'),
        'product_name': "TICKET_MANAGEMENT"}
        debugger
      var url =  config.NOTIFICATION_GROUP_URL + '/get_all_notification_groups'
      postToServer(this, url, data).then(Response => {
        if(Response){
          this.notificationGroupList = Response;
          this.tableList.headers[1].option = Response;
        }
      });
    },

    GetUserList() {
      var _this =this;
      var client_id = _this.$session.get('client_id');
      var data = {"client_id": client_id}
      var get_user_url = config.Security_URL + GET_ALL_USERS_LIST
      postToServer(_this, get_user_url, data).then(response => {
      _this.userList = response;
      _this.tableList.headers[0].option = response;
      }).catch(error_response => {
      }); 
    },

    onValueUpdate(record) {
      
        this.tableList.headers[1].option = this.notificationGroupList;
        this.tableList.headers[0].option = this.userList
    },

    GetMappedGroupList() {
      var _this =this;
      var client_id = _this.$session.get('client_id');
      var data = {"client_id":client_id, 'product_name': "TICKET_MANAGEMENT"}
      var url =  config.NOTIFICATION_GROUP_URL + '/get_user_group_mapping'
      postToServer(_this, url, data).then(response => {
        response.forEach(function(obj,idx){
                obj['unique_id'] = idx+1
              })
              this.tableList.rows = response;
        this.mappedGroupList = response;
        mappedGroupList.map(function(obj){
        obj.isselected = false
      })
      }).catch(error_response => {
      }); 
    },
    onNew() {
      let obj = _.find(this.tableList.rows,["is_row_editable",true])
      if(obj)
      {
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = 'First fiilup the open row';
        return
      }
      this.tableList.rows.unshift({
        'user_name':'',
        'name':'',
        'is_row_editable': true,
      });
    },

    onCancel() {
      this.GetMappedGroupList();
    },

    onSave(record) {
      if(!record.user_name || !record.name){
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = 'All fields are required';
        return false
      }
      record.map_id ? this.updateUser(record) : this.saveUser(record);            
    },

    saveUser(record) {
         let userData = {
        'client_id':this.$session.get('client_id'),
        'group_id':_.find(this.notificationGroupList,['name',record.name]).id,
        'user_id':_.find(this.userList,['full_name',record.user_name]).user_id,
        'user_name':record.user_name,
        'user_mail':_.find(this.userList,['full_name',record.user_name]).username,
      }
      var url = config.NOTIFICATION_GROUP_URL + '/add_user_group_mapping'
      postToServer(this, url, userData).then(Response => {
        this.snackbar = true
        this.colorValue = 'success'
        this.snackbartext = "Role added successfully";
        this.GetNotificationGroupList();
        this.GetMappedGroupList();
      }).catch(error_response => {
        if (error_response) {
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = error_response;
        }else{
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = error_response;
        }
      });
    },

    updateUser(record) {  
         let userData = {
           'id': record.map_id,
        'client_id':this.$session.get('client_id'),
        'group_id':_.find(this.notificationGroupList,['name',record.name]).id,
        'user_id':_.find(this.userList,['full_name',record.user_name]).user_id,
        'user_name':record.user_name,
        'user_mail':_.find(this.userList,['full_name',record.user_name]).username,
      }
      var url =  config.NOTIFICATION_GROUP_URL + '/update_user_group_mapping'
      postToServer(this, url, userData).then(Response => {
        this.snackbar = true
        this.colorValue = 'success'
        this.snackbartext = "User updated successfully";
        this.GetNotificationGroupList();
        this.GetMappedGroupList();
      }).catch(error_response => {
        if (error_response) {
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = error_response;
        }else{
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = error_response;
        }
      });
    },

    onDelete(record) {
      let inputJson = {
        'client_id':this.$session.get('client_id'),
        'id': record.map_id
      }
      let url = config.NOTIFICATION_GROUP_URL + '/delete_user_group_mapping'            
      postToServer(this, url, inputJson).then(response  => {
        this.snackbar = true
        this.colorValue = 'success'
        this.snackbartext = 'User Role deleted successfully';
        this.GetMappedGroupList();
      }).catch(error_response => {
        this.GetMappedGroupList();
        if(error_response){
          this.snackbar = true;
          this.colorValue = 'error';
          this.snackbartext = error_response;
        } else {
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = SERVER_ERROR;
        }
      });               
    },
  }
}
</script>
<style scoped>

</style>