/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Document_Edit': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M40.636 17.707l8 8" _fill="none" _stroke="#000" stroke-width="2"/><path pid="1" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M17 27a1 1 0 100 2h7a1 1 0 100-2zm0-8a1 1 0 100 2h14a1 1 0 100-2zM13.168 7.723c-2.853 0-5.188 2.305-5.188 5.144v37.71c0 2.839 2.335 5.146 5.188 5.146h29.623c2.853 0 5.19-2.307 5.19-5.147v-12.57h-1.913v12.57c0 1.803-1.44 3.233-3.277 3.233H13.168c-1.836 0-3.275-1.43-3.275-3.233V12.867c0-1.803 1.439-3.232 3.275-3.232h23.338V7.723zm34.478 3.15a4.983 4.983 0 00-3.535 1.459l-22.47 22.47-.067.268c-.707 2.829-1.244 4.956-1.478 6.598-.118.82-.164 1.523-.086 2.168.078.644.306 1.269.767 1.73.462.462 1.086.69 1.73.768.645.078 1.346.031 2.167-.086 1.642-.235 3.769-.771 6.597-1.478l.27-.069L54.01 22.232a5.01 5.01 0 000-7.07l-2.828-2.83a4.983 4.983 0 00-3.536-1.459zm0 1.982a2.99 2.99 0 012.122.891l2.828 2.83a2.971 2.971 0 010 4.242L30.527 42.887c-2.658.664-4.744 1.182-6.136 1.38-.726.104-1.284.124-1.645.08-.36-.043-.486-.126-.555-.195-.068-.068-.151-.195-.195-.556-.044-.361-.024-.919.08-1.645.2-1.392.715-3.476 1.38-6.133l22.07-22.072a2.99 2.99 0 012.12-.89z" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/><path pid="2" d="M30 36l11-11" _fill="none" _stroke="#000" stroke-width="2" stroke-linecap="round"/>'
  }
})
