/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'archive_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M.53.53v1.588h5.29V.53H.53zm.529.529H5.29v.53H1.06v-.53zM.816 2.416v2.938c0 .29.225.558.53.558H5.05c.304 0 .529-.268.529-.558V2.416h-.53v2.938c0 .035-.01.03 0 .03H1.347c.01 0 0 .005 0-.03V2.416h-.53zm1.565.229v.53h1.588v-.53H2.38z" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
