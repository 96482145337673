/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Object_Publish': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<g _fill="none" _stroke="#000" stroke-linecap="round"><path pid="0" d="M55.111 17.972l-9.525 5.617-9.524 5.617L36 21a17.202 17.271 0 00-8.306 4.276 17.202 17.271 0 00-4.603 17.887 17.202 17.271 0 011.32-23.814 17.202 17.271 0 0111.543-4.64l-.057-7.685 9.607 5.474z" stroke-width="3.25"/><path pid="1" d="M30 9H11.621C10.17 9 9 9.892 9 11v43c0 1.108 1.17 2 2.621 2H44.38C45.83 56 47 55.108 47 54V30" stroke-width="2" stroke-linejoin="round"/><path pid="2" d="M16 48h24M29 40h11" stroke-width="2"/></g>'
  }
})
