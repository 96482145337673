/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user_password_reset_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M4.77.264a.26.26 0 00-.19.074l-.215.207a.265.265 0 00-.086-.058A2.911 2.911 0 00.44 4.202a.265.265 0 10.495-.187A2.377 2.377 0 013.96.935l-.174.168a.265.265 0 00.11.446l.53.153.528.153c.196.057.38-.12.33-.319l-.133-.535L5.02.465a.264.264 0 00-.25-.201zm.882 1.727a.265.265 0 00-.255.36 2.381 2.381 0 01-2.234 3.205 2.38 2.38 0 01-.78-.134l.181-.175a.265.265 0 00-.11-.446l-.53-.153-.528-.153a.264.264 0 00-.33.319l.133.535.131.536a.264.264 0 00.44.127l.206-.197a.265.265 0 00.089.055 2.91 2.91 0 003.828-3.703.265.265 0 00-.241-.176zm-3.906.127a.532.532 0 00-.529.527v1.059c0 .288.24.529.53.529h.634c.288 0 .529-.24.529-.53V3.44h.53v.264c-.008.36.537.36.529 0V3.44h.529v.53c0 .352.53.352.53 0v-.53c.353 0 .353-.53 0-.53H2.91v-.265a.532.532 0 00-.53-.527h-.634zm0 .527h.635v1.059h-.635v-1.06z" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
