<template>
  <div style="width: 100% !important"> 
    <loading-panel :loader="loader"></loading-panel>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
      </v-col>
      <v-col cols="4">
      </v-col>
    </v-row>
    <v-container style="width: 100% !important;">
      <v-card elevation-1  style="width: 100% !important">
        <v-overlay
          :absolute="absolute"
          :value="overlay"
          
        >
          <v-img
                  :src="selected_image"
                  width="98vw"
                  height="98vh"
                  contain
                  
                >
                <span style="float:center;font-weight: bold;background-color:blue;color: primary !important;">Attachment Preview</span>
                 <v-btn  v-if="selected_image != ''"  small fab rounded style="float:right;height:2.2em;width:2.2em;">
                  <!-- <v-icon>close</v-icon> -->
                  <svgicon  class="svg-icon-grid svg-fill-toolbar" name="cancel_v2" style="max-width='100vw';min-width='100vw" :original="true" @click="overlay = false" title="Close Preview"></svgicon>
                  </v-btn>
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                    <span style="float:center;font-weight: bold;">Attachment Preview</span>
                      <!-- <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular> -->
                    </v-row>
                  </template>
                </v-img>
        </v-overlay>
         <v-row class="EDC-Row" v-if="isForEdit" no-gutters>
           
           <v-col cols="12" style="background-color:white;" class="EDC-Col columns-alignment" md="5">
                  <v-row v-if="isForEdit" class="EDC-Row" justify="start">
                  <v-col cols="6"  class="EDC-Col">
                      <!-- <pre style="float:left;font-size:16px !important">{{title}}</pre> -->
                      <span class="override-font" style="float:left;font-weight: bold;padding-left: 1%">{{title}}</span>
                  </v-col>
                  <v-col cols="2"  class="EDC-Col">
                         <!-- <span style="float:left;font-size:16px !important">Ticket ID-  # {{ticket_id}}</span> -->
                         <span class="override-font" style="float:left;">{{ticket_id}}</span>
                    </v-col>
                    <v-col cols="2"  style="margin-left:0px !important;" v-if="isForEdit && url" class="EDC-Col EDC-ColsSpacing"> 
                          <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-icon @click="redirectToIssue" class="mr-1" v-on="on">link</v-icon>
                        </template>
                        <span>Click to open</span>
                      </v-tooltip>
                    </v-col>
                </v-row>
            </v-col>
            <v-col v-if="isForEdit">
            </v-col>
            <v-col cols="5" style="background-color:white;" class="EDC-Col columns-alignment">
                <v-row v-if="isForEdit" class="EDC-Row EDC-RowsSpacing" justify="start">
                  <v-col cols="2"  class="EDC-Col EDC-ColsSpacing">
                    <v-row class="EDC-Row EDC-RowsSpacing" justify="start">
                      <span style="float:left;font-weight: bold;" class="override-font">Status:</span>
                    </v-row>
                    <v-row class="EDC-Row EDC-RowsSpacing" justify="start">
                      <v-chip
                          :color="getColor(status.status?status.status:'')"
                          :text-color="status.status == 'Closed'? 'black' : 'white'"
                          v-if="status.status != 'Closed'"
                          class="override-font"
                          >
                          {{status.status}}
                      </v-chip>
                      <span v-else>{{status.status}}</span>
                      </v-row>
                    </v-col>
                    <v-col cols="2" class="EDC-Col Top_Line">
                      <v-row class="EDC-Row EDC-RowsSpacing" justify="start">
                      <span class="override-font" style="float:left;font-weight: bold;">Priority</span>
                      </v-row>
                      <v-row class="EDC-Row EDC-RowsSpacing" v-if="!show_priority" justify="start">
                        <span @dblclick="showHideControl('text','priority')" style="padding-top: 0.3em;" class="override-font">{{priority.sequence_number}}-{{priority.name}}</span>
                      </v-row>
                      <v-row class="EDC-Row EDC-RowsSpacing" v-if="show_priority" justify="start">
                        <v-autocomplete ref="prioritytest" class="override-font"  :readonly="priority_readonly" v-model="priority" @blur="showHideControl('control','priority')" :rules="priorityRules" validate-on-blur item-text="name" return-object :items="priority_list" dense></v-autocomplete>
                      </v-row>
                     </v-col>
                    <v-col cols="2"  class="EDC-Col EDC-ColsSpacing">
                      <v-row class="EDC-Row EDC-RowsSpacing" justify="start">
                        <span class="override-font" style="float:left;font-weight: bold;">Assignee</span>
                      </v-row>
                      <v-row class="EDC-Row EDC-RowsSpacing" v-if="!show_assignee" justify="start">
                        <span @dblclick="showHideControl('text','assignee')" class="override-font"  style="padding-top: 0.3em;">{{assignee.username?assignee.username:'Select Assignee '}}</span>
                      </v-row>
                      <v-row class="EDC-Row EDC-RowsSpacing" v-if="show_assignee" justify="start">
                        <v-autocomplete class="customInputClass override-font" :clearable="!assignee_readonly" :rules="assigneeRule" ref="assignee" @blur="showHideControl('control','assignee')" :readonly="assignee_readonly"  @change="assigne_fullname = assignee.full_name_with_user_name" return-object   v-model="assignee" item-text="full_name_with_user_name" :items="user_list" dense></v-autocomplete>
                      </v-row>
                    
                    <input type="file" ref="multiple_file_input" multiple="single" hidden @change="handleMultipleFileUpload()">
                    </v-col>
                    <v-col cols="2" v-if="status.status == 'Closed'" class="EDC-Col EDC-ColsSpacing Span_Line">
                      <v-row class="EDC-Row EDC-RowsSpacing" justify="start">
                        <span class="override-font" style="float:left;font-weight: bold;">Resolution:</span>
                      </v-row>
                      <v-row class="EDC-Row" justify="start">
                        <span class="override-font" style="float:left;padding-top: 0.3em;"> {{status.sub_status}}</span>
                      </v-row>
                    
                     </v-col>
                    <v-col cols="2"  class="EDC-Col EDC-ColsSpacing Span_Line" style="padding-bottom:2px !important">
                      <v-row class="EDC-Row EDC-RowsSpacing" justify="start">
                      <span class="override-font" style="float:left;font-weight: bold;">Ticket Source:</span>
                      </v-row>
                      <v-row class="EDC-Row" justify="start">
                      <span class="override-font" style="float:left;padding-top: 0.4em;">{{resource_type}}</span>
                      </v-row>
                       
                     </v-col>
                      <!-- <v-col cols="2" class="EDC-Col Top_Line Span_Line">
                    <span style="float:left;font-weight: bold;">Created By:</span>
                    <span style="float:left;">{{created_by}}</span>
                     </v-col> -->
                  <v-col cols="4"  class="EDC-Col EDC-ColsSpacing Span_Line">
                    <v-row class="EDC-Row EDC-RowsSpacing" justify="start">
                    <span style="float:left;font-weight: bold;" class="override-font">Created Datetime:</span>
                  </v-row>
                    <v-row class="EDC-Row EDC-RowsSpacing" justify="start">
                    <!-- <span style="float:left;padding-top: 0.2em;">{{tz_created_date_time}}</span> -->
                    <span class="override-font" style="float:left;padding-top: 0.2em;">{{tz_created_date_time}}</span>
                  </v-row>
                    
                  </v-col>
              </v-row>
              </v-col>
          </v-row>
          <v-row class="EDC-Row" v-if="isForEdit" no-gutters>
            <v-col cols="12" style="background-color:white;" class="EDC-Col columns-alignment" md="5">
              <v-row class="EDC-Row EDC-RowsSpacing" no-gutters>
              <v-col cols="12" class="EDC-Col">
                <v-autocomplete class="mycustomcls" :disabled="queue_readonly" :filter="queueCustomFIlter"  style="float:left" v-model="queue" hide-details clearable :items="queue_list" item-text="queue_name" item-value="id" dense return-object  label="Select Queue"></v-autocomplete>
              <!-- <v-combobox
                v-model="tag"
                :items="tag_list"                        
                item-text="tag_name"
                item-value="id"
                :search-input.sync="search"
                hide-selected
                :disabled="tag_readonly"
                label="Add tags"
                multiple
                class="border-change mycustomcls"
                
                validate-on-blur
                @keydown.enter="addTag(search)"
                small-chips
              >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-if="search" class="override-font">
                      No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip
            v-if="item === Object(item)"
            v-bind="attrs"
            :input-value="selected"
            label
            small
            >
            <span class="pr-2">
              {{ item.tag_name }}
            </span>
            <v-icon
              small
              @click="parent.selectItem(item)"
            >
              $delete
            </v-icon>
            </v-chip>
          </template>
            </v-combobox> -->
              </v-col>
            </v-row>
            <v-row class="EDC-Row EDC-RowsSpacing" no-gutters>
              <v-col cols="12" v-show="isForEdit" style="padding-left:0.1% !important;"  class="EDC-Col">
              <!-- <rich-text-box style="backgroud:white;" @onBlur="change_focus('show_rich_description')" @DescriptionChange="DescriptionChange" id="rich-ticketdescription" :vueEditorObj="vueEditorObj"></rich-text-box> -->
              <v-textarea rows="5"  class="border-change mycustomcls" 
                      height="120"   :disabled="description_readonly" auto-grow label="Description"  v-model="description" ></v-textarea>
              </v-col>
              <!-- <v-col cols="12" v-show="isForEdit && !showrich_description" id="normal-comment" style="padding-left:1% !important;"  class="EDC-Col">
              <normal-comment @onFocus="change_focus('show_rich_description')" @click="change_focus('show_rich_description')"  style="backgroud:white;" @DescriptionChange="DescriptionChange" :disabled="description_readonly" :vueEditorObj="vueEditorObj"></normal-comment>
              </v-col> -->
            </v-row>
            <v-row class="EDC-Row EDC-RowsSpacing" justify="end" no-gutters>
              <v-col cols="3" class="EDC=Cols"  v-if="isForEdit" style="padding-right:5px !important;padding-top:6px!important;"  > 
                      <v-autocomplete class="override-font"  ref="other_actions" style="float:right" :readonly="action_readonly" v-model="ticket_action" hide-details clearable :items="ticket_action_list" item-text="name" item-value="name" outlined dense  label="Resolution Status"></v-autocomplete>
                      </v-col>
                      
                      <v-btn
                        
                        :disabled="status.status =='Closed' && action_readonly || !ticket_action"
                        style="height:40px !important;margin-bottom:6px !important;padding-top:3px!important;"
                        
                        @click.native="onCloseAction(ticket_action)"
                        >
                        <span class="override-font" v-if="status.status =='Closed'" style="color: gray">Open</span>
                        <span class="override-font" v-else style="color: gray"> Close</span>
                        </v-btn>
                    
            </v-row>
            <!-- <v-row no-gutters class="EDC-Row EDC-RowsSpacing"  v-if="isForEdit" justify="end">
                  <vc-button :disabled="!show_form_buttons" class="override-font"  itemText="Update" @click.native="submit"></vc-button>
                  <vc-button :disabled="!show_form_buttons" class="override-font" style="margin-right:0px !important;" itemText="Cancel" @click.native="cancle"></vc-button>
                  </v-row> -->
            <v-row class="EDC-Row EDC-RowsSpacing" no-gutters>
              <v-col cols="12" style="background-color:white;padding-left:0.5% !important" v-if="isForEdit" class="EDC-Col columns-alignment" md="6">
              <v-card class="GroupCard overflow-y-auto style-2scroll " height="100" style="min-width: 38.2vw  !important; max-width: 38.2vw !important;"  v-if="isForEdit" elevation-1>
            <!-- <legend class="title-fixed">
              
            </legend> -->
            <v-card-title  class="override-font">Attachment List</v-card-title>
            <v-row v-if="isForEdit" class="EDC-Row" no-gutters>
            <v-col cols="12"  style="background-color:white" class="EDC-Col">
              <v-row class="EDC-Row" no-gutters>
              </v-row>
              <v-row class="EDC-Row" no-gutters>
              <v-list   v-for="(item) in attachementTableList.rows" style="padding-right:1% !important;" :key="item.ticket_id" >
                <v-row class="EDC-Row" no-gutters>
                <v-card :color="getTileColor(index)" min-height="30px" >
                  <v-col cols="12"   class="EDC-Col  EDC-ColsSpacing">
                    <span class="override-font" v-if="item.filename.length <= 13" style="padding-left:3px !important;padding-right:3px !important;cursor: pointer;" @click="preview_file(item.id, item.content_type)">{{item.filename}}</span>
                  <span class="override-font" v-if="item.filename.length > 13" style="padding-left:3px !important;padding-right:3px !important;cursor: pointer;" @click="preview_file(item.id, item.content_type)">{{item.filename.split('.')[0].slice(0, 9)+'...'+item.filename.split('.')[1]}}</span>
                  <v-icon class="svg-icon-grid svg-fill-grid"  :disabled="attachment_delete_readonly"   @click="removeAttachmentConfirmation(item.ticket_id,item.id,item.filename)" title="Close">highlight_off</v-icon>
                  </v-col>
                </v-card>
                </v-row>
                <v-row class="EDC-Row" no-gutters>
                </v-row>
              </v-list>
              <v-btn
                        
                :disabled="attachment_readonly"
                rounded
                style="height:2% !important;margin-right:8px !important;margin-top:1.5% !important;padding-left:1% !important;"
                @click="onCloseAction('Add Attachment')"
                >
                <v-icon class="svg-icon-grid svg-fill-grid" small style="padding-top:4% !important;padding-left:4% !important;" :disabled="attachment_readonly"   @click="onCloseAction('Add Attachment')" title="Add Attachment">add</v-icon>
                <span class="override-font" style="color: gray;"> Add</span>
              </v-btn>
              </v-row>
            </v-col>
          </v-row>
          </v-card>
            </v-col>
            </v-row>
          <v-row class="EDC-Row EDC-RowsSpacing" v-if="isForEdit" no-gutters>
            <v-col cols="12" class="EDC-Col columns-alignment" style="padding-left: 3% !important;">
               <v-row justify="start">
                <v-img
                  :src="selected_image"
                  lazy-src=""
                  max-width="100wv"
                  min-width="100wv"
                  max-height="25vh"
                  min-height="25vh"
                  contain
                  @dblclick="selected_image?overlay = true:''"
                >
                 <v-btn color="primary" v-if="selected_image != ''"  x-small rounded fab style="position:relative;float:right;height:1.2em;width:1.2em">
                  <!-- <v-icon>close</v-icon> -->
                  <svgicon  class="svg-icon-grid svg-fill-toolbar" name="cancel_v2"  :original="true" @click="closePreview" title="Close"></svgicon>
                  </v-btn>
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                    <span class="override-font" style="float:center;font-weight: bold;background-color:blue;">Attachment Preview</span>
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-row>
             </v-col>
          </v-row>
            </v-col>
            <v-col v-if="isForEdit">
               <v-row class="EDC-Row EDC-RowsSpacing" style="width:100% !important">
              <v-col class="EDC-Col" cols="12" style="padding:0px !important;">
                <v-card class="GroupCard overflow-y-auto style-2scroll" style="min-height: 25vh !important; max-height: 28vh !important;" v-if="isForEdit" elevation-1>
                  <v-row class="EDC-Row" style="width:100% !important">
                  <v-card-title  class="override-font">Ticket Age</v-card-title>
                  </v-row>
                  <v-row class="EDC-Row" v-if="age" style="width:100% !important">
                    <v-col class="EDC-Col" cols="3" v-if="age._data.years > 0">
                  <v-card-title  class="override-font">{{age._data.years}} Y </v-card-title>
                    </v-col>
                  <v-col class="EDC-Col" cols="3" v-if="age._data.months > 0">
                  <v-card-title  class="override-font">{{age._data.months}} M: </v-card-title>
                    </v-col>
                  <v-col class="EDC-Col" cols="3" v-if="age._data.days > 0">
                  <v-card-title  class="override-font">{{age._data.days}} D </v-card-title>
                    </v-col>
                    <v-col class="EDC-Col" cols="3" v-if="age._data.hours > 0">
                  <v-card-title  class="override-font">{{age._data.hours}} Hrs </v-card-title>
                    </v-col>
                     <v-col class="EDC-Col" cols="4" v-if="age._data.minutes > 0">
                  <v-card-title  class="override-font">{{age._data.minutes}} Min </v-card-title>
                    </v-col>
                    <!-- <v-col class="EDC-Col" cols="4" v-if="age._data.seconds > 0">
                  <v-card-title  class="override-font">{{age._data.seconds}} Sec </v-card-title>
                    </v-col> -->
                  </v-row>
                   <v-row class="EDC-Row" style="width:100% !important">
                  <v-card-title  class="override-font">Inactive Time</v-card-title>
                  </v-row>
                   <!-- <v-row class="EDC-Row" v-if="inactiveTime" style="width:100% !important">
                     <v-col class="EDC-Col">
                       {{inactiveTime._data.days ? inactiveTime._data.days : '00'}} D:
                     </v-col>
                     <v-col class="EDC-Col">
                       {{inactiveTime._data.hours ? inactiveTime._data.hours : '00'}} Hrs:
                     </v-col>
                     <v-col class="EDC-Col" >
                       {{inactiveTime._data.minutes ? inactiveTime._data.minutes : '00'}} Min:
                     </v-col>
                     <v-col class="EDC-Col">
                       {{inactiveTime._data.seconds}} Sec
                     </v-col>
                  </v-row> -->
                  <v-row class="EDC-Row" v-if="inactiveTime" style="width:100% !important">
                    <v-col class="EDC-Col" cols="3" v-if="inactiveTime._data.years > 0">
                  <v-card-title  class="override-font">{{inactiveTime._data.years}} Y </v-card-title>
                    </v-col>
                  <v-col class="EDC-Col" cols="3" v-if="inactiveTime._data.months > 0">
                  <v-card-title  class="override-font">{{inactiveTime._data.months}} M: </v-card-title>
                    </v-col>
                  <v-col class="EDC-Col" cols="3" v-if="inactiveTime._data.days > 0">
                  <v-card-title  class="override-font">{{inactiveTime._data.days}} D </v-card-title>
                    </v-col>
                    <v-col class="EDC-Col" cols="3" v-if="inactiveTime._data.hours > 0">
                  <v-card-title  class="override-font">{{inactiveTime._data.hours}} Hrs </v-card-title>
                    </v-col>
                     <v-col class="EDC-Col" cols="4" v-if="inactiveTime._data.minutes > 0">
                  <v-card-title  class="override-font">{{inactiveTime._data.minutes}} Min </v-card-title>
                    </v-col>
                    <!-- <v-col class="EDC-Col" cols="4" v-if="inactiveTime._data.seconds > 0">
                  <v-card-title  class="override-font">{{inactiveTime._data.seconds}} Sec </v-card-title>
                    </v-col> -->
                  </v-row>
                </v-card>
              </v-col>
               </v-row>
            </v-col>
            <v-col cols="12" style="background-color:white;padding:0px !important;" v-if="isForEdit" class="EDC-Col columns-alignment" md="5">
              <v-row class="EDC-Row EDC-RowsSpacing" style="width:100% !important">
              <v-col class="EDC-Col" cols="12" style="padding:0px !important;">
              <v-card class="GroupCard overflow-y-auto style-2scroll" style="min-height: 25vh !important; max-height: 28vh !important;" v-if="isForEdit" elevation-1>
              <!-- <legend class="title-fixed">
                
              </legend> -->
              <v-card-title  class="override-font">Contact(Place Holder)</v-card-title>
              <v-row v-if="isForEdit" class="EDC-Row" no-gutters>
              <v-col cols="3"  style="background-color:white" class="EDC-Col">
              <v-row class="EDC-Row"  no-gutters>
              <!-- <v-card style="border-radius: 50%; "> -->
                <!-- src="https://m.media-amazon.com/images/M/MV5BNzEzODA0OTktZTlkNi00NDZjLWE5YjItZDkyZThiMDFjNmI0XkEyXkFqcGdeQXVyMTExNDQ2MTI@._V1_UY1200_CR133,0,630,1200_AL_.jpg" -->
              <!-- <v-img
                src=""
                max-width="125"
                v-if="src !=''"
                max-height="125"
                style="border-radius: 50%; "
                aspect-ratio
                class="grey darken-4"
              ></v-img> -->
              <v-btn  fab text :title="getUserTitle()"  style="height: 100px !important; width: 100px !important;color: #A0A0A0 !important;border: 1px solid #A0A0A0 !important;margin: 6px !important;">
                <span id="profile" style="font-size:60px !important;">{{initials}}</span>
              </v-btn>
            <!-- <v-card-title class="text-h6">
              height
            </v-card-title> -->
          <!-- </v-card> -->
              </v-row>
            </v-col>
            <v-col cols="9"  style="background-color:white" class="EDC-Col">
              <v-row class="EDC-Row" style="padding: 4px !important"  no-gutters>
              <v-col cols="4"  style="background-color:white" class="EDC-Col">
                <span style="float:left;font-weight: bold;" class="override-font">{{first_name}}</span>
              </v-col>
              <v-col cols="6"  style="background-color:white" class="EDC-Col">
                <span style="float:left;" class="override-font">{{occupation ? occupation : 'NA'}}</span>
              </v-col>
              </v-row>
              <v-row class="EDC-Row" style="padding: 4px !important"   no-gutters>
              <v-col cols="4" style="background-color:white" class="EDC-Col">
                <span style="float:left;" class="override-font">Email</span>
              </v-col>
              <v-col cols="6"  style="background-color:white" class="EDC-Col">
                <span style="float:left;" class="override-font">{{email ? email : 'NA'}}</span>
              </v-col>
              </v-row>
              <v-row class="EDC-Row" style="padding: 4px !important"  no-gutters>
              <v-col cols="4" style="background-color:white" class="EDC-Col">
                <span style="float:left;font-size: 14px" class="override-font">Phone</span>
              </v-col>
              <v-col cols="6"  style="background-color:white" class="EDC-Col">
                <span style="float:left;" class="override-font">{{phone_number?phone_number:'NA'}}</span>
              </v-col>
              </v-row>
              <v-row class="EDC-Row" style="padding: 4px !important" no-gutters>
              <v-col cols="4" style="background-color:white" class="EDC-Col">
                <span style="float:left;" class="override-font">Mobile</span>
              </v-col>
              <v-col cols="6"  style="background-color:white" class="EDC-Col">
                <span style="float:left;" class="override-font">{{mobile_number?mobile_number:'NA'}}</span>
              </v-col>
              </v-row>
              <v-row class="EDC-Row" style="padding: 4px !important" no-gutters>
              <v-col cols="4" style="background-color:white" class="EDC-Col">
                <span style="float:left;" class="override-font">Organization</span>
              </v-col>
              <v-col cols="6"  style="background-color:white" class="EDC-Col">
                <span style="float:left;" class="override-font">{{organization?organization:'NA'}}</span>
              </v-col>
              </v-row>
              <v-row class="EDC-Row" style="padding: 4px !important" no-gutters>
              <v-col cols="4" style="background-color:white" class="EDC-Col">
                <span style="float:left;" class="override-font">Location</span>
              </v-col>
              <v-col cols="6"  style="background-color:white" class="EDC-Col">
                <span style="float:left;" class="override-font">{{location?location:'NA'}}</span>
              </v-col>
              </v-row>
              <v-row class="EDC-Row" style="padding: 4px !important" no-gutters>
              <v-col cols="4" style="background-color:white" class="EDC-Col">
                <span style="float:left;" class="override-font">Time Zone</span>
              </v-col>
              <v-col cols="6"  style="background-color:white" class="EDC-Col">
                <span style="float:left;" class="override-font">{{time_zone?time_zone:'NA'}}</span>
              </v-col>
              </v-row>
            </v-col>
          </v-row>
          </v-card>
          </v-col>
          </v-row>
          <v-row class="EDC-Row EDC-RowsSpacing" justify="end" style="width:100% !important">
            <!-- <v-col cols="12" v-if="isForEdit && show_richcomment" class="EDC-Col " align="left" justify="left"  >
                    <comment-text-box  style="backgroud:white;" @onBlur="change_focus('show_normal_text')" @CommentChange="CommentChange" :commentEditorObj="commentEditorObj"></comment-text-box>
                    </v-col> -->
                    <v-col cols="12" v-if="isForEdit"  class="EDC-Col">
                    <v-textarea rows="2" class="border-change mycustomcls"  style="min-width: 97% !important; max-width: 97% !important;"
                      height="70"  :disabled="comment_readonly"  label="Click Here To Post Comment" @focus="change_focus('show_rich_text')" v-model="comment" ></v-textarea>
                    </v-col>
                    
              <!-- <v-col cols="12">
              </v-col> -->
          </v-row>
          <v-row class="EDC-Row"  justify="end">
            <v-col cols="3" v-show="isForEdit  && show_comment_buttons" style="padding-top: 2.5% !important;padding-left: 6% !important;"   class="EDC-Col commentbox-alignment">
                      <span style="float:left;" class="override-font" >Private Note</span>
                    </v-col>
                    <v-col cols="1" v-show="isForEdit  && show_comment_buttons" style="padding-top: 3% !important"   class="EDC-Col commentbox-alignment">
                    <v-checkbox v-model="private_note" ></v-checkbox>
                  </v-col>
                    <v-col cols="1" v-show="isForEdit  && show_comment_buttons"  class="EDC-Col commentbox-alignment">
                    <vc-button class="override-font" itemText="Post" @click.native="addComment" ></vc-button>
                  </v-col>
                  <v-col cols="2" v-show="isForEdit  && show_comment_buttons"  class="EDC-Col commentbox-alignment">
                    <vc-button class="override-font" itemText="Discard" @click.native="change_focus('show_normal_text')"></vc-button>
                  </v-col>
                  <!-- <v-col cols="3">
                    <span>  </span>
                  </v-col> -->
          </v-row>
          <v-row class="EDC-Row " style="width:100% !important;">
            <v-col cols="12" class="EDC-Col columns-alignment">
               <v-card elevation-1  class="GroupCard overflow-x-auto style-2scroll" style="width: 100% !important;max-height:30vh !important;">
                 <!-- <legend class="title-fixed">
              
                </legend> -->
                <v-row no-gutters class="EDC-Row">
                  <v-col cols="2" class="EDC-Col">
                  <v-card-title class="override-font">Activity</v-card-title>
                  </v-col>
                  <!-- <v-row no-gutters class="EDC-Row"  > -->
                      <v-col cols="1" class="EDC-Col" style="padding-top:0.9% !important" v-if="SelectedSort == 'Latest'" align="left">
                            <v-icon title="Oldest" @click="sortActivities('Oldest')">expand_less</v-icon>
                            </v-col>
                      <v-col cols="2" class="EDC-Col" style="padding-top:0.9% !important" v-if="SelectedSort == 'Latest'" align="left">
                            <span style="float:left;padding-top: 3px;" class="override-font">Latest First</span>
                            </v-col>
                          <!-- </v-row> -->
                            <v-col cols="1" class="EDC-Col" align="left"  v-if="SelectedSort != 'Latest'">
                            <v-icon class="override-font" title="Latest" @click="sortActivities('Latest')">expand_more</v-icon>
                            </v-col>
                      <v-col cols="2" class="EDC-Col" align="left" style="padding-top:0.9% !important" v-if="SelectedSort != 'Latest'">
                            <span style="float:left;padding-top: 3px;" class="override-font">Oldest First</span>
                            </v-col>
                  <v-col cols="4" class="EDC-Col">
                  <v-text-field dense label="Search"  clearable v-model="search_text" @keydown.enter="applySearch" prepend-inner-icon="search" autocomplete="off"></v-text-field>
                 </v-col>
                </v-row>
                
                 <v-row no-gutters class="EDC-Row EDC-RowsSpacing">
                 <v-col cols="12" style="background-color:white;padding-left:1% !important" class="EDC-Col">
               
              <v-row v-if="isForEdit" class="EDC-Row commentbox-alignment">
              <v-col cols="12" style="background-color:white" class="EDC-Col">
               <v-row no-gutters class="EDC-Row" style="padding-top:11px !important">
                  <v-col cols="12" class="EDC-Col EDC-ColsSpacing" v-if="isForEdit">
                    <v-list   v-for="(item, index) in activityTableList.rows" :key="item.ticket_id" style="">
                          <v-card :color="getTileColor(index)" v-if="!item.is_private || item.is_private && item.is_private && item.action_by == $session.get('email')" >
                          <v-row no-gutters class="EDC-Row EDC-RowsSpacing">
                            <v-col cols="3" class="EDC-Col EDC-ColsSpacing" align="left">
                            <v-list-item-title style="padding-left:8px !important;" class="override-font">{{item.activity_name}}</v-list-item-title>
                            </v-col>

                            <v-col cols="4" class="EDC-Col EDC-ColsSpacing" align="left">
                            <v-list-item-title class="override-font">{{item.action_by_fullname}}</v-list-item-title>
                            </v-col>
                            <v-col cols="2" class="EDC-Col EDC-ColsSpacing" align="left">                                
                              <v-list-item-title class="override-font">{{item.tz_action_date_time}}</v-list-item-title>
                            </v-col>
                          </v-row>
                            <v-row no-gutters class="EDC-Row EDC-RowsSpacing">
                            <v-col cols="3" class="EDC-Col EDC-ColsSpacing" align="left">                                
                              <v-icon
                                large
                                v-if="item.activity_name == 'COMMENT ADDED'"
                                color="primary"
                                >
                                mdi-message-text
                                </v-icon>
                                <v-icon
                                large
                                v-if="item.activity_name == 'ATTACHMENT ADDED'"
                                color="primary"
                                @click="preview_file(item.related_id, item.content_type)"
                                >
                                attachment
                                </v-icon>
                                <v-icon
                                large 
                                v-if="item.activity_name == 'TICKET CREATED' || item.activity_name == 'DESCRIPTION ADDED'
                                || item.activity_name == 'DESCRIPTION REMOVED' || item.activity_name == 'DESCRIPTION UPDATED'"
                                color="primary"
                                >
                                create
                                </v-icon>
                                <v-icon
                                large
                                v-if="item.activity_name == 'TICKET UPDATED'"
                                color="primary"
                                >
                                create
                                </v-icon>
                                <v-icon
                                large
                                v-if="item.activity_name == 'UPDATED TICKET STATUS'"
                                color="primary"
                                >
                                update
                                </v-icon>
                            </v-col>
                            <v-col cols="8" class="EDC-Col EDC-ColsSpacing" align="left">
                              <v-content>
                              <v-container fluid style="padding: 0px !important;">
                              <!-- <v-list-item v-html="item.description" class="Image-Resize"></v-list-item>  -->
                              <span v-html="item.description" class="override-font" :id="'activity-' + item.log_id"></span> <v-btn v-if="item.activity_name == 'ATTACHMENT ADDED'" @click="preview_file(item.related_id, item.content_type)" color="blue" text style="text-decoration: underline;" v-html="item.file_name"></v-btn>
                              </v-container>
                              </v-content>
                            </v-col>

                          </v-row>
                          </v-card>
                    </v-list>
                  </v-col>
                </v-row>
              </v-col>
                    </v-row>
            </v-col> 
               </v-row> 
               </v-card>   
             </v-col>
          </v-row>
          </v-col>
          </v-row>
        <v-row class="EDC-Row" no-gutters> 
        <v-col cols="12" style="background-color:white;padding-top:0% !important" v-if="isForEdit" class="EDC-Col columns-alignment" md="6">
          <!-- <v-row v-if="isForEdit" class="EDC-Row" style="padding-left:1%!important;padding-bottom:8px !important">
            
            </v-row> -->
        </v-col>
          <v-col cols="12" style="background-color:white" v-if="isForEdit || !isForEdit" class="EDC-Col columns-alignment" md="6">
            
          </v-col>
          <v-col cols="12" style="background-color:white" v-if="isForEdit" class="EDC-Col columns-alignment" md="6">
                  <!-- <v-row class="EDC-Row" style="padding-top:8px !important;margin-top:4px !important; padding-left:0px !important">
                    <v-col cols="12" v-show="showrich_description || !isForEdit" style="padding-left:1% !important;"  class="EDC-Col">
                    <rich-text-box style="backgroud:white;" @onBlur="change_focus('show_rich_description')" @DescriptionChange="DescriptionChange" id="rich-ticketdescription" :vueEditorObj="vueEditorObj"></rich-text-box>
                    </v-col>
                    <v-col cols="12" v-show="isForEdit && !showrich_description" id="normal-comment" style="padding-left:1% !important;"  class="EDC-Col">
                    <normal-comment @onFocus="change_focus('show_rich_description')" @click="change_focus('show_rich_description')"  style="backgroud:white;" @DescriptionChange="DescriptionChange" :disabled="description_readonly" :vueEditorObj="vueEditorObj"></normal-comment>
                    </v-col>
                    </v-row> -->
              <v-row  v-if="!isForEdit" class="EDC-Row" style="padding-top:10px !important" justify="start">
                    <v-col cols="6" class="EDC-Col EDC-ColsSpacing">
                    <v-file-input
                    v-model="files"
                    placeholder="Select File"
                    label="Attachments"
                    single
                    @change="handleFileUpload()"
                    prepend-icon="mdi-paperclip"
                        >
                    <template v-slot:selection="{ text }">
                    <v-chip
                    small
                    label
                    color="primary"
                    >
                    {{ text }}
                  </v-chip>
                  </template>
                  </v-file-input>
                  </v-col>
                  </v-row>
                  <v-row no-gutters class="EDC-Row EDC-RowsSpacing"  v-if="isForEdit" justify="start">
                  <vc-button :disabled="!show_form_buttons || update_ticket_readonly" class="override-font" itemText="Update" @click.native="submit"></vc-button>
                  <vc-button :disabled="!show_form_buttons" class="override-font" style="margin-right:0px !important;" itemText="Cancel" @click.native="cancle"></vc-button>
                  </v-row>
                   <v-row no-gutters class="EDC-Row EDC-RowsSpacing"  v-if="!isForEdit" justify="end">
                  <vc-button v-show="show_form_buttons" :disabled="create_ticket_readonly" itemText="Save" @click.native="submit"></vc-button>
                  <vc-button v-show="show_form_buttons" itemText="Cancel" @click.native="cancle"></vc-button>
                  </v-row>
                  </v-col>
          <v-col cols="12" style="background-color:white;padding-left:1vh !important;padding-top: 0.5% !important;" v-if="isForEdit"  class="EDC-Col" md="6">
            <!-- <v-row v-if="isForEdit" class="EDC-Row" justify="end">
               <v-col cols="12" hidden class="EDC-Col">
                      <pre style="float:left;font-size:16px !important">{{title}}</pre>
                  </v-col>
            </v-row>
            <v-row v-if="isForEdit" class="EDC-Row"  align= "left">
                      <v-col cols="2"    v-if="isForEdit" class="EDC-Col EDC-ColsSpacing columns-alignment">
                      <v-btn
                        
                        :disabled="status.status =='Closed' || action_readonly"
                        style="margin-top:-5px !important;height:40px !important;margin-right:8px !important;margin-bottom:4px !important;"
                        @click.native="onCloseAction('Resolved')"
                        >
                        <span style="color: gray"> Close</span>
                        </v-btn>
                    </v-col>
                      <v-col cols="3"  v-if="isForEdit" class="EDC-Col EDC-ColsSpacing columns-alignment"> 
                    <v-autocomplete  ref="other_actions" :readonly="action_readonly" v-model="ticket_action" @change="onCloseAction(ticket_action)" clearable :items="ticket_action_list" item-text="id" item-value="id" outlined dense  label="Other Actions"></v-autocomplete>
                    </v-col>
            </v-row> -->
            <!-- <v-card class="GroupCard" style="min-height: 271px !important; max-height: 271px !important;" v-if="isForEdit" elevation-1>
            <legend class="title-fixed">
              <v-card-title  style="font-size: 14px">Contact(Place Holder)</v-card-title>
            </legend>
            <v-row v-if="isForEdit" class="EDC-Row" no-gutters>
            <v-col cols="2"  style="background-color:white" class="EDC-Col">
              <v-row class="EDC-Row"  no-gutters>
              <v-card>
            <v-img
              src="https://m.media-amazon.com/images/M/MV5BNzEzODA0OTktZTlkNi00NDZjLWE5YjItZDkyZThiMDFjNmI0XkEyXkFqcGdeQXVyMTExNDQ2MTI@._V1_UY1200_CR133,0,630,1200_AL_.jpg"
              max-width="125"
              max-height="125"
              
              aspect-ratio
              class="grey darken-4"
            ></v-img>
          </v-card>
              </v-row>
            </v-col>
            <v-col cols="8"  style="background-color:white" class="EDC-Col">
              <v-row class="EDC-Row" style="padding: 4px !important"  no-gutters>
              <v-col cols="2"  style="background-color:white" class="EDC-Col">
                <span style="float:left;font-weight: bold;font-size: 14px">Jhony Walker</span>
              </v-col>
              <v-col cols="3"  style="background-color:white" class="EDC-Col">
                <span style="float:left;font-size: 14px">Drink Specialist</span>
              </v-col>
              </v-row>
              <v-row class="EDC-Row" style="padding: 4px !important"   no-gutters>
              <v-col cols="2" style="background-color:white" class="EDC-Col">
                <span style="float:left;font-size: 14px">Email</span>
              </v-col>
              <v-col cols="3"  style="background-color:white" class="EDC-Col">
                <span style="float:left;font-size: 14px">JWalker@daaru.com</span>
              </v-col>
              </v-row>
              <v-row class="EDC-Row" style="padding: 4px !important"  no-gutters>
              <v-col cols="2" style="background-color:white" class="EDC-Col">
                <span style="float:left;font-size: 14px">Phone</span>
              </v-col>
              <v-col cols="3"  style="background-color:white" class="EDC-Col">
                <span style="float:left;font-size: 14px">+91 0226668926</span>
              </v-col>
              </v-row>
              <v-row class="EDC-Row" style="padding: 4px !important" no-gutters>
              <v-col cols="2" style="background-color:white" class="EDC-Col">
                <span style="float:left;font-size: 14px">Mobile</span>
              </v-col>
              <v-col cols="3"  style="background-color:white" class="EDC-Col">
                <span style="float:left;font-size: 14px">+91 02512542343</span>
              </v-col>
              </v-row>
              <v-row class="EDC-Row" style="padding: 4px !important" no-gutters>
              <v-col cols="2" style="background-color:white" class="EDC-Col">
                <span style="float:left;font-size: 14px">Organization</span>
              </v-col>
              <v-col cols="3"  style="background-color:white" class="EDC-Col">
                <span style="float:left;font-size: 14px">Bollywood</span>
              </v-col>
              </v-row>
              <v-row class="EDC-Row" style="padding: 4px !important" no-gutters>
              <v-col cols="2" style="background-color:white" class="EDC-Col">
                <span style="float:left;font-size: 14px">Location</span>
              </v-col>
              <v-col cols="3"  style="background-color:white" class="EDC-Col">
                <span style="float:left;font-size: 14px">Mumbai,Maharashtra,India</span>
              </v-col>
              </v-row>
              <v-row class="EDC-Row" style="padding: 4px !important" no-gutters>
              <v-col cols="2" style="background-color:white" class="EDC-Col">
                <span style="float:left;font-size: 14px">Time Zone</span>
              </v-col>
              <v-col cols="3"  style="background-color:white" class="EDC-Col">
                <span style="float:left;font-size: 14px">IST</span>
              </v-col>
              </v-row>
            </v-col>
          </v-row>
          </v-card> -->
                    
          </v-col>
        </v-row>
        <v-row class="EDC-Row" no-gutters>  
                <v-form ref="ticket_details_form" style="width: 100% !important">
                <v-row class="EDC-Row">
                <v-col cols="12" style="background-color:white" v-if="!isForEdit" class="EDC-Col" md="4">
                
                    <!-- <v-row class="EDC-Row" v-if="isForEdit" style="padding-top:5px !important">
                    </v-row> -->
                    <v-row v-if="!isForEdit" class="EDC-Row" >
                    <v-col cols="8" class="EDC-Col">
                    <!-- <v-text-field label="Title" v-model="title" append-icon="edit" @click:append="editAction('title')" :readonly="title_readonly" dense></v-text-field> -->
                    <v-text-field label="Title" v-model="title" :readonly="title_readonly" :rules="titleRules" autocomplete="off" dense></v-text-field>
                     </v-col>
                    </v-row>
                     <v-row v-if="!isForEdit" hidden class="EDC-Row" style="padding-top:6px !important">
                    <v-col cols="9" class="EDC-Col" style="padding-left:6px !important">
                    <v-text-field label="Url" v-model="url"  readonly autocomplete="off" dense></v-text-field>
                     </v-col>
                    </v-row>
                    <v-row v-if="!isForEdit" class="EDC-Row" style="padding-top:5px !important;padding-bottom:8px !important">
                    <v-col cols="8" class="EDC-Col">
                      <v-autocomplete class="mycustomcls"  :filter="queueCustomFIlter"  :disabled="queue_readonly"  style="float:left" v-model="queue" hide-details clearable :items="queue_list" item-text="queue_name" item-value="id" return-object  label="Select Queue"></v-autocomplete>
                      <!-- <v-combobox
                        v-model="tag"
                        :items="tag_list"                        
                        item-text="tag_name"
                        item-value="id"
                        :search-input.sync="search"
                        hide-selected
                        :disabled="tag_readonly"
                        label="Add tags"
                        multiple
                        
                        
                        
                        validate-on-blur
                        @keydown.enter="addTag(search)"
                        small-chips
                      >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title v-if="search">
                              No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ attrs, item, parent, selected }">
										<v-chip
										v-if="item === Object(item)"
										v-bind="attrs"
										:input-value="selected"
										label
										small
										>
										<span class="pr-2">
											{{ item.tag_name }}
										</span>
										<v-icon
											small
											@click="parent.selectItem(item)"
										>
											$delete
										</v-icon>
										</v-chip>
									</template>
                    </v-combobox> -->
                    
                     
                     </v-col>
                    </v-row>
                    <v-row v-if="!isForEdit" class="EDC-Row" style="padding-top:5px !important;">
                      <v-col cols="8" class="EDC-Col">
                    <v-autocomplete :clearable="!priority_readonly" class="mycustomcls" style="float:left" label="Select Priority" ref="priority" :readonly="priority_readonly"  v-model="priority"  validate-on-blur item-text="name" return-object :items="priority_list" dense></v-autocomplete>
                     </v-col>
                    </v-row>
                    <v-row v-if="!isForEdit" class="EDC-Row" style="padding-bottom:8px !important">
                      <v-col cols="8" class="EDC-Col">
                    <v-autocomplete :clearable="!assignee_readonly" class="mycustomcls" style="float:left" :rules="assigneeRule" label="Select Assignee" ref="status" :readonly="assignee_readonly"  v-model="assignee" @change="assigne_fullname = assignee ? assignee.full_name_with_user_name : ''" return-object item-text="full_name_with_user_name" :items="user_list" dense></v-autocomplete>
                     </v-col>
                    </v-row>
                    <!-- <v-row class="EDC-Row" v-if="!isForEdit"  style="padding-top:8px !important;">
                    
                    </v-row>
                    <v-row class="EDC-Row" v-if="!isForEdit" style="padding-bottom:10px !important">
                    
                    </v-row> -->
                    
                  </v-col>
                  <v-col cols="12" style="background-color:white" v-if="!isForEdit" class="EDC-Col" md="8">
                    <v-row class="EDC-Row" >
                    <v-col cols="12" v-show="!isForEdit"  class="EDC-Col">
                    <!-- <rich-text-box style="backgroud:white;" @onBlur="change_focus('show_rich_description')" @DescriptionChange="DescriptionChange" id="rich-ticketdescription" :vueEditorObj="vueEditorObj"></rich-text-box> -->
                    <v-textarea rows="2"  style="padding-top:0px !important;margin-top:0px !important"
                      height="9vh"  :disabled="description_readonly" label="Description"  v-model="description" ></v-textarea>
                    </v-col>
                    <!-- <v-col cols="12" v-show="isForEdit && !showrich_description" id="normal-comment"  class="EDC-Col">
                    <normal-comment @onFocus="change_focus('show_rich_description')" @click="change_focus('show_rich_description')"  style="backgroud:white;" @DescriptionChange="DescriptionChange" :disabled="description_readonly" :vueEditorObj="vueEditorObj"></normal-comment>
                    </v-col> -->
                    </v-row>
                    <v-row class="EDC-Row" no-gutters>
                      <v-col cols="12" style="background-color:white;" v-if="!isForEdit" class="EDC-Col">
                      <v-card class="GroupCard overflow-y-auto style-2scroll " height="100" width="100%"  v-if="!isForEdit" elevation-1>
                    <!-- <legend class="title-fixed">
                      
                    </legend> -->
                    <v-card-title  style="font-size: 14px">Attachment List</v-card-title>
                    <v-row v-if="!isForEdit" class="EDC-Row" no-gutters>
                    <v-col     style="background-color:white" class="EDC-Col">
                      <v-row class="EDC-Row" no-gutters>
                      <v-list   v-for="(item,index) in files" style="padding-right:1% !important;" :key="item.name" >
                        <v-row class="EDC-Row" no-gutters>
                        <v-card :color="getTileColor(index)" min-height="30px" >
                          <v-col cols="12"   class="EDC-Col  EDC-ColsSpacing">
                            <span v-if="item.name.length <= 13" style="padding-left:3px !important;padding-right:3px !important;cursor: pointer;" @click="view_file(index)">{{item.name}}</span>
                          <span v-if="item.name.length > 13" style="padding-left:3px !important;padding-right:3px !important;cursor: pointer;" @click="view_file(index)">{{item.name.split('.')[0].slice(0, 9)+'...'+item.name.split('.')[1]}}</span>
                          <v-icon class="svg-icon-grid svg-fill-grid"  :disabled="attachment_delete_readonly"   @click="removeAttachmentFromList(index)" title="Close">highlight_off</v-icon>
                          </v-col>
                        </v-card>
                        </v-row>
                        <v-row class="EDC-Row" no-gutters>
                        </v-row>
                      </v-list>
                      <v-btn
                        rounded
                        style="height:2% !important;margin-right:8px !important;margin-top:1% !important;padding-left:1% !important;"
                         @click="addAttachmentlist()"
                        >
                        <v-icon class="svg-icon-grid svg-fill-grid" small style="padding-top:4% !important;padding-left:4% !important;"  @click="addAttachmentlist()"  title="Add Attachment">add</v-icon>
                        <span style="color: gray;"> Add</span>
                      </v-btn>
                      <input type="file" ref="file_input" multiple="single" hidden @change="handleFileUpload()">
                      </v-row>
                    </v-col>
                  </v-row>
                  </v-card>
                    </v-col>
            </v-row>
              <!-- <v-row  v-if="!isForEdit" class="EDC-Row" style="padding-top:10px !important" justify="start">
                    <v-col cols="6" class="EDC-Col EDC-ColsSpacing">
                    <v-file-input
                    v-model="files"
                    placeholder="Select File"
                    label="Attachments"
                    ref="fileInput"
                    single
                    @change="handleFileUpload()"
                    prepend-icon="mdi-paperclip"
                        >
                    <template v-slot:selection="{ text }">
                    <v-chip
                    small
                    label
                    color="primary"
                    >
                    {{ text }}
                  </v-chip>
                  </template>
                  </v-file-input>
                  </v-col>
                  </v-row> -->
              </v-col>
                  
              <v-row no-gutters class="EDC-Row EDC-RowsSpacing"  v-if="!isForEdit" justify="start">
                  <vc-button v-show="show_form_buttons" :disabled="create_ticket_readonly"  itemText="Save" @click.native="submit"></vc-button>
                  <vc-button v-show="show_form_buttons" itemText="Cancel" @click.native="cancle"></vc-button>
                  </v-row>
              <v-row class="EDC-Row EDC-RowsSpacing" v-if="!isForEdit" no-gutters justify="center">
                <v-col cols="5"  class="EDC-Col">
               <v-row justify="start">
                <v-img
                  :src="selected_image"
                  lazy-src=""
                  width="30wv"
                  height="25vh"
                  contain
                  @dblclick="selected_image?overlay = true:''"
                >
                 <v-btn color="primary" v-if="selected_image != ''"  x-small rounded fab style="float:right;height:1.2em;width:1.2em">
                  <!-- <v-icon>close</v-icon> -->
                  <svgicon  class="svg-icon-grid svg-fill-toolbar" name="cancel_v2"  :original="true" @click="closePreview" title="Close"></svgicon>
                  </v-btn>
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                    <span class="override-font" style="float:center;font-weight: bold;background-color:blue;">Attachment Preview</span>
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-row>
                </v-col>
          </v-row>
              </v-row>
                  </v-form>
              <!-- <v-row v-if="isForEdit" class="EDC-Row" justify="end">
               <v-col cols="12" hidden class="EDC-Col">
                      <pre style="float:left;font-size:16px !important">{{title}}</pre>
                  </v-col>
            </v-row> -->
            
            <v-col cols="12" style="background-color:white" v-if="isForEdit" class="EDC-Col columns-alignment" md="6">
            <v-row v-if="isForEdit" class="EDC-Row"  justify="end">
                      <!-- <v-col cols="3"  v-if="isForEdit"   class="EDC-Col EDC-ColsSpacing columns-alignment"> 
                      <v-autocomplete  ref="other_actions" style="float:right" :readonly="action_readonly" v-model="ticket_action"  clearable :items="ticket_action_list" item-text="name" item-value="id" outlined dense  label="Resolution Status"></v-autocomplete>
                      </v-col>
                      <v-col cols="1"    v-if="isForEdit" class="EDC-Col EDC-ColsSpacing columns-alignment">
                      <v-btn
                        
                        :disabled="status.status =='Closed' || action_readonly"
                        style="margin-top:-5px !important;height:40px !important;margin-right:8px !important;margin-bottom:4px !important;"
                        @click.native="onCloseAction('Resolved')"
                        >
                        <span style="color: gray"> Close</span>
                        </v-btn>
                    </v-col> -->
            </v-row>
            </v-col>
            <!-- <v-col cols="12" style="background-color:white;padding-left:0.5% !important" v-if="isForEdit" class="EDC-Col columns-alignment" md="6">
              <v-row v-if="isForEdit" class="EDC-Row commentbox-alignment">
                      <v-col cols="12" v-if="isForEdit && show_richcomment" class="EDC-Col " align="left" justify="left"  >
                    <comment-text-box  style="backgroud:white;" @onBlur="change_focus('show_normal_text')" @CommentChange="CommentChange" :commentEditorObj="commentEditorObj"></comment-text-box>
                    </v-col>
                    <v-col cols="12" v-if="isForEdit && !show_richcomment"  class="EDC-Col">
                    <v-textarea rows="1" outlined :disabled="comment_readonly"  label="Click Here To Post Comment" @focus="change_focus('show_rich_text')" v-model="comment"  dense></v-textarea>
                    </v-col>
                    <v-col cols="1" v-if="isForEdit && show_richcomment && show_comment_buttons"  class="EDC-Col commentbox-alignment">
                    <vc-button itemText="Post" @click.native="addComment"></vc-button>
                  </v-col>
                  <v-col cols="1" v-if="isForEdit && show_richcomment && show_comment_buttons"  class="EDC-Col commentbox-alignment">
                    <vc-button itemText="Discard" @click.native="change_focus('show_normal_text')" ></vc-button>
                  </v-col>
                    </v-row>
            </v-col> -->
          </v-row>
           <!-- <v-row no-gutters v-if="isForEdit" class="EDC-Row">
             <v-col cols="12" class="EDC-Col columns-alignment" md="6">
               <v-card elevation-1  class="GroupCard" style="width: 100% !important">
                 <legend class="title-fixed">
              <v-card-title style="font-size: 14px;">Ticket History</v-card-title>
                </legend>
                 <v-row no-gutters class="EDC-Row EDC-RowsSpacing">
                 <v-col cols="12" style="background-color:white;padding-left:1% !important" class="EDC-Col">
               <v-row no-gutters class="EDC-Row columns-alignment">
                </v-row>
                <v-row no-gutters class="EDC-Row EDC-RowsSpacing">
                 <v-col cols="4" class="EDC-Col">
                <v-text-field label="Search"  clearable v-model="search_text" @keydown.enter="applySearch" prepend-inner-icon="search" autocomplete="off"></v-text-field>
                 </v-col>
               </v-row>
                    <v-row v-if="isForEdit" class="EDC-Row commentbox-alignment">
                      <v-col cols="12" style="background-color:white" class="EDC-Col">
              <v-row no-gutters v-if="isForEdit && activityTableList.rows.length > 1" class="EDC-Row" >
             <v-col cols="12" class="EDC-Col" >
                    <v-row no-gutters class="EDC-Row EDC-RowsSpacing" v-if="SelectedSort == 'Latest'" >
                      <v-col cols="1" class="EDC-Col" align="left">
                            <v-icon title="Oldest" @click="sortActivities('Oldest')">expand_less</v-icon>
                            </v-col>
                      <v-col cols="3" class="EDC-Col" align="left">
                            <span style="float:left;padding-top: 3px;">Latest First</span>
                            </v-col>
                          </v-row>
                    <v-row no-gutters class="EDC-Row EDC-RowsSpacing" v-else>
                            <v-col cols="1" class="EDC-Col" align="left">
                            <v-icon title="Latest" @click="sortActivities('Latest')">expand_more</v-icon>
                            </v-col>
                      <v-col cols="3" class="EDC-Col" align="left">
                            <span style="float:left;padding-top: 3px;">Oldest First</span>
                            </v-col>
                          </v-row>
             </v-col>
           </v-row  >
               <v-row no-gutters class="EDC-Row" style="padding-top:11px !important">
                  <v-col cols="12" class="EDC-Col EDC-ColsSpacing" v-if="isForEdit">
                    <v-list   v-for="(item, index) in activityTableList.rows" :key="item.ticket_id" >
                          <v-card :color="getTileColor(index)" >
                          <v-row no-gutters class="EDC-Row EDC-RowsSpacing">
                            <v-col cols="3" class="EDC-Col EDC-ColsSpacing" align="left">
                            <v-list-item-title style="padding-left:8px !important;">{{item.activity_name}}</v-list-item-title>
                            </v-col>

                            <v-col cols="4" class="EDC-Col EDC-ColsSpacing" align="left">
                            <v-list-item-title>{{item.action_by_fullname}}</v-list-item-title>
                            </v-col>
                            <v-col cols="2" class="EDC-Col EDC-ColsSpacing" align="left">                                
                              <v-list-item-title>{{item.tz_action_date_time}}</v-list-item-title>
                            </v-col>
                          </v-row>
                            <v-row no-gutters class="EDC-Row EDC-RowsSpacing">
                            <v-col cols="3" class="EDC-Col EDC-ColsSpacing" align="left">                                
                              <v-icon
                                large
                                v-if="item.activity_name == 'COMMENT ADDED'"
                                color="primary"
                                >
                                mdi-message-text
                                </v-icon>
                                <v-icon
                                large
                                v-if="item.activity_name == 'ATTACHMENT ADDED'"
                                color="primary"
                                @click="preview_file(item.related_id, item.content_type)"
                                >
                                attachment
                                </v-icon>
                                <v-icon
                                large 
                                v-if="item.activity_name == 'TICKET CREATED' || item.activity_name == 'DESCRIPTION ADDED'
                                || item.activity_name == 'DESCRIPTION REMOVED' || item.activity_name == 'DESCRIPTION UPDATED'"
                                color="primary"
                                >
                                create
                                </v-icon>
                                <v-icon
                                large
                                v-if="item.activity_name == 'TICKET UPDATED'"
                                color="primary"
                                >
                                create
                                </v-icon>
                                <v-icon
                                large
                                v-if="item.activity_name == 'UPDATED TICKET STATUS'"
                                color="primary"
                                >
                                update
                                </v-icon>
                            </v-col>
                            <v-col cols="8" class="EDC-Col EDC-ColsSpacing" align="left">
                              <v-content>
                              <v-container fluid style="padding: 0px !important;">
                              <span v-html="item.description" :id="'activity-' + item.log_id"></span> <v-btn v-if="item.activity_name == 'ATTACHMENT ADDED'" @click="preview_file(item.related_id, item.content_type)" color="blue" text style="text-decoration: underline;" v-html="item.file_name"></v-btn>
                              </v-container>
                              </v-content>
                            </v-col>

                          </v-row>
                          </v-card>
                    </v-list>
                  </v-col>
                </v-row>
              </v-col>
                    </v-row>
            </v-col> 
               </v-row> 
               </v-card>   
             </v-col>
           </v-row  > -->
        </v-card>
    </v-container>

      <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
    <v-dialog v-model="show_preview" width="auto">
        	<v-col cols="12" class="EDC-Col">
	          	<v-card elevation-1>
                <v-row no-gutters class="EDC-Row">
	             		<v-toolbar dark dense absolute class="EDC-Toolbar" style="width:100%; padding-bottom:10px;border-radius:0px !important;">
                    <v-toolbar-title class="EDC-ToolbarTitle">Preview</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-spacer v-for="index in 24" :key="index"></v-spacer>
				<v-toolbar-items style="padding-top:12px !important;">
                	<svgicon style="float:right" v-if="showFileViewer || showImageViewer" class="svg-icon-grid svg-fill-toolbar"  name="download_v2" slot="activator" :original="true"  title="Download File"  @click="createTempFile()"></svgicon>

					<!-- <svgicon v-if="showTextViewer" class="svg-icon-grid svg-fill-toolbar"  name="download_v2" slot="activator" :original="true"  title="Download File" @click="createTempFile()"></svgicon> -->
				</v-toolbar-items>
        <v-spacer></v-spacer>
                <svgicon  class="svg-icon-grid svg-fill-toolbar" name="cancel_v2"  :original="true" @click="closePanel" title="Close"></svgicon>
	              		</v-toolbar>
	            	</v-row>
                <v-row class="EDC-Row EDC-RowsSidePadding" style="padding-top:28px !important;">
	            	 <v-col class="EDC-Col EDC-ContainerPadding" style="width:410px;height:410px;display:grid !important;">
      			    <object v-show="showImageViewer" id="imageviewer_v1" style="margin: auto" :data="imgFilePath" :type="imgFileContentType">
      				    Unable to view the file.
      			  </object>
              <object v-show="showFileViewer" id="fileviewer_v1" width="425" height="400" :data="filePath" :type="fileContentType">
      				Unable to view the file.
      			</object>
      			<!-- <div style="overflow-y:auto !important;height:400px !important;" class="style-2scroll">
      			 	<v-textarea v-if="showTextViewer" class="pa-2"  name="input-7-1" filled disabled auto-grow :value="normalTextData"></v-textarea>
      			</div> -->
      		    </v-col>
                </v-row>
	          	</v-card>
	        </v-col>
    </v-dialog>
    <v-dialog v-model="dialog" width="300">
      	<v-form ref="ticket_form">
        	<v-col cols="12" class="EDC-Col">
	          	<v-card elevation-1>
	            	<v-row no-gutters class="EDC-Row">
	             		<v-toolbar dark dense absolute style="width:100%">
                      <v-toolbar-title class="EDC-ToolbarTitle">Ticket ID: {{ task_name }}</v-toolbar-title>
                    <v-spacer></v-spacer>
	                		<!-- <span><b>Ticket ID: {{ task_name }}</b></span> -->
                      <svgicon  class="svg-icon-grid svg-fill-toolbar" name="cancel_v2"  :original="true" @click="closePanel" title="Close"></svgicon>
	              		</v-toolbar>
	            	</v-row>   
	            	<v-row no-gutters v-if="showActionList" class="EDC-Row EDC-RowsSpacing">
	             		<v-text-field v-if="action_type === 'other'" v-model="reason" label="Enter comment" :rules="reasonRules" required @keypress="reasonKeypress" class="EDC-TextField" hide-details autocomplete="off" dense></v-text-field>
	            		<v-text-field v-else v-model="reason" label="Enter comment" class="EDC-TextField" hide-details autocomplete="off" dense></v-text-field>
	            	</v-row>
                <v-row no-gutters v-if="!showActionList"  style="padding-top:60px !important;" class="EDC-Row EDC-RowsSpacing">
	             		<v-text-field v-if="action_type === 'other'" v-model="reason" label="Enter comment" :rules="reasonRules" required @keypress="reasonKeypress" class="EDC-TextField" hide-details autocomplete="off" dense></v-text-field>
	            		<v-text-field v-else v-model="reason" label="Enter comment" class="EDC-TextField" hide-details autocomplete="off" dense></v-text-field>
	            	</v-row>
	            	<v-row no-gutters class="EDC-Row EDC-RowsSpacing" justify="end">
	              		<vc-button itemText="Done" @click.native="ticket_action_update"></vc-button>
	            	</v-row> 
	          	</v-card>
	        </v-col>
      	</v-form>
    </v-dialog>
    
    <loading-panel :loader="loader"></loading-panel>
    <v-dialog v-model="show_confirm_delete" width="300">
        	<v-col cols="12" class="EDC-Col">
	          	<v-card elevation-1>
	            	<v-row no-gutters class="EDC-Row">
	             		<v-toolbar dark dense absolute style="width:100%">
                      <v-toolbar-title class="EDC-ToolbarTitle">Confirmation</v-toolbar-title>
                    <v-spacer></v-spacer>
	                		<!-- <span><b>Ticket ID: {{ task_name }}</b></span> -->
                      <svgicon  class="svg-icon-grid svg-fill-toolbar" name="cancel_v2"  :original="true" @click="closePanel" title="Close"></svgicon>
	              		</v-toolbar>
	            	</v-row>
	            	<v-row no-gutters class="EDC-Row EDC-RowsSpacing" style="padding-top:60px !important;"  justify="center">
	              		<span>Are you sure you want to delete attachment</span>
	            	</v-row> 
                <v-row no-gutters class="EDC-Row EDC-RowsSpacing"  justify="center">
	              		<vc-button itemText="Delete" @click.native="removeAttachment()"></vc-button>
	            	</v-row> 
	          	</v-card>
	        </v-col>
    </v-dialog>
    </div> 
</template>
<script>
var moment = require("moment-timezone")
import config from '../../config.json'
import {COMMENT_TABLE_LIST, ACTIVITY_TABLE_LIST, ATTACHEMENT_TABLE_LIST} from './ticketMetaData.js'
import {
  post as postToServer
} from './../../methods/serverCall.js';
import {
  SERVER_ERROR
} from '../../data/client_message.js'
import {
  get as getToServer
} from './../../methods/serverCall.js';
import cloneDeep from 'lodash/cloneDeep';
import { GET_ALL_USER,DECRYPT_URL_DATA,GET_TICKET_SUMMARY,GET_TICKET_COMMENTS,GET_TICKET_LOGS,GET_RECENT_TICKET_LOGS,GET_TICKET_STATUS,
          GET_TICKET_PRIORITY,GET_TICKET_DETAILS_BY_ID,MANAGE_COMMENT,UPDATE_TICKET,CREATE_TICKET_URL,GET_TICKET_ATTACHMENTS,MANAGE_ATTACHEMENTS,GET_TICKET_DETAILS,
          DELETE_TICKET_ATTACHMENT,SEARCH_TAGS,CREATE_TAG,GET_ACTIONS_BY_GROUP_ID_LIST,GET_ALL_TAGS,UPDATE_TICKET_STATUS,GET_USERS_FROM_QUEUE, GET_ALL_QUEUES,
          GET_TICKET_ACTION, GET_CONFIG_DATA} from '../../data/url_constants.js'
import RichTextBox from "@/views/richTextEditor/index.vue";
import {Snackbar_Default_Timeout, GRID_STATUS_COLOR_CODE, Ticket_management} from '@/constants/constants.js'
import CommentBox from "@/views/richTextEditor/commentRichText.vue";
import NormalCommentBox from "@/views/richTextEditor/commentNoToolbar.vue";
import {getUserRole, getTicketRole} from './../../methods/GetUserRole.js'
import {UPDATE_ACTION, TICKET_MANAGEMENT_SERVICE, CREATE_ACTION, TAG_SERVICE, DELETE_ACTION, CREATE_TICKET, CHANGE_DESCRIPTION, CREATE_TICKET_ACTION, CREATE_ATTACHMENT, CREATE_COMMENT,
        DELETE_ATTACHMENT, DELETE_TICKET, READ_TICKET_ACTION, READ_ATTACHMENT, READ_COMMENT, READ_TICKET, UPDATE_TICKET_ACTION, CHANGE_TICKET_QUEUE, CHANGE_TICKET_ASSIGNEE} from '../../data/macros';
import {getActionByGroup} from './../../methods/TicketActionsAndQueueList.js'
// import Breadcrumb from "../Breadcrumbs.vue"
export default {
  name: 'TicketDetails',
  props:{
    callFrom:String,
    ticketObj:Object,
  },
  data: function () {
    return {
      userRole:{},
      items: [
        { title: 'Click Me 1' },
        { title: 'Click Me 2 ' },
        { title: 'Click Me 3' },
      ],
      loader:false,
      snackbartext:'',
      activetab:0,
      ticket_id:'',
      dialog:false,
      task_name:'',
      task_status:'',
      reason:"",
      showActionList:true,
      show_confirm_delete:false,
      current_attachment:'',
      current_attachment_name:'',
      selected_image:'',
      ticket_action_list:[{name:"Resolved",
                          id:"Resolved"},
                          {name:"Unable to reproduce issue",
                          id:"Invalid"},
                          {name:"Duplicate",
                          id:"Duplicate"},
                          // {name:"On-hold",
                          // id:"On-hold"},
                          // {name:"Closed",
                          // id:"Other"},
                          // {name:"Add Attachment",
                          // id:"Add Attachment"}
                          ],
      action_list:["TICKET CREATED","UPDATED TICKET STATUS","COMMENT ADDED","ATTACHMENT ADDED"],                          
      close_action_list:[{name:"Open",
                          id:"Open"},],
      action_type:'Resolved',
      reasonRules:[
				v => !!v || 'User comments are required'
            	],
      titleRules:[v => !!v || 'Title is required'],
      tagRules:[v => !!v.length >0 || 'Tags are required'],
      priorityRules:[v => !_.isEmpty(v)|| 'Priority is required'],
      assigneeRule: [],
      colorValue: 'error',
      snackbar:false,
      ticketsummary:[],
      priority_id:'',
      filterList:['User','Action',],
      SelectedFilter:{user:'',action:''},
      sortList:['User','Action','Latest', 'Oldest'],
      SelectedSort:['Latest'],
      url:'',
      Summary_txt:'Summary',
      NoDataFound:'No data found',
      commenTabletList: _.cloneDeep(COMMENT_TABLE_LIST),
      attachementTableList:_.cloneDeep(ATTACHEMENT_TABLE_LIST),
      activityTableList: _.cloneDeep(ACTIVITY_TABLE_LIST),
      snackbartimeout: Snackbar_Default_Timeout,
      title:'',
      show_preview:false,
      title_readonly:false,
      comment_readonly:false,
      attachment_readonly:false,
      attachment_delete_readonly:false,
      action_readonly:false,
      description_readonly:false,
      queue_readonly:false,
      priority_readonly:false,
      status_readonly:false,
      assignee_readonly:false,
      create_ticket_readonly:false,
      update_ticket_readonly:false,
      commentEditorObj:{},
      description:'',
      show_form_buttons:false,
      show_comment_buttons:false,
      old_description:'',
      old_priority:{},
      old_tag:[],
      old_queue:[],
      old_assignee:'',
      assignee:{},
      assigne_fullname:'',
      status:{},
      status_txt:'',
      sub_status:'',
      show_richcomment:false,
      show_normalcomment:true,
      showrich_description:true,
      resource_type:'',
      assigned_by:'',
      user_list:[],
      imgFilePath:'',
      imgFileContentType:'',
      fileContentType:'',
      filePath:'',
      pathUrl:'',
      ticket_action:'',
      showImageViewer:false,
      showFileViewer:false,
      vueEditorObj:{},
      priority:{},
      notForEdit: true,
      tag:[],
      status_list:[],
      priority_list:[],
      comment:'',
      files:[],
      oldFiles:[],
      attached_files:[],
      tz_assigned_date_time:'',
      created_by: '',
      age:'',
      tz_created_date_time:'',
      recentLogItems:[],
      search_text:'',
      highlight_txt:'',
      tag_list:[],
      search:'',
      private_note:false,
      overlay:false,
      show_priority:false,
      show_assignee:false,
      initials:'',
      queue:'',
      queue_list:[],
      first_name:'',
      email:'',
      last_name:'',
      actions_by_group:[],
      envIpJson : {
          "filter": [],
          "sort": [],
          "page": 1,
          "page_size": 1000
      },
      is_closed_ticket:false
    }
  },
  mounted() {
    debugger;
    var _this = this
    this.is_closed_ticket = this.$route.params.is_closed_ticket
    this.userRole = this.$session.get('user_role_mapping');
    //If user opens create ticket dialogue again we clear the values
    // if(this.callFrom == "create_dialog")
    //     this.resetForm()
    this.getQueueList()
    getActionByGroup(this)
    this.email = this.$session.get('email')
     if(this.$session.get('last_name') && this.$session.get('last_name').length > 0){
          this.initials = this.$session.get('first_name').charAt(0).toUpperCase() + this.$session.get('last_name').charAt(0).toUpperCase()
          this.first_name = this.$session.get('first_name') + ' ' + this.$session.get('last_name')
        }
        else{
          this.initials = this.$session.get('first_name').charAt(0).toUpperCase()
          this.first_name = this.$session.get('first_name')
         
        }
    if(this.$route.params.ticket_id){
      this.ticket_id = this.$route.params.ticket_id
      this.getTicketSummery()
      this.getAttachements()
    }
    if(this.$route.query.ticket_id){
      // this.tokenverify()
      this.notForEdit = true
      window.sessionStorage.setItem('lastUrl', this.$route.fullPath);
    }
    if(this.ticketObj){
      this.url = this.ticketObj.url
    }
     this.attachementTableList.actions=[
            {'text':'attachment','key':"ticket_id",index:1},
            {'text':'delete','key':"ticket_id", selectType:"single", role:true,index:2},
            ];
    if(!this.isForEdit){
      this.getTicketStatus()
      this.getActivityLogs(this.envIpJson)
      
    }
      this.GetUserList()
      // this.getAllTags()
     this.getPriorityList()
     this.getTicketActionList()
     this.getDefaultValues()
    if(this.isForEdit && this.notForEdit){
      this.getTicketDetails()
      this.getActivityLogs(this.envIpJson)
      this.showrich_description = false
    }
    if(this.$route.params.activity_id && this.$route.params.search_param){
      var _this = this
      setTimeout(function () {
                var scrollTo = document.getElementById('activity-' + _this.$route.params.activity_id)
                // var position = scrollTo.getBoundingClientRect();
                // var x = position.left;
                // var y = position.top;
                scrollTo.scrollIntoView();
                 _this.highlight_txt = _this.$route.params.search_parama
                scrollTo.innerHTML = scrollTo.innerHTML.replace(new RegExp(_this.highlight_txt, "gi"), (match) => `<mark  style="padding: 0px !important;background-color: #68FF33 !important;">${match}</mark>`);               
                _this.highlight_txt = ''
            }, 500);
    }
    
    if(!this.userRole.is_superadmin && !getUserRole(this.userRole,TICKET_MANAGEMENT_SERVICE,UPDATE_ACTION)){
      this.onReadOnly()
    }
    // if(!this.userRole.is_superadmin && !getUserRole(this.userRole,TICKET_MANAGEMENT_SERVICE,DELETE_ACTION)){
    //     this.attachment_readonly = true
    // }
  this.applySecurity()
  this.startTimer()
  if(this.$route.params.edcurl && this.$route.params.postData){
      // this.decryptUrl()
      this.ticketObj = {
        'url':this.$route.params.edcurl,
        'postData':JSON.parse(this.$route.params.postData),
        'url_type':this.$route.params.url_type
      }
      this.resource_type = "dataviewer_service"
      this.getMappedWorkflow();
    }
    // else{
    //   this.Summary_txt = "Create Ticket"
    // }
  },
  computed:{
    isForEdit(){
      if(this.$route.params.ticket_id || this.$route.query.ticket_id)
				return true
      else
        return false
			},
  },
  components: {
    // 'rich-text-box':RichTextBox,
    // 'comment-text-box':CommentBox,
    // 'normal-comment':NormalCommentBox
  },
  watch:{
    'ticketObj':{
      handler(newValue){
        this.url = newValue.url
      }
    },
    imgFilePath:{
      handler(newValue){
        this.imgFilePath = newValue
      }
    },
    filePath:{
      handler(newValue){
        debugger
        this.filePath = newValue
      }
    },
    fileContentType:{
      handler(newValue){
        debugger
        this.fileContentType = newValue
      }
    },
    description:{
      handler(newValue){
        if(this.old_description != newValue){
          this.show_form_buttons = true
        }
        if(this.old_description == newValue){
          this.show_form_buttons = false
        }
      }
    },
    title:{
      handler(newValue){
         this.show_form_buttons = true
      }
    },
    assignee:{
      handler(newValue){
        if(newValue){
          if( !_.isEmpty(newValue))
            this.assigneeRule = []
        if(this.old_assignee != newValue.username){
          this.show_form_buttons = true
        }
        if(this.old_assignee == newValue.username){
          this.show_form_buttons = false
        }
        }
        else
          this.show_form_buttons = true
      }
    },
    priority:{
      handler(newValue){
        if(this.old_priority.id != newValue.id){
          this.show_form_buttons = true
        }
        if(this.old_priority.id == newValue.id){
          this.show_form_buttons = false
        }
      }
    },
    comment:{
      handler(newValue){
        if(newValue != ''){
          this.show_comment_buttons = true
        }
        if(newValue == ''){
          this.show_comment_buttons = false
        }
      }
    },
    tag:{
      handler(newValue){
        if(this.old_tag.id != newValue){
          this.show_form_buttons = true
        }
        if(this.old_tag.id == newValue){
          this.show_form_buttons = false
        }
      }
    },
    queue:{
      handler(newValue){
        this.GetUserList()
        if(this.old_queue.id != newValue.id){
          this.show_form_buttons = true
        }
        if(this.old_queue.id == newValue.id){
          this.show_form_buttons = false
        }
      }
    },
    user_list:{
      handler(newValue){
        this.assigneeRule = [] 
        if( this.assignee.user_name && !_.find(newValue,["user_name",this.assignee.user_name]) ){
          // this.assigneeRule = ['Current assigne has no access in selected queue']
          this.assignee = {}
        }
      }
    },
    callFrom:{
      handler(newValue){
        //If user opens create ticket dialogue again we clear the values
        if(this.callFrom == "create_dialog")
            this.resetForm()
      }
    },
  },
  methods: {
    getMappedWorkflow(){
      var data_to_send = {"client_id":this.$session.get('client_id'),
                          "request_for": "Dataviewer"}
      var url = config.EDC_QUEUE_MANAGEMENT_URL+"/fetch_mapped_wf_dv"
      postToServer(this, url, data_to_send).then(response=>{
        if(response){
          // function that will create ticket and start wf
        }
      }).catch(error_response=>{
        if(error_response){
          this.snackbar = true
          this.colorValue = "error"
          this.snackbar = error_response
        }
        else{
          this.snackbar = true
          this.colorValue = "error"
          this.snackbar = SERVER_ERROR
        }
      })
    },
    handleFileUpload(){
      debugger
              this.files.push(this.$refs.file_input.files[0])
              // this.files = this.$refs.file_input.files[0]
          },
    
    handleMultipleFileUpload(){
      debugger
              this.attached_files = this.$refs.multiple_file_input.files[0];
              this.addMultipleAttachment()
          },
    addAttachmentlist(){
      this.$refs.file_input.click()
      
    },
    handleAttachedFile(){
      debugger
              this.attached_files = this.$refs.file_input.files[0];
              this.addAttachment()
          },
    change_focus(value){
      if(value == "show_rich_text"){
        this.show_richcomment = true
        this.show_normalcomment =false
        document.getElementById("rich-ticketdescription").focus()
      }
      if(value == "show_normal_text"){
        this.commentEditorObj={}
        this.comment=""        
        this.show_richcomment = false
        this.show_normalcomment =true
      }
      if(value == "show_rich_description"){   
        // this.vueEditorObj = {}
        // this.vueEditorObj['description'] = this.description
        if(!this.description_readonly){
        this.showrich_description = !this.showrich_description        
        document.getElementById("editor1").focus()
        document.getElementById("editor1").click()
        }
      }
    },
    getTileColor(index){
      if(index % 2 != 0)
        return '#F5F5F5'
      else
       return 'white'
    },
    CommentChange(comment){
          this.commentEditorObj['comment'] = _.cloneDeep(this.comment)
	    	  this.comment = comment
	    	  this.savedstatus = 'edited'
        },
    submit() {
      if(!this.title){
        this.snackbar = true                   
        this.colorValue = 'error'
        this.snackbartext = "Fill required fields" 
        return
      }
      else if(this.$refs.ticket_details_form.validate()){
        if(this.isForEdit)
          this.updateTicket()
        else
          this.createTicket();
      }
    },
    cancle(){
      if(this.callFrom == "create_dialog")
        this.$emit('onCancled')
      else
        this.$router.go(-1)
    },
    getColor(value){
        return GRID_STATUS_COLOR_CODE[value.toLowerCase()]
    },
    getTicketSummery(){
        var _this =this;
        var client_id = _this.$session.get('client_id');
        var ticket_id = _this.$route.params.ticket_id ? _this.$route.params.ticket_id : _this.ticket_id
        var data = {"client_id": client_id,
                    "ticket_id": ticket_id,
                    'product_name': "TICKET_MANAGEMENT",
                    "is_ticket_closed":_this.is_closed_ticket}
        var get_summary_url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + GET_TICKET_SUMMARY
        postToServer(_this, get_summary_url, data).then(response => {
            this.loader = false
            if(response){
              var keys  = Object.keys(response)
              // index used for sorting
              var data_list = []
              for (var i = 0; i <keys.length; i++) {

                if(keys[i] === 'ticket_id' && response.ticket_id){
                  data_list.push({"name":"Ticket ID","value":response.ticket_id,"index":1})
                }

                if(keys[i] === "title")
                  data_list.push({"name":"Title","value":response.title,"index":2})

                // if(keys[i] === "description"){
                //   data_list.push({"name":"Description","value":response.description,"index":3})
                // }

                // if you change this index then also make change in live status watch
                 if(keys[i] === 'priority_name')
                  data_list.push({"name":"Priority","value":response.priority_name ,"index":4})
                
                if(keys[i] === "status"){
                  this.status_txt = response.status
                  data_list.push({"name":"Status","value":response.status,"index":5})
                }

                if(keys[i] === "sub_status"){
                  this.sub_status = response.sub_status
                  data_list.push({"name":"Sub Status","value":response.sub_status,"index":6})
                }

                if(keys[i] === "resource_type"){
                  this.resource_type = response.resource_type
                  data_list.push({"name":"Request From","value":response.resource_type,"index":7})
                }

                if(keys[i] === "assigned_to")
                  data_list.push({"name":"Assignee","value":response.assigned_to,"index":8})

                if(keys[i] === "assigned_by"){
                  this.assigned_by = response.assigned_by
                  data_list.push({"name":"Assigned By","value":response.assigned_by,"index":9})
                }

                if(keys[i] === "tz_assigned_date"){
                  this.tz_assigned_date_time = response.tz_assigned_date + " "+ response.tz_assigned_time
                  data_list.push({"name":"Assigned Datetime","value":response.tz_assigned_date + " "+ response.tz_assigned_time,"index":10})
                }

                if(keys[i] === "created_by"){
                  this.created_by = response.created_by
                  data_list.push({"name":"Created By","value":response.created_by,"index":11})
                }
                 
                if(keys[i] === "tz_created_date"){
                  let dateFormat = this.$session.get('UI_date_format')
                  let timeZoneFormat = this.$session.get('time_zone_preference')
                  this.tz_created_date_time = moment(response.tz_created_date,dateFormat).format('ll') +' at '+moment(response.tz_created_date+' '+response.tz_created_time,dateFormat+" HH:mm:ss").tz(timeZoneFormat).format('LT') +' '+moment().tz(timeZoneFormat).format('z')

                  data_list.push({"name":"Created Datetime","value":response.tz_created_date + " "+ response.tz_created_time,"index":11})
                }
                
              }
              this.ticketsummary = _.sortBy(data_list,"index")
            }
        }).catch(error_response => {
            if(error_response){
                this.snackbar = true
                this.colorValue = "error"
                this.snackbar = error_response
            }
            else{
                this.snackbar = true
                this.colorValue = "error"
                this.snackbar = SERVER_ERROR
            }
        }); 
    },
    // redirectToLogs(){
    //   this.activetab = 3
    //   this.TabChanged()
    // },
    // TabChanged(){
    //     if(this.activetab === 1 && this.commenTabletList.rows.length === 0)
    //         this.getTicketComments()
    //     if(this.activetab === 2 && this.attachementTableList.rows.length === 0)
    //         this.getAttachements()
    //     if(this.activetab === 3 && this.activityTableList.rows.length === 0)
    //         this.getActivityLogs(this.envIpJson)
    // },
     getTicketComments(){
        var _this =this;
        var client_id = _this.$session.get('client_id');
        var ticket_id = _this.$route.params.ticket_id ? _this.$route.params.ticket_id : _this.ticket_id
        var data = {"client_id": client_id,
                    "ticket_id": ticket_id,
                    'product_name': "TICKET_MANAGEMENT"}
        var get_summary_url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + GET_TICKET_COMMENTS
        postToServer(_this, get_summary_url, data).then(response => {
                response = _.sortBy(response,"ticket_id").reverse()
                this.commenTabletList.rows = response
            }).catch(error_response => {
                if(error_response){
                // this.snackbar = true
                // this.colorValue = "error"
                // this.snackbar = error_response
            }
            else{
                // this.snackbar = true
                // this.colorValue = "error"
                // this.snackbar = SERVER_ERROR
            }
        }); 
    },
    getActivityLogs(envIpJson){
        var _this =this;
        var client_id = _this.$session.get('client_id');
        var ticket_id = _this.$route.params.ticket_id ? _this.$route.params.ticket_id : _this.ticket_id
        var data = {"client_id": client_id,
                    "ticket_id": ticket_id,
                    "page":envIpJson.page,
                    "page_size":envIpJson.page_size,
                    "sort":envIpJson.sort,
                    "filter":envIpJson.filter,
                    'product_name': "TICKET_MANAGEMENT"}
        var get_summary_url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + GET_TICKET_LOGS
        _this.loader = true
        // _this.activityTableList.rows = []
        // _this.activityTableList.total_count = 0
        postToServer(_this, get_summary_url, data).then(response => {
                _this.loader = false
                if(this.SelectedSort == 'Oldest')
                  _this.activityTableList.rows = _.sortBy(response.result,"actiontaken_date_time").reverse()
                else
                  _this.activityTableList.rows = _.sortBy(response.result,"actiontaken_date_time")

                 _.forEach(_this.activityTableList.rows,function(obj){
                   if(obj.activity_name == 'ATTACHMENT ADDED'){
                    var description = obj.description.split(':')
                    obj.description = description[0]
                    obj.file_name = description[1]
                     if(_this.highlight_txt !=''){
                     obj.file_name = obj.file_name.replace(new RegExp(_this.highlight_txt, "gi"), (match) => `<mark  style="padding: 0px !important;background-color: #FEB72B !important; ">${match}</mark>`);
                     obj.description = obj.description.replace(new RegExp(_this.highlight_txt, "gi"), (match) => `<mark style="padding: 0px !important;background-color: #FEB72B !important;">${match}</mark>`);
                    }
                   }
                  //  else if(obj.activity_name == 'COMMENT ADDED' && obj.is_private && obj.action_by != _this.$session.get("email")){
                  //    _this.activityTableList.rows.splice(obj,1)
                  //    response.total = response.total -1
                  //  }
                   else{
                     if(_this.highlight_txt !='')
                      obj.description = obj.description.replace(new RegExp(_this.highlight_txt, "gi"), (match) => `<mark  style="padding: 0px !important;background-color: #FEB72B !important;">${match}</mark>`);
                   }
                   
                })
                
                _this.highlight_txt = ''
                _this.activityTableList.total_count = response.total
            }).catch(error_response => {
              _this.loader = false
                if(error_response){
                  _this.activityTableList.rows = []
                   _this.activityTableList.total_count = 0
                // this.snackbar = true
                // this.colorValue = "error"
                // this.snackbar = error_response
            }
            else{
                // this.snackbar = true
                // this.colorValue = "error"
                // this.snackbar = SERVER_ERROR
            }
        }); 
    },
    getRecentActivity(){
        var _this =this;
        var client_id = _this.$session.get('client_id');
        var ticket_id = _this.$route.params.ticket_id ? _this.$route.params.ticket_id : _this.ticket_id
        var data = {"client_id": client_id,
                    "ticket_id": ticket_id,
                    'product_name': "TICKET_MANAGEMENT"}
        var get_summary_url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + GET_RECENT_TICKET_LOGS
        postToServer(_this, get_summary_url, data).then(response => {
                  this.recentLogItems = response
            }).catch(error_response => {
                if(error_response){
                // this.snackbar = true
                // this.colorValue = "error"
                // this.snackbar = error_response
            }
            else{
                // this.snackbar = true
                // this.colorValue = "error"
                // this.snackbar = SERVER_ERROR
            }
        }); 
    },
    getTicketStatus(){
         getToServer(this, config.EDC_TICKET_MANAGEMENT_SERVICE_URL + GET_TICKET_STATUS
        ).then(response  => {
            var data =response
            this.status_list = data
        }).catch(ticketResponseError => {
                // _this.loader = false;
                if(ticketResponseError){
                    this.loader = null                   
                }
                else { 
                    this.snackbar = true                   
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;                    
                }
            });
        },
    getPriorityList(){
        var _this =this;
        var client_id = _this.$session.get('client_id');
        var data = {"client_id": client_id,
        'product_name': window.sessionStorage.getItem('product')}
        postToServer(_this, config.EDC_TICKET_MANAGEMENT_SERVICE_URL + GET_TICKET_PRIORITY, data
        ).then(response  => {
            var data =response
            this.priority_list = data
        }).catch(ticketResponseError => {
                this.loader = false;
                if(ticketResponseError){
                    this.colorValue = 'error'
                    this.snackbartext = ticketResponseError;                    
                }
                else {                    
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;                    
                }
            });
        },
    GetUserList() {
      var _this =this;
      var client_id = _this.$session.get('client_id');
      var queue_id_list = []
      queue_id_list.push(this.queue ? this.queue.id : 1)
      var data = {"queue_id_list": queue_id_list,
      'product_name': "TICKET_MANAGEMENT"}
      var get_user_url = config.EDC_QUEUE_MANAGEMENT_URL + GET_USERS_FROM_QUEUE
     postToServer(_this, get_user_url, data).then(response => {
      _this.user_list = response;
      }).catch(error_response => {
        _this.user_list = [];
      }); 
    },
    getTicketDetails(){
       var _this =this;
        var client_id = _this.$session.get('client_id');
        var ticket_id = _this.$route.params.ticket_id ? _this.$route.params.ticket_id : _this.ticket_id
        var data = {"client_id": client_id,
                    "ticket_id": ticket_id,
                    'product_name': "TICKET_MANAGEMENT",
                    "is_ticket_closed":_this.is_closed_ticket}
        var get_ticket_detail_url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + GET_TICKET_DETAILS_BY_ID
        _this.loader = true;
        postToServer(_this, get_ticket_detail_url, data).then(response => {
                _this.loader = false;
                var data = response
                this.title = response.title
                this.description = response.description
                this.old_description = response.description
                this.url = response.master_details.url
                this.queue = {"id": response.queue_id,
                              "queue_name": response.queue_name}
                this.old_queue = this.queue
                this.assignee = {'username':response.assigned_to,
                                  'full_name_with_user_name':response.assigned_to_fullname }
                this.assigne_fullname = response.assigned_to_fullname
                this.old_assignee = response.assigned_to
                this.priority = response.priority_details
                this.old_priority = response.priority_details
                this.priority_id = response.priority_details.id
                this.status = response.status_details
                this.files = response.attachment_data
                this.oldFiles = response.attachment_data
                this.created_date_time = response.tz_created_date_time
                this.modified_date_time = response.tz_modified_date_time
                var now = moment().tz(_this.$session.get('time_zone_preference')).format( _this.$session.get("UI_date_format")  +" HH:mm:ss")
                now =  moment(now,  _this.$session.get("UI_date_format") + " HH:mm:ss")
                
                _this.created_date_time = moment(_this.created_date_time,  _this.$session.get("UI_date_format") + " HH:mm:ss")
                _this.age = moment.duration(now.diff(_this.created_date_time));
                var lastActivity = this.modified_date_time ?  moment(_this.modified_date_time,  _this.$session.get("UI_date_format") + " HH:mm:ss") : _this.created_date_time
                _this.inactiveTime = moment.duration(now.diff(lastActivity));
                // this.created_by = response.created_by
                // this.sub_status = response.status.sub_status
                this.vueEditorObj = {}
                this.vueEditorObj['description'] = _.cloneDeep(response.description)
                if(this.status.status == "Closed"){
                  this.ticket_action_list =[{name:"Open",
                          id:"Open"}]
                  this.onReadOnly()                        
                          }
            }).catch(error_response => {
              _this.loader = false;
                if(error_response){
                // this.snackbar = true
                // this.colorValue = "error"
                // this.snackbar = error_response
            }
            else{
                // this.snackbar = true
                // this.colorValue = "error"
                // this.snackbar = SERVER_ERROR
            }
        }); 
    },
    addComment(){
      var _this =this
      var data = {
                  "ticket_id": this.$route.params.ticket_id ? this.$route.params.ticket_id : _this.ticket_id,
                  "client_id": this.$session.get('client_id'),
                  "comment":this.comment,
                  'product_name': "TICKET_MANAGEMENT",
                  "is_private": this.private_note,

      }
      var add_comment_url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + MANAGE_COMMENT
      postToServer(_this, add_comment_url, data).then(response => {
                this.commentEditorObj={}
                this.comment = ''
                this.CommentChange(this.comment)
                this.change_focus('show_normal_text')
                this.activityTableList.rows = []
                this.activityTableList.total_count = 0
                this.getActivityLogs(this.envIpJson)
          }).catch(error_response => {
                if(error_response){
                // this.snackbar = true
                // this.colorValue = "error"
                // this.snackbar = error_response
          }
              else{
                // this.snackbar = true
                // this.colorValue = "error"
                // this.snackbar = SERVER_ERROR
            }
        }); 
    },
    updateTicket(){
        var _this =this
        let formData = new FormData();
            formData.append('ticket_id', this.ticket_id);
            formData.append('client_id', this.$session.get('client_id'));
            formData.append('title', this.title);
            formData.append('description', this.description);
            formData.append('priority_details',this.priority ? JSON.stringify(this.priority) : "")
            formData.append('resource_type', this.resource_type);
            // formData.append('resource_code', "DAV");
            // let tags = []
            // if(this.tag.length > 0)
            // {
            // _.forEach(this.tag,function(obj){
            //     tags.push({"tag_id":obj.id,
            //                 "tag_name": obj.tag_name})
            //     })

            // }
            // formData.append('tag_list',JSON.stringify(this.tag));
            let queues = {}
            if(this.queue)
            {
              queues ={"queue_id":this.queue.id,
                            "queue_name": this.queue.queue_name}

            }
            // formData.append('queue_list',JSON.stringify(queues));
            formData.append('queue_id', queues.queue_id ? queues.queue_id : '');
            formData.append('queue_name',queues.queue_name ? queues.queue_name : '');
            if(this.assignee){
              formData.append('assigned_to', this.assignee.username);
              formData.append('assigned_to_fullname', this.assigne_fullname);
            }
            else{
              formData.append('assigned_to', '');
              formData.append('assigned_to_fullname', '');
            }
            formData.append('ticket_details', JSON.stringify({
              "api": "",
              "method_type": "",
              "content_type": "",
              "request_data": "",
              "description": ""
            }));
            formData.append('update_ticket_flag', true);
            formData.append('add_comment_flag', false);
            if(this.files && this.files != this.oldFiles){
              formData.append('attachment_data', this.files);
              formData.append('add_attachment_flag', true);
            }
            else
              formData.append('add_attachment_flag', false);
            // formData.append('comment_data', JSON.stringify({"comment_data":{
            //   "comment": this.comment
            //   },
            // }));
        _this.loader = true;
        postToServer(this, config.EDC_TICKET_MANAGEMENT_SERVICE_URL + UPDATE_TICKET, formData
        ).then(response  => {
            this.colorValue = 'success'
            _this.loader = false;
            this.snackbartext = response
            //Update status when saving ticket changes it
            if(response && response.status)
              this.status = response
            this.getActivityLogs(this.envIpJson)
            this.show_form_buttons = false
        }).catch(ticketResponseError => {
                _this.loader = false;
                if(ticketResponseError){
                    this.snackbar = true 
                    this.colorValue = 'error'
                    this.snackbartext = ticketResponseError                  
                }
                else {     
                    this.snackbar = true             
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;                    
                }
            });
        },
    DescriptionChange(description){
          this.vueEditorObj['description'] = _.cloneDeep(this.description)
          this.description= ''
	    	  this.description = description
	    	  this.savedstatus = 'edited'
	  },
    // editAction(element){
    //   if(element == "title")
    //     this.title_readonly = ! this.title_readonly
    //   if(element == "tag")
    //     this.tag_readonly = ! this.tag_readonly
    //   if(element == "priority")
    //     this.priority_readonly = ! this.priority_readonly
    //   if(element == "status")
    //     this.status_readonly = ! this.status_readonly
    //   if(element == "assignee")
    //     this.assignee_readonly = ! this.assignee_readonly
     
    // },
    onReadOnly(){
       this.title_readonly = true
        this.queue_readonly = true
        this.priority_readonly = true
        this.status_readonly = true
        this.assignee_readonly = true
        this.description_readonly = true
        this.comment_readonly = true
        this.attachment_readonly = true
        //stoping from making other actions readonly when ticket is closed
        if(!this.userRole.is_superadmin && !getUserRole(this.userRole,TICKET_MANAGEMENT_SERVICE,UPDATE_ACTION)){
          this.action_readonly = true
        }
    },
    onPageChange(page, perPage) {
          let _this = this;
          let inputJson = _this.envIpJson;
          inputJson.page = page;
          inputJson.page_size = perPage;
          if(this.activetab == 0)
            pass
          else if(this.activetab == 1)
            pass
          else if(this.activetab == 2)
            pass
          else if(this.activetab == 3)
            this.getActivityLogs(inputJson);
        },

        onPerPageChange(perPage, page) {
          let _this = this;
          let inputJson = _this.envIpJson;
          inputJson.page = page;
          inputJson.page_size = perPage;
          if(this.activetab == 0)
            pass
          else if(this.activetab == 1)
            pass
          else if(this.activetab == 2)
            pass
          else if(this.activetab == 3)
            this.getActivityLogs(inputJson);
        },
    createTicket(){
        var _this =this
        let formData = new FormData();
        if(this.files.length > 0){
          // formData.append('attachment_data', []);
        _.forEach(this.files,function(obj, i){
                // formData.append('attachment_data'[i], obj);
                // formData.append("attachment_data[" + i + "].Id",obj);
                formData.append('attachment_data',obj);
                })
          // formData.append('attachment_data',JSON.stringify(attached_files));  
            }
        else
          formData.append('attachment_data', []);

            formData.append('client_id', this.$session.get('client_id'));
            formData.append('title', this.title);
            formData.append('description', this.description);
            formData.append('priority_details',this.priority ? JSON.stringify(this.priority) : "")
            formData.append('resource_type', this.resource_type);
            // formData.append('resource_code', "DAV");
            if(this.callFrom == "create_dialog")
              formData.append('resource_code', "DAV");
            // else
            //   formData.append('resource_code', "");
            
            // let tags = []
            // if(this.tag.length > 0)
            // {
            // _.forEach(this.tag,function(obj){
            //     tags.push({"tag_id":obj.id,
            //                 "tag_name": obj.tag_name})
            //     })

            // }
            // formData.append('tag_list',JSON.stringify(this.tag));
            let queues = {}
            if(this.queue)
            {
               queues ={"queue_id":this.queue.id,
                            "queue_name": this.queue.queue_name}

            }
            // formData.append('queue_list',JSON.stringify(queues));
            formData.append('queue_id', queues.queue_id ? queues.queue_id : '');
            formData.append('queue_name',queues.queue_name ? queues.queue_name : '');
            if(this.assignee && typeof this.assignee.username != 'undefined'){
              formData.append('assigned_to', this.assignee.username);
              formData.append('assigned_to_fullname', this.assigne_fullname);
            }
            else{
              formData.append('assigned_to', '');
              formData.append('assigned_to_fullname', '');
            }
            if(this.ticketObj){
              formData.append('ticket_details', JSON.stringify({
              "url": this.ticketObj.url,
              "url_type": this.ticketObj.url_type,
              "content_type": "json",
              "request_data": this.ticketObj.postData,
              "description": ""
              }));
            }
            
        _this.loader = true;
        postToServer(this, config.EDC_TICKET_MANAGEMENT_SERVICE_URL + CREATE_TICKET_URL, formData
        ).then(response  => {
            this.colorValue = 'success'
            this.snackbartext = response
             _this.loader = false;
            this.$router.push("/ticketstatus")
            this.$emit('onCreated')
        }).catch(ticketResponseError => {
                _this.loader = false;
                if(ticketResponseError){
                    this.snackbar = true 
                    this.colorValue = 'error'
                    this.snackbartext = ticketResponseError                  
                }
                else {     
                    this.snackbar = true             
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;                    
                }
            });
        },
    getAttachements(){
        var _this =this;
        var client_id = _this.$session.get('client_id');
        var ticket_id = _this.$route.params.ticket_id ? _this.$route.params.ticket_id : _this.ticket_id
        var data = {"client_id": client_id,
                    "ticket_id": ticket_id,
                    'product_name': "TICKET_MANAGEMENT",
                    "is_ticket_closed":_this.is_closed_ticket}
        var get_summary_url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + GET_TICKET_ATTACHMENTS
        _this.loader = true
        postToServer(_this, get_summary_url, data).then(response => {
                _this.attachementTableList.rows = []
                this.attachementTableList.rows =response
                _this.loader = false
                // this.attachementTableList.total_count = response.total
            }).catch(error_response => {
              _this.loader = false
                if(error_response){
                  _this.attachementTableList.rows = []
                // this.snackbar = true
                // this.colorValue = "error"
                // this.snackbar = error_response
            }
            else{
                // this.snackbar = true
                // this.colorValue = "error"
                // this.snackbar = SERVER_ERROR
            }
        }); 
    },
  onAddAttachment(){
  
    document.getElementById('file_input').click()
  },
  addAttachment(){
        var _this =this
        let formData = new FormData();
            formData.append('ticket_id', this.ticket_id);
            formData.append('client_id', this.$session.get('client_id'));
            formData.append('update_ticket_flag', false);
            formData.append('add_comment_flag', false);
            formData.append('attachment_data', this.attached_files);
            formData.append('add_attachment_flag', true);
            // formData.append('comment_data', JSON.stringify({"comment_data":{
            //   "comment": this.comment
            //   },
            // }));
        _this.loader = true;
        postToServer(this, config.EDC_TICKET_MANAGEMENT_SERVICE_URL + UPDATE_TICKET, formData
        ).then(response  => {
            this.colorValue = 'success'
            this.snackbartext = response
            _this.loader = false;
            this.attached_files = []
            _this.getAttachements()
        }).catch(ticketResponseError => {
                _this.loader = false;
                if(ticketResponseError){
                    this.snackbar = true 
                    this.colorValue = 'error'
                    this.snackbartext = ticketResponseError                  
                }
                else {     
                    this.snackbar = true             
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;                    
                }
            });
  },
  addMultipleAttachment(){
        var _this =this
        let formData = new FormData();
            formData.append('ticket_id', this.ticket_id);
            formData.append('client_id', this.$session.get('client_id'));
             formData.append('attachment_data', this.attached_files);
            // _.forEach(this.attached_files, function(value) {
            //    formData.append('attachment_data',value);
            // });
            // formData.append('title', this.title);
            // formData.append('description', this.description);
            // formData.append('priority_details',JSON.stringify(this.priority))
            // formData.append('resource_type', "dataviewer_service");
            // formData.append('resource_code', "DAV");
            // formData.append('tag', this.tag);
            // formData.append('assigned_to', this.assignee);
            // formData.append('ticket_details', JSON.stringify({
            //   "url": this.ticketObj.url,
            //   "url_type": this.ticketObj.url_type,
            //   "content_type": "json",
            //   "request_data": this.ticketObj.paramsData,
            //   "description": ""
            // }));
        _this.loader = true;
        postToServer(this, config.EDC_TICKET_MANAGEMENT_SERVICE_URL + MANAGE_ATTACHEMENTS, formData
        ).then(response  => {
            this.colorValue = 'success'
            this.snackbartext = response
             _this.loader = false;
            this.attached_files = []
             _this.getAttachements()
            _this.getActivityLogs(_this.envIpJson)
        }).catch(ticketResponseError => {
                _this.loader = false;
                if(ticketResponseError){
                    this.snackbar = true
                    this.attached_files = [] 
                    this.colorValue = 'error'
                    this.snackbartext = ticketResponseError                  
                }
                else {     
                    this.snackbar = true 
                    this.attached_files = []            
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;                    
                }
            });
  },
  redirectToIssue(){
    let _this = this
    let redirect_url = this.$session.get('protocol')+'://'
    let queryString = '?product_key='+this.$session.get('access_token')+'&url1='+encodeURIComponent(this.url)
    let domain_name = this.$session.get('erp_cloud_host') ? this.$session.get('erp_cloud_host') : "localhost"
    if(config.DOMAIN_NAME)
      redirect_url = redirect_url +'dataviewer.'+config.DOMAIN_NAME+'/login'+queryString
    else
      redirect_url = redirect_url + domain_name+":"+config.DATAVIEWER_PORT+'/login'+queryString
    window.open(redirect_url, '_blank')

    // let routeData = this.$router.resolve({path: this.url});
    // window.open(routeData.route.fullPath, '_blank');

    // window.open(this.url, '_blank');
  },
  closePanel(){
        this.show_preview =false
				this.showImageViewer = false
        this.showFileViewer = false
        this.dialog =false
        this.show_confirm_delete = false
			},
  preview_file(id,content_type){
    var _this = this
    let client_id = _this.$session.get('client_id')
    var data = {"attachment_id": id,
                  "client_id": client_id,
                  "view":"Y",
                  'product_name': "TICKET_MANAGEMENT"}
    // _this.loader = true;
    // this.show_preview =true
    var content_type = content_type ? content_type : 'image/jpeg'
    // if(typeof content_type === 'undefined' || content_type == null)
    //   concontent_type = 'image/jpeg'
    if(content_type.includes('image')){
      this.imgFilePath = ''
      this.imgFileContentType  = ''
      this.showImageViewer = true
      this.imgFileContentType = 'image/jpeg'
      this.imgFilePath = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + "/view_file?attachment_id=" + id+"&view=Y"+"&client_id="+client_id
      this.selected_image = this.imgFilePath
      _this.pathUrl = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + "/view_file?attachment_id=" + id
    }
    else{
      _this.fileContentType =''
		  _this.filePath =  ''
      _this.showFileViewer = true
		  _this.fileContentType =content_type
		  _this.filePath =  config.EDC_TICKET_MANAGEMENT_SERVICE_URL + "/view_file?attachment_id=" + id+"&view=Y"+"&client_id="+client_id
      _this.pathUrl = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + "/view_file?attachment_id=" + id
		  }
      // postToServer(_this, _this.pathUrl, data).then(response => {
      //              this.imgFilePath = response
      //             }).catch(error_response => {
      //                 if(error_response){
      //                   }
      //                 else{
      //                   }
      //               });
  },
  onCloseAction(action){
          if(action == "Add Attachment")
            this.$refs.multiple_file_input.click()
          else{
            if(action){
              this.task_name = this.ticket_id
              this.task_status = this.status
              this.showActionList = false
              this.action_type = action
              // this.dialog = true
              this.ticket_action_update()
          }
          }
          this.$refs.other_actions.reset()
        },
  ticket_action_update(){
            var _this = this
              this.dialog = false
              var _this =this;
              var client_id = _this.$session.get('client_id');
              var data = {"client_id": client_id,
                    "ticket_id": this.task_name,
                    "status": this.action_type,
                    'product_name': "TICKET_MANAGEMENT"}
              var update_status_url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + UPDATE_TICKET_STATUS
              _this.loader = true
              postToServer(_this, update_status_url, data).then(response => {
                    _this.loader = false
                    this.$router.push({ name: 'TicketStatus'});
                  }).catch(error_response => {
                    _this.loader = false
                      if(error_response){
                        _this.getOpenTicketList(this.envIpJson)
                        _this.getClosedTicketList(this.envIpJson);
                        // this.snackbar = true
                        // this.colorValue = "error"
                        // this.snackbar = error_response
                        }
                      else{
                        _this.getOpenTicketList(this.envIpJson)
                        _this.getClosedTicketList(this.envIpJson);
                          // this.snackbar = true
                          // this.colorValue = "error"
                        // this.snackbar = SERVER_ERROR
                        }
                    }); 
        },
  tokenverify(){
				// var token_verify_url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL +"/task_verify_token/" + this.$route.query.token
        var token_verify_url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL +GET_TICKET_DETAILS + this.$route.query.ticket_id
				getToServer(this, token_verify_url, true).then(response => {
        this.title = response.title
        this.ticket_id = response.id
        this.description = response.description
        this.tag = response.tag
        this.url = response.master_details.url
        this.assignee = response.assigned_to
        this.priority = response.priority_details
        this.priority_id = response.priority_details.id
        this.status = response.status_details
        this.files = response.attachment_data
        this.oldFiles = response.attachment_data
        this.getTicketSummery()
        this.getTicketDetails()
        this.getRecentActivity()
        this.getActivityLogs(this.envIpJson)
        this.showrich_description = false
				}).catch(error_response => {
				if(error_response){
					this.snackbar = true
					this.colorValue = 'error'
					this.snackbartext = error_response;
				}
				else {
					this.snackbar = true
					this.colorValue = 'error'
					this.snackbartext = SERVER_ERROR;
					}
				});
			},
  applyFilter(){
    this.envIpJson.filter = []
    if(this.SelectedFilter.user){
      var filterObj = {column_name: "action_by",
            e_parentheses: ")",
            is_drv_table: false,
            operator: "_eq_",
            ref_row_id: "",
            ref_step_id: "",
            s_parentheses: "(",
            table_name: "",
            v_type1: "Value",
            v_type2: "Value",
            value1: this.SelectedFilter.user,
            value2: ""}
     this.envIpJson.filter.push(filterObj)
    }
    if(this.SelectedFilter.action){
      var filterObj = {column_name: "activity_name",
            e_parentheses: ")",
            is_drv_table: false,
            operator: "_eq_",
            ref_row_id: "",
            ref_step_id: "",
            s_parentheses: "(",
            table_name: "",
            v_type1: "Value",
            v_type2: "Value",
            value1: this.SelectedFilter.action,
            value2: ""}
    this.envIpJson.filter.push(filterObj)
    }
    this.getActivityLogs(this.envIpJson)
  },
  applySearch(){
    if(this.search_text){
    this.envIpJson.filter = []
      var filterObj1 = {column_name: "action_by",
            e_parentheses: ")",
            is_drv_table: false,
            operator: "_cl_",
            ref_row_id: "",
            ref_step_id: "",
            s_parentheses: "(",
            table_name: "",
            v_type1: "Value",
            v_type2: "Value",
            value1: this.search_text ? this.search_text : '' ,
            operation:"or",
            value2: ""}
     this.envIpJson.filter.push(filterObj1)
      var filterObj2 = {column_name: "activity_name",
            e_parentheses: ")",
            is_drv_table: false,
            operator: "_cl_",
            ref_row_id: "",
            ref_step_id: "",
            s_parentheses: "(",
            table_name: "",
            v_type1: "Value",
            v_type2: "Value",
            operation:"or",
            value1: this.search_text ? this.search_text : '',
            value2: ""}
    this.envIpJson.filter.push(filterObj2)
    var filterObj3 = {column_name: "description",
            e_parentheses: ")",
            is_drv_table: false,
            operator: "_cl_",
            ref_row_id: "",
            ref_step_id: "",
            s_parentheses: "(",
            table_name: "",
            v_type1: "Value",
            v_type2: "Value",
            operation:"or",
            value1: this.search_text ? this.search_text : '',
            value2: ""}
    this.envIpJson.filter.push(filterObj3)
    this.highlight_txt = this.search_text
    }
    else{
      this.envIpJson.filter = []
    }
    this.getActivityLogs(this.envIpJson)
  },
  sortActivities(sort){
    if(sort){
    if(sort == "Latest"){
      this.SelectedSort = sort
      this.getActivityLogs(this.envIpJson)
    }
    if(sort == "Oldest"){
      this.SelectedSort = sort
      this.getActivityLogs(this.envIpJson)
    }
    if(sort == "User"){
      var sortObj = this.envIpJson
      sortObj.sort = [{"column_name":"action_by"}]
      this.getActivityLogs(sortObj)
    }
    if(sort == "Action"){
     var sortObj = this.envIpJson
      sortObj.sort = [{"column_name":"activity_name"}]
      this.getActivityLogs(sortObj)
      }
    }
    else{
      this.envIpJson.sort = []
      this.getActivityLogs(this.envIpJson)
    }
    },
  removeAttachmentConfirmation(ticket_id, attachment_id, attachment_name){
    this.current_attachment = attachment_id
    this.current_attachment_name = attachment_name
    this.show_confirm_delete = true
  },
  removeAttachment(){
  var _this =this;
        var client_id = _this.$session.get('client_id');
        var data = {"client_id": client_id,
                    "ticket_id": this.ticket_id,
                    "attachment_id": this.current_attachment,
                    "attachment_name": this.current_attachment_name,
                    'product_name': "TICKET_MANAGEMENT"}
        var get_summary_url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + DELETE_TICKET_ATTACHMENT
        postToServer(_this, get_summary_url, data).then(response => {
          _this.getActivityLogs(_this.envIpJson)
                _this.getAttachements()
                _this.show_confirm_delete = false
                // this.attachementTableList.total_count = response.total
            }).catch(error_response => {
                if(error_response){
                  _this.getAttachements()
                // this.snackbar = true
                // this.colorValue = "error"
                // this.snackbar = error_response
            }
            else{
                // this.snackbar = true
                // this.colorValue = "error"
                // this.snackbar = SERVER_ERROR
            }
        }); 
  },
  createTempFile(){
				let _this = this
				_this.loader = true
       let url = _this.pathUrl
       window.open(url, '_blank');
				let data = {"path":_this.pathUrl}
        _this.loader = false
				// postToServer(_this, config.EDC_TICKET_MANAGEMENT_SERVICE_URL + '/attachment_download',data).then(response =>{
				// 	_this.loader = false
				// 	let url = config.AGENT_API_URL + "/get_media_file?path=" + response.unique_id;
	      //     		window.open(url, '_blank');
			  //   }).catch(error=>{
			  //   	_this.loader = false
			  //   })
			},
 searchTags(search){
   let _this = this
    let url =  config.EDC_TAG_SERVICE_URL + SEARCH_TAGS;
    let inputJson={
         'client_id':this.$session.get('client_id'),
         'search_param':search,
         'product_name': "TICKET_MANAGEMENT"
        }
    postToServer(this, url, inputJson).then(response  => {
      this.tag_list= response
      // _.forEach(response,function(obj){
      //           _this.tag_list.push(obj.tag_name)
      //           })
      }).catch(error => {
         this.tag_list =[];
      if(error){
        // this.snackbar = true;
        // this.colorValue = 'error';
        // this.snackbartext = error;
        }
      else {
          }
         });
    },
  addTag(search){
    var _this = this
    if(!this.userRole.is_superadmin && !getUserRole(this.userRole,TAG_SERVICE,CREATE_ACTION)){
        _this.tag.pop()
        this.snackbar = true;
          this.colorValue = 'error';
          this.snackbartext = "You dont have permission to add tag";
      }
    else if(!this.tag_list.includes(search)){
      let url =  config.EDC_TAG_SERVICE_URL + CREATE_TAG;
      let inputJson={
          'client_id':this.$session.get('client_id'),
          'tag_name':search,
          'product_name': "TICKET_MANAGEMENT"
          }
      postToServer(this, url, inputJson).then(response  => {
        //after creating new tag remove it from tag object as its just a string
        var added_tag =  _this.tag.pop()
        this.getAllTags()
        //If response has id we add that in tags
        _this.tag.push(response)
        //  setTimeout(function() {
        //   let result = _.find(_this.tag_list, function(obj) {
        //     if (obj.tag_name == added_tag) {
        //         return true;
        //     }
        // });
        // _this.tag.push(result)
        // }, 500);
        }).catch(error => {
          this.tag_list =[];
        if(error){
          // this.snackbar = true;
          // this.colorValue = 'error';
          // this.snackbartext = error;
          }
        else {
            }
          });
  }
  },
  getAllTags(){
    let _this = this
    let url =  config.EDC_TAG_SERVICE_URL + GET_ALL_TAGS;
    let inputJson={
        'client_id':this.$session.get('client_id'),
        'product_name': 'TICKET_MANAGEMENT'
    }
    postToServer(this, url, inputJson).then(response  => {

            _this.tag_list= _.orderBy(response, ['tag_name'], ['asc'])
        }).catch(error => {
            this.tableList.rows =[];
        if(error){
             // this.snackbar = true;
            // this.colorValue = 'error';
            // this.snackbartext = error;
            }
            else {
            }
                });
            },
  decryptUrl(){
    let _this = this
    let data_to_send = {
      'url':this.$route.query.edcurl,
      'postData':this.$route.query.postData
    }
    postToServer(this, config.EDC_TICKET_MANAGEMENT_SERVICE_URL + DECRYPT_URL_DATA, data_to_send
      ).then(response  => {
          _this.ticketObj = {
            'url':response.url,
            'postData':JSON.parse(response.postData),
            'url_type':'POST'
          }
          _this.url = response.url,
          _this.loader = false;
      }).catch(ticketResponseError => {
              _this.loader = false;
              if(ticketResponseError){
                  this.snackbar = true 
                  this.colorValue = 'error'
                  this.snackbartext = ticketResponseError                  
              }
              else {     
                  this.snackbar = true             
                  this.colorValue = 'error'
                  this.snackbartext = SERVER_ERROR;                    
              }
          });
  },
  getUserTitle(){
          if(this.$session.get('last_name') && this.$session.get('last_name').length > 0){
          this.fullName = this.$session.get('first_name') + " "+ this.$session.get('last_name') 
          }
          else{
            this.fullName = this.$session.get('first_name')
          }
          return this.fullName
        },
  resetForm(){
    this.files = []
    this.title = ""
    this.description = ""
    this.priority = {}
    this.tag =[]
    this.assignee = ""
  },
  closePreview(){
    //Clear image preview section
    this.selected_image = ""
  },
  showHideControl(type,name){
    var _this = this
    if(name == "priority"){
      this.show_priority = type =="text"? true : false
       setTimeout(function () {
        _this.$refs.prioritytest.focus();
            }, 100);
    }
    else if(name == "assignee"){
      this.show_assignee = type =="text"? true : false
      setTimeout(function () {
        _this.$refs.assignee.focus();
            }, 100);
    }

    
    
  },
  view_file(index){
    this.selected_image = URL.createObjectURL(this.files[index])
  },
  removeAttachmentFromList(index){
    this.files.splice(index,1)
  },
  queueCustomFIlter(item, queryText, itemText){
    return (
        item.queue_name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.tag_name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );

  },
  getQueueList() {
            let _this = this;
            _this.loader = true;
            _this.queue_list = []
            var request_data = {
                'client_id': this.$session.get('client_id'),
                'product_name': window.sessionStorage.getItem('product')}
            postToServer(_this, config.EDC_QUEUE_MANAGEMENT_URL + GET_ALL_QUEUES, request_data).then(response => {
                _this.loader = false;
                if (response) {
                    _this.queue_list = response;
                } else {
                    _this.queue_list = [];
                }
            }).catch(error_response => {
                _this.loader = false;
                 _this.queue_list= [];
                if (error_response) {
                    this.loader = null
                    /* this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = error_response; */
                } else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                }
            });
        },
  getTicketActionList(){
        var _this =this;
        var client_id = _this.$session.get('client_id');
        var data = {"client_id": client_id,
        'product_name': window.sessionStorage.getItem('product')}
        postToServer(_this, config.EDC_TICKET_MANAGEMENT_SERVICE_URL + GET_TICKET_ACTION, data
        ).then(response  => {
            var data =response
            this.ticket_action_list = data
        }).catch(ticketResponseError => {
                this.loader = false;
                if(ticketResponseError){
                    this.colorValue = 'error'
                    this.snackbartext = ticketResponseError;                    
                }
                else {                    
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;                    
                }
            });
        },
  getDefaultValues() {
      var _this = this
      let url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + GET_CONFIG_DATA;
      var data = {'client_id': this.$session.get('client_id'), 
                  'product_name': "TICKET_MANAGEMENT" }
      
        postToServer(this, url, data).then(response  => {
        if(!_this.isForEdit){
        let priority_name = response.default_priority
          _this.priority = _.find(_this.priority_list, function(obj) {
            if (obj.name == priority_name) {
                return true;
            }
        });
        }
        if(_this.isForEdit){
         let action_name = response.default_action
          _this.ticket_action = _.find(_this.ticket_action_list, function(obj) {
            if (obj.name == action_name) {
                return true;
            }
        });
        }
      }).catch(e => {
        this.snackbar = true
                this.colorValue = "error"
                this.snackbartext = e
      })
    },
  startTimer(){
    var _this = this
    if(this.age && this.inactiveTime){
     window.setInterval(() => {
      _this.inactiveTime._data.seconds +=  1
      if(_this.inactiveTime._data.seconds == 60){
        _this.inactiveTime._data.seconds = 0
        _this.inactiveTime._data.minutes +=  1
        if( _this.inactiveTime._data.minutes == 60){
          _this.inactiveTime._data.minutes = 0
          _this.inactiveTime._data.hours +=  1
        }
        if( _this.inactiveTime._data.hourse == 24){
          _this.inactiveTime._data.hourse = 0
          _this.inactiveTime._data.days +=  1
        }
      }
    _this.age._data.seconds  +=  1
    if( _this.age._data.seconds == 60){
        _this.age._data.seconds = 0
         _this.age._data.minutes +=  1
        if( _this.age._data.minutes == 60){
           _this.age._data.minutes = 0
           _this.age._data.hours +=  1
        }
        if( _this.age._data.hourse == 24){
           _this.age._data.hourse = 0
           _this.age._data.days +=  1
        }
    }
    }, 1000)
    }
    
  },
  applySecurity(){
    var _this = this
    if(!_this.userRole.is_superadmin && !getTicketRole(_this.actions_by_group,TICKET_MANAGEMENT_SERVICE,CREATE_TICKET)){
        _this.create_ticket_readonly = true
    }
    if(!_this.userRole.is_superadmin && !getTicketRole(_this.actions_by_group,TICKET_MANAGEMENT_SERVICE,CREATE_TICKET)){
        _this.update_ticket_readonly = true
    }
    if(!_this.userRole.is_superadmin && !getTicketRole(_this.actions_by_group,TICKET_MANAGEMENT_SERVICE,CHANGE_DESCRIPTION)){
        _this.description_readonly = true
    }
    if(!_this.userRole.is_superadmin && !getTicketRole(_this.actions_by_group,TICKET_MANAGEMENT_SERVICE,CREATE_ATTACHMENT)){
        _this.attachment_readonly = true
    }
    if(!_this.userRole.is_superadmin && !getTicketRole(_this.actions_by_group,TICKET_MANAGEMENT_SERVICE,DELETE_ATTACHMENT)){
        _this.attachment_delete_readonly = true
    }
    if(!_this.userRole.is_superadmin && !getTicketRole(_this.actions_by_group,TICKET_MANAGEMENT_SERVICE,CHANGE_TICKET_QUEUE)){
        _this.queue_readonly = true
    }
    if(!_this.userRole.is_superadmin && !getTicketRole(_this.actions_by_group,TICKET_MANAGEMENT_SERVICE,CHANGE_TICKET_ASSIGNEE)){
        _this.assignee_readonly = true
    }
    
  }
  }
}
</script>

<style scoped>

.mycustomcls >>> textarea {
  padding-left: 6px !important;
  font-size: 14px !important;
}

.mycustomcls >>> input {
  padding-left: 6px !important;
  font-size: 14px !important;
}

  .label-margin label{
    margin-top:5%;
  }
  .EDC-VCards {
  margin: 0px !important;
  height: 165px !important;
  width: 875px !important;
  overflow:none !important;
}
.EDC-Scroll {
  height: 525px !important;
  overflow:auto !important;
}
.Tile_White {
  background: white !important;
}
.Top_Line {
  padding-left: 1% !important;
}
.Span_Line {
  padding-top: 0.3% !important;
}
.EDC-Container {
  margin: 0px !important;
  padding: 0px !important;
  padding-left: 8px !important;
  padding-right: 0px !important;
  object-fit:fill !important;
}
.Tile_Dark {
  background: #636363 !important;
}
.Image-Resize {
object-fit:contain !important;
}
.EDC-VListItemContent {
  margin: 0px !important;
  padding: 0px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  color:var(--v-apptext-base) !important;
}
.Chip-Active {
  color:white;
}
.v-chip.v-size--default {
    border-radius: 11px !important;
    font-size: 10px !important;
    height: 24px !important;
}
.GroupCard {
    padding-left: 5px !important;
    padding-bottom: 5px !important;
    padding-top: 5px !important;
    padding-right: 0px !important;
    margin-top: 6px !important;
    margin-bottom: 6px !important;
    margin-right: 25px !important;
}
.EDC-TextField {
 	padding-top:0px !important;
 	padding-left:5px !important;
 	padding-right:10px !important;
}
.customInputClass >>> label{
    font-weight: bold !important;
}
.columns-alignment{
  padding-top:8px !important;
  padding-right:24px !important;
  padding-left:6px !important
}
.commentbox-alignment{
  padding-top:8px !important;
  padding-right:2% !important;
  padding-left:0px !important
}
.v-card__title{
  padding:0px !important;
  margin:0px !important;
}
.title-fixed{
  right: 2% !important;
  left: 2% !important;
  top: 1% !important;
  margin-top: -3% !important;
  position:relative;
}
.border-change{
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 1px 0 rgba(0, 0, 0, 0.4);
}
.v-input >>> label {
  padding-left: 6px !important;
  font-size: 14px !important;;
}

.override-font{
  font-size: 14px !important;
  
}

.v-input {
  padding-left: 6px !important;
  font-size: 14px !important;;
}
</style>
<style scoped>
.v-input__slot{
  font-size: 14px;
  margin-bottom: -8px;
  margin-top: -5px;
}
</style>
