/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Add_Multiple': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M46 7a12 12 0 00-12 12 12 12 0 00.951 4.68c-3.041-2.35-6.843-3.764-10.978-3.764-9.93 0-18 8.07-18 18s8.07 18 18 18c9.929 0 18-8.07 18-18 0-3.156-.823-6.12-2.254-8.701A12 12 0 0046 31a12 12 0 0012-12A12 12 0 0046 7zm-.014 3.887A1 1 0 0147 11.9V18h6.1a1 1 0 110 2H47v6.1a1 1 0 11-2 0V20h-6.1a1 1 0 110-2H45v-6.1a1 1 0 01.986-1.013zM23.973 21.916c8.848 0 16 7.152 16 16 0 8.848-7.152 16-16 16-8.849 0-16-7.152-16-16 0-8.848 7.151-16 16-16zm.011 5.07A1 1 0 0023 28v9h-9a1 1 0 100 2h9v9a1 1 0 102 0v-9h9a1 1 0 100-2h-9v-9a1 1 0 00-1.016-1.014z" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
