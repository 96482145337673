<template>
    <v-container class="EDC-Container">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <v-toolbar dense>
                    <v-text-field clearable hide-details v-model="searchResult" label="Search Ticket Id/Ticket Name/Users/Keywords" prepend-icon="mdi-magnify" single-line
                    return-object hide-no-data @click:prepend="searchForTickets(searchResult, status)" @keypress.enter="searchForTickets(searchResult, status)"></v-text-field>
                    <v-menu  bottom center transition="scale-transition" style="display:block" v-model="showMoreActions" :close-on-content-click="false" :nudge-width="100">
                        <template v-slot:activator="{ on }">
                            <svgicon style="cursor:pointer; height:16px; weidth:16px" v-on="on" class="focus svg-fill-mini" v-bind:class="{'change_highlight':filterList.length>0}" name="filter_v2" slot="activator"  :original="true" title="Show More Options"></svgicon>
                        </template>
                        <v-card class="EDC-Card" style="padding:8px !important;width:320px !important;">
                            <v-row class="EDC-Row">
                                <v-col class="EDC-Col" align="left"><b>Search Options</b></v-col>
                            </v-row>
                            <v-row class="EDC-Row" align="center">
                                <v-col class="EDC-Col" cols="12">
                                    <v-autocomplete clearable multiple hide-details v-model="status" :items="statusList" item-text="status" item-value="status" label="Status" return-object></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row class="EDC-Row" align="center">
                                <v-col class="EDC-Col" cols="12">
                                    <v-autocomplete multiple clearable hide-details v-model="priority" :items="priorityList" item-text="name" item-value="name" label="Priority" return-object></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row class="EDC-Row" align="center">
                                <v-col class="EDC-Col" cols="12">
                                    <v-autocomplete multiple clearable hide-details v-model="queue" :items="queueList" item-text="queue_name" item-value="queue_name" label="Queue" return-object></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row class="EDC-Row" style="padding-top:8px !important;">
                                <v-col class="EDC-Col" align="left"><b>Select User</b></v-col>
                            </v-row>
                            <v-row class="EDC-Row" align="center">
                                <v-col class="EDC-Col" cols="12">
                                    <v-radio-group hide-details v-model="userSearchOpt" row style="padding-top:2% !important">
                                        <v-radio class="customCheckbox" value="created_by" label="Created By" style="margin-right:4px !important;"></v-radio>
                                        <v-radio class="customCheckbox" value="assigned_to" label="Assigned To" style="margin-right:4px !important;"></v-radio>
                                        <v-radio class="customCheckbox" value="modified_by" label="Modified By" style="margin-right:0px !important;"></v-radio>
                                    </v-radio-group>
                                    <v-autocomplete multiple :disabled="!userSearchOpt" style="padding-top:0px !important;" clearable hide-details v-model="users" :items="userList" item-text="full_name_with_user_name" item-value="full_name_with_user_name" label="Users" return-object></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row class="EDC-Row" style="padding-bottom:8px !important;padding-top:8px !important;">
                                <v-col class="EDC-Col" align="left"><b>Select Date</b></v-col>
                            </v-row>
                            <v-radio-group hide-details v-model="DateSearchOpt" row >
                                <v-radio class="customCheckbox" value="created_date" label="Created Date" style="margin-right:4px !important;"></v-radio>
                                <v-radio class="customCheckbox" value="modified_date" label="Modified Date" style="margin-right:0px !important;"></v-radio>
                            </v-radio-group>
                            <v-row class="EDC-Row">
                                <v-col class="EDC-Col EDC-UIPadding" cols="5">
	                                <calender format='MM/dd/yyyy' v-model="startDate" :input="startDate" style=" float:left" @update="setStartDate" label="Start Date" :dense="true" key="startdate" :isMinDate="false"></calender>
	                            </v-col>
                                <v-col class="EDC-Col" cols="1">
                                </v-col>
                                <v-col class="EDC-Col EDC-UIPadding" cols="5">
	                                <calender format='MM/dd/yyyy' @update="setEndDate" :input="endDate" v-model="endDate" style=" float:left" label="End Date" :dense="true" key="enddate" :isMinDate="true"></calender>
	                            </v-col>
                            </v-row>
                            <v-row class="EDC-Row" justify="center" style="padding-top:1% !important;">
                                <vc-button itemText="Apply" @click.native="searchForTickets()"></vc-button>
                                <vc-button itemText="Clear" @click.native="clearSearch"></vc-button>
                            </v-row>
                        </v-card>
                    </v-menu>
                </v-toolbar>
            </v-col>
        </v-row>
        <loading-panel :loader="loader"></loading-panel>
    </v-container>
</template>
<script>
import config from '../../config.json';
import {mapActions} from 'vuex';
import Calender from '../ticketmanagement/calendar'
import {post as postToServer} from './../../methods/serverCall.js';
import {get as getToServer} from './../../methods/serverCall.js';
import {manageSearchDisplay} from '@/methods/special.js'
export default{
    props:{
        isFromHeader:{
		    type:Boolean,
			default:false
	    },
    },
    components:{
		Calender
	},
    watch:{
        'isFromHeader':{
            handler(newvalue){}
        },
        searchword(newValue1){
            this.searchForTickets(newValue1)
        },
        '$store.state.clearSearchText':{
            handler(newValue){
                this.filterList=[]
                this.searchResult=''
                this.priority=''
                this.status=''
                this.queue=''
                this.users=''
                this.userSearchOpt='created_by'
                this.DateSearchOpt='created_date'
                this.startDate=null
                this.endDate=null
            }
        },
        '$store.state.clearDate':{
            handler(newValue){
                this.startDate=null
                this.endDate=null
            }
        }
    },
    data(){
        return{
            searchword:'',
            page:1,
            filterList:[],
            userSearchOpt:'created_by',
            DateSearchOpt:'created_date',
            createbtn:0,
            assignbtn:0,
            modifiedbtn:0,
            startDate:null,
            endDate:null,
            statusList:[],
            status:null,
            loader:false,
            priorityList:[],
            priority:null,
            userList:[],
            users:null,
            queueList:[],
            queue:null,
            searchResult:'',
            ticket_details:'',
            ticket_list: [],
            radioGroup:'All'
        }
    },
    mounted(){
        this.getAllStatus();
        this.getAllPriority();
        this.getAllUsers();
        this.getAllQueues();
    },
    methods:{
        ...mapActions(["setticketDetailsObj"]),
        ...mapActions(["setsearchTicketResult"]),
        clearSearch(){
            this.filterList=[]
            this.priority=''
            this.userSearchOpt='created_by'
            this.status=''
            this.queue=''
            this.users=''
            this.DateSearchOpt='created_date'
            this.startDate=null
            this.endDate=null
        },
        getAllStatus(){
            var _this = this
            var get_status_url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + '/get_ticket_status'
            getToServer(_this, get_status_url).then(statusResponse=>{
                if(statusResponse){
                    this.statusList=statusResponse
                }
            }).catch(statusErrorResponse=>{
            })
        },
        setStartDate(dateParam){
            this.startDate = dateParam
        },
        setEndDate(dateParam){
            this.endDate = dateParam
        },
        getAllPriority(){
            var tkt_priority_data = {"client_id": this.$session.get('client_id')}
            var url_queue_user = config.EDC_TICKET_MANAGEMENT_SERVICE_URL+"/get_ticket_priority"
            postToServer(this, url_queue_user, tkt_priority_data).then(response=>{
                if(response){
                _.forEach(response, (element) => {
                        this.priorityList.push(element)
                    });
                }
            }).catch(priorityErrorResponse=>{
            })
        },
        getAllUsers() {
            let _this = this;
            var client_id = this.$session.get('client_id')
            getToServer(this, config.USER_PROVISION_URL + "/get_allusers/" + client_id).then(response => {
                if (response) {
                    _this.userList = response;
                } else {
                    _this.userList = [];
                }
            }).catch(error_response => {
            });
        },
        getAllQueues() {
            let _this = this;
            var request_data = {
                'client_id': this.$session.get('client_id'),
                'product_name': window.sessionStorage.getItem('product')}
            postToServer(_this, config.EDC_QUEUE_MANAGEMENT_URL + "/fetch_all_queues", request_data).then(response => {
                if (response) {
                    _this.queueList = response;
                } else {
                    _this.queueList = [];
                }
            }).catch(error_response => {
            });
        },
        searchForTickets(key_to_search){
            var search_word = key_to_search
            var search_dict = {}
            var field_list = []
            let search_data = {
            "page":this.page,
            "page_size": manageSearchDisplay(),
            "search_keyword": search_word}
            if(this.status){
                var selected_status=[]
                for(var i=0; i<this.status.length; i++){
                    selected_status.push(this.status[i].status)
                }
                search_dict["field_name"] = "status"
                search_dict["keyword"] = selected_status
                field_list.push(search_dict)
                search_dict = {}
            }
            if(this.priority){
                var selected_priority=[]
                for(var i=0; i<this.priority.length; i++){
                    selected_priority.push(this.priority[i].name)
                }
                search_dict["field_name"] = "priority"
                search_dict["keyword"] = selected_priority
                field_list.push(search_dict)
                search_dict = {}
            }
            if(this.users){
                var selected_users=[]
                for(var i=0; i<this.users.length; i++){
                    selected_users.push(this.users[i].username)
                }
                search_dict["field_name"] = "user"
                search_dict["keyword"] = selected_users
                search_dict["search_type"] = this.userSearchOpt
                field_list.push(search_dict)
                search_dict = {}
            }
            if(this.queue){
                var selected_queue=[]
                for(var i=0; i<this.queue.length; i++){
                    selected_queue.push(this.queue[i].queue_name)
                }
                search_dict["field_name"] = "queue"
                search_dict["keyword"] = selected_queue
                field_list.push(search_dict)
                search_dict = {}
            }
            if(this.startDate || this.endDate){
                search_dict["field_name"] = "date_range"
                search_dict["from_date"] = this.startDate
                search_dict["to_date"] = this.endDate
                search_dict["format"] = "YYYY-MM-DD"
                search_dict["search_type"] = this.DateSearchOpt
                field_list.push(search_dict)
                search_dict = {}
            }
            this.filterList=field_list
            search_data["filter_list"] = field_list
            this.loader=true
            var search_url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + '/search_data_from_es'
            postToServer(this, search_url, search_data).then(response => {
                this.loader=false
                if(response && response.result.length>0){
                    if(this.$route.path != '/searchpage'){
                        this.$router.push({name:'searchPage', params: {"ticket_list":response,"field_list": field_list, "search_keyword": search_word}})
                    }
                    else{
                        this.setsearchTicketResult({"ticket_list":response,"field_list": field_list, "search_keyword": search_word})
                    }
                }
            }).catch(error_response => {
                this.loader=false
                console.log(error_response)
            });
        },
        redirectToTicketDetails(searchResult){
            var _this = this
            this.setticketDetailsObj(searchResult)
            // this.$route.params = {ticket_details:searchResult}
            // this.$route.params.subscribe(params => {this.ticket_details = searchResult;})
            // window.location.reload().catch(()=>{})
            // this.$route.params.subscribe(params => {searchResult;})
        }
    }
}
</script>
<style scoped>
.customCheckbox >>> label{
    margin-bottom: 0px !important;
}
.customCheckbox >>> div{
    margin-right: 0px !important;
}
.change_highlight{
    fill: var(--v-primary-base) !important;
    font-weight: bold !important;
    height: 24px !important;
    width: 24px !important;
}
.focus{
    fill: var(--v-primary-base) !important;
      /* fill:none;
      color:inherit; */
      /* font-weight: bold o !important; */
    }
</style>
