<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>Supplier Contact Information</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <v-container>
      <v-form ref="suplier_contact_form" v-model="valid">
        <v-card elevation-1>
          <v-container class="EDC-Container">
            <v-col cols="12" class="EDC-Col">
              <v-row class="EDC-Row" style="padding-top:20px !important">
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field label="Company Name" v-model="company_name" :rules="CompanyNameRules" dense></v-text-field>
                </v-col>
              </v-row>
              <v-row class="EDC-Row" style="padding-top:20px !important">
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field label="Recipient Name" v-model="receiver_name" :rules="receiverNameRules" dense></v-text-field>
                </v-col>
              </v-row>
              <v-row class="EDC-Row" style="padding-top:20px !important">
                 <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field label=" Recipient Email" v-model="receiver_email" :rules="emailRules" dense></v-text-field>
                </v-col>
              </v-row>
              <v-row class="EDC-Row" style="padding-top:20px !important">
                 <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field label="Email Subject" v-model="email_subject" :rules="subjectRules" dense></v-text-field>
                </v-col>
              </v-row>
              <v-row class="EDC-Row" style="padding-top:20px !important">
                 <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field label="Email Content" v-model="email_content" :rules="contentRules" dense></v-text-field>
                </v-col>
              </v-row> 

              <v-row no-gutters class="EDC-Row EDC-RowsSpacing" justify="start">
                <vc-button itemText="Send" @click.native="submit"></vc-button>
                <vc-button itemText="Clear" @click.native="$router.go(-1)"></vc-button>
                <vc-button itemText="Cancel" @click.native="endWorkFlow"></vc-button>
              </v-row>
            </v-col>
          </v-container>
        </v-card>
      </v-form>
    </v-container>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    </div>
</template>

<script>
import config from '../../config.json'
import Breadcrumb from "../Breadcrumbs.vue"
import {
  post as postToServer
} from './../../methods/serverCall.js';
import {
  get as getToServer
} from './../../methods/serverCall.js';
import {
  GET_USER, UPDATE_PROFILE,UPDATE_TOKEN_DETAILS, GET_USER_ROLE,CREATE_SUPPLIER_TICKET,SEND_FROM_EMAIL
} from '../../data/url_constants.js'
import {
  SERVER_ERROR
} from '../../data/client_message.js'
import {
  COLOR_CODE,
  BTN_COLOR
} from '../../data/macros.js'
import {
  DATE_TIME_FORMAT,GetUIDateFormat
} from '@/data/manage_account_constants.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import {mapActions} from 'vuex'
var moment = require("moment-timezone");
export default {
  name: 'UserProfile',
  mounted() {
      this.addSupplierTicket()
  },
  data() {
    return {
      valid: false,
      snackbar: false,
      snackbartext: '',
      snackbartimeout: Snackbar_Default_Timeout,
      colorValue: 'error',
      loader: "loading",
      loading: false,
      company_name:"",
      default_ticket_title:"Supplier Ticket",
      receiver_name: "",
      receiver_email:"",
      ticket_id:'',
      email_subject:'Supplier Request Form',
      email_content:'Fill the from in given link.',
      emailRules: [
          v => !!v || 'E-mail is required',
          v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) || 'E-mail must be valid'
        ],
      subjectRules: [v => !!v || 'Email Subject is required.'],
      contentRules: [v => !!v || 'Email Content is required.'],
      addressRules: [v => !!v || 'Adress is required.'],
      CompanyNameRules: [v => !!v || 'Company Name is required.'],
      receiverNameRules: [v => !!v || 'Receiver  Name As is required.'],
      user_role: '',
      current_step_id:'',
      ticket_response:{},
      current_step_status:'',
      workflow_request_id:''
    }
  },
  components: {
    // Breadcrumb
  },
  props: {
    msg: String
  },
  methods: {
    submit(){
      if (this.$refs.suplier_contact_form.validate()) {
        this.save()
      }
    },
    addSupplierTicket(){
        var _this =this
        let formData = {
            'title':this.default_ticket_title,
            "option_name": "Add Supplier",
            'client_id': this.$session.get('client_id')
        }
            
        _this.loader = true;
        postToServer(this, config.EDC_TICKET_MANAGEMENT_SERVICE_URL + CREATE_SUPPLIER_TICKET, formData
        ).then(response  => {
            this.colorValue = 'success'
            this.ticket_id = response.ticket_id
            this.receiver_email = response.receiver_email
            this.current_step_id = response.current_step_id
            this.current_step_status = response.current_step_status
            this.workflow_request_id = response.workflow_request_id
            this.input_data = response.input_data
            this.ticket_response = response
        }).catch(ticketResponseError => {
                _this.loader = false;
                if(ticketResponseError){
                    this.snackbar = true 
                    this.colorValue = 'error'
                    this.snackbartext = ticketResponseError                  
                }
                else {     
                    this.snackbar = true             
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;                    
                }
            });
        },
    save () {
        var client_id = this.$session.get('client_id')
        var UserData = {
            'client_id': this.$session.get('client_id'),
            'user_id': "Essentio",
            'client_name': "Essentio",            
            'workflow_request_id':this.workflow_request_id,
            'ticket_id': this.ticket_id,
            'receiver_email': this.receiver_email,
            'current_step_id': this.current_step_id,
            'company_name':this.company_name,
            'receiver_name':this.receiver_name,
            'current_step_status': this.current_step_status,
            'workflow_request_id':this.workflow_request_id,
            'email_subject': this.email_subject,
            'email_content': this.email_content,

          }
        var data_to_send = {}
        data_to_send =  Object.assign({}, this.ticket_response, UserData);
        this.loader = true
        postToServer(this, config.EDC_FORM_MANAGEMENT_URL + SEND_FROM_EMAIL, data_to_send).then(Response  => {
              if(Response){
              }
          }).catch(error_response => {            
        this.loader = null
        if(error_response){
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = error_response;
          }
          else {
            this.snackbar = true
            this.snackbartext = 'Something went wrong.Try Again';
            this.colorValue = 'error'
            
          }
      });
    },
    endWorkFlow(){
    }
  }
}
</script>

<style>
  .label-margin label{
    margin-top:5%;
  }
</style>
