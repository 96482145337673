/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'approval_approve_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" d="M3.672.574a.42.42 0 00-.187.025V.6c-.14.052-.243.222-.253.36-.02.287-.083.816-.21 1.107a3.961 3.961 0 01-.38.465c-.174.183-.335.336-.335.336l-.084.076-.012 2.832.248.015s.539.032 1.156-.012c.618-.043 1.32-.117 1.732-.529l.05-.047.02-.066s.172-.61.321-1.223c.075-.306.143-.612.182-.853.02-.12.031-.221.031-.316 0-.048.001-.093-.021-.16a.295.295 0 00-.075-.124.32.32 0 00-.205-.084H4.592c.004-.186.013-.37.002-.587-.017-.312-.085-.657-.34-.92L4.246.86 4.24.856a1.214 1.214 0 00-.416-.247.654.654 0 00-.15-.035zm.059.585c.056.031.115.062.144.085.123.131.176.321.19.573.013.255-.014.551 0 .838l.011.252h1.328c-.003.028-.002.038-.007.07-.035.213-.1.513-.173.812-.133.549-.265 1.01-.29 1.1-.2.171-.796.324-1.356.363-.444.032-.674.017-.836.01l.01-2.092c.054-.051.13-.123.274-.273.185-.195.38-.385.482-.617.163-.374.197-.806.223-1.121zM.726 2.64c-.1 0-.178.08-.179.178L.535 5.624c0 .098.08.178.18.178H1.79c.099 0 .178-.08.179-.178l.012-2.806a.178.178 0 00-.18-.178H.726zm.715.269c.148 0 .268.12.268.267 0 .147-.12.266-.268.266a.268.268 0 01-.269-.266c0-.148.12-.267.269-.267z"/>'
  }
})
