/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Menu_Manage_Tickets': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" style="-inkscape-stroke:none" d="M37.656 1.807c-2.31 0-4.619.87-6.363 2.615L4.422 31.293c-3.49 3.489-3.49 9.24 0 12.728l6.365 6.364 2.121-2.121c.806-.807 2.022-.807 2.828 0 .807.806.807 2.022 0 2.828l-2.12 2.12 6.363 6.366c3.488 3.49 9.24 3.49 12.728 0l26.871-26.871c3.49-3.489 3.49-9.24 0-12.728l-6.365-6.364-2.121 2.121c-.806.807-2.022.807-2.828 0-.807-.806-.807-2.022 0-2.828l2.12-2.12-6.363-6.366c-1.744-1.745-4.055-2.615-6.365-2.615zm0 5.949c.758 0 1.515.304 2.121.91l2.557 2.555c-1.212 2.91-.662 6.408 1.687 8.758 2.35 2.349 5.848 2.9 8.758 1.687l2.555 2.557c1.212 1.212 1.212 3.03 0 4.242l-26.87 26.869c-1.211 1.212-3.03 1.212-4.241 0l-2.557-2.555c1.212-2.91.662-6.408-1.687-8.758-2.35-2.349-5.848-2.9-8.758-1.687l-2.555-2.557c-1.212-1.212-1.212-3.03 0-4.242l26.87-26.869c.605-.606 1.363-.91 2.12-.91zM33.414 19.27L30.586 22.1 41.9 33.414l2.829-2.828zM22.1 30.586l-2.829 2.828L30.586 44.73l2.828-2.829z"/>'
  }
})
