/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Email_Send': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M55 27v-8.297C55 17.76 54.127 17 53.041 17H10.912c-1.085 0-1.959.76-1.959 1.703v30.643c0 .943.874 1.7 1.96 1.7H53.04c1.086 0 1.959-.757 1.959-1.7V44" _fill="none" _stroke="#000" stroke-width="1.953" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M9 18l23 15 23-15" _fill="none" _stroke="#000" stroke-width="2" stroke-linejoin="round"/><path pid="2" d="M58.524 35.383l-4.762 2.808L49 41l-.031-4.103a8.601 8.636 0 00-4.153 2.138 8.601 8.636 0 00-2.302 8.944 8.601 8.636 0 01.66-11.907 8.601 8.636 0 015.772-2.32l-.029-3.843 4.804 2.737z" _fill="none" _stroke="#000" stroke-width="2" stroke-linecap="round"/><path pid="3" d="M74.652 21.757l11.59-3.106 3.107 11.591-11.591 3.106z"/>'
  }
})
