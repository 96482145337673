import {CLIENT_SIDE, SERVER_SIDE} from '../../data/macros.js'
export const TABLE_LIST = {
    headers: [
        { text: 'Ticket ID', value: 'ticket_id', align:'right', title: 'Ticket Id'},
        { text: 'Title', value: 'title', align:'left', title: 'Subject'},
        { text: 'Priority', value: 'priority_name', title:'Priority Name' },
        { text: 'Status', value: 'status', options:[], useChip:true,chipFor:'status'},
        // { text: 'Tags', value: 'tag_list', options:[], title: 'Tags'},
        { text: 'Queue', value: 'queue_name', options:[], title: 'Queue Name'},
        { text: 'Ticket Source', value: 'source_name', options:[], title: 'Ticket Source',},
        { text: 'Assignee', value: 'assigned_to_fullname', width: '140px', options:[], title: 'Assignee'},
        { text: 'Assigned By', value: 'assigned_by', options:[],align:'left',title:'Assigned By'},
        { text: 'Assigned Date', value: 'tz_assigned_date', title: 'Assigned Date', hideInlineFilter:true},
        { text: 'Assigned Time', value: 'tz_assigned_time', title: 'Assigned Time', hideInlineFilter:true},
        { text: 'Created By', value: 'created_by', options:[],align:'left',title:'Created By'},
        { text: 'Age', value: 'time_slab_name', title: 'Age Of Ticket'},
        { text: 'Created Date', value: 'tz_created_date', title: 'Created Date', hideInlineFilter:true },
        { text: 'Created Time', value: 'tz_created_time', title: 'Created Time', hideInlineFilter:true },
        ], 
        actions:
        [],//if we need conditional action in row then provide key
        sorting_type: SERVER_SIDE,
        filterType: SERVER_SIDE,
        paginationType: SERVER_SIDE,
        rows: [],
        total_count: 0,
        selected_rows: [],
        hideExport:true,
        hideShowColumns:true,
        caption:'Ticket Status',
        multipleGrid:true,
        hideFilter:true,
        showNew:false,
        itemkey:'ticket_id',
        updateInlineFilter:[],
        // is_in_tab:true,
        rowRemoved:{},
}

export const COMMENT_TABLE_LIST = {
    headers: [
        { text: 'Comment', value: 'comment', align:'left', title: 'Comment'},
        { text: 'Comment By', value: 'commented_by', options:[],align:'left',title:'Cooment By', hideInlineFilter:true},      
        { text: 'Comment Date', value: 'tz_commented_date', title: 'Comment Date' },
        { text: 'Comment Time', value: 'tz_commented_time', title: 'Comment Time' },
        ], 
        actions:
        [],//if we need conditional action in row then provide key
        sorting_type: CLIENT_SIDE,
        filterType: CLIENT_SIDE,
        paginationType: CLIENT_SIDE,
        rows: [],
        total_count: 0,
        selected_rows: [],
        hideExport:true,
        hideShowColumns:true,
        hideFilter:true,
        caption:'Comment List',
        multipleGrid:true,
        itemkey:'id',
        is_in_tab:true,
        rowRemoved:{},
}

export const ACTIVITY_TABLE_LIST = {
    headers: [
        { text: 'Log ID', value: 'log_id', align:'left', title: 'Comment'},
        { text: 'Activity Type', value: 'activity_name', title: 'Assigned Date' }, 
        // { text: 'Status', value: 'status', title: 'Comment Date' },
        { text: 'Log Details', value: 'description', options:[],align:'left',title:'Cooment By', hideInlineFilter:true},       
        { text: 'Action By', value: 'action_by', title: 'Comment Time' },
        { text: 'Action Date', value: 'tz_action_date', title: 'Comment Time' },
        { text: 'Action Time', value: 'tz_action_time', title: 'Comment Time' },
        ], 
        actions:
        [],//if we need conditional action in row then provide key
        sorting_type: CLIENT_SIDE,
        filterType: CLIENT_SIDE,
        paginationType: SERVER_SIDE,
        rows: [],
        total_count: 0,
        selected_rows: [],
        hideExport:true,
        hideShowColumns:true,
        hideFilter:true,
        caption:'Comment List',
        multipleGrid:true,
        itemkey:'id',
        is_in_tab:true,
        rowRemoved:{},
}

export const ATTACHEMENT_TABLE_LIST = {
    headers: [
        { text: 'Name', value: 'file_name', align:'left', title: 'Comment'},
        { text: 'Attachment Type', value: 'attachment_type', options:[],align:'left',title:'Cooment By', hideInlineFilter:true},        
        { text: 'Attached Date', value: 'tz_attached_date', title: 'Comment Time' },
        { text: 'Attached Time', value: 'tz_attached_time', title: 'Comment Time' },
        ], 
        actions:
        [],//if we need conditional action in row then provide key
        sorting_type: CLIENT_SIDE,
        filterType: CLIENT_SIDE,
        paginationType: CLIENT_SIDE,
        rows: [],
        total_count: 0,
        selected_rows: [],
        hideExport:true,
        hideShowColumns:true,
        hideFilter:true,
        caption:'Comment List',
        multipleGrid:true,
        itemkey:'id',
        is_in_tab:true,
        rowRemoved:{},
}

export const DASHBOARD_TABLE = {
    headers: [
        { text: 'Priority', value: 'priority_name', title:'Priority Name'},
        { text: 'Age', value: 'time_slab_name', title: 'Age Of Ticket'},
        { text: 'Ticket ID', value: 'ticket_id', align:'right', title: 'Ticket Id'},        
        { text: 'Title', value: 'title', align:'left', title: 'Subject'},
        { text: 'Queue', value: 'queue_name', options:[], title: 'Queue Name'},
        { text: 'Created By', value: 'created_by', options:[],align:'left',title:'Created By'},
        { text: 'Created Date', value: 'tz_created_date', title: 'Created Date'},
        { text: 'Created Time', value: 'tz_created_time', title: 'Created Time'},
        
        ], 
        actions:
        [],//if we need conditional action in row then provide key
        sorting_type: SERVER_SIDE,
        filterType: SERVER_SIDE,
        paginationType: SERVER_SIDE,
        rows: [],
        total_count: 0,
        selected_rows: [],
        hideExport:true,
        hideShowColumns:true,
        caption:'Ticket Status',
        perPage:8,
        multipleGrid:true,
        hideInlineFilter: true,
        hideSelect:true,
        hidePagination:true,
        hideItemPerPage:true,
        hideRowInfo:true,
        hideFilter:true,
        showNew:false,
        itemkey:'ticket_id',
        // is_in_tab:true,
        rowRemoved:{},
}