import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        generalDownloadNotification:{},
        pendingDownloadStatusUpdate:{},
        themeType:'',
        workflowQueueMappingChange:{},
        ticketDetailsObj:{},
        clearSearchText:{},
        clearDate:{},
        searchTicketResult:{}
    },
    mutations:{
        setGeneralDownloadNotification(state, obj) {
            state.generalDownloadNotification = obj;
        },
        setPendingDownloadStatusUpdate(state, obj){
            state.pendingDownloadStatusUpdate = obj;
        },
        setthemeInfo(state,theme){
            state.themeType = theme
        },
        setworkflowQueueMappingChange(state,obj){
            state.workflowQueueMappingChange = obj
        },
        setticketDetailsObj(state,obj){
            state.ticketDetailsObj = obj
        },
        setclearSearchText(state,obj){
            state.clearSearchText=obj
        },
        setclearDate(state,obj){
            state.clearDate=obj
        },
        setsearchTicketResult(state,obj){
            state.searchTicketResult=obj
        },
    },
    actions:{
        setGeneralDownloadNotification:(context,obj)=>{
            context.commit('setGeneralDownloadNotification',obj)
        },
        setPendingDownloadStatusUpdate:(context,obj)=>{
            context.commit('setPendingDownloadStatusUpdate',obj)
        },
        setthemeInfo(context,theme){
            context.commit('setthemeInfo',theme)
        },
        setworkflowQueueMappingChange:(context,obj)=>{
            context.commit('setworkflowQueueMappingChange',obj)
        },
        setticketDetailsObj(context,obj){
            context.commit('setticketDetailsObj',obj)
        },
        setclearSearchText(context,obj){
            context.commit('setclearSearchText',obj) 
        },
        setclearDate(context,obj){
            context.commit('setclearDate',obj) 
        },
        setsearchTicketResult(context,obj){
            context.commit('setsearchTicketResult',obj) 
        }
    }
})