<template>
    <div style="width: 100% !important">
        <loading-panel :loader="loader"></loading-panel>
        <v-container fill-height fluid>
            <p><b>Ticket ID Generation</b></p>
            <v-row no-gutters align="center" class="EDC-Row" style="padding-top:15px !important">
                <v-col cols="2" class="EDC-Col" style="padding-right:15px !important">
                    <v-text-field label="Name Schema" v-model="nameSchema" :disabled="readonly" autocomplete="off" dense></v-text-field>
                </v-col>
                <v-col cols="2" class="EDC-Col" style="padding-right:15px !important">
                    <v-text-field label="Ticket ID Starting From" v-model="seqNumber" :disabled="readonly" autocomplete="off" dense></v-text-field>
                </v-col>
                <v-col cols="2" class="EDC-Col" style="padding-right:15px !important">
                    <v-text-field label="Ticket ID Size" v-model="numberSize" :disabled="readonly"  autocomplete="off" dense></v-text-field>
                </v-col>
                <v-col cols="2" class="EDC-Col" style="padding-right:15px !important">
                    <v-text-field label="Example" v-model="createIDExample" readonly  autocomplete="off" dense></v-text-field>
                </v-col>
                <v-col cols="3" align="left" class="EDC-Col">
                    <vc-button itemText="Save" @click.native="submit"></vc-button>
                    <vc-button itemText="Cancel" @click.native="$router.go(-1)"></vc-button>
                </v-col>
            </v-row>
            <!-- <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar> -->
        </v-container>
    </div>
</template>
<script>
import { post as postToServer } from './../../methods/serverCall.js';
import config from '../../config.json'
import {GET_TICKET_MASTER_SEQ_DATA, UPDATE_TICKET_MASTER_SEQ_DATA, GET_ACTIONS_BY_GROUP_ID_LIST} from "../../data/url_constants.js"
import {getUserRole, getTicketRole} from './../../methods/GetUserRole.js'
import {
  COLOR_CODE,
  BTN_COLOR,
  UPDATE_ACTION,
  TICKET_MANAGEMENT_SERVICE,
  UPDATE_ID_CONFIGURATION,
  READ_CONFIGURATION
} from '../../data/macros.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import {getActionByGroup} from './../../methods/TicketActionsAndQueueList.js'
export default{
    data (){
        return{
            nameSchema: "",
            seqNumber: 0,
            numberSize: "",
            actions_by_group:[],
            userRole:{},
            readonly:false,
            loader: false,
        }
    },
    mounted (){
        this.getTicketMasterSeqData()
        this.userRole = this.$session.get('user_role_mapping');
        getActionByGroup(this)
        if(!this.userRole.is_superadmin && !getTicketRole(this.actions_by_group,TICKET_MANAGEMENT_SERVICE,UPDATE_ID_CONFIGURATION)){
                    this.readonly = true
                }
    },
    computed:{
        createIDExample(){
            if(!this.nameSchema  || !this.numberSize)
                return ''
            // if (!this.seqNumber)
            //     this.seqNumber = "0"
            let numberId = this.nameSchema + this.seqNumber.toString().padStart(this.numberSize, '0')
            return numberId
        }
    },
    methods: {
        getTicketMasterSeqData(){
            var _this = this
            _this.loader = true
            postToServer(_this, config.EDC_TICKET_MANAGEMENT_SERVICE_URL+GET_TICKET_MASTER_SEQ_DATA).then(response =>{
                if(response){
                    _this.loader = false
                    _this.nameSchema = response.naming_schema
                    _this.seqNumber = response.sequence_number
                    _this.numberSize = response.number_size
                }
            }).catch(error_response =>{
                alert(error_response)
            })
        },
        submit(){
            var _this = this
            _this.loader = true
            if(!this.numberSize || !this.seqNumber || !this.nameSchema){
                alert('Please input all data')
                return
            }
            var data = {"table_name": "edc_tkt_master",
                        "client_id": _this.$session.get('client_id'),
                        "naming_schema": _this.nameSchema,
                        "sequence_number": _this.seqNumber,
                        "number_size": _this.numberSize}
            postToServer(_this, config.EDC_TICKET_MANAGEMENT_SERVICE_URL+UPDATE_TICKET_MASTER_SEQ_DATA, data).then(response =>{
                if(response){
                    _this.loader = false
                    _this.getTicketMasterSeqData()
                }
            }).catch(error_response =>{
                alert("error!!")
                _this.loader = false
                _this.getTicketMasterSeqData()
            })
        },
    //     getActionByGroup(){
    //     var _this = this
    //     let input_details ={
    //       'group_id_list': this.$session.get('mapped_user_groups'),
    //       "product_name":Ticket_management
    //     }
    //     this.loading = true;
    //     var security_end_point = config.Security_URL + GET_ACTIONS_BY_GROUP_ID_LIST
    //     postToServer(this, security_end_point, input_details).then(securityResponse  => {
    //       this.loading=false;
    //           if(securityResponse){
    //             this.actions_by_group = securityResponse
    //             if(!_this.userRole.is_superadmin && !getTicketRole(_this.actions_by_group,TICKET_MANAGEMENT_SERVICE,UPDATE_ID_CONFIGURATION)){
    //                 _this.readonly = true
    //             }
                
    //           }
    //       }).catch(error_response => {
    //         this.loading=false;
           
    //     });
    //   },

    }
}
</script>