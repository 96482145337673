/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'archive_unsynchronize_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" d="M4.71.265v.528H.794a.265.265 0 00-.264.266V2.91h.53V1.323H4.71v.53l.687-.398.687-.397-.687-.397-.687-.396zm-1.8 1.852l-.661.529-.661.529.661.53.661.528V3.44a1.588 1.588 0 011.454.955 1.588 1.588 0 00.002-.03 1.588 1.588 0 00-1.456-1.58v-.668zM5.291 3.44v1.588H1.64v-.53l-.687.397-.687.397.687.397.687.396v-.528h3.918c.146 0 .264-.12.263-.265V3.44h-.529z"/>'
  }
})
