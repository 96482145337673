/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dependency_remove': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" d="M.794.794v.794h4.762V.794H.794zm3.43 1.324a.261.261 0 00-.127.037l-1.324.793a.265.265 0 000 .455l1.324.793c.177.105.4-.021.4-.227V3.44h1.06V2.118h-.53v.792h-.53v-.529a.264.264 0 00-.272-.263zm-3.431.527v1.06h1.588v-1.06H.792zm.266.265h1.056v.53H1.06l-.001-.53zM.794 4.763v.793h4.762v-.793H.794z"/>'
  }
})
