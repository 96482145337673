/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Menu_Approve_old2': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<circle pid="0" r="28" cy="31.5" cx="32"/><path pid="1" d="M43.314 19.025a3.992 3.992 0 00-2.83 1.176l-14.14 14.143-5.658-5.658a3.99 3.99 0 00-5.657 0 3.993 3.993 0 000 5.658l8.487 8.484a3.986 3.986 0 005.656 0l16.97-16.97a3.99 3.99 0 000-5.657 3.987 3.987 0 00-2.828-1.176z" _fill="#fff"/>'
  }
})
