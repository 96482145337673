<template>
	<v-container class="EDC-Container ">
		<v-row class="EDC-Row" style="padding-top:64px !important;">
			<v-col cols="1" class="EDC-Col"></v-col>
			<v-col cols="10" class="EDC-Col">
				<v-text-field dense label="Title" v-model="ticketTitle" autocomplete="off"></v-text-field>
            </v-col>
			<v-col cols="1" class="EDC-Col"></v-col>
		</v-row>
		<!-- <v-row class="EDC-Row controlVerticalPadding">
			<v-col cols="1" class="EDC-Col"></v-col>
			<v-col cols="10" class="EDC-Col" align="left">
				<v-icon @click="redirectToIssue" class="mr-1" v-on="on" title="Link To Redirect Dataviewer">link</v-icon><span @click="redirectToIssue" class="linkProps">Redirect To Dataviewer</span>
            </v-col>
			<v-col cols="1" class="EDC-Col"></v-col>
		</v-row> -->
		<!-- <v-row class="EDC-Row controlVerticalPadding">
			<v-col cols="1" class="EDC-Col"></v-col>
			<v-col cols="10" class="EDC-Col">
				 <v-autocomplete class="mycustomcls" v-model="queue" hide-details dense clearable :items="queue_list" item-text="queue_name" item-value="id" return-object  label="Select Queue"></v-autocomplete>
            </v-col>
			<v-col cols="1" class="EDC-Col"></v-col>
		</v-row> -->
		<v-row class="EDC-Row controlVerticalPadding">
			<v-col cols="1" class="EDC-Col"></v-col>
			<v-col cols="10" class="EDC-Col">
				 <v-autocomplete class="mycustomcls"  v-model="priority" dense hide-details clearable :items="priority_list" item-text="name" item-value="id" return-object  label="Select Priority"></v-autocomplete>
            </v-col>
			<v-col cols="1" class="EDC-Col"></v-col>
		</v-row>
		<v-row class="EDC-Row controlVerticalPadding">
			<v-col cols="1" class="EDC-Col"></v-col>
			<v-col cols="10" class="EDC-Col">
				<v-textarea rows="3"  class="border-change" 
                auto-grow label="Description"  hide-details dense v-model="ticketDescription" ></v-textarea>
            </v-col>
			<v-col cols="1" class="EDC-Col"></v-col>
		</v-row>
		<v-row no-gutters class="EDC-Row controlVerticalPadding">
			<v-col cols="12" align="center">
		    	<vc-button class="override-font"  itemText="Save Ticket" @click.native="saveTicket"></vc-button>
			</v-col>
		    <!-- <vc-button class="override-font"  itemText="Cancel" @click.native="cancel"></vc-button> -->
		</v-row>
		<loading-panel :loader="loader"></loading-panel>
		<v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
	</v-container>
</template>
<style scoped>
	.controlVerticalPadding{
		padding-top: 8px !important;
		padding-bottom: 8px !important;
	}

	.linkProps{
		cursor: pointer !important;
	}
</style>
<script>
	import { GET_ALL_USER,DECRYPT_URL_DATA,GET_TICKET_SUMMARY,GET_TICKET_COMMENTS,GET_TICKET_LOGS,GET_RECENT_TICKET_LOGS,GET_TICKET_STATUS,
          GET_TICKET_PRIORITY,GET_TICKET_DETAILS_BY_ID,MANAGE_COMMENT,UPDATE_TICKET,CREATE_TICKET_URL,GET_TICKET_ATTACHMENTS,MANAGE_ATTACHEMENTS,GET_TICKET_DETAILS,
          DELETE_TICKET_ATTACHMENT,SEARCH_TAGS,CREATE_TAG,GET_ACTIONS_BY_GROUP_ID_LIST,GET_ALL_TAGS,UPDATE_TICKET_STATUS,GET_USERS_FROM_QUEUE, GET_ALL_QUEUES,
          GET_TICKET_ACTION, GET_CONFIG_DATA} from '../../data/url_constants.js'
    var moment = require("moment-timezone")
	import config from '../../config.json'
	import {
	  post as postToServer
	} from './../../methods/serverCall.js';
	import {
	  SERVER_ERROR
	} from '../../data/client_message.js'
	import {
	  get as getToServer
	} from './../../methods/serverCall.js';
	export default {
		name:'Ticket',
		data(){
			return{
				ticket_id: '',
				email_title: '',
				ticketTitle:'',
				ticketDescription:'',
				ticket_details:'',
				queue_list:[],
				queue:'',
				source_type: '',
				source_code: '',
				env_id:'',
				object_id:'',
				source_view:false,
				priority:'',
				priority_list:[],
				snackbar:false,
				colorValue:'',
				option_name:'',
				snackbartimeout:5,
				snackbartext:'',
				loader:false,
				url:this.$route.params.url
			}
		},
		props:{
			applicationRedirectKey:{
				type:String,
				default:''
			},
			dataviewerDetails:{
				type:String,
				default:''
			},
			isForDV:{
				type:Boolean,
				default:true
			},
			ticketDetails:{
				type:String,
				default:''
			}
		},
		watch:{
			'applicationRedirectKey':{
				handler(newValue){
					this.url = newValue
				}
			},
			'dataviewerDetails':{
				handler(newValue){

				}
			},
			'isForDV':{
				handler(newvalue){

				}
			},
			'ticketDetails':{
				handler(newvalue){
				}
			}
		},
		mounted(){
			var _this = this
			this.getPriorityList();
			if(this.isForDV){
				// this.source_type = this.dataviewerDetails.source_type
				// this.source_code = this.dataviewerDetails.source_code
				// this.env_id = this.dataviewerDetails.env_id
				// this.object_id = this.dataviewerDetails.object_id
				// this.source_view = this.dataviewerDetails.source_view === "true"?true:false
				// this.getMappedWorkflow();
				this.url = this.applicationRedirectKey
			}
			else{
				// var ticket_details = _this.ticketDetails
				// this.source_type = _this.ticketDetails.source_type
				// this.source_code = _this.ticketDetails.source_code
				// this.option_name = _this.ticketDetails.option_name
				// this.source_view = false
				// this.queue = {"id": ticket_details.queue_id, "queue_name": ticket_details.queue_name}
			}
		},
		methods:{
			getPriorityList(){
		        var _this =this;
		        var client_id = _this.$session.get('client_id');
		        var data = {"client_id": client_id,
		        'product_name': config.PRODUCT_NAME}
		        postToServer(_this, config.EDC_TICKET_MANAGEMENT_SERVICE_URL + GET_TICKET_PRIORITY, data
		        ).then(response  => {
		            var data =response
		            this.priority_list = data
		        }).catch(ticketResponseError => {
	                this.loader = false;
	                if(ticketResponseError){
	                    this.colorValue = 'error'
	                    this.snackbartext = ticketResponseError;                    
	                }
	                else {                    
	                    this.colorValue = 'error'
	                    this.snackbartext = SERVER_ERROR;                    
	                }
	            });
		    },
	        redirectToIssue(){

	        },
	        saveTicket(){
				var _this =this
				let formData = new FormData();
				this.getMappedWorkflow();
				if(this.isForDV){
					formData.append('source_type', this.dataviewerDetails.source_type);
					formData.append('source_code', this.dataviewerDetails.source_code);
					formData.append('queue_id',_this.queue.queue_id ? _this.queue.queue_id : '');
					formData.append('queue_name',_this.queue.queue_name ? _this.queue.queue_name : '');
					delete this.dataviewerDetails.source_code
					delete this.dataviewerDetails.source_type
					delete this.dataviewerDetails.source_view
					this.dataviewerDetails["source_view"] = JSON.stringify(this.source_view)
					this.dataviewerDetails["request_data"] = JSON.parse(this.dataviewerDetails.postData)
					formData.append('ticket_details', JSON.stringify(this.dataviewerDetails))
				}
				else{
					formData.append('source_type', this.ticketDetails.source_type);
					formData.append('source_code', this.ticketDetails.source_code);
					formData.append('queue_id',_this.ticketDetails.queue_id ? _this.ticketDetails.queue_id : '');
					formData.append('queue_name',_this.ticketDetails.queue_name ? _this.ticketDetails.queue_name : '');
				}
				formData.append('client_id', this.$session.get('client_id'));
				formData.append('title', this.ticketTitle);
				formData.append('description', this.ticketDescription);
				formData.append('option_name', this.ticketDetails.option_name)
				if(this.priority){
					formData.append('priority_details',JSON.stringify({"name":this.priority.name,
					"id":this.priority.id}));
				}
				
				_this.loader = true;
				postToServer(this, config.EDC_TICKET_MANAGEMENT_SERVICE_URL + "/create_ticket", formData
				).then(response  => {
					_this.loader = false;
					this.ticket_id=response.ticket_id
					this.email_title = response.title+" "+response.ticket_id
					this.colorValue = 'success'
					this.snackbartext = "Success"
					this.$emit('onSuccess',response)
				}).catch(ticketResponseError => {
						_this.loader = false;
						if(ticketResponseError){
							this.snackbar = true 
							this.colorValue = 'error'
							this.snackbartext = ticketResponseError                  
						}
						else {     
							this.snackbar = true             
							this.colorValue = 'error'
							this.snackbartext = SERVER_ERROR;                    
						}
					});

			},
			getMappedWorkflow(){
				let data_to_send={}
				if(this.isForDV){
					data_to_send = {"client_id":this.$session.get('client_id'),
										"source_type": this.dataviewerDetails.source_type,
										"source_code": this.dataviewerDetails.source_code,
										"source_view": this.dataviewerDetails.source_view === "true"?true:false,
										"env_id": this.dataviewerDetails.env_id,
										"object_id": this.dataviewerDetails.object_id}
				}	
				else{
					data_to_send = {"client_id":this.$session.get('client_id'),
										"source_type": this.ticketDetails.source_type,
										"source_code": this.ticketDetails.source_code,
										"source_view": false}
				}				
				var url = config.EDC_QUEUE_MANAGEMENT_URL+"/get_queue_workflow_form_rule_by_option"
				postToServer(this, url, data_to_send).then(response=>{
					if(response){
						this.queue = {"queue_id": response.queue_id, "queue_name": response.queue_name}
						this.option_name = response.option_name
					}
				}).catch(error_response=>{
					if(error_response){
						this.queue_list = []
					}
					else{
					this.snackbar = true
					this.colorValue = "error"
					this.snackbar = SERVER_ERROR
					}
				})
    		},
		}
	}
</script>