/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Menu_Configure': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M28 8c-1.108 0-2 .446-2 1v3.936a20 20 0 00-7.486 4.35l-3.432-1.981c-.48-.277-1.313.27-1.867 1.23l-4 6.93c-.554.96-.613 1.953-.133 2.23l3.432 1.98a20 20 0 00-.5 4.311 20 20 0 00.502 4.336l-3.434 1.983c-.48.277-.421 1.27.133 2.23l4 6.93c.554.96 1.387 1.507 1.867 1.23l3.434-1.982A20 20 0 0026 51.055V55c0 .554.892 1 2 1h8c1.108 0 2-.446 2-1v-3.953a20 20 0 007.486-4.334l3.432 1.982c.48.277 1.313-.27 1.867-1.23l4-6.93c.554-.96.613-1.953.133-2.23l-3.412-1.971a20 20 0 00.508-4.348 20 20 0 00-.498-4.326l3.402-1.965c.48-.277.421-1.27-.133-2.23l-4-6.93c-.554-.96-1.387-1.507-1.867-1.23l-3.398 1.963A20 20 0 0038 12.906V9c0-.554-.892-1-2-1h-8zm4.014 15.986a8 8 0 018 8 8 8 0 01-8 8 8 8 0 01-8-8 8 8 0 018-8z"/>'
  }
})
