<template>
	<v-container class="EDC-Container">
		<v-row class="EDC-Row">
			<v-col class="EDC-Col">
				<v-card class="EDC-VCards">
					<v-card-title class="cardHeader">
						{{headerText}}
						<v-spacer></v-spacer><svgicon style="font-weight:normal !important;cursor:pointer;"  class="svg-icon-mini svg-fill-mini" name="filter_v2"  :original="true" @click="showFilter" title="Filter"></svgicon>
					</v-card-title>
					<v-card-text>{{bodyText}}</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>
<style scoped>
	.EDC-VCards {
		margin: 4px !important;
		height: 43vh;
		padding: 0px !important;
	}
	.cardHeader{
		font-weight: bold;
	}

	.v-card__subtitle, .v-card__text, .v-card__title {
		padding-top: 0px !important;
		padding-left: 8px !important;
		padding-right: 8px !important;
	}

</style>
<script>
	export default {
		name:"noDtaFoundGraph",
		props:{
			headerText:{
				type:String,
				default:'Graph Title'
			},
			bodyText:{
				type:String,
				default:'No data found'
			}
		},
		methods:{
			showFilter(){
				this.$emit('showFilter')
			}
		}
	}
</script>