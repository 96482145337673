/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'document_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<g _fill="none" _stroke="#000" stroke-width=".529"><path pid="0" d="M2.91.794H1.565c-.275 0-.496.236-.496.529v3.704c0 .293.22.53.496.53h3.222c.275 0 .496-.237.496-.53V3.175"/><path pid="1" d="M5.288 2.644L3.447.792v1.44a.41.41 0 00.41.412z" stroke-linejoin="round"/><path pid="2" d="M1.852 1.852h.794M1.852 2.91H2.91M1.852 3.969h2.381"/></g>'
  }
})
