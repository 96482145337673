/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item_activate': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M2.91.53a.264.264 0 00-.264.264v2.91c0 .147.118.265.264.265h.53a.264.264 0 00.264-.265V.794A.264.264 0 003.44.529h-.53zm-.794.515a2.382 2.382 0 00-1.323 2.13 2.385 2.385 0 002.38 2.381 2.387 2.387 0 002.383-2.381c0-.932-.54-1.738-1.323-2.13v.61c.48.334.794.888.794 1.52a1.849 1.849 0 01-1.854 1.852 1.847 1.847 0 01-1.851-1.852c0-.632.314-1.187.794-1.52v-.61z" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
