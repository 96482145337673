/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Business Object': {
    width: 201.063,
    height: 201.19,
    viewBox: '0 0 53.198 53.232',
    data: '<g transform="translate(-34.264 -16.787)"><rect pid="0" width="23.652" height="9.556" x="46.305" y="24.718" ry="1.559" _stroke="#000" stroke-width=".265"/><rect pid="1" width="5.492" height="5.492" x="42.21" y="56.53" ry=".794" _stroke="#000" stroke-width=".265"/><rect pid="2" y="56.436" x="55.156" height="5.492" width="5.492" ry=".794" _stroke="#000" stroke-width=".265"/><rect pid="3" width="5.492" height="5.492" x="73.903" y="50.935" ry=".794" _stroke="#000" stroke-width=".265"/><path pid="4" d="M51.75 34.275l-.062 12.077M57.925 56.324l.025-10.272M58.209 46.307h-6.65M51.688 46.307h-6.756V56.53" _fill="none" _stroke="#000" stroke-width=".794"/><path pid="5" d="M69.902 32.7v-4.725a2.362 2.362 0 00-2.362 2.363 2.362 2.362 0 002.362 2.362zM51.783 31.803a2.362 2.362 0 00-2.363 2.362h4.725a2.362 2.362 0 00-2.362-2.362z" _fill="#fff"/><path pid="6" d="M76.84 50.845v-20.58m-6.882.098l7.148.033" _fill="none" _stroke="#000" stroke-width=".794" stroke-linejoin="round"/><path pid="7" d="M76.439 29.998h.794v.794h-.794z"/></g>'
  }
})
