<template>
	<v-container class="EDC-Container" style="height: 100vh !important;width: 100vw !important">
        <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <v-card-title class="cardHeader">
					<!-- {{getGraphHeader("Open Ticket Grid")}}	 -->
					Approval
					</v-card-title>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
		<v-row class="EDC-Row">
			<v-col class="EDC-Col">
                <!-- <v-dialog v-model="show_dialog" width="100vw" style="height: 100vh !important">
                <v-overlay :value="show_dialog" elevation-1 style="height: 100vh !important;width: 100vw !important" :dark="false">
        	    <v-col cols="12" class="EDC-Col">
	          	<v-card elevation-1 style="height: 100% !important">
	            	<v-row no-gutters class="EDC-Row">
	             		<v-toolbar dark dense absolute style="width:100vw" max-height="100vh">
                      <v-toolbar-title class="EDC-ToolbarTitle">Approval</v-toolbar-title>
                    <v-spacer></v-spacer>
	              		</v-toolbar>
	            	</v-row>   
	            	<v-row no-gutters class="EDC-Row EDC-RowsSpacing">
	             		<edc-form :formJson="selected_form" :formObj="form_response" @contact_form_event="sendEmail(...arguments)" :showContactForm="show_contact_form" :isApproval="true"></edc-form>
	            	</v-row>
                    <v-row no-gutters class="EDC-Row EDC-RowsSpacing" justify="end">
	              		<vc-button itemText="Approv" @click.native="approve"></vc-button>
                          <vc-button itemText="Reject" @click.native="reject"></vc-button>
	            	</v-row>
	          	</v-card>
	         </v-col>
            </v-overlay>
            </v-dialog> -->
            <v-row class="EDC-Row" no-gutters>
            <v-col class="EDC-Col">
              <v-card elevation-1  style="width: 98% !important">
              <edc-form :formJson="selected_form" :formObj="form_response" @contact_form_event="sendEmail(...arguments)" :showContactForm="show_contact_form" :isApproval="true"></edc-form>
              </v-card>
            </v-col>
          </v-row>
			</v-col>
		</v-row>
	</v-container>
</template>
<style scoped>
	
</style>
<script>
	import { get as getToServer } from '../../methods/serverCall.js';
	import { post as postToServer } from '../../methods/serverCall.js';
	import config from '../../config.json'
    import edcForm from '../edcForm/edcTabForm.vue'
	export default {
		name:'approvalForm',
		components: {
            'edc-form':edcForm,
        },
		data(){
			return {
				loader:false,
                show_contact_form:false,
				snackbar:false,
				formJson:{},
                show_dialog:true,
                form_response:'',
                selected_form:{},
				onlyFieldList:[],
			}
		},
		mounted(){
			var _this = this
			if(this.$route.query.token)
				this.validateToken()
				
			// setTimeout(()=>{
			// 	_this.loadUDCValues()
			// },100)
		},
		methods:{
			validateToken(){
				var _this = this
				var token = this.$route.query.token
				this.formJson = {}
				getToServer(this, config.WORKFLOW_PRODUCT_URL + '/manageaform/' + token, true).then(response => {
						for(let i=0;i<response.form_json.fieldList.length;i++)
						{	let field = response.form_json.fieldList[i]
							if(field.edcType === 'field')
								field.readonly = true
						}
					this.formJson = response.form_json
					this.addFieldsToFieldList(this.formJson.fieldList)
					this.form_response = response
                    this.selected_form = this.formJson
					console.log("Success")
					}).catch(error_response => {
					console.log("Failled")
					if(error_response){
						this.loader = null 
						this.snackbar = true
						this.colorValue = 'error'
						this.snackbartext = "Access Denied.";
						setTimeout(function(){
							_this.$router.push("/unauthorizedaccess")
						},1500)
						
						}
						else {
						this.snackbar = true
						this.colorValue = 'error'
						this.snackbartext = 'Something went wrong. Try Again';
						}
					})
				},
			addFieldsToFieldList(fieldList){
				var _this = this 
				for(let i =0;i<fieldList.length;i++){
					if(fieldList[i].groupId)
						_this.addFieldsToFieldList(fieldList[i].fieldList)
					else
						_this.onlyFieldList.push(fieldList[i])
				}
			},

		}
	}
</script>
<style scoped>
.cardHeader{
	 	font-family: "IBM ", "Plex" , "Sans";
		font-weight: bold;
        padding: 0px;
	}
</style>