<template>
	<v-container class="EDC-Row">
		<v-form id="edcTextform" ref="form">
			<v-row class="EDC-Row">
				<v-col class="EDC-Col" cols="12">
					<v-autocomplete :hide-details="hideDetails" :dense="propDense" :items="itemList" 
					:item-text="itemText" :item-value="itemValue" :return-object="returnObject" :label="labelText" :rules='rules' @input="emitInputChange($event)" :clearable="isClearable" v-model="autoCompleteModel" :disabled="readOnly">
						<template #label>
							{{labelText}}
    						<span class="red--text" v-if="isRequired"><strong> *</strong></span>
  						</template>
					</v-autocomplete>
				</v-col>
			</v-row>
		</v-form>
	</v-container>
</template>
<style scoped>
	
</style>
<script>
	export default {
		name:'EdcAutoComplete',
		props:{
			hideDetails:{
				type:Boolean,
				default:false
			},
			propDense:{
				type:Boolean,
				default:true
			},
			itemList:{
				type:Array,
				default:()=>{
					return []
				}
			},
			itemText:{
				type:String,
				default:'text'
			},
			itemValue:{
				type:String,
				default:'value'
			},
			returnObject:{
				type:Boolean,
				default:false
			},
			labelText:{
				type:String,
				default:'Select'
			},
			isRequired:{
				type:Boolean,
				default:true
			},
			formValidate:{
				type:Boolean,
				default:true
			},
			fieldUniqueId:{
				type:String,
				default:''
			},
			vModel:{

			},
			isClearable:{
				type:Boolean,
				default:false
			},
			autoCompleteModel:{
				type:String,
				default:''
				// this defined here because in case of autocomplete we are not sure about datatype.Plus if we defined this data hook, it causing validation on load
			},
			readOnly:{
				type:Boolean,
				default:false
			}
		},
		data(){
			return{

			}
		},
		computed:{
			rules(){
				let rules=[]
				if(!this.formValidate || this.readOnly)
					return
				if(this.isRequired){
					let rule = v => !!v || this.labelText +' field is required'
					rules.push(rule)
				}
				return rules
			}
		},
		watch:{
			itemList:{
				handler(newvalue){

				}
			},
			itemText:{
				handler(newvalue){

				}
			},
			itemValue:{
				handler(newvalue){
					alert(newvalue)
				}
			},
			vModel:{
				handler(newvalue){
					if(newvalue!=this.autoCompleteModel)
						this.autoCompleteModel = _.cloneDeep(newvalue)
				}
			},
			isRequired:{

			},
			fieldUniqueId:{
				
			},
			readOnly:{
				handler(newvalue){

				}
			}
		},
		mounted(){
			this.autoCompleteModel = _.cloneDeep(this.vModel)
			// alert(this.itemText)
		},
		methods:{
			emitInputChange(event){
				this.$emit('input',this.autoCompleteModel,this.fieldUniqueId,this.$refs.form.validate())
			}
		}
	}
</script>