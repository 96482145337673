/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'archive_restore_solid': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" d="M3.175 0a.528.528 0 00-.53.53v1.058H6.35V.529A.528.528 0 005.82 0zm0 2.117v1.19c0 .22.168.397.378.397h1.89c.21 0 .378-.177.378-.397v-1.19zm.794.264h1.058v.265H3.97zm-.792 1.594s-.388.84-.846 1c-.275.096-.637.107-.888.1l-.128-.5-.655.642-.653.643.883.245.883.245-.13-.506c.26-.002.662-.105.864-.243.39-.265.67-1.626.67-1.626z"/>'
  }
})
