<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="4" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>
                <router-link to="/queue_list">Queue List</router-link>
              </li>
              <!-- <li>
                Queue Management
              </li> -->
            </ul>
          </v-col>
          <v-col cols="8"></v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <v-container>
      <v-card elevation-1>
        <v-form ref="form" v-model="valid">
        <v-col cols="12" class="EDC-Col">
          <v-row class="EDC-Row EDC-TopRow">
            <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
              <v-text-field class="EDC-UIComponent" label="Name of Queue" v-model="queue_name" hide-details :rules="NameRules" autocomplete="off" dense></v-text-field>
            </v-col>
            <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
              
            </v-col>
          </v-row>
          <v-row class="EDC-Row EDC-TopRow">
            <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
              <!-- <v-autocomplete  label="Select Tags"  v-model="tags" item-text="tag_name" item-value="tag_name" :items="tag_list" multiple dense></v-autocomplete>
               -->
              <v-combobox
                v-model="tags"
                :items="tag_list"                        
                item-text="tag_name"
                item-value="id"
                :search-input.sync="search"
                hide-selected
                label="Selects Tags To Filter Tickets By"
                multiple
                :disabled="map_tag_readonly"
                small-chips
                @keydown.enter="addTag(search)"
                :rules="TagRules"
                dense
              >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-if="search">
                      No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:selection="{ attrs, item, parent, selected }">
										<v-chip
										v-if="item.tag_name === item.tag_name"
										v-bind="attrs"
										:input-value="selected"
										label
										small
										>
										<span class="pr-2">
											{{item.tag_name}}
										</span>
										<v-icon
											small
                      :disabled="delete_tag_readonly"
											@click="parent.selectItem(item)"
										>
											$delete
										</v-icon>
										</v-chip>
									</template>
            </v-combobox>
            </v-col>
          </v-row>
          <v-row class="EDC-Row EDC-TopRow">
            <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
              <v-combobox
                v-model="groups"
                :items="user_group_list"                        
                item-text="group_name"
                item-value="id"
                :search-input.sync="search"
                hide-selected
                :disabled="map_group_readonly"
                label="Selected associated groups for queue"
                multiple
                small-chips
                :rules="GroupRules"
                dense
              >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-if="search">
                      No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:selection="{ attrs, item, parent, selected }">
										<v-chip
										v-if="item.group_name === item.group_name"
										v-bind="attrs"
										:input-value="selected"
										label
										small
										>
										<span class="pr-2">
											{{item.group_name}}
										</span>
										<v-icon
											small
                      :disabled="delete_group_readonly"
											@click="parent.selectItem(item)"
										>
											$delete
										</v-icon>
										</v-chip>
									</template>
            </v-combobox>
            </v-col>
          </v-row>
          <v-row no-gutters class="EDC-Row EDC-ButtonRow" justify="start">
            <vc-button itemText="Save" @click.native="save"></vc-button>
            <vc-button itemText="Cancel" @click.native="$router.go(-1)"></vc-button>
          </v-row>
        </v-col>
        </v-form>
      </v-card>
    </v-container>
    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
    
  </div>
</template>
<script>
import { post as postToServer } from "../../methods/serverCall.js";
import { get as getToServer } from "../../methods/serverCall.js";
import config from "../../config.json";
import { SERVER_ERROR } from "../../data/client_message.js";
import {
  SERVICE_ACTION_DETAILS,
  SET_ROLE_ACTION,
  GET_ALL_ACTIVE_USERS_LIST,GET_ALL_GROUP_DETAILS,
  CREATE_TAG, ADD_QUEUE, UPDATE_QUEUE, GET_QUEUE_BY_ID, GET_ALL_TAGS, QUEUE_MANAGEMENT_SERVICE
} from "../../data/url_constants.js";
import _ from 'lodash'
import {Snackbar_Default_Timeout,  CREATE_QUEUE, READ_QUEUE, DELETE_QUEUE, MAP_QUEUE_AND_TAG, MAP_QUEUE_AND_USER_GROUP, REMOVE_QUEUE_AND_TAG_MAPPING, 
        REMOVE_QUEUE_AND_USER_GROUP_MAPPING} from '@/constants/constants.js'
import {getUserRole, getTicketRole} from './../../methods/GetUserRole.js'    
import {getActionByGroup} from './../../methods/TicketActionsAndQueueList.js'  

export default {
  data() {
    return {
      userRole:{},
      chipBackgroundColor:'rgb(144, 135, 135)',
      snackbar: false,
      snackbartext: "",
      colorValue: "error",
      snackbartimeout: Snackbar_Default_Timeout,
      loader: false,
      loading: false,
      selectAll: false,
      action_type: "administrator",
      isDisabled:false,
      queue_id:'',
      queue_name: "Untitled Queue Name",
      group_description: "",
      all_events: [],
      tag_list:[],
      user_group_list:[],
      tags:[],
      groups:[],
      NameRules: [v => !!v || 'Queue Name is required.'],
      TagRules:[v => !!v || 'Tags are required.'],
      GroupRules:[v => !!v || 'Groups are required.'],
      search:'',
      actions_by_group:[],
      map_group_readonly:false,
      map_tag_readonly:false,
      delete_group_readonly:false,
      delete_tag_readonly:false,
      tableList:{
        headers: [
          { text:'Events', value:'name', title:'Name of Apps', width:'694px'},
          { text:'Checked', value:'all', title:'All', width:'500px', useCheckbox:true, sortable:false, toggleRow:true, useIndeterminateState:false},
          // { text:'Create', value:'Create', title:'Info', width:'200px', useCheckbox:true, sortable:false, toggleRow:true},
          // { text:'Update', value:'Update', title:'Error', width:'200px', useCheckbox:true, sortable:false, toggleRow:true},
          // { text:'Delete', value:'Delete', title:'Approval', width:'200px', useCheckbox:true, sortable:false, toggleRow:true},
        ],
        caption:'ticketQueue',
        actions: [],
        sorting_type: '',
        filterType: '',
        paginationType: '',
        total_count: 0,
        rows: [],
        selected_rows:[],
        showCreateReport:true,
        select_all:false,
        disableDefaltSorting:true,
        hideItemPerPage:true,
        hideActions:true,
        hideExport:true,
        hideShowColumns:true,
        hideFilter:true,
        hideSelect:true,
        hideRowInfo:true,
        hidePagination:true,
        itemkey:'name'
      }
    };
  },
  mounted() {
    this.userRole = this.$session.get('user_role_mapping');
    this.getAllTags()
    getActionByGroup(this)     
    this.getAllUserGroups()
    if(this.$route.params && this.$route.params.queue_id){
      this.queue_id =  this.$route.params.queue_id
      this.getTicketQueue();
    }
    this.applySecurity()
  },
  methods: {
    selectDefaultAction(item) {
      item.event_type_list.map(event => {
        event.is_map = item.is_default == true ? true : false;
      });
    },
    save(){
      if(!this.queue_name && this.tags){
        this.snackbar = true;
        this.colorValue = "error";
        this.snackbartext = "Fill all details first";
      }       
      else  
        this.saveQueue() 

      // if (this.$refs.form.validate()) {
      //    saveQueue() 
      //  }
    },
    saveQueue() {
      let _this = this
      let client_id = this.$session.get("client_id")
      let tags = []
      //Creating input for tag mapping
      if(this.tags.length > 0)
      {
      _.forEach(this.tags,function(obj){
          tags.push({"id":obj.id,
                      "name": obj.tag_name})
          })

      }
      let groups = []
      //Creating input for tag mapping
      if(this.groups.length > 0)
      {
      _.forEach(this.groups,function(obj){
          groups.push({"id":obj.id,
                      "name": obj.group_name})
          })

      }
      var request_data = {
        client_id: client_id,
        'queue_name':this.queue_name,
        'tag_list':tags,
        'group_list':groups,
        'product_name': window.sessionStorage.getItem('product')};     
        let url = config.EDC_QUEUE_MANAGEMENT_URL + ADD_QUEUE;
        if(this.queue_id){
          request_data["queue_id"] =this.queue_id
          url = config.EDC_QUEUE_MANAGEMENT_URL + UPDATE_QUEUE;
        }
      _this.loader = true;
      postToServer(this, url, request_data)
        .then(response => {
          this.snackbar = true;
          _this.loader = false;
          this.colorValue = "success";
          this.snackbartext = "Queue Saved Successfully";
          if(response.id){
            _this.queue_id = response.id
          }
          this.$router.push("/ticketqueuelist")
        //   this.$router.push("/")
        })
        .catch(DatasourceError => {
          if (DatasourceError) {
            _this.loader = false;
            this.snackbar = true;
            this.colorValue = "error";
            this.snackbartext = DatasourceError;
          } else {
            this.snackbar = true;
            _this.loader = false;
            this.colorValue = "error";
            this.snackbartext = SERVER_ERROR;
          }
        });
    },
    getTicketQueue() {
      let l = this.loader;
      this[l] = !this[l];
      var _this = this
      var request_data = {
        client_id: this.$session.get("client_id"), 'queue_id': this.$route.params.queue_id,
        'product_name': window.sessionStorage.getItem('product')};
      this.loader = true;
      var url = config.EDC_QUEUE_MANAGEMENT_URL + GET_QUEUE_BY_ID;
      var _this = this
      postToServer(this, url, request_data)
        .then(Response => {
          this.loader = false;
          this.queue_name = Response.queue_name;
          _.forEach(Response.mapping,function(obj){
            obj.id = obj.tag_id
          })
          this.tags =Response.mapping
          _.forEach(Response.group_mapping,function(obj){
            obj.id = obj.group_id
          })
          this.groups = Response.group_mapping;
        })
        .catch(error_response => {
          this.loader = false;
          if (error_response) {
            this.snackbar = true;
            this.colorValue = "error";
            this.snackbartext = error_response;
            this[l] = false;
            this.loader = null;
          } else {
            this.loader = null;
            this.colorValue = "error";
            this.snackbartext = LOGIN_FAILED_MESSAGE;
            this[l] = false;
            this.snackbar = true;
          }
        });
    },
    getAllTags(){
                let url =  config.EDC_TAG_SERVICE_URL + GET_ALL_TAGS;
                let inputJson={
                    'client_id':this.$session.get('client_id'),
                    'product_name': 'TICKET_MANAGEMENT'
                }
                postToServer(this, url, inputJson).then(response  => {
                        this.tag_list = response;
                    }).catch(error => {
                        this.tag_list =[];
                    if(error){
                        // this.snackbar = true;
                        // this.colorValue = 'error';
                        // this.snackbartext = error;
                        }
                        else {
                        }
                });
            },
    getAllUserGroups(){
            let _this = this;
            _this.loader = true;
            _this.user_group_list = []
            var request_data = {
                'client_id': this.$session.get('client_id'),
                'product_name': window.sessionStorage.getItem('product')}
            postToServer(_this, config.USER_PROVISION_URL + GET_ALL_GROUP_DETAILS, request_data).then(response => {
                _this.loader = false;
                if (response) {
                    _this.user_group_list = response;
                } else {
                    _this.user_group_list = [];
                }
               
            }).catch(error_response => {
                _this.loader = false;
                 _this.user_group_list = [];
                if (error_response) {
                    this.loader = null
                    /* this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = error_response; */
                } else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                }
            });
        },
    addTag(search){
    var _this = this
    if(!this.userRole.is_superadmin && !getUserRole(this.userRole,TAG_SERVICE,CREATE_ACTION)){
        _this.tags.pop()
        this.snackbar = true;
          this.colorValue = 'error';
          this.snackbartext = "You dont have permission to add tag";
      }
    else if(!this.tag_list.includes(search)){
      let url =  config.EDC_TAG_SERVICE_URL + CREATE_TAG;
      let inputJson={
          'client_id':this.$session.get('client_id'),
          'tag_name':search,
          'product_name': "TICKET_MANAGEMENT"
          }
      postToServer(this, url, inputJson).then(response  => {
        //after creating new tag remove it from tag object as its just a string
        var added_tag =  _this.tags.pop()
        this.getAllTags()
        //If response has id we add that in tags
        _this.tags.push(response)
        }).catch(error => {
          _this.tags.pop()
        if(error){
          // this.snackbar = true;
          // this.colorValue = 'error';
          // this.snackbartext = error;
          }
        else {
            }
          });
  }
  },
  applySecurity(){
    var _this = this
    if(!_this.userRole.is_superadmin && !getTicketRole(_this.actions_by_group,QUEUE_MANAGEMENT_SERVICE,MAP_QUEUE_AND_TAG)){
        _this.map_tag_readonly = true
    }
    if(!_this.userRole.is_superadmin && !getTicketRole(_this.actions_by_group,QUEUE_MANAGEMENT_SERVICE,MAP_QUEUE_AND_USER_GROUP)){
        _this.map_group_readonly = true
    }
    if(!_this.userRole.is_superadmin && !getTicketRole(_this.actions_by_group,QUEUE_MANAGEMENT_SERVICE,REMOVE_QUEUE_AND_USER_GROUP_MAPPING)){
        _this.delete_group_readonly = true
    }
    if(!_this.userRole.is_superadmin && !getTicketRole(_this.actions_by_group,QUEUE_MANAGEMENT_SERVICE,REMOVE_QUEUE_AND_TAG_MAPPING)){
        _this.delete_tag_readonly = true
    }
    
  }
  },
};
</script>
<style scoped>
.customCheckbox >>> label {
  top: 5px !important;
}
.v-expansion-panel >>> .v-expansion-panel__header {
  padding-bottom: 0px !important;
}

.container {
  padding-top: 5px !important;
}

.EDC-TopRow {
  padding-top: 20px !important;
  padding-bottom: 15px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.EDC-GridRow {
  padding-top: 10px !important;
}

.EDC-ButtonRow {
  padding: 10px !important;
}

.EDC-ColTextarea {
  margin-top: -6px !important;
}

.EDC-UIComponent {
  margin: 0px !important;
  padding: 0px !important;
}
</style>