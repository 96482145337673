<template>
    <v-container>
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL" style="padding-left:0px !important;">
              <li>Form Security Page</li>
            </ul>
          </v-col>
        </v-row>
        <v-card elevation-1>
            <v-col cols="12" class="EDC-Col">
                <v-row no-gutters align="start" class="EDC-Row EDC-TopRow">
                    <v-col cols="3" class="EDC-Col EDC-ColsSpacing">
                        <v-autocomplete clearable label="User Group" hide-details v-model="user_group" :items="userGroupList" item-text="user_group_name" item-value="user_group_name" return-object></v-autocomplete>
                    </v-col>
                    <v-col cols="3" class="EDC-Col EDC-ColsSpacing">
                        <v-autocomplete clearable label="Form" v-model="form" :items="formList" item-text="form_name" item-value="form_name" @change="getAllFields" return-object></v-autocomplete>
                    </v-col>
                </v-row>

                <v-divider class="EDC-UIComponent"/>

                <v-card elevation-1 style="margin-top:8px !important;margin-bottom:8px!important;margin-right:4px !important;" class="service-card overflow-y-auto style-2scroll overflow-x-auto" v-if="final_fields_list.length>0 && form">
                    <v-row class="EDC-Row">
                        <v-col cols="12" align="left" style="padding-bottom:0px !important;">
                            <v-row class="EDC-Row">
                            <v-checkbox dense class="customCheckbox" style="padding-right: 1% !important;" v-model="allCheckBox" label="ALL" @change="checkAllActions($event, final_fields_list)"></v-checkbox>
                            <v-radio-group hide-details v-model="mainOpt" @change="changeAllRadio" row style="padding-top:0.2% !important">
                                <v-radio class="customRadiobtn" value="no_view" label="Not Viewable" style="margin-right:4px !important;"></v-radio>
                                <v-radio class="customRadiobtn" value="view_only" label="View Only" style="margin-right:4px !important;"></v-radio>
                                <v-radio class="customRadiobtn" value="update" label="Update" style="margin-right:0px !important;"></v-radio>
                            </v-radio-group>
                            </v-row>
                        </v-col>
                    </v-row>
                    <!-- <v-row class="EDC-Row">
                        <v-col cols="12" align="left" style="padding-top:0px !important;">
                            <template v-for="(row,r) in final_fields_list.length">
                                <v-row class="EDC-Row" v-if="r%4===0" :key="r+'row'">
                                    <template v-for="(col,c) in [0,1,2,3]">
                                        <v-col class="EDC-Col" cols="3" align="left"  :key="r+'row'+'col'+c" v-if="final_fields_list[r+c]"> 
                                            <v-row class="EDC-Row">
                                                <v-checkbox dense class="customCheckboxSectionName" v-model="final_fields_list[r+c].section_name.isSelected" style="margin:0px;padding:0px font-weight:bold !important;" hide-details color="primary-lighten2" :label="final_fields_list[r+c].section_name.field_name" @change="manageGroupCheckbox($event,final_fields_list[r+c])" :key="'checkbox'+r+'row'+'col'+c"></v-checkbox>
                                            </v-row>
                                            <v-row class="EDC-Row" v-for="fields in final_fields_list[r+c].fields_under_section" :key="fields.fieldId">
                                                <v-col class="EDC-Col" cols="3">
                                                <v-checkbox dense class="customCheckbox" v-model="fields.isSelected" style="margin:0px;padding:0px" hide-details color="primary-lighten2" :label="fields.field_name" @change="manageFieldCheckbox($event,final_fields_list[r+c])" :key="'checkbox'+r+'row'+'col'+c"></v-checkbox>
                                                <span class="red--text" v-if="fields.required"><strong>* </strong></span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </template>
                                </v-row>
							</template>
                        </v-col>
                    </v-row> -->
                    <v-row class="EDC-Row" v-for="section in final_fields_list" :key="section">
                        <v-col cols="12" align="left" style="padding-top:0px !important;">
                            <v-row class="EDC-Row">
                                <v-checkbox dense class="customCheckboxSectionName" v-model="section.section_name.isSelected" style="margin:0px;padding:0px font-weight:bold !important; padding-right: 1% !important;" hide-details color="primary-lighten2" :label="section.section_name.field_name" @change="manageGroupCheckbox($event,section)"></v-checkbox>
                                <v-radio-group hide-details v-model="headerOpt" @change="changeHeaderRadio" row style="padding-top:0.2% !important">
                                            <v-radio class="customRadiobtn" value="no_view" label="Not Viewable" style="margin-right:4px !important;"></v-radio>
                                            <v-radio class="customRadiobtn" value="view_only" label="View Only" style="margin-right:4px !important;"></v-radio>
                                            <v-radio class="customRadiobtn" value="update" label="Update" style="margin-right:0px !important;"></v-radio>
                                </v-radio-group>
                            </v-row>
                            <v-row class="EDC-Row" style="padding-top: 1% !important">
                                <v-col class="EDC-Col" cols="4" v-for="fields in section.fields_under_section" :key="fields.fieldId">
                                    <v-row class="EDC-Row">
                                    <v-checkbox dense class="customCheckbox" v-model="fields.isSelected" style="margin:0px;padding:0px" hide-details color="primary-lighten2" :label="fields.field_name" @change="manageFieldCheckbox($event,section)">
                                    </v-checkbox>
                                    <span class="red--text" v-if="fields.required"><strong>*</strong></span>
                                    <v-radio-group hide-details v-model="securityOpt" @change="changeFieldRadio" row style="padding-top:0.2% !important">
                                            <v-radio class="customRadiobtn" value="no_view" label="Not Viewable" style="margin-right:4px !important;"></v-radio>
                                            <v-radio class="customRadiobtn" value="view_only" label="View Only" style="margin-right:4px !important;"></v-radio>
                                            <v-radio class="customRadiobtn" value="update" label="Update" style="margin-right:0px !important;"></v-radio>
                                    </v-radio-group>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
                <v-row no-gutters class="EDC-Row EDC-ButtonRow" justify="end">
                    <vc-button itemText="Save" @click.native="saveFormMapping"></vc-button>
                    <vc-button itemText="Cancel" @click.native="cancel"></vc-button>
                </v-row>
            </v-col>
        </v-card>
        <loading-panel :loader="loader"></loading-panel>
    </v-container>
</template>
<script>
	import config from "../../config.json";
    import { post as postToServer } from "./../../methods/serverCall.js";
    export default{
        data(){
            return{
                final_fields_list:[],
                userGroupList:[],
                formList:[],
                fieldsList:[],
                allCheckBox:false,
                user_group:'',
                form:'',
                securityOpt:'no_view',
                headerOpt:'no_view',
                mainOpt:"no_view",
                loader:false,
            }
        },
        mounted(){
            this.GetUserGroupList();
            this.GetFormList();
        },
        methods:{
            changeAllRadio(){
                this.securityOpt=this.mainOpt
                this.headerOpt=this.mainOpt
            },
            changeHeaderRadio(){
                this.securityOpt=this.headerOpt
                this.mainOpt=''
            },
            changeFieldRadio(){
                this.headerOpt=''
                this.mainOpt=''
            },
            manageGroupCheckbox(event,selection_field){
                var _this = this
                var count = 0
                let checked = event
                selection_field.section_name.isSelected=checked
                selection_field.fields_under_section.forEach(function(obj){
                    obj.isSelected=checked
                })

                this.final_fields_list.forEach(function(obj){
                    if(obj.section_name.isSelected){
                        count=count+1
                    }
                })
                if(count===this.final_fields_list.length){
                    this.allCheckBox=true
                }
                else{
                    this.allCheckBox=false
                }

            },
            manageFieldCheckbox(event,selection_field){
                var _this = this
                var count = 0
                selection_field.fields_under_section.forEach(function(obj){
                    if(obj.isSelected){
                        count=count+1
                    }
                })
                if(count === selection_field.fields_under_section.length){
                    selection_field.section_name.isSelected=true
                }
                else{
                    selection_field.section_name.isSelected=false
                }

                this.final_fields_list.forEach(function(obj){
                    if(obj.section_name.isSelected){
                        count=count+1
                    }
                })
                if(count===this.final_fields_list.length){
                    this.allCheckBox=true
                }
                else{
                    this.allCheckBox=false
                }

            },
            GetUserGroupList() {
                var _this =this;
                var client_id = _this.$session.get('client_id');
                var data = {"client_id": client_id,
                            'product_name': window.sessionStorage.getItem('product')}
                var get_user_url = config.USER_PROVISION_URL + '/get_all_group_details'
                postToServer(_this, get_user_url, data).then(response => {
                response.forEach(function(obj){
                    obj.user_group_name = obj.group_name
                })
                _this.userGroupList = response;
                }).catch(error_response => {
                }); 
            },
            GetFormList(){
                var _this = this;
                var client_id = _this.$session.get('client_id');
                var filter = [{'s_parentheses': '(', 'value1': 'Form', 'actual_value1': 'Form', 'operator': '_cl_', 'column_actual_name': 'object_type_desc', 'hideKey': true, 'e_parentheses': ')', 'column_name': 'object_type_desc', 'isInlineFilter': true}]
                var search_data = {"client_id": client_id,
                "filter": filter}
                var get_all_forms_url = config.REPOSITORY_API_URL + "/fetch_all_objects"
                postToServer(_this, get_all_forms_url, search_data).then(response =>{
                    if (response){
                        var frm_data = response.result
                        frm_data.forEach(function(obj){
                            obj.form_name = obj.object_name
                        })
                        _this.formList=frm_data
                    }
                }).catch(error_response => {
                    _this.formList=[]
                });
            },
            getAllFields(){
                var object_id = this.form.object_id
                var data_to_send = {"object_id": object_id,
                "object_revision": "1"}
                var object_details_url = config.REPOSITORY_API_URL+"/fetch_object_details"
                this.loader=true
                postToServer(this, object_details_url, data_to_send).then(response =>{
                    this.loader=false
                    if(response){
                        var field_json = response.object_json.fieldList
                        var fields = []
                        field_json.forEach(function(obj){
                            if(obj.label != ''){
                                fields.push(obj)
                            }
                        })
                        fields.forEach(function(obj){
                            obj.field_name = obj.label
                            obj.isSelected=false
                            if("validationDetails" in obj){
                                if(("clientSide" in obj.validationDetails)&&(obj.validationDetails.clientSide.required)){
                                    obj.required=true
                                }
                                else{
                                    obj.required=false
                                }
                            }
                            else{
                                obj.required=false
                            }
                        })
                        this.fieldsList = fields.slice()
                        this.groupingFields(this.fieldsList)
                    }
                })
            },
            groupingFields(fieldList){
                this.final_fields_list = []
                var grouping_id = []
                var field_list = []
                fieldList.forEach(function(element){
                    if(element["id"].startsWith("edctab0") || element["id"].startsWith("edcgrp")){
                        grouping_id.push(element)
                    }
                    else if(element["id"].startsWith("edctablst")){
                        return;
                    }
                    else if(element["id"].startsWith("edcfield")){
                        field_list.push(element)
                    }
                })
                var general_fields = []
                for(var i=0;i<field_list.length;i++){
                    if(!("parentId" in field_list[i])){
                        general_fields.push(field_list[i])
                    }
                }
                if(general_fields.length>0){
                    var form_as_field_dict = {"fieldId": this.form.object_id, "field_name": this.form.object_name, "isSelected":false}
                    this.final_fields_list.push({"section_name":form_as_field_dict, "fields_under_section": general_fields})
                }
                for(var x=0; x<grouping_id.length; x++){
                    var fields_under_section = []
                    field_list.forEach(function(obj){
                        if(grouping_id[x]["id"] === obj["parentId"]){
                            fields_under_section.push(obj)
                        } 
                    })
                    if(fields_under_section.length>0){
                        this.final_fields_list.push({"section_name":grouping_id[x], "fields_under_section": fields_under_section})
                    }
                }
            },
            checkAllActions(event, fieldList){
    			let checkall = false
    			if(event)
    				checkall = true
    			fieldList.forEach(function(obj){
                    obj.section_name.isSelected = checkall
                    obj.fields_under_section.forEach(function(item){
                        item.isSelected=checkall
                    })
    			})
            },
            saveFormMapping(){
                var _this = this
                var data_for_mapping = {
                    "client_id": _this.$session.get('client_id'),
                    "user_group_id": this.user_group.id,
                    "user_group_name": this.user_group.group_name,
                    "form_id": this.form.object_id,
                    "form_name": this.form.object_name,
                    "fields":this.final_fields_list,
                    "group_details":[
                        {
                            "group_name": "Headquarter",
                            "group_id": "edcgrp0001",
                            "field_details":[
                                {
                                "field_id": "edcfield0005",
                                "field_name": "addressline1"
                                },{
                                "field_id": "edcfield0017",
                                "field_name": "addressline2"
                                },{
                                "field_id": "edcfield0018",
                                "field_name": "addressline3"
                                }
                            ]
                        }
                    ]
                }
            }
        }
    }
</script>
<style scoped>
.service-card{
		width: 95vw !important;
		max-height: 50vh !important;

		padding: 16px !important;
	}
.customCheckbox >>> label {
  		top: 5px !important;
  		font-size: 12px !important;
	}
.customRadiobtn >>> div{
    margin-right: 0px !important;
}
.customRadiobtn >>> label{
    margin-bottom: 0px !important;
    font-size: 10px !important;
}
.customCheckboxSectionName >>> label {
    top: 5px !important;
    font-size: 12px !important;
    font-weight: bold !important;
}
</style>