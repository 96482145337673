<template>
	<v-container class="EDC-Container">
		<v-row class="EDC-Row">
	      	<v-col cols="8" class="EDC-Col">
	        	<v-row class="EDC-Row breadcrumbRow">
	          		<v-col cols="4" class="EDC-Col">
	            		<ul class="breadcrumb breadcrumbUL">
	              			<router-link to="/rolelist">Security Role</router-link>
	            		</ul>
	          		</v-col>
	          		<v-col cols="8"></v-col>
	        	</v-row>
	    	</v-col>
			<v-col cols="4"></v-col>
    	</v-row>
    	<v-card elevation-1>
    		<v-col cols="12" class="EDC-Col">
    			<v-row no-gutters align="start" class="EDC-Row EDC-TopRow">
		            <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
		              <v-text-field label="Name of Role" v-model="role_name" class="EDC-UIComponent" hide-details autocomplete="off" dense></v-text-field>
		              <!-- <input v-model="role_name" type="text" autofocus :disabled="$route.params.type == 'edit'" @blur="untitle_environment"> -->
		            </v-col>
		            <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
		              <!-- <v-autocomplete :items="action_type" item-text="name" label="Role Type" class="EDC-UIComponent" @change="onRadioBtnChange"></v-autocomplete> -->
		              <v-radio-group v-model="radioGroup" :disabled="isDisabled" row @change="onRadioBtnChange" class="EDC-UIComponent" hide-details dense>
		                <v-radio class="radioClass" :color="colorCode" label="Administrator" value="administrator" dense/>
		                <v-radio class="radioClass" :color="colorCode" label="Queue Based" value="environmental" dense/>    
		              </v-radio-group>
		            </v-col>
		            <v-col cols="4" class="EDC-Col EDC-ColsSpacing EDC-ColDescription">
		              <v-textarea v-model="roleInput.role_description" label="Description" value rows="1" class="EDC-UIComponent" hide-details dense/>
		            </v-col>
          		</v-row>
          	</v-col>
        </v-card>
  		<v-divider class="EDC-UIComponent"/>
  		<v-row class="EDC-Row" :key="unqiueCard">
  			<v-tabs v-model="activetab">
  				<v-tab class="EDC-Tabs" v-for="(item,i) in expansionPanelServices" :key="i+'servicesheadertab'">
  					{{item.display_name}}
  				</v-tab>
  				<v-tab-item class="EDC-Tabs" v-for="(item,i) in expansionPanelServices" :key="i+'servicesheadertabitems'"> 
      				<v-card elevation-1 style="margin-top:8px !important;margin-bottom:8px!important;margin-right:4px !important;" class="service-card overflow-y-auto style-2scroll">
      					<v-row class="EDC-Row" v-for="(grp,g) in item.group_details" :key="i+'group'+g">
      						<v-col cols="12" align="left">
	          						<v-row class="EDC-Row">
	          							<v-col cols="12" align="left" class="EDC-Col">
	          								<v-checkbox dense class="customCheckbox" v-model="grp.allGroupActionsSelected"  style="margin:0px;padding:0px" hide-details color="primary-lighten2" :label="grp.group_name" :indeterminate="grp.indeterminate" @change="checkAllActions($event,grp)" :key="i+'group'+g+'checkbox'"> </v-checkbox>
	          							</v-col>
	          						</v-row>
	          						<v-row class="EDC-Row">
	          							<v-col cols="12" class="EDC-Col" style="padding-left:16px !important;padding-right:16px !important;">
		          							<template v-for="(row,r) in grp.action_details.length">
							        			<v-row class="EDC-Row" v-if="r%4===0" :key="r+'row'+i+'services'+g">
							        				<template v-for="(col,c) in [0,1,2,3]">
							        					<v-col class="EDC-Col" cols="3" align="left"  :key="r+'row'+i+'services'+g+'col'+c" v-if="grp.action_details[r+c]"> 
							        						<v-checkbox dense class="customCheckbox" v-model="grp.action_details[r+c].isSelected" style="margin:0px;padding:0px" hide-details color="primary-lighten2" :label="grp.action_details[r+c].description" @change="manageGroupCheckbox($event,grp,grp.action_details[r+c])" :key="'checkbox'+r+'row'+i+'services'+g+'col'+c"> </v-checkbox>
							        					</v-col>
							        				</template>
							        			</v-row>
							        		</template>
						        		</v-col>
	          						</v-row>
      						</v-col>
      					</v-row>
          			</v-card>
  				</v-tab-item>
  			</v-tabs>
  		</v-row>
  		<v-row class="EDC-Row">
  			<v-col class="EDC-Col" cols="12" align="end" style="padding-right:4px !important;">
  				<vc-button itemText="Save" @click.native="saveRole"></vc-button>
            	<vc-button itemText="Cancel" @click.native="cancel"></vc-button>
  			</v-col>
  		</v-row>
	</v-container>
</template>
<style scoped>
	.customCheckbox >>> label {
  		top: 5px !important;
  		font-size: 12px !important;
	}

	.EDC-TopRow {
	  	padding-top: 20px !important;
	  	padding-bottom: 10px !important;
	  	padding-left: 10px !important;
	  	padding-right: 10px !important;
	}

	.radioClass >>> label {
  		top: 3px !important;
	}
	.service-card{
		width: 95vw !important;
		max-height: 50vh !important;

		padding: 16px !important;
	}
	.v-expansion-panel-header{
		height: 24px !important;
		padding: 8px !important;
	}
	.v-expansion-panel--active > .v-expansion-panel-header{
		min-height: 32px !important;
	}

	.v-card__title{
		padding-left: 4px !important;
		padding-right: 4px !important;
		padding-bottom: 4px !important;
		padding-top: 0px !important;
	}

	.group-row{
		/*border: 1px solid black;*/
		/*padding-top: 4px !important;*/
		/*padding-bottom: 4px important;*/
	}
</style>
<script>
	import config from "../../config.json";
	import { post as postToServer } from "./../../methods/serverCall.js";
	export default {
		name:'manageRole',
		data(){
			return {
				activetab:0,
				radioGroup: "administrator",
				role_name:'',
				roleInput: {
        			role_description: "",
        			service_details: []
      			},
      			expansionPanelServices:[],
      			administrativeServices:[],
      			environmentalServices:[],
      			loading:false,
      			servicesToExclude:['environment_setting_service','datasource',"object_authorization_service","publisher_service","repository_service","account_management","user_provisioning"],
      			isDisabled:false,
      			colorCode:'',
      			unqiueCard:1
			}
		},
		computed:{

		},
		mounted(){
			if(this.$route.params && this.$route.params.role_id){
    			this.action_type = this.$route.params.role_type;
				if (this.$route.params.role_type.toLowerCase()=="administrator"){  
					  this.radioGroup = "administrator";
					  this.action_type = "administrator";
				}
				else if(this.$route.params.role_type.toLowerCase()=="queue based"){
					this.radioGroup = "environmental";
					this.action_type = "environmental";;
				}
      			this.isDisabled=true;
    		}
			this.getAllServices();
		},
		methods:{
			getAllServices(){
				var _this = this
				var request_data = {
        			client_id: this.$session.get("client_id"),
        			role_id: this.$route.params.role_id,
        			action_type: this.radioGroup,
        			'product_name': "TICKET_MANAGEMENT"
      			}
      			this.loading = true;
      			var url = config.Security_URL + '/fetch_group_service_action_details';
      			postToServer(_this,url,request_data).then(response=>{
      				if(response){
						  _this.roleInput.role_description = response.role_description
			          	if(_this.$route.params.role_id){
			            	_this.role_name = response.role_name
			            	? response.role_name
			            	: "Untitled Role Name";
			          	}
 						console.log('response',response)
 						_this.manageServiceList(response.service_details,response.mapped_actions)
      				}
      			}).catch(error=>{
      				console.log('error',error)
      			})
			},
			manageServiceList(listOfServices,mappedActionsList){
				var _this = this
				_this.administrativeServices = listOfServices
				_.forEach(listOfServices,(obj)=>{
					if(_this.servicesToExclude.indexOf(obj.service_id)>-1)
						return
					_.forEach(obj.group_details,(group)=>{
						_.forEach(group.action_details,(action)=>{
							if(action.action_type === 'administrator')
								_this.addActionToList(_this.administrativeServices,obj,group,action,mappedActionsList)
							else if(action.action_type === 'environmental')
								_this.addActionToList(_this.environmentalServices,obj,group,action,mappedActionsList)
						})
						_this.manageGroupCheckbox(false,group,{})
					})
				})
				_this.onRadioBtnChange()
			},
			addActionToList(listToAdd,serviceObj,groupObj,actionObj,mappedActionsList=[]){
				actionObj['isSelected'] = false
				let actionMapped = _.find(mappedActionsList,["action_id",actionObj.id])
				if(actionMapped)
					actionObj['isSelected'] = true
				let obj = _.find(listToAdd,['service_id',serviceObj.service_id])
				if(!obj){
					obj = {"service_id":serviceObj.service_id,'display_name':serviceObj.display_name,'product_name':serviceObj.product_name,'group_details':[{"group_name":groupObj.group_name,'action_details':[actionObj],'allGroupActionsSelected':false,'indeterminate':false}],'allServiceGroupsSelected':false}
					listToAdd.push(obj)
					return
				}
				let group = _.find(obj.group_details,['group_name',groupObj.group_name])
				if(!group){
					obj.group_details.push({"group_name":groupObj.group_name,'action_details':[actionObj],'allGroupActionsSelected':false})
					return
				}
				let action = _.find(group.action_details,['id',actionObj.id])
				if(!action){
					group.action_details.push(actionObj)
					return
				}
			},
			onRadioBtnChange(){
				let _this = this
				_this.unqiueCard++
				_this.expansionPanelServices = this.radioGroup === 'administrator'?_.cloneDeep(_this.administrativeServices):_.cloneDeep(_this.environmentalServices)
			},
			saveRole(){
				var _this = this
				let serviceList = _.cloneDeep(_this.expansionPanelServices)
				_.forEach(serviceList,(serviceObj)=>{
					_.forEach(serviceObj.group_details,(grpObj)=>{
						// reset map. in case of edit role, some values might be is_map true
						_.forEach(grpObj.action_details,(action)=>{
							action.is_map = false
						})

						let isSingleActionSelected = _.find(grpObj.action_details,['isSelected',true])

						if(!isSingleActionSelected)
							return
						_.forEach(grpObj.action_details,(action)=>{
							action.is_map=false
							if(action.isSelected || action.is_default)
								action.is_map=true
						})
					})
				})
				if(this.$route.params.role_id)
			        this.roleInput['role_id'] = this.$route.params.role_id
			    this.roleInput['service_details'] = serviceList
			    this.roleInput["client_id"] = this.$session.get("client_id");
			    this.roleInput["user_id"] = this.$session.get("user_id");
			    this.roleInput["created_by"] = this.$session.get("email");
			    this.roleInput["action_type"] = this.radioGroup;
			    this.roleInput["role_name"] = this.role_name;
			    this.roleInput["product_name"] = "TICKET_MANAGEMENT";

			    let url = config.Security_URL + '/manage_role_action';
			    postToServer(this, url, this.roleInput).then(response => {
			        // this.snackbar = true;
			        // this.colorValue = "success";
			        // this.snackbartext = "Role save Successfully";
			        this.$router.push("/rolelist")
			    }).catch(DatasourceError => {
			    	alert('error')
			        if (DatasourceError) {
			            // this.snackbar = true;
			            // this.colorValue = "error";
			            // this.snackbartext = DatasourceError;
			        } else {
			            // this.snackbar = true;
			            // this.colorValue = "error";
			            // this.snackbartext = SERVER_ERROR;
			        }
			    });
			},
			cancel () {
     			this.$router.push('/rolelist');
    		},
    		manageGroupCheckbox(event,groupObj,current_action){
    			var _this = this
    			let indeterminate = false
    			let checkAll = false  

    			// if all actions checked the checkAll true, if some checked the indeterminate true else checkall false
    			let checked = _.filter(groupObj.action_details,(obj)=>{
    				return obj.isSelected
    			})
    			if(checked.length === groupObj.action_details.length)
    				checkAll = true
    			else if(checked.length > 0)
    				indeterminate = true
    			if(!_.isEmpty(current_action) && indeterminate){
    				if(current_action.is_default){
    					setTimeout(()=>{
    						current_action.isSelected = true
    					},10)
    				}
    			}
    			groupObj.indeterminate = indeterminate
    			groupObj.allGroupActionsSelected = checkAll	
    		},
    		checkAllActions(event,group){
    			let checkall = false
    			if(event)
    				checkall = true
    			_.forEach(group.action_details,(obj)=>{
    				obj.isSelected = checkall
    			})
    		},

		}
	}
</script>