import {get as getToServer,post as postToServer } from './serverCall.js';
import config from '../config.json'
import {GET_ACTIONS_BY_GROUP_ID_LIST} from '../data/url_constants'
import {Snackbar_Default_Timeout, GRID_STATUS_COLOR_CODE, Ticket_management} from '@/constants/constants.js'

export function getActionByGroup(_this) {
    // store all actions in sesseion for first call then pass from session
    if(_this.$session.get('actions_by_group'))
	{
		_this.actions_by_group = _this.$session.get('actions_by_group')
		return
	}
    let input_details ={
        'group_id_list': _this.$session.get('mapped_user_groups'),
        "product_name":Ticket_management
    }
    var security_end_point = config.Security_URL + GET_ACTIONS_BY_GROUP_ID_LIST
	postToServer(_this, security_end_point, input_details).then(response => {
	if(response){
		_this.$session.set('actions_by_group',response)
		_this.actions_by_group = respons
        } else {
        	_this.errorMsg = response
        }
        // _this.focus_env()
    },response => {
        _this.ShowErrorMessage=true
        _this.ErrorMessage=response
    }).catch(EnvironmentError => {
    	if(EnvironmentError){
            // this[l]  = false
            _this.loader = null 
            _this.snackbar = true
            _this.colorValue = 'error'
            _this.snackbartext = EnvironmentError;
        }
        else {
        	_this.snackbar = true
        	_this.colorValue = 'error'
        	_this.snackbartext = SERVER_ERROR;
        }
    })
 }