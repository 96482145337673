<template>
    <div>
        <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow">
                    <v-col cols="4" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li>List Management</li>
                        </ul>
                    </v-col>
                    <v-col cols="8"></v-col>
                </v-row>
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>
        <v-row no-gutters align="center" class="EDC-Row">
             <v-tabs v-model="tabIndex" non-linear @change="tabChange">
                  <v-tab class="EDC-Tabs">Priority List</v-tab>
                  <v-tab class="EDC-Tabs">Ticket Action List</v-tab>
                  <v-tab-item>
                      <edc-data-grid key="grid-A" :dataList="tableList" @onNew="onNew" @onDelete="onDelete" @onSave="onSave" @onCancel="onCancel"></edc-data-grid>
                  </v-tab-item>
                  <v-tab-item>
                      <edc-data-grid key="grid-B" :dataList="actionTableList" @onNew="onNewAction" @onDelete="onDeleteAction" @onSave="onSaveAction" @onCancel="onCancel"></edc-data-grid>
                  </v-tab-item>
             </v-tabs>
        </v-row>
        <!-- <edc-data-grid :dataList="tableList" @onNew="onNew" @onDelete="onDelete" @onSave="onSave" @onCancel="onCancel"></edc-data-grid> -->

        <loading-panel :loader="loader"></loading-panel>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    </div>
</template>
<script>
import { get as getToServer, deleteFromServer, post as postToServer } from '@/methods/serverCall.js';
import { GET_ALL_EXECUTION_RULE, GET_ALL_PUBLISHESD_PROCESS_DEF_LIST,DELETE_EXECUTION_RULE_MAPPING,
     GET_ALL_EXECUTION_RULE_MAPPING, ADD_EXECUTION_RULE_MAPPING, UPDATE_EXECUTION_RULE_MAPPING} from '@/data/url_constants.js'
import {CLIENT_SIDE, SERVER_SIDE} from '@/data/macros.js'
import config from '../../config.json'
import environmentList from '../../methods/EnvironmentList.js'
import _ from 'lodash'
import {getUserRole, getTicketRole} from './../../methods/GetUserRole.js'
import {getActionByGroup} from './../../methods/TicketActionsAndQueueList.js'
import { UPDATE_ACTION, CREATE_ACTION, TICKET_MANAGEMENT_SERVICE, DELETE_ACTION, CREATE_TICKET_ACTION, UPDATE_TICKET_ACTION, DELETE_TICKET_ACTION, DELETE_PRIORITY, CREATE_PRIORITY, READ_PRIORITY, READ_TICKET_ACTION,
        UPDATE_PRIORITY } from '../../data/macros';
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import {
  SERVER_ERROR
} from '../../data/client_message.js'

export default {
    name:'ListManagement',
        
        data: function () {
        return {
               tableList: {
                headers: [
                    { text: 'Priority', value: 'name', option:[], title: 'Priority', useTextField:true,align:'left'},
                    { text: 'Sequence Number', value: 'sequence_number',  option:[], title: 'Sequence Number', useTextField:true, inputType:'number',isNumber:true, dataType:'int', align:'right'},
                    { text: 'Resolution Time (In Hours)', value: 'resolution_time',  option:[], title: 'Resolution Time', useTextField:true, inputType:'number',isNumber:true, dataType:'int', align:'right'},
                    { text: 'Created By', value: 'created_by', option:[], title: 'Created By', align:'left'},
                    { text: 'Created Date', value: 'tz_created_date', option:[], title: 'Created Date', align:'left'},
                    { text: 'Created Time', value: 'tz_created_time', option:[], title: 'Created Time', align:'left'},
                    { text: 'Modified By', value: 'modified_by',  option:[], title: 'Modified By', align:'left'},
                    { text: 'Modified Date', value: 'tz_modified_date', option:[], title: 'Modified Date', align:'left'},
                    { text: 'Modified Time', value: 'tz_modified_time', option:[], title: 'Modified Time', align:'left'}
                ], 
                actions:[],
                sorting_type: CLIENT_SIDE,
                filterType: CLIENT_SIDE,
                paginationType: CLIENT_SIDE,
                total_count: 0,
                rows: [],
                selected_rows: [],
                syncHeaderScroll:false,
                hideExport:true,
                hideShowColumns:true,
                hideFilter:true,
                showNew:true, 
                is_grid_inline_edit:true,
                itemkey:'id'
            },
            actionTableList: {
                headers: [
                    { text: 'Action', value: 'name', option:[], title: 'Priority', useTextField:true,align:'left'},
                    { text: 'Sequence Number', value: 'sequence_number',  option:[], title: 'Sequence Number', useTextField:true, inputType:'number',isNumber:true, dataType:'int', align:'right'},
                    // { text: 'Resolution Time (In Hours)', value: 'resolution_time',  option:[], title: 'Resolution Time', useTextField:true, inputType:'number',isNumber:true, dataType:'int'},
                    { text: 'Created By', value: 'created_by',  option:[], title: 'Created By', align:'left'},
                    { text: 'Created Date', value: 'tz_created_date',  option:[], title: 'Created Date', align:'left'},
                    { text: 'Created Time', value: 'tz_created_time',  option:[], title: 'Created Time', align:'left'},
                    { text: 'Modified By', value: 'modified_by', width:"20%", option:[], title: 'Modified By', align:'left'},
                    { text: 'Modified Date', value: 'tz_modified_date', option:[], title: 'Modified Date', align:'left'},
                    { text: 'Modified Time', value: 'tz_modified_time', option:[], title: 'Modified Time', align:'left'}
                ], 
                actions:[],
                sorting_type: CLIENT_SIDE,
                filterType: CLIENT_SIDE,
                paginationType: CLIENT_SIDE,
                total_count: 0,
                rows: [],
                selected_rows: [],
                syncHeaderScroll:false,
                hideExport:true,
                hideShowColumns:true,
                hideFilter:true,
                showNew:true, 
                is_grid_inline_edit:true,
                itemkey:'id'
            },
            snackbar:false,
            snackbartext:'',
            loader:false,           
            colorValue:'success',
            userRole:{},
            actions_by_group:[],
            tabIndex:0,
            snackbartimeout: Snackbar_Default_Timeout,
        }},
        mounted(){
            this.userRole = this.$session.get('user_role_mapping');
            getActionByGroup(this)
            this.tableList.is_grid_inline_edit = this.userRole.is_superadmin || getUserRole(this.userRole,TICKET_MANAGEMENT_SERVICE)
            this.tableList.actions = [{'text':'edit','key':"id", selectType:"single", role:this.userRole.is_superadmin || getTicketRole(_this.actions_by_group,TICKET_MANAGEMENT_SERVICE,UPDATE_TICKET_ACTION), index: 1},
                        {'text':'new','key':"id", 'enabled':this.Environment, role:this.userRole.is_superadmin || getTicketRole(_this.actions_by_group,TICKET_MANAGEMENT_SERVICE,CREATE_PRIORITY), index: 2},
                        {'text':"save","key":"is_row_editable",selectType:"single", index: 3},
                        {'text':"cancel","key":"is_row_editable",selectType:"single", index: 4},
                        {'text':'delete','key':"id", selectType:"single", role:this.userRole.is_superadmin || getTicketRole(_this.actions_by_group,TICKET_MANAGEMENT_SERVICE,DELETE_PRIORITY), index: 5}],//if we need conditional action in row then provide key

            this.actionTableList.actions = [{'text':'edit','key':"id", selectType:"single", role:this.userRole.is_superadmin || getTicketRole(_this.actions_by_group,TICKET_MANAGEMENT_SERVICE,UPDATE_TICKET_ACTION), index: 1},
                        {'text':'new','key':"id", 'enabled':this.Environment, role:this.userRole.is_superadmin || getTicketRole(_this.actions_by_group,TICKET_MANAGEMENT_SERVICE,CREATE_TICKET_ACTION), index: 2},
                        {'text':"save","key":"is_row_editable",selectType:"single", index: 3},
                        {'text':"cancel","key":"is_row_editable",selectType:"single", index: 4},
                        {'text':'delete','key':"id", selectType:"single", role:this.userRole.is_superadmin || getTicketRole(_this.actions_by_group,TICKET_MANAGEMENT_SERVICE,DELETE_TICKET_ACTION), index: 5}],//if we need conditional action in row then provide key
            this.getAllPriorities();
            if(!this.userRole.is_superadmin && !getUserRole(this.userRole,TICKET_MANAGEMENT_SERVICE,CREATE_ACTION))
            this.tableList.showNew = false
            },
        methods:{
            getAllPriorities(){
                var _this = this
                let url =  config.EDC_TICKET_MANAGEMENT_SERVICE_URL + '/get_ticket_priority';
                let inputJson={
                    'client_id':this.$session.get('client_id'),
                    'product_name': 'TICKET_MANAGEMENT'
                }
                _this.loader = true
                postToServer(this, url, inputJson).then(response  => {
                        _this.loader = false
                        this.tableList.rows = response;
                    }).catch(error => {
                        _this.tableList.rows =[];
                        _this.loader = false
                    if(error){
                        // this.snackbar = true;
                        // this.colorValue = 'error';
                        // this.snackbartext = error;
                        }
                        else {
                        }
                });
            },
            onNew(){
                    let obj = _.find(this.tableList.rows,["is_row_editable",true])
                    if(obj)
                    {
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = 'First fillup the open row';
                        return
                    }

                    this.tableList.rows.unshift({
                        'name':'',
                        'sequence_number':'',
                        'resolution_time':'',
                        'is_row_editable' : true,
                    });
            },
            onNewAction(){
                    let obj = _.find(this.actionTableList.rows,["is_row_editable",true])
                    if(obj)
                    {
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = 'First fillup the open row';
                        return
                    }

                    this.actionTableList.rows.unshift({
                        'name':'',
                        'sequence_number':'',
                        'is_row_editable' : true,
                    });
            },
            onDelete(record){
                var _this = this  
                let url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL+ '/delete_priority_or_action';
                let inputJson = {"id": record.id, 'client_id': this.$session.get('client_id'),
                "name": record.name,
                "type":"Priority",
                "sequence_number": record.sequence_number,
                "master_id": record.master_id,
                'product_name': 'TICKET_MANAGEMENT'}
                _this.loader = true
                postToServer(this, url, inputJson).then(response  => {
                        this.snackbar = true
                        this.colorValue = 'success'
                        this.snackbartext = response;
                        _this.loader = false
                        this.getAllPriorities();
                        // this.getRuleList();
                    }).catch(error => {
                        _this.loader = false
                        this.getAllPriorities();
                    if(error){
                        this.snackbar = true;
                        this.colorValue = 'error';
                        this.snackbartext = error;
                        }
                        else {
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = SERVER_ERROR;
                        }
                });  
            },
            onDeleteAction(record){
                var _this = this  
                let url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL+ '/delete_priority_or_action';
                let inputJson = {"id": record.id, 'client_id': this.$session.get('client_id'),
                "name": record.name,
                "type":"Action",
                "sequence_number": record.sequence_number,
                "master_id": record.master_id,
                'product_name': 'TICKET_MANAGEMENT'}
                _this.loader = true
                postToServer(this, url, inputJson).then(response  => {
                        this.snackbar = true
                        this.colorValue = 'success'
                        this.snackbartext = response;
                        _this.loader = false
                        this.getAllActions();
                        // this.getRuleList();
                    }).catch(error => {
                        _this.loader = false
                        this.getAllActions();
                    if(error){
                        this.snackbar = true;
                        this.colorValue = 'error';
                        this.snackbartext = error;
                        }
                        else {
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = SERVER_ERROR;
                        }
                });  
            },
            onSave(record){
                var _this= this
                if(!record.name || !record.sequence_number || !record.resolution_time){
                    this.snackbar = true;
                    this.colorValue = 'error';
                    this.snackbartext = "Fill all fields first.";
                    return
                }
                let url =  config.EDC_TICKET_MANAGEMENT_SERVICE_URL + '/add_priority_or_action';
                let inputJson={
                    'client_id':this.$session.get('client_id'),
                    "name": record.name,
                    "sequence_number": record.sequence_number,
                    "resolution_time": record.resolution_time,
                    "type":"Priority",
                    // 'product_name': 'TICKET_MANAGEMENT'
                    }
                if(record.id){
                    inputJson["id"]= record.id
                    inputJson["master_id"]= record.master_id
                    url =  config.EDC_TICKET_MANAGEMENT_SERVICE_URL + '/update_priority_or_action';
                }
                _this.loader = true
                postToServer(this, url, inputJson).then(response  => {
                        _this.snackbar = true
                        _this.colorValue = 'success'
                        _this.snackbartext = response;
                        _this.loader = false
                        _this.getAllPriorities();
                    }).catch(error => {
                        // this.getRuleList();
                        _this.loader = false
                    if(error){
                        this.snackbar = true;
                        this.colorValue = 'error';
                        this.snackbartext = error;
                        }
                        else {
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = SERVER_ERROR;
                        }
                });
            },
            onSaveAction(record){
                var _this= this
                if(!record.name || !record.sequence_number){
                    this.snackbar = true;
                    this.colorValue = 'error';
                    this.snackbartext = "Fill all fields first.";
                    return
                }
                let url =  config.EDC_TICKET_MANAGEMENT_SERVICE_URL + '/add_priority_or_action';
                let inputJson={
                    'client_id':this.$session.get('client_id'),
                    "name": record.name,
                    "type":"Action",
                    "sequence_number": record.sequence_number,
                    // "resolution_time": record.resolution_time,
                    // 'product_name': 'TICKET_MANAGEMENT'
                    }
                if(record.id){
                    inputJson["id"]= record.id
                    inputJson["master_id"]= record.master_id
                    url =  config.EDC_TICKET_MANAGEMENT_SERVICE_URL + '/update_priority_or_action';
                }
                _this.loader = true
                postToServer(this, url, inputJson).then(response  => {
                        _this.snackbar = true
                        _this.colorValue = 'success'
                        _this.snackbartext = response;
                        _this.loader = false
                        _this.getAllActions();
                    }).catch(error => {
                        // this.getRuleList();
                        _this.loader = false
                        _this.getAllActions();
                    if(error){
                        this.snackbar = true;
                        this.colorValue = 'error';
                        this.snackbartext = error;
                        }
                        else {
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = SERVER_ERROR;
                        }
                });
            },
            onCancel(){
                if(this.tabIndex === 1)
                   this.getAllActions();
                else
                    this.getAllPriorities()
            },
            tabChange(){
                if(this.tabIndex === 1)
                   this.getAllActions();
            },
            getAllActions(){
                var _this = this
                let url =  config.EDC_TICKET_MANAGEMENT_SERVICE_URL + '/get_ticket_action';
                let inputJson={
                    'client_id':this.$session.get('client_id'),
                    'product_name': 'TICKET_MANAGEMENT'
                }
                _this.loader = true
                postToServer(this, url, inputJson).then(response  => {
                        _this.loader = false
                        this.actionTableList.rows = response;
                    }).catch(error => {
                        _this.actionTableList.rows =[];
                        _this.loader = false
                    if(error){
                        // this.snackbar = true;
                        // this.colorValue = 'error';
                        // this.snackbartext = error;
                        }
                        else {
                        }
                });
            },
        }   
}
</script>
