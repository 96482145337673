/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Document': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M16 8c-2.216 0-4 1.784-4 4v40c0 2.216 1.784 4 4 4h32c2.216 0 4-1.784 4-4V28L32 8z"/><path pid="1" _fill="#fff" d="M20 32h24v4H20zM52 28L32 8v16c0 2.216 1.784 4 4 4z"/><path pid="2" d="M42 24l-6-6v4.8c0 .665.535 1.2 1.2 1.2z"/><path pid="3" _fill="#fff" d="M20 44h24v4H20z"/>'
  }
})
