import Vue from 'vue'
import Router from 'vue-router'

import PageNotFound from '../views/pageNotFound.vue'
import Login from '../components/authorization/login.vue'
import ChangePassword from '../components/authorization/change_password.vue'
import UserProfile from '../components/authorization/userProfile.vue'

import ResetUserPassword from '../components/authorization/reset_user_password.vue'


import userlist from '../components/configuration/userListNew.vue'
import UserRoleSet from '../components/configuration/userroleset.vue'
import UserGroupRoleSet from '../components/configuration/usergrouproleset.vue'
import RoleList from '../components/configuration/RoleList.vue'
import ManageRole from '../components/configuration/revisedCreateSecurityRole.vue'
//ManageRole
import AddUser from '../components/configuration/manage_user.vue'
import UpdateUser from '../components/configuration/manage_user.vue'

import AgentList from '../components/agentmanagement/agentList.vue'
import AgentQueueList from '../components/agentmanagement/agent_queue_list.vue'
import FileExport from '../components/customs/fileExports.vue'

import CreateTicket from '../components/ticketmanagement/createTicket.vue'
import TicketStatus from '../components/ticketmanagement/ticketStatus.vue'
import ConfigureTicketManagement from '../components/ticketmanagement/configurationTicketManagement.vue'
// import TicketDetails from '../components/ticketmanagement/ticketDetails.vue'
import TicketDetailsNew from '../components/ticketmanagement/ticketForDataviewer.vue'
import TicketDetails from '../components/ticketmanagement/ticketWorkflowStatus.vue'
// import TicketDetails from '../components/ticketmanagement/ticketDetailsNew.vue'
import ticketIDGeneration from '../components/ticketmanagement/ticketIDGeneration.vue'
import TicketTokenVerification from '../components/ticketmanagement/ticketTokenVerification.vue'
import TicketDetailsForDv from '../components/ticketmanagement/ticketDetailsForDV.vue'

import ManageNotificationGroup from '../components/notification_group/manage_notification_group.vue'
import NotificationGroupList from '../components/notification_group/notification_group_list.vue'
import NotificationGroupRule from '../components/notification_group/notification_group_rule.vue'
import NotificationGroupUserMapping from '../components/notification_group/notification_group_mapping.vue'
import NotificationGroupUserGroupMapping from '../components/notification_group/notificationgroup_usergroup_mapping.vue'

import tagManagement from '../components/tag_management/tagManagement.vue'

import ticketQueueList from '../components/queueManagement/ticketQueueList.vue'
// import ticketQueueList from '../components/queueManagement/manageTicketQueueNew.vue'
import ticketQueueManagement from '../components/queueManagement/manageTicketQueue.vue'

import userGroupList from '../components/user_group/userGroupList.vue'
import userGroupManagement from '../components/user_group/userGroupManagement.vue'
import userGroupFormMapping from '../components/user_group/userGroupFormMapping.vue'
import userGroupFormMappingNew from '../components/user_group/userGroupFormMappingNew.vue'

import dashboard from '../components/dashboard/dashboard.vue'

//Priority Management
import ListManagement from '../components/priorityManagement/priorityManagement.vue'

//Supplier
import AddSupplier from '../components/supplier/addSupplier.vue'

import WorkflowRouteList from '../components/workflow/WorkflowRouteList.vue'
import WorkflowMapping from '../components/workflow/workflowMapping.vue'
import WorkspaceMapping from '../components/workflow/workspaceMapping.vue'
// import edcForm from '../components/edcForm/edcForm.vue'
import edcForm from '../components/edcForm/edcTabForm.vue'
import approvalForm from '../components/edcForm/approvalForm.vue'

// workfspace
import ResourceWorkpsaceManagement from '../components/workflow/resourceWorkspaceManagement.vue'
import ManageWorkSpace from '../components/workflow/manageWorkSpace.vue'
import WorkSpacesList from '../components/workflow/workSpacesList.vue'

import DataviewerLander from '../components/ticketmanagement/dataviewer_landing.vue'

import searchPage from '../components/ticketmanagement/searchPage.vue'
Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: '/Login'
        },
        {
            path: "*",
            component: PageNotFound,
            meta:   {
                breadcrumb: 'Home / pageNotFound'
            }
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
            meta: {
                breadcrumb: 'Home / Login'
            }
        },
        {
            path: '/userprofile',
            name: 'userprofile',
            component: UserProfile,
            meta: {
                breadcrumb: 'Home / UserProfile'
            }
        },
        {
            path: '/changepassword',
            name: 'ChangePassword',
            component: ChangePassword,
            meta: {
                breadcrumb: 'Home / Change Password'
            }
        },
        {
            path: '/userlist',
            name: 'userlist',
            component: userlist,
            meta: {
                breadcrumb: 'Home / Users List'
            }
        },
        {
            path: '/userroleset',
            name: 'UserRoleSet',
            component: UserRoleSet,
            meta: {
                breadcrumb: 'Home / userroleset'
            }
        },
        {
            path: '/usergrouproleset',
            name: 'UserGroupRoleSet',
            component: UserGroupRoleSet,
            meta: {
                breadcrumb: 'Home / usergrouproleset'
            }
        },
        {
            path: '/ManageRole',
            name: 'ManageRole',
            component: ManageRole,
            meta: {
                breadcrumb: 'Home / ManageRole'
            }
        },
        {
            path: '/rolelist',
            name: 'rolelist',
            component: RoleList,
            meta: {
                breadcrumb: 'Home / RoleList'
            }
        },
        {
            path: '/adduser',
            name: 'AddUser',
            component: AddUser,
            meta: {
                breadcrumb: 'Home / AddUser'
            }
        },
        {
            path: '/update_user',
            name: 'update_user',
            component: UpdateUser,
            meta: {
                breadcrumb: 'Home / update_user'
            }
        },
        {
            path: '/ticketidgeneration',
            name: 'ticketidgeneration',
            component: ticketIDGeneration,
            meta: {
                breadcrumb: 'Home / ticketIDGeneration'
            }
        },
		{
            path: '/agent_list',
            name: 'AgentList',
            component: AgentList,
            meta: {
                breadcrumb: 'Home / AgentList'
            }
        },
        {            
            path: '/agent_queue_list',
            name: 'AgentQueueList',
            component: AgentQueueList,
            meta: {
                breadcrumb: 'Home / AgentQueueList'
            }
        },
        {
            path: '/resetuserpassword',
            name: 'ResetUserPassword',
            component: ResetUserPassword,
            meta: {
                breadcrumb: 'Home / Reset User Password'
            }
        },
        {            
            path: '/fileexport',
            name: 'FileExport',
            component: FileExport,
            meta: {
                breadcrumb: 'Home / FileExport'
            }
        },
        {            
            path: '/createticket',
            name: 'CreateTicket',
            component: CreateTicket,
            meta: {
                breadcrumb: 'Home / CreateTicket'
            }
        },
        {            
            path: '/ticketstatus',
            name: 'TicketStatus',
            component: TicketStatus,
            meta: {
                breadcrumb: 'Home / TicketStatus',
                showSearchBar: true
            }
        },
        {            
            path: '/ticketdetails',
            name: 'TicketDetails',
            component: TicketDetails,
            meta: {
                breadcrumb: 'Home / TicketDetails'
            }
        },
        {            
            path: '/generalticketdetails',
            name: 'TicketDetailsForDv',
            component: TicketDetailsForDv,
            meta: {
                breadcrumb: 'Home / TicketDetailsForDv',
                showSearchBar: true
            }
        },
        {            
            path: '/searchpage',
            name: 'searchPage',
            component: searchPage,
            meta: {
                breadcrumb: 'Home / searchPage',
                showSearchBar: true
            }
        },
        {            
            path: '/tickettokenverification',
            name: 'TicketTokenVerification',
            component: TicketTokenVerification,
            meta: {
                breadcrumb: 'Home / TicketTokenVerification'
            }
        },
        {
            path: '/manage_notification_group',
            name: 'ManageNotificationGroup',
            component: ManageNotificationGroup,
            meta: {
                breadcrumb: 'Home / manage notification group'
            }
        },
        {
            path: '/get_all_notification_group',
            name: 'NotificationGroupList',
            component: NotificationGroupList,
            meta: {
                breadcrumb: 'Home / manage notification group'
            }
        },
        {
            path: '/get_all_notification_group_rule',
            name: 'NotificationGroupRule',
            component: NotificationGroupRule,
            meta: {
                breadcrumb: 'Home / manage notification group'
            }
        },
        {
            path: '/get_all_notification_group_mapping',
            name: 'NotificationGroupUserMapping',
            component: NotificationGroupUserMapping,
            meta: {
                breadcrumb: 'Home / manage notification group'
            }
        },
        {
            path: '/get_all_notificationgroup_usergroup_mapping',
            name: 'NotificationGroupUserGroupMapping',
            component: NotificationGroupUserGroupMapping,
            meta: {
                breadcrumb: 'Home / manage notification group'
            }
        },
        {            
            path: '/configureticketmanagement',
            name: 'ConfigureTicketManagement',
            component: ConfigureTicketManagement,
            meta: {
                breadcrumb: 'Home / ConfigureTicketManagement'
            }
        },
        {            
            path: '/tagmanagement',
            name: 'tagManagement',
            component: tagManagement,
            meta: {
                breadcrumb: 'Home / tagManagement'
            }
        },
        {            
            path: '/ticketqueuelist',
            name: 'ticketQueueList',
            component: ticketQueueList,
            meta: {
                breadcrumb: 'Home / ticketQueueList'
            }
        },
        {            
            path: '/ticketqueuemanagement',
            name: 'ticketQueueManagement',
            component: ticketQueueManagement,
            meta: {
                breadcrumb: 'Home / ticketQueueManagement'
            }
        },
        {            
            path: '/usergrouplist',
            name: 'userGroupList',
            component: userGroupList,
            meta: {
                breadcrumb: 'Home / userGroupList'
            }
        },
        {            
            path: '/usergroupmanagement',
            name: 'userGroupManagement',
            component: userGroupManagement,
            meta: {
                breadcrumb: 'Home / userGroupManagement'
            }
        },
        {            
            path: '/usergroupfrommapping',
            name: 'userGroupFormMapping',
            component: userGroupFormMapping,
            meta: {
                breadcrumb: 'Home / userGroupFormMapping'
            }
        },
        {            
            path: '/usergroupformmappingnew',
            name: 'userGroupFormMappingNew',
            component: userGroupFormMappingNew,
            meta: {
                breadcrumb: 'Home / userGroupFormMappingNew'
            }
        },
        {
            path: '/dashboard',
            name: 'Dashboard',
            component: dashboard,
            meta: {
                breadcrumb: 'Home / Dashboard'
            }
        },
        {            
            path: '/listmanagement',
            name: 'ListManagement',
            component: ListManagement,
            meta: {
                breadcrumb: 'Home / ListManagement'
            }
        },
        {            
            path: '/addsupplier',
            name: 'AddSupplier',
            component: AddSupplier,
            meta: {
                breadcrumb: 'Home / AddSupplier'
            }
        },
        {
            path: '/workflowRouteList',
            name: 'WorkflowRouteList',
            component: WorkflowRouteList,
            meta: {
                breadcrumb: 'Home / WorkflowRouteList'
            }
        },
        {
            path: '/workflowmapping',
            name: 'WorkflowMapping',
            component: WorkflowMapping,
            meta: {
                breadcrumb: 'Home / WorkflowMapping'
            }
        },
        {
            path: '/workspacemapping',
            name: 'WorkspaceMapping',
            component: WorkspaceMapping,
            meta: {
                breadcrumb: 'Home / WorkspaceMapping'
            }
        },
        {
            path: '/edcform',
            name: 'edcForm',
            component: edcForm,
            meta: {
                breadcrumb: 'Home / edcForm'
            }
        },
        {
            path: '/approvalform',
            name: 'approvalForm',
            component: approvalForm,
            meta: {
                breadcrumb: 'Home / approvalForm'
            }
        },
        {
            path: '/ResourceWorkpsaceManagement',
            name: 'ResourceWorkpsaceManagement',
            component: ResourceWorkpsaceManagement,
            meta: {
                breadcrumb: 'Home / Resource Workpsace Management'
            }
        },
        {
            path: '/ManageWorkSpace',
            name: 'ManageWorkSpace',
            component: ManageWorkSpace,
            meta: {
                breadcrumb: 'Home / Manage WorkSpace'
            }
        },
        {
            path: '/WorkSpacesList',
            name: 'WorkSpacesList',
            component: WorkSpacesList,
            meta: {
                breadcrumb: 'Home / WorkSpaces List'
            }
        },
        {
            path: '/dataviewer_landing',
            name: 'dataviewer_landing',
            component: DataviewerLander,
            meta: {
                breadcrumb: 'Home / Dataviewer Landing'
            }
        },
        {            
            path: '/ticketdetailsnew',
            name: 'TicketDetailsNew',
            component: TicketDetailsNew,
            meta: {
                breadcrumb: 'Home / TicketDetailsNew'
            }
        },
    ]
})
