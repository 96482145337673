var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:{EDCHeaderContainer:!_vm.isGrayTheme,EDCHeaderContainerGray:_vm.isGrayTheme}},[_c('v-row',{staticClass:"EDC-Row topLines",attrs:{"justify":"start","align":"center","dense":"","no-gutters":""}},[(!_vm.isGrayTheme)?_c('v-col',{staticClass:"EDC-PageHeader primary--text",staticStyle:{"text-align":"left !important"},attrs:{"cols":_vm.$route.meta.showSearchBar?2:3},on:{"click":function($event){return _vm.routeUrl('/dashboard')}}},[_vm._v(" Task Former ")]):_c('v-col',{staticClass:"EDC-PageHeader graythmprimary--text",staticStyle:{"text-align":"left !important"},attrs:{"cols":_vm.$route.meta.showSearchBar?2:3},on:{"click":function($event){return _vm.routeUrl('/dashboard')}}},[_vm._v(" Task Former ")]),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.meta.showSearchBar),expression:"$route.meta.showSearchBar"}]},[_c('ticket-details-header',{key:"onHeader",attrs:{"isFromHeader":true}})],1),_c('v-col',{staticStyle:{"max-width":"65% !important"},attrs:{"cols":_vm.$route.meta.showSearchBar?6:8}},[(!_vm.hideHeader)?_c('v-row',{staticClass:"EDC-Row",attrs:{"dense":"","no-gutters":"","justify":"start"}},[_c('v-col',{class:{normal:_vm.isSelected !== 'Home',focus:_vm.isSelected == 'Home'},staticStyle:{"max-width":"20% !important"},attrs:{"cols":"3"},on:{"click":function($event){return _vm.routeUrl('/dashboard')}}},[_c('v-row',{staticClass:"EDC-Row",attrs:{"dense":"","no-gutters":"","justify":"start"}},[_c('v-menu',{staticClass:"menuCss",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{normal:_vm.isSelected !== 'Home',focus:_vm.isSelected == 'Home','primary--text':_vm.isSelected==='Home' && !_vm.isGrayTheme,'secondary--text':_vm.isSelected!='Home'  && !_vm.isGrayTheme,'graythmprimary--text':_vm.isSelected==='Home' && _vm.isGrayTheme,'graythmsecondary--text':_vm.isSelected!='Home' && _vm.isGrayTheme },attrs:{"slot":"activator","text":"","small":""},on:{"click":function($event){_vm.isSelected='Home',_vm.routeUrl('/dashboard')}},slot:"activator"},on),[_c('svgicon',{staticStyle:{"margin-bottom":"5%"},attrs:{"name":"Menu_Home","original":true}}),_c('span',[_vm._v(" Home")])],1)]}}],null,false,744125431)})],1)],1),_c('v-col',{class:{normal:_vm.isSelected !== 'NewTicket',focus:_vm.isSelected == 'NewTicket'},staticStyle:{"max-width":"25% !important"},attrs:{"cols":"3"},on:{"click":function($event){return _vm.routeUrl('/ticketdetails')}}},[_c('v-row',{staticClass:"EDC-Row",attrs:{"dense":"","no-gutters":"","justify":"start"}},[_c('v-menu',{staticClass:"menuCss",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{normal:_vm.isSelected !== 'NewTicket',focus:_vm.isSelected == 'NewTicket','primary--text':_vm.isSelected==='NewTicket' && !_vm.isGrayTheme,'secondary--text':_vm.isSelected!='NewTicket'  && !_vm.isGrayTheme,'graythmprimary--text':_vm.isSelected==='NewTicket' && _vm.isGrayTheme,'graythmsecondary--text':_vm.isSelected!='NewTicket' && _vm.isGrayTheme },attrs:{"slot":"activator","text":"","small":""},on:{"click":function($event){_vm.isSelected='NewTicket'}},slot:"activator"},on),[_c('svgicon',{staticStyle:{"margin-bottom":"5%","margin-top":"5%"},attrs:{"name":"Menu_Create_Ticket","original":true}}),_c('span',[_vm._v(" New Ticket")])],1)]}}],null,false,1239431629)},[_c('v-list',{staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},_vm._l((_vm.topNavigation.NewTicketOption),function(tile,index2){return (tile.role)?_c('v-list-item',{key:index2,attrs:{"id":"menulistitemhover"},on:{"click":function($event){return _vm.routeUrl(tile.url,tile.moreInfo)}}},[_c('v-list-tile-title',{attrs:{"id":tile.name},on:{"click":function($event){return _vm.routeUrl(tile.url,tile.moreInfo);}}},[_vm._v(_vm._s(tile.name))])],1):_vm._e()}),1)],1)],1)],1),_c('v-col',{class:{normal:_vm.isSelected !== 'Archive',focus:_vm.isSelected == 'Archive'},staticStyle:{"max-width":"35% !important"},attrs:{"cols":"3"},on:{"click":function($event){return _vm.routeUrl('/ticketstatus')}}},[_c('v-row',{staticClass:"EDC-Row",attrs:{"dense":"","no-gutters":"","justify":"start"}},[_c('v-menu',{staticClass:"menuCss",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{normal:_vm.isSelected !== 'Archive',focus:_vm.isSelected == 'Archive','primary--text':_vm.isSelected==='Archive' && !_vm.isGrayTheme,'secondary--text':_vm.isSelected!='Archive'  && !_vm.isGrayTheme,'graythmprimary--text':_vm.isSelected==='Archive' && _vm.isGrayTheme,'graythmsecondary--text':_vm.isSelected!='Archive' && _vm.isGrayTheme},attrs:{"slot":"activator","text":"","small":""},on:{"click":function($event){_vm.isSelected='Archive',_vm.routeUrl('/ticketstatus')}},slot:"activator"},on),[_c('svgicon',{staticStyle:{"margin-bottom":"3%"},attrs:{"name":"Menu_Manage_Tickets","original":true}}),_c('span',[_vm._v(" Ticket Status")])],1)]}}],null,false,2665713649)})],1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$route.meta.showSearchBar),expression:"!$route.meta.showSearchBar"}],class:{normal:_vm.isSelected !== 'Workflow',focus:_vm.isSelected == 'Workflow'},attrs:{"cols":"3"},on:{"click":function($event){return _vm.routeUrl('/workflowRouteList')}}},[_c('v-menu',{staticClass:"menuCss",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{normal:_vm.isSelected !== 'Workflow',focus:_vm.isSelected == 'Workflow','primary--text':_vm.isSelected==='Workflow' && !_vm.isGrayTheme,'secondary--text':_vm.isSelected!='Archive'  && !_vm.isGrayTheme,'graythmprimary--text':_vm.isSelected==='Workflow' && _vm.isGrayTheme,'graythmsecondary--text':_vm.isSelected!='Workflow' && _vm.isGrayTheme},attrs:{"slot":"activator","text":"","small":""},on:{"click":function($event){_vm.isSelected='Workflow',_vm.routeUrl('/workflowRouteList')}},slot:"activator"},on),[_c('svgicon',{staticStyle:{"margin-bottom":"3%"},attrs:{"name":"Menu_Manage_Tickets","original":true}}),_c('span',[_vm._v("Workflow")])],1)]}}],null,false,3214534538)})],1)],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-row',{staticClass:"EDC-Row",attrs:{"justify":"start","dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"5","align":"left"}},[_c('v-row',{staticClass:"EDC-Row"},[_c('v-menu',{staticClass:"menuCss",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{normal:_vm.isSelected !== 'Configure',focus:_vm.isSelected == 'Configure','primary--text':_vm.isSelected==='Configure' && !_vm.isGrayTheme,'secondary--text':_vm.isSelected!='Configure'  && !_vm.isGrayTheme,'graythmprimary--text':_vm.isSelected==='Configure' && _vm.isGrayTheme,'graythmsecondary--text':_vm.isSelected!='Configure' && _vm.isGrayTheme},attrs:{"slot":"activator","text":"","x-small":"","id":"smallbtn"},on:{"click":function($event){_vm.isSelected='Configure'}},slot:"activator"},on),[_c('svgicon',{attrs:{"name":"Menu_Configure","original":true,"title":"Configure"}})],1)]}}])},[_c('v-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.showConfigure),expression:"showConfigure"}],staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},[_vm._l((_vm.topNavigation.configure),function(tile,index){return (tile.role)?_c('v-list-item',{key:index,attrs:{"id":"menulistitemhover"},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_c('v-list-tile-title',{attrs:{"id":tile.name},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_vm._v(_vm._s(tile.name))])],1):_vm._e()}),_c('v-menu',{directives:[{name:"show",rawName:"v-show",value:(_vm.showConfigure),expression:"showConfigure"}],staticClass:"menuCss",staticStyle:{"width":"100%"},attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-tile-title',[_vm._v("Notification Group")]),_c('v-list-tile-action',{staticClass:"justify-end"},[_c('v-icon',[_vm._v("play_arrow")])],1)],1)]}}])},[_c('v-list',{staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},_vm._l((_vm.topNavigation.notificationGroup),function(tile,index2){return (tile.role)?_c('v-list-item',{key:index2,attrs:{"id":"menulistitemhover"},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_c('v-list-tile-title',{attrs:{"id":tile.name},on:{"click":function($event){return _vm.routeUrl(tile.url);}}},[_vm._v(_vm._s(tile.name))])],1):_vm._e()}),1)],1),_c('v-menu',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRuleMenu),expression:"showRuleMenu"}],staticClass:"menuCss",staticStyle:{"width":"100%"},attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-tile-title',[_vm._v("Rules")]),_c('v-list-tile-action',{staticClass:"justify-end"},[_c('v-icon',[_vm._v("play_arrow")])],1)],1)]}}])},[_c('v-list',{staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},_vm._l((_vm.topNavigation.rule),function(tile,index2){return (tile.role)?_c('v-list-item',{key:index2,attrs:{"id":"menulistitemhover"},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_c('v-list-tile-title',{attrs:{"id":tile.name},on:{"click":function($event){return _vm.routeUrl(tile.url);}}},[_vm._v(_vm._s(tile.name))])],1):_vm._e()}),1)],1),_c('v-menu',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRuleMenu),expression:"showRuleMenu"}],staticClass:"menuCss",staticStyle:{"width":"100%"},attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-tile-title',[_vm._v("Agent")]),_c('v-list-tile-action',{staticClass:"justify-end"},[_c('v-icon',[_vm._v("play_arrow")])],1)],1)]}}])},[_c('v-list',{staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},_vm._l((_vm.topNavigation.agent),function(tile,index3){return (tile.role)?_c('v-list-item',{key:index3,attrs:{"id":"menulistitemhover"},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_c('v-list-tile-title',{attrs:{"id":tile.name},on:{"click":function($event){return _vm.routeUrl(tile.url);}}},[_vm._v(_vm._s(tile.name))])],1):_vm._e()}),1)],1)],2)],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{staticClass:"EDC-Row"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","text":"","x-small":"","id":"smallbtn","title":_vm.getUserTitle()}},on),[_c('span',{class:{'graythmprimary--text':_vm.isGrayTheme,'primary--text':!_vm.isGrayTheme},attrs:{"id":"profile"}},[_vm._v(_vm._s(_vm.initials))])])]}}])},[_c('v-list',{staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},[(!_vm.hideHeader)?_c('v-list-item',[_c('v-list-tile-title',{attrs:{"id":"Edit Profile"},on:{"click":function($event){return _vm.routeUrl('/userprofile')}}},[_vm._v("Edit Profile")])],1):_vm._e(),(!_vm.hideHeader)?_c('v-list-item',[_c('v-list-tile-title',{attrs:{"id":"Change Password"},on:{"click":function($event){return _vm.routeUrl('/changepassword')}}},[_vm._v("Change Password")])],1):_vm._e(),_c('v-list-item',[_c('v-list-tile-title',{attrs:{"id":"Sign out"},on:{"click":function($event){return _vm.logout()}}},[_vm._v("Sign out")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',{staticClass:"EDC-Row"},[(!_vm.hideHeader)?_c('v-menu',{attrs:{"offset-x":"","z-index":"1000","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"border":"0px !important"},attrs:{"fab":"","text":"","x-small":"","id":"smallbtn"}},on),[_c('i',{staticClass:"material-icons",class:{'graythmprimary--text':_vm.isGrayTheme,'primary--text':!_vm.isGrayTheme},attrs:{"title":"Applications","id":"smallicon"}},[_vm._v("apps")])])]}}],null,false,4279851092)},[(_vm.applicationList.length)?_c('v-list',{staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},[_vm._l((_vm.applicationList),function(item,idx){return _c('v-list-item',{key:item.product_name},[_c('v-list-tile-title',{attrs:{"id":item.product_name},on:{"click":function($event){return _vm.redirectProduct(item)}}},[_vm._v(_vm._s(item.product_name))])],1)}),_c('v-list-item',[_c('v-menu',{staticClass:"menuCss",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('svgicon',_vm._g({staticStyle:{"margin-bottom":"3%"},attrs:{"name":"User_Admin","original":true}},on)),_c('v-list-tile-title',[_vm._v("Admin")])]}}],null,false,1597630322)},[_c('v-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAdmin),expression:"showAdmin"}],staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},_vm._l((_vm.topNavigation.admin),function(tile,index){return (tile.role)?_c('v-list-item',{key:index,attrs:{"id":"menulistitemhover"},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_c('v-list-tile-title',{attrs:{"id":tile.name},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_vm._v(_vm._s(tile.name))])],1):_vm._e()}),1)],1)],1)],2):_vm._e()],1):_vm._e()],1)],1)],1)],1),_c('v-snackbar',{attrs:{"right":true,"top":true,"timeout":_vm.snackbartimeout,"color":_vm.colorValue},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbartext)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }