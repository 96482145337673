<template>
	<v-container class="EDC-Container">
		<v-row class="EDC-Row">
			<v-col class="EDC-Col">
				<div style="width:100%;" id="main_container" ref="main_container">
					<edc-graph :graphFor="graphFor"  :dataForGraphRedesign="dataForGraphRedesign" :isReadOnly="isReadOnly" :key="graphFor"  :running_node="running_node_id" :completed_nodes="completed_node_list" :showMinimap="false" @sendElementEvent="receivedEmailEvent"></edc-graph>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>
<style scoped>
	
</style>
<script>
	const uuidv4 = require('uuid/v4')
	import edcGraph from '@/views/edcGraph/edcGraph.vue'
	import { shapes, dia } from 'jointjs'
	import * as graphObj from "@/methods/edcGraph/graph.js"
	import * as edcAnonymousObj from  "@/methods/edcGraph/anonymousFunctions.js"
	export default {
		name:'edcWorkFlowStatus',
		components:{
			'edc-graph':edcGraph
		},
		props:{
			graphData:{
				type:Object
			}
		},
		data(){
			return{
				graphFor:'edcWorkflow',
				isReadOnly:true,
				dataForGraphRedesign:{},
				running_node_id:'',
				completed_node_list:[],
				step_list:[],
				last_data_loaded_for:''
			}
		},
		watch:{
			'graphData':{
				handler(newvalue){
					this.manageGraphData()
				}
			}
		},
		mounted(){
			this.manageGraphData()
		},
		methods:{
			manageGraphData(){
				this.running_node_id = this.graphData.running_node_id
				this.completed_node_list =   this.graphData.completed_node_id_list
				this.step_list = _.cloneDeep(this.completed_node_list)
				this.step_list.push(this.graphData.running_node_id)
				this.dataForGraphRedesign = {
					"cells":this.getModifieldCells(this.graphData.workflow_json),
					"isAug2020ReleasedUI":true
				}
			},
			getModifieldCells(data){
				let newData = _.cloneDeep(data)
				let graph = new dia.Graph({},{ cellNamespace: shapes })
				let cells = graphObj.unzipGraph(this,newData,this.graphFor)
				let validCells = cells.cells.filter((obj)=>{
	            	return obj.prop('edc_cell_type')!='process_designer_step_container'
	            })
				graph.fromJSON(JSON.parse(JSON.stringify({"cells":validCells})));
	    		let rootEle =graph.getSources()[0]
	    		console.log('rootEle',rootEle)
	    		let element_list = graphObj.generateGraphObj(graph,rootEle,[rootEle.attributes])
	    		console.log('element_list',element_list)
	    		let newElementList = []
				let stepTypeToIgnore = ['Start','End','Condition','Form Mapping']
				let positionX = 10
				let positionY = 10
				for(let i=0;i<element_list.length;i++){
					let stepType = element_list[i].steptype
					let stepId = element_list[i].id

					if((!element_list[i].is_for_status || element_list[i].shape_type === "form_data_transfer" || !this.step_list.includes(stepId)))
						continue
					let element = _.find(newData.cells,['id',stepId])
					element.positionY = positionY
					element.positionX = positionX
					positionX =positionX+160
					newElementList.push(element)
				}
				let copiedEle = _.cloneDeep(newElementList)
				for(let i=0;i<copiedEle.length-1;i++){
					newElementList.push(this.getLinkJson(copiedEle[i].id,copiedEle[i+1].id))
				}

				return newElementList
			},
			getLinkJson(source_id,target_id){
				return  {'label2': '', 'source': {'id': source_id}, 'target': {'id': target_id}, 'label1': '', 'type': 'link', 'id': uuidv4(), 'is_zipped_link': true}
			},
			receivedEmailEvent(action,element){
				if(action === 'click' || action === 'doubleclick'){
					let newElement = edcAnonymousObj.getEmbeddedCell(element.element)
					if(newElement  && newElement.id === this.last_data_loaded_for)
						return
					this.last_data_loaded_for =newElement.id
					var data = {'is_email':true,
								'node_id': newElement.id,
								'step_code':newElement.prop('shape_type')}
					this.$emit('stepEvent', data)
				}
			}
		}
	}
</script>