/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Database_Table': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M8 8c-2.216 0-4 1.823-4 4.086v10.707a1 1 0 00-.023.207v28.094c0 2.786 2.522 4.93 5.468 4.93h45.067c2.946 0 5.467-2.144 5.467-4.93V24H60V12.086C60 9.823 58.216 8 56 8zM5.977 24H18v9H5.977zM20 24h11v9H20zm13 0h11v9H33zm13 0h11.979v9H46zM5.977 35H18v8H5.977zM20 35h11v8H20zm13 0h11v8H33zm13 0h11.979v8H46zM5.977 45H18v9.023H9.445c-2.003 0-3.468-1.363-3.468-2.93zM20 45h11v9.023H20zm13 0h11v9.023H33zm13 0h11.979v6.094c0 1.566-1.464 2.93-3.467 2.93H46z"/>'
  }
})
