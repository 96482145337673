/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Archive_Restore': {
    width: 80,
    height: 80,
    viewBox: '0 0 80 80',
    data: '<rect pid="0" width="16" height="4" x="32" y="35" rx="1.067" ry="1.6" _fill="#fff"/><g _fill="none" _stroke="#000"><path pid="1" d="M19 9c-3.799 0-6.857 4.08-6.857 9.145V25h56v-6.855c0-5.066-3.058-9.145-6.857-9.145zM16 41V31h48v25.904C64 60.281 61.38 63 58.123 63H38" stroke-width="2"/><path pid="2" d="M34 37h12" stroke-width="4" stroke-linecap="round"/><path pid="3" d="M9.812 59.773l5.576-3.288 5.575-3.289L21 58a10.07 10.11 0 004.862-2.503 10.07 10.11 0 002.695-10.471 10.07 10.11 0 01-.772 13.94 10.07 10.11 0 01-6.758 2.717l.034 4.498-5.625-3.204z" stroke-width="1.903" stroke-linecap="round"/></g>'
  }
})
