<template>
    <div style="width: 100% !important">
        <v-container style="width: 100% !important;">
            <v-card elevation-1  style="max-width: 100% !important">
                <v-overlay :absolute="absolute" :value="overlay">
                    <v-img :src="selected_image" width="98vw" height="98vh" contain>
                        <span style="float:center;font-weight: bold;background-color:blue;color: primary !important;">Attachment Preview</span>
                        <v-btn  v-if="selected_image != ''"  small fab rounded style="float:right;height:2.2em;width:2.2em;">
                            <svgicon  class="svg-icon-grid svg-fill-toolbar" name="cancel_v2" style="max-width='100vw';min-width='100vw" :original="true" @click="overlay = false" title="Close Preview"></svgicon>
                        </v-btn>
                        <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                                <span style="float:center;font-weight: bold;">Attachment Preview</span>
                            </v-row>
                        </template>
                    </v-img>
                </v-overlay>
                <v-row class="EDC-Row" style="width: 100% !important; padding-left: 1% !important;" align="center">                        
                    <v-col cols="2" class="EDC-Col" style="max-width: 20% !important">
                        <v-row class="EDC-Row EDC-RowsSpacing" justify="start" dense no-gutters>
                            <span class="override-font" style="float:left;font-size: 150% !important">{{ticket_title}}</span>
                        </v-row>
                    </v-col>
                    <v-col cols="2" class="EDC-Col" style="max-width: 20% !important">
                        <v-row class="EDC-Row EDC-RowsSpacing" justify="start" no-gutters dense>
                            <span class="override-font" style="float:left;font-size: 150% !important">{{ticket_id}}</span>
                        </v-row>
                    </v-col>
                    <v-col cols="2" class="EDC-Col" style="max-width: 20% !important">
                        <v-row class="EDC-Row EDC-RowsSpacing" justify="start" no-gutters dense>
                            <v-chip :color="getColor(ticket_status.status?ticket_status.status:'')" :text-color="ticket_status.status == 'Closed'? 'black' : 'white'" small dark class="override-font">
                                {{ticket_status.status}}
                            </v-chip>
                        </v-row>
                    </v-col>
                    <v-col cols="6" class="EDC-Col">
                        <v-row class="EDC-Row EDC-RowsSpacing" v-show="show_btns" justify="end" no-gutters dense>
                            <vc-button itemText="Save" v-show="show_form_buttons" :disabled="disable_for_closed_tkt" @click.native="updateTicket"></vc-button>
                            <vc-button itemText="Resolved" :disabled="disable_for_closed_tkt"  @click.native="onCloseAction('Resolved')" ></vc-button>
                        </v-row>
                    </v-col>
                </v-row>
                <v-tabs v-model="mainTabIndex" v-if="has_wf">
                    <v-tab class="EDC-Tabs">General Information</v-tab>
                    <v-tab v-show="has_wf" class="EDC-Tabs">Workflow Information</v-tab>
                    <v-tab-item>
                        <v-row class="EDC-Row" justify="start" no-gutters dense  style="padding-top: 1% !important;">
                            <v-col cols="5" class="EDC-Col EDC-ColsSpacing">
                                <v-textarea rows="5" hide-details outlined v-model="ticket_description" placeholder="Please Enter description" class="mycustomcls" height="100" auto-grow label="Description" :disabled="disable_for_closed_tkt"></v-textarea>
                            </v-col>
                            <v-col cols="1" class="EDC-Col EDC-ColsSpacing"></v-col>
                            <v-col cols="6" class="EDC-Col EDC-ColsSpacing" style="border: 1px solid !important;">
                                <v-row class="EDC-Row" justify="start" no-gutters dense>
                                    <v-col cols="4" class="EDC-Col">
                                        <v-row class="EDC-Row EDC-RowsSpacing" justify="start" no-gutters dense>
                                            <span class="override-font" style="float:left;font-weight: bold;padding-left: 1%">Priority:</span>
                                        </v-row>
                                        <v-row class="EDC-Row EDC-RowsSpacing" v-if="!show_priority" justify="start">
                                            <span class="override-font" @dblclick="showHideControl('text','ticket_priority')" style="float:left;padding-left: 1%">{{ticket_priority.id}}-{{ticket_priority.name}}</span>
                                        </v-row>
                                        <v-row class="EDC-Row EDC-RowsSpacing" v-if="show_priority" justify="start">
                                            <v-autocomplete class="customInputClass override-font"  ref="ticket_priority" @blur="showHideControl('control','ticket_priority')"  @change="ticket_priority.name" return-object v-model="ticket_priority" item-text="name" :items="priority_list" dense :disabled="disable_for_closed_tkt"></v-autocomplete>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="4" class="EDC-Col">
                                        <v-row class="EDC-Row EDC-RowsSpacing" justify="start" no-gutters dense>
                                            <span class="override-font" style="float:left;font-weight: bold;padding-left: 1%">Queue:</span>
                                        </v-row>
                                        <v-row class="EDC-Row EDC-RowsSpacing" justify="start" no-gutters dense>
                                            <span class="override-font" style="float:left;padding-left: 1%">{{ticket_queue.queue_name}}</span>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="4" class="EDC-Col">
                                        <v-row class="EDC-Row EDC-RowsSpacing" justify="start" no-gutters dense>
                                            <span class="override-font" style="float:left;font-weight: bold;padding-left: 1%">User:</span>
                                        </v-row>
                                        <v-row class="EDC-Row EDC-RowsSpacing" justify="start" no-gutters dense>
                                            <span class="override-font" style="float:left;padding-left: 1%">{{user_name}}</span>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row class="EDC-Row" justify="start" no-gutters dense>
                                    <v-col cols="4" class="EDC-Col">
                                        <v-row class="EDC-Row EDC-RowsSpacing" justify="start" no-gutters dense>
                                            <span class="override-font" style="float:left;font-weight: bold;padding-left: 1%">Source:</span>
                                        </v-row>
                                        <v-row class="EDC-Row EDC-RowsSpacing" no-gutters dense justify="start">
                                            <span class="override-font text" style="float:left;padding-left: 1%">{{ticket_source}}</span>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="4" class="EDC-Col">
                                        <v-row class="EDC-Row EDC-RowsSpacing" justify="start" no-gutters dense>
                                            <span class="override-font" style="float:left;font-weight: bold;padding-left: 1%">Assignee:</span>
                                        </v-row>
                                        <v-row class="EDC-Row EDC-RowsSpacing" v-if="!show_assignee" justify="start">
                                            <span @dblclick="showHideControl('text','assignee')" class="override-font text"  style="padding-top: 0.3em;">{{assignee.username?assignee.username:'Select Assignee '}}</span>
                                        </v-row>
                                        <v-row class="EDC-Row EDC-RowsSpacing" v-if="show_assignee" justify="start">
                                            <v-autocomplete class="customInputClass override-font"  ref="assignee" @blur="showHideControl('control','assignee')"  @change="assignee_fullname = assignee.full_name_with_user_name" return-object v-model="assignee" item-text="full_name_with_user_name" :items="assignee_list" dense :disabled="disable_for_closed_tkt"></v-autocomplete>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="4" class="EDC-Col">
                                        <v-row class="EDC-Row EDC-RowsSpacing" justify="start" no-gutters dense>
                                            <span class="override-font" style="float:left;font-weight: bold; text-align: left !important;">Date / Time Created:</span>
                                        </v-row>
                                        <v-row class="EDC-Row EDC-RowsSpacing" justify="start" no-gutters dense>
                                            <span class="override-font" style="float:left;padding-left: 1%">{{tz_created_date_time}}</span>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="EDC-Row" justify="start" no-gutters dense>
                            <v-col cols="6" class="EDC-Col EDC-ColsSpacing">
                                <v-card flat>
                                    <v-row class="EDC-Row EDC-RowsSpacing" justify="start">
                                        <v-col cols="9" class="EDC-Col">
                                            <v-textarea rows="2" class="border-change mycustomcls"  style="min-width: 97% !important; max-width: 97% !important;"
                                            height="70" label="Click Here To Post Comment" v-model="comment" :disabled="disable_for_closed_tkt"></v-textarea>
                                        </v-col>
                                        <v-col cols="3" class="EDC-Col" style="padding-top: 3% !important;">
                                            <v-row class="EDC-Row EDC-RowsSpacing" justify="center">
                                                <v-col cols="2" v-show="show_comment_buttons" class="EDC-Col">
                                                    <v-checkbox hide-details v-model="is_private" :disabled="disable_for_closed_tkt" ></v-checkbox>
                                                </v-col>
                                                <v-col cols="6" v-show="show_comment_buttons" class="EDC-Col">
                                                    <span style="float:left; padding-top:1% !important;"><b>Private Note</b></span>
                                                </v-col>
                                            </v-row>
                                            <v-row class="EDC-Row EDC-RowsSpacing" v-show="show_comment_buttons" justify="center">
                                                <vc-button style="margin:0px !important;" itemText="Post" @click.native="addComment()" :disabled="disable_for_closed_tkt"></vc-button>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <v-row class="EDC-Row" justify="start" no-gutters dense>
                                    <v-col cols="12" class="EDC-Col">
                                        <v-card flat outlined>
                                            <input type="file" ref="multiple_file_input" multiple="single" hidden @change="handleMultipleFileUpload()">
                                            <v-card-title  class="override-font" style="padding: 0px !important; font-size: 16px !important;">Attachment List</v-card-title>
                                            <v-row class="EDC-Row" no-gutters>
                                                <v-col cols="12"  style="background-color:white" class="EDC-Col">
                                                    <v-row class="EDC-Row" no-gutters></v-row>
                                                    <v-row class="EDC-Row" no-gutters>
                                                        <v-list v-for="(item) in attachementTableList.rows" style="padding-right:1% !important;" :key="item.ticket_id">
                                                            <v-row class="EDC-Row" no-gutters>
                                                                <v-card :color="getTileColor(index)" min-height="30px">
                                                                    <v-col cols="12"   class="EDC-Col  EDC-ColsSpacing">
                                                                        <span class="override-font" v-if="item.file_name.length <= 13" style="padding-left:3px !important;padding-right:3px !important;cursor: pointer;" @click="preview_file(item.id, item.content_type)">{{item.file_name}}</span>
                                                                        <span class="override-font" v-if="item.file_name.length > 13" style="padding-left:3px !important;padding-right:3px !important;cursor: pointer;" @click="preview_file(item.id, item.content_type)">{{item.file_name.split('.')[0].slice(0, 9)+'...'+item.file_name.split('.')[1]}}</span>
                                                                        <v-icon class="svg-icon-grid svg-fill-grid"  :disabled="disable_for_closed_tkt"   @click="removeAttachmentConfirmation(item.ticket_id,item.id,item.file_name)" title="Close">highlight_off</v-icon>
                                                                    </v-col>
                                                                </v-card>
                                                            </v-row>
                                                            <v-row class="EDC-Row" no-gutters></v-row>
                                                        </v-list>
                                                        <v-btn :disabled="attachment_readonly" rounded style="height:2% !important;margin-right:8px !important;margin-top:1.5% !important;padding-left:1% !important;" @click="onCloseActionAttachment('Add Attachment')">
                                                            <v-icon class="svg-icon-grid svg-fill-grid" small style="padding-top:4% !important;padding-left:4% !important;" :disabled="attachment_readonly"   @click="onCloseActionAttachment('Add Attachment')" title="Add Attachment">add</v-icon>
                                                            <span class="override-font" style="color: gray;">Add</span>
                                                        </v-btn>
                                                        <template v-slot:placeholder>
                                                            <v-row class="fill-height ma-0" align="center" justify="center">
                                                                <span class="override-font" style="float:center;font-weight: bold;background-color:blue;">Attachment Preview</span>
                                                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                                            </v-row>
                                                        </template>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                        <v-row class="EDC-Row EDC-RowsSpacing" no-gutters style="padding-top: 1% !important;" justify="center">
                                            <v-col cols="6" class="EDC-Col columns-alignment">
                                                <v-row justify="start" style="padding-top: 3% !important;">
                                                    <v-img :src="selected_image" lazy-src="" max-width="50wv" min-width="50wv" max-height="25vh" min-height="25vh" contain @dblclick="selected_image?overlay = true:''">
                                                        <v-btn color="primary" v-if="selected_image != ''" x-small rounded fab style="position:relative;float:right;height:1.2em;width:1.2em">
                                                            <svgicon  class="svg-icon-grid svg-fill-toolbar" name="cancel_v2"  :original="true" @click="closePreview" title="Close"></svgicon>
                                                        </v-btn>
                                                    </v-img>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col> 
                                </v-row>
                            </v-col>
                            <v-col cols="6" class="EDC-Col EDC-ColsSpacing">
                                <v-card flat outlined class="overflow-y-auto" style="max-height: 350px !important;">
                                    <v-row no-gutters class="EDC-Row">
                                        <v-col cols="2" class="EDC-Col">
                                            <v-card-title class="override-font" style="padding: 0px !important; padding-left:10% !important; font-size: 16px !important;">Activity</v-card-title>
                                        </v-col>
                                        <v-col cols="1" class="EDC-Col" style="padding-top:0.9% !important" v-if="SelectedSort == 'Latest'" align="left">
                                            <v-icon title="Oldest" @click="sortActivities('Oldest')">expand_less</v-icon>
                                        </v-col>
                                        <v-col cols="2" class="EDC-Col" style="padding-top:0.9% !important" v-if="SelectedSort == 'Latest'" align="left">
                                            <span style="float:left;padding-top: 3px;" class="override-font">Latest First</span>
                                        </v-col>
                                        <v-col cols="1" class="EDC-Col" align="left"  v-if="SelectedSort != 'Latest'">
                                            <v-icon class="override-font" title="Latest" @click="sortActivities('Latest')">expand_more</v-icon>
                                        </v-col>
                                        <v-col cols="2" class="EDC-Col" align="left" style="padding-top:0.9% !important" v-if="SelectedSort != 'Latest'">
                                            <span style="float:left;padding-top: 3px;" class="override-font">Oldest First</span>
                                        </v-col>
                                        <v-col cols="4" class="EDC-Col">
                                            <v-text-field dense label="Search" clearable v-model="search_text" @keydown.enter="applySearch" @click:clear="raiseAlert" prepend-inner-icon="search" autocomplete="off"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters class="EDC-Row EDC-RowsSpacing">
                                        <v-col cols="12" style="background-color:white;padding-left:1% !important" class="EDC-Col">
                                            <v-row class="EDC-Row commentbox-alignment">
                                                <v-col cols="12" style="background-color:white" class="EDC-Col">
                                                    <v-row no-gutters class="EDC-Row" style="padding-top:11px !important">
                                                        <v-col cols="12" class="EDC-Col EDC-ColsSpacing">
                                                            <v-list v-for="(item, index) in activityTableList.rows" :key="item.ticket_id">
                                                                <v-card :color="getTileColor(index)" v-if="!item.is_private || item.is_private && item.is_private && item.action_by == $session.get('email')">
                                                                    <v-row no-gutters class="EDC-Row EDC-RowsSpacing">
                                                                        <v-col cols="3" class="EDC-Col EDC-ColsSpacing" align="left">
                                                                            <v-list-item-title style="padding-left:8px !important;" class="override-font">{{item.activity_name}}</v-list-item-title>
                                                                        </v-col>
                                                                        <v-col cols="4" class="EDC-Col EDC-ColsSpacing" align="left">
                                                                            <v-list-item-title class="override-font">{{item.action_by_fullname}}</v-list-item-title>
                                                                        </v-col>
                                                                        <v-col cols="2" class="EDC-Col EDC-ColsSpacing" align="left">                                
                                                                            <v-list-item-title class="override-font">{{item.tz_action_date_time}}</v-list-item-title>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row no-gutters class="EDC-Row EDC-RowsSpacing">
                                                                        <v-col cols="3" class="EDC-Col EDC-ColsSpacing" align="left">
                                                                            <v-icon large v-if="item.activity_name == 'COMMENT ADDED'" color="primary">mdi-message-text</v-icon>
                                                                            <v-icon large v-if="item.activity_name == 'ATTACHMENT ADDED'" color="primary" @click="preview_file_from_activity(item.related_id, item.content_type)">attachment</v-icon>
                                                                            <v-icon large v-if="item.activity_name == 'TICKET CREATED' || item.activity_name == 'DESCRIPTION ADDED' || item.activity_name == 'DESCRIPTION REMOVED' || item.activity_name == 'DESCRIPTION UPDATED'" color="primary">
                                                                                create
                                                                            </v-icon>
                                                                            <v-icon large v-if="item.activity_name == 'TICKET UPDATED'" color="primary">create</v-icon>
                                                                            <v-icon large v-if="item.activity_name == 'UPDATED TICKET STATUS'" color="primary">update</v-icon>
                                                                        </v-col>
                                                                        <v-col cols="8" class="EDC-Col EDC-ColsSpacing" align="left">
                                                                            <v-content>
                                                                                <v-container fluid style="padding: 0px !important;">
                                                                                    <span v-html="item.description" class="override-font" :id="'activity-' + item.log_id"></span> <v-btn v-if="item.activity_name == 'ATTACHMENT ADDED'" @click="preview_file_from_activity(item.related_id, item.content_type)" color="blue" text style="text-decoration: underline;" v-html="item.file_name"></v-btn>
                                                                                </v-container>
                                                                            </v-content>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-card>
                                                            </v-list>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row class="EDC-Row" no-gutters>
                            <v-col class="EDC-Col">
                                <v-card elevation-1  style="width: 98% !important">
                                    <edc-worflowStatus :graphData="workflowObj" @stepEvent="stepEvent( ...arguments)"></edc-worflowStatus>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row class="EDC-Row" v-if="show_contact_form" no-gutters>
                            <v-col class="EDC-Col">
                                <v-card elevation-1  style="width: 98% !important">
                                    <edc-form :formJson="selected_form" :formObj="ticket_response" @contact_form_event="sendEmail(...arguments)" :showContactForm="show_contact_form"></edc-form>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row no-gutters align="center" class="EDC-Row">
                            <v-tabs v-model="tabIndex" v-if="!show_contact_form && (!(Object.keys(selected_form).length === 0) || !(Object.keys(info_data).length === 0))" non-linear>
                                <v-tab class="EDC-Tabs" :disabled="Object.keys(selected_form).length === 0">Form</v-tab>
                                <v-tab class="EDC-Tabs" :disabled="Object.keys(info_data).length === 0">Step Info</v-tab>
                                <v-tab-item>
                                    <v-row class="EDC-Row" v-if="show_overlay"  style="padding-top: 8px!important" no-gutters>
                                        <v-card elevation-1  style="width: 98% !important">
                                            <v-col class="EDC-Col">
                                                <edc-form :formJson="selected_form" key="testform" :formObj="ticket_response" @contact_form_event="sendEmail(...arguments)" :showContactForm="show_contact_form" :isApproval="is_approval"></edc-form>
                                            </v-col>
                                        </v-card>
                                    </v-row>
                                </v-tab-item>
                                <v-tab-item>
                                    <v-row class="EDC-Row" v-if="show_text_info" style="padding-top: 8px!important;padding-left: 8px!important;padding-bottom: 8px!important;" no-gutters>
                                        <v-card elevation-1   style="width: 98% !important">
                                        <v-col class="EDC-Col">
                                            <v-row class="EDC-Row" v-for="(key, index) in Object.keys(info_data)" :key="index+'info'">
                                                <v-col class="EDC-Col" cols="2">
                                                <span class="override-font" style="float:left;font-weight: bold;">{{key}}</span>
                                                </v-col>
                                                <v-col class="EDC-Col" v-if="key === 'Email Link'" cols="4">
                                                <a class="override-font" style="float:left;font-weight: bold;" @click="redirectLink(info_data[key])">{{info_data[key].split("_")[0]+"..."}}</a>
                                                </v-col>
                                                <v-col class="EDC-Col" v-else cols="4">
                                                <span class="override-font" style="float:left;font-weight: bold;">{{info_data[key]}}</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        </v-card>
                                    </v-row>
                                    <v-row class="EDC-Row" v-else-if="show_review_info" style="padding-top: 8px!important;padding-left: 8px!important;padding-bottom: 8px!important;" no-gutters>
                                        <v-card elevation-1   style="width: 98% !important">
                                            <v-col class="EDC-Col" cols="12">
                                                <v-row class="EDC-Row">
                                                    <v-col class="EDC-Col" cols="4">
                                                        <v-card elevation-1   style="width: 98% !important">
                                                            <v-row class="EDC-Row">
                                                                <span class="override-font" style="float:left;font-weight: bold;">Status</span>
                                                            </v-row>
                                                            <v-row class="EDC-Row" v-if="info_data.Status">
                                                                <v-col class="EDC-Col" cols="12">
                                                                    <span class="override-font" style="float:left;font-weight: bold;">{{info_data.Status.content}}</span>
                                                                </v-col>
                                                                <v-col class="EDC-Col" cols="12">
                                                                    <span class="override-font" style="float:left;font-weight: bold;">Form Review{{info_data.Status.review}}</span>
                                                                </v-col>
                                                                <v-col class="EDC-Col" cols="12">
                                                                    <span class="override-font" style="float:left;font-weight: bold;">Initiated:{{info_data.Status.time}}</span>
                                                                </v-col>
                                                                <v-col class="EDC-Col" cols="12">
                                                                    <span class="override-font" style="float:left;font-weight: bold;">Completed:{{info_data.Status.time}}</span>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col class="EDC-Col"></v-col>
                                                    <v-col class="EDC-Col" cols="4">
                                                        <v-card elevation-1   style="width: 98% !important">
                                                            <v-row class="EDC-Row">
                                                                <span class="override-font" style="float:left;font-weight: bold;">Approvals</span>
                                                            </v-row>
                                                            <v-row class="EDC-Row" v-for="(key, index) in info_data.Approvals" :key="index+'info'">
                                                                <v-col class="EDC-Col" cols="6">
                                                                    <span class="override-font" style="float:left;font-weight: bold;">{{key.action}}</span>
                                                                </v-col>
                                                                <v-col class="EDC-Col" cols="6">
                                                                    <span class="override-font" style="float:left;font-weight: bold;">{{key.group}}</span>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-card>
                                    </v-row>
                                    <v-row class="EDC-Row" v-else-if="show_mapping_info" style="padding-top: 8px!important;padding-left: 8px!important;padding-bottom: 8px!important;" no-gutters>
                                        <v-card elevation-1   style="width: 98% !important">
                                            <v-col class="EDC-Col" cols="12">
                                                <v-row class="EDC-Row">
                                                    <v-col class="EDC-Col" cols="4">
                                                        <v-row class="EDC-Row" v-for="(key, index) in Object.keys(info_data.source_details)" :key="index+'info'">
                                                            <v-col class="EDC-Col" cols="6">
                                                                <span class="override-font" style="float:left;font-weight: bold;">{{key}}</span>
                                                            </v-col>
                                                            <v-col class="EDC-Col" cols="6">
                                                                <span class="override-font" style="float:left;font-weight: bold;">{{info_data.source_details[key]}}</span>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                    <v-col class="EDC-Col" cols="4">
                                                        <v-row class="EDC-Row" v-for="(key, index) in Object.keys(info_data.target_details)" :key="index+'info'">
                                                            <v-col class="EDC-Col" cols="6">
                                                                <span class="override-font" style="float:left;font-weight: bold;">{{key}}</span>
                                                            </v-col>
                                                            <v-col class="EDC-Col" cols="6">
                                                                <span class="override-font" style="float:left;font-weight: bold;">{{info_data.target_details[key]}}</span>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-card>
                                    </v-row>
                                </v-tab-item>
                            </v-tabs>
                        </v-row>
                    </v-tab-item>
                </v-tabs>
                <v-container v-else>
                    <v-row class="EDC-Row" justify="start" no-gutters dense  style="padding-top: 1% !important;">
                        <v-col cols="5" class="EDC-Col EDC-ColsSpacing">
                            <v-textarea rows="5" hide-details outlined v-model="ticket_description" placeholder="Please Enter description" class="mycustomcls" height="100" auto-grow label="Description" :disabled="disable_for_closed_tkt"></v-textarea>
                        </v-col>
                        <v-col cols="1" class="EDC-Col EDC-ColsSpacing"></v-col>
                        <v-col cols="6" class="EDC-Col EDC-ColsSpacing" style="border: 1px solid !important;">
                            <v-row class="EDC-Row" justify="start" no-gutters dense>
                                <v-col cols="4" class="EDC-Col">
                                    <v-row class="EDC-Row EDC-RowsSpacing" justify="start" no-gutters dense>
                                        <span class="override-font" style="float:left;font-weight: bold;padding-left: 1%">Priority:</span>
                                    </v-row>
                                    <v-row class="EDC-Row EDC-RowsSpacing" v-if="!show_priority" justify="start">
                                        <span class="override-font" @dblclick="showHideControl('text','ticket_priority')" style="float:left;padding-left: 1%">{{ticket_priority.id}}-{{ticket_priority.name}}</span>
                                    </v-row>
                                    <v-row class="EDC-Row EDC-RowsSpacing" v-if="show_priority" justify="start">
                                        <v-autocomplete class="customInputClass override-font"  ref="ticket_priority" @blur="showHideControl('control','ticket_priority')"  @change="ticket_priority.name" return-object v-model="ticket_priority" item-text="name" :items="priority_list" dense :disabled="disable_for_closed_tkt"></v-autocomplete>
                                    </v-row>
                                </v-col>
                                <v-col cols="4" class="EDC-Col">
                                    <v-row class="EDC-Row EDC-RowsSpacing" justify="start" no-gutters dense>
                                        <span class="override-font" style="float:left;font-weight: bold;padding-left: 1%">Queue:</span>
                                    </v-row>
                                    <v-row class="EDC-Row EDC-RowsSpacing" justify="start" no-gutters dense>
                                        <span class="override-font" style="float:left;padding-left: 1%">{{ticket_queue.queue_name}}</span>
                                    </v-row>
                                </v-col>
                                <v-col cols="4" class="EDC-Col">
                                    <v-row class="EDC-Row EDC-RowsSpacing" justify="start" no-gutters dense>
                                        <span class="override-font" style="float:left;font-weight: bold;padding-left: 1%">User:</span>
                                    </v-row>
                                    <v-row class="EDC-Row EDC-RowsSpacing" justify="start" no-gutters dense>
                                        <span class="override-font" style="float:left;padding-left: 1%">{{user_name}}</span>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="EDC-Row" justify="start" no-gutters dense>
                                <v-col cols="4" class="EDC-Col">
                                    <v-row class="EDC-Row EDC-RowsSpacing" justify="start" no-gutters dense>
                                        <span class="override-font" style="float:left;font-weight: bold;padding-left: 1%">Source:</span>
                                    </v-row>
                                    <v-row class="EDC-Row EDC-RowsSpacing" no-gutters dense justify="start">
                                        <span class="override-font text" style="float:left;padding-left: 1%">{{ticket_source}}</span>
                                    </v-row>
                                </v-col>
                                <v-col cols="4" class="EDC-Col">
                                    <v-row class="EDC-Row EDC-RowsSpacing" justify="start" no-gutters dense>
                                        <span class="override-font" style="float:left;font-weight: bold;padding-left: 1%">Assignee:</span>
                                    </v-row>
                                    <v-row class="EDC-Row EDC-RowsSpacing" v-if="!show_assignee" justify="start">
                                        <span @dblclick="showHideControl('text','assignee')" class="override-font text"  style="padding-top: 0.3em;">{{assignee.username?assignee.username:'Select Assignee '}}</span>
                                    </v-row>
                                    <v-row class="EDC-Row EDC-RowsSpacing" v-if="show_assignee" justify="start">
                                        <v-autocomplete class="customInputClass override-font"  ref="assignee" @blur="showHideControl('control','assignee')"  @change="assignee_fullname = assignee.full_name_with_user_name" return-object v-model="assignee" item-text="full_name_with_user_name" :items="assignee_list" dense :disabled="disable_for_closed_tkt"></v-autocomplete>
                                    </v-row>
                                </v-col>
                                <v-col cols="4" class="EDC-Col">
                                    <v-row class="EDC-Row EDC-RowsSpacing" justify="start" no-gutters dense>
                                        <span class="override-font" style="float:left;font-weight: bold; text-align: left !important;">Date / Time Created:</span>
                                    </v-row>
                                    <v-row class="EDC-Row EDC-RowsSpacing" justify="start" no-gutters dense>
                                        <span class="override-font" style="float:left;padding-left: 1%">{{tz_created_date_time}}</span>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="EDC-Row" justify="start" no-gutters dense>
                        <v-col cols="6" class="EDC-Col EDC-ColsSpacing">
                            <v-card flat>
                                <v-row class="EDC-Row EDC-RowsSpacing" justify="start">
                                    <v-col cols="9" class="EDC-Col">
                                        <v-textarea rows="2" class="border-change mycustomcls"  style="min-width: 97% !important; max-width: 97% !important;"
                                        height="70" label="Click Here To Post Comment" v-model="comment" :disabled="disable_for_closed_tkt"></v-textarea>
                                    </v-col>
                                    <v-col cols="3" class="EDC-Col" style="padding-top: 3% !important;">
                                        <v-row class="EDC-Row EDC-RowsSpacing" justify="center">
                                            <v-col cols="2" v-show="show_comment_buttons" class="EDC-Col">
                                                <v-checkbox hide-details v-model="is_private" :disabled="disable_for_closed_tkt" ></v-checkbox>
                                            </v-col>
                                            <v-col cols="6" v-show="show_comment_buttons" class="EDC-Col">
                                                <span style="float:left; padding-top:1% !important;"><b>Private Note</b></span>
                                            </v-col>
                                        </v-row>
                                        <v-row class="EDC-Row EDC-RowsSpacing" v-show="show_comment_buttons" justify="center">
                                            <vc-button style="margin:0px !important;" itemText="Post" @click.native="addComment()" :disabled="disable_for_closed_tkt"></vc-button>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <v-row class="EDC-Row" justify="start" no-gutters dense>
                                <v-col cols="12" class="EDC-Col">
                                    <v-card flat outlined>
                                        <input type="file" ref="multiple_file_input" multiple="single" hidden @change="handleMultipleFileUpload()">
                                        <v-card-title  class="override-font" style="padding: 0px !important; font-size: 16px !important;">Attachment List</v-card-title>
                                        <v-row class="EDC-Row" no-gutters>
                                            <v-col cols="12"  style="background-color:white" class="EDC-Col">
                                                <v-row class="EDC-Row" no-gutters></v-row>
                                                <v-row class="EDC-Row" no-gutters>
                                                    <v-list v-for="(item) in attachementTableList.rows" style="padding-right:1% !important;" :key="item.ticket_id">
                                                        <v-row class="EDC-Row" no-gutters>
                                                            <v-card :color="getTileColor(index)" min-height="30px">
                                                                <v-col cols="12"   class="EDC-Col  EDC-ColsSpacing">
                                                                    <span class="override-font" v-if="item.file_name.length <= 13" style="padding-left:3px !important;padding-right:3px !important;cursor: pointer;" @click="preview_file(item.id, item.content_type)">{{item.file_name}}</span>
                                                                    <span class="override-font" v-if="item.file_name.length > 13" style="padding-left:3px !important;padding-right:3px !important;cursor: pointer;" @click="preview_file(item.id, item.content_type)">{{item.file_name.split('.')[0].slice(0, 9)+'...'+item.file_name.split('.')[1]}}</span>
                                                                    <v-icon class="svg-icon-grid svg-fill-grid"  :disabled="disable_for_closed_tkt"   @click="removeAttachmentConfirmation(item.ticket_id,item.id,item.file_name)" title="Close">highlight_off</v-icon>
                                                                </v-col>
                                                            </v-card>
                                                        </v-row>
                                                        <v-row class="EDC-Row" no-gutters></v-row>
                                                    </v-list>
                                                    <v-btn :disabled="attachment_readonly" rounded style="height:2% !important;margin-right:8px !important;margin-top:1.5% !important;padding-left:1% !important;" @click="onCloseActionAttachment('Add Attachment')">
                                                        <v-icon class="svg-icon-grid svg-fill-grid" small style="padding-top:4% !important;padding-left:4% !important;" :disabled="attachment_readonly"   @click="onCloseActionAttachment('Add Attachment')" title="Add Attachment">add</v-icon>
                                                        <span class="override-font" style="color: gray;">Add</span>
                                                    </v-btn>
                                                    <template v-slot:placeholder>
                                                        <v-row class="fill-height ma-0" align="center" justify="center">
                                                            <span class="override-font" style="float:center;font-weight: bold;background-color:blue;">Attachment Preview</span>
                                                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                                        </v-row>
                                                    </template>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                    <v-row class="EDC-Row EDC-RowsSpacing" no-gutters style="padding-top: 1% !important;" justify="center">
                                        <v-col cols="6" class="EDC-Col columns-alignment">
                                            <v-row justify="start" style="padding-top: 3% !important;">
                                                <v-img :src="selected_image" lazy-src="" max-width="50wv" min-width="50wv" max-height="25vh" min-height="25vh" contain @dblclick="selected_image?overlay = true:''">
                                                    <v-btn color="primary" v-if="selected_image != ''" x-small rounded fab style="position:relative;float:right;height:1.2em;width:1.2em">
                                                        <svgicon  class="svg-icon-grid svg-fill-toolbar" name="cancel_v2"  :original="true" @click="closePreview" title="Close"></svgicon>
                                                    </v-btn>
                                                </v-img>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col> 
                            </v-row>
                        </v-col>
                        <v-col cols="6" class="EDC-Col EDC-ColsSpacing">
                            <v-card flat outlined class="overflow-y-auto" style="max-height: 350px !important;">
                                <v-row no-gutters class="EDC-Row">
                                    <v-col cols="2" class="EDC-Col">
                                        <v-card-title class="override-font" style="padding: 0px !important; padding-left:10% !important; font-size: 16px !important;">Activity</v-card-title>
                                    </v-col>
                                    <v-col cols="1" class="EDC-Col" style="padding-top:0.9% !important" v-if="SelectedSort == 'Latest'" align="left">
                                        <v-icon title="Oldest" @click="sortActivities('Oldest')">expand_less</v-icon>
                                    </v-col>
                                    <v-col cols="2" class="EDC-Col" style="padding-top:0.9% !important" v-if="SelectedSort == 'Latest'" align="left">
                                        <span style="float:left;padding-top: 3px;" class="override-font">Latest First</span>
                                    </v-col>
                                    <v-col cols="1" class="EDC-Col" align="left"  v-if="SelectedSort != 'Latest'">
                                        <v-icon class="override-font" title="Latest" @click="sortActivities('Latest')">expand_more</v-icon>
                                    </v-col>
                                    <v-col cols="2" class="EDC-Col" align="left" style="padding-top:0.9% !important" v-if="SelectedSort != 'Latest'">
                                        <span style="float:left;padding-top: 3px;" class="override-font">Oldest First</span>
                                    </v-col>
                                    <v-col cols="4" class="EDC-Col">
                                        <v-text-field dense label="Search" clearable v-model="search_text" @keydown.enter="applySearch" @click:clear="raiseAlert" prepend-inner-icon="search" autocomplete="off"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters class="EDC-Row EDC-RowsSpacing">
                                    <v-col cols="12" style="background-color:white;padding-left:1% !important" class="EDC-Col">
                                        <v-row class="EDC-Row commentbox-alignment">
                                            <v-col cols="12" style="background-color:white" class="EDC-Col">
                                                <v-row no-gutters class="EDC-Row" style="padding-top:11px !important">
                                                    <v-col cols="12" class="EDC-Col EDC-ColsSpacing">
                                                        <v-list v-for="(item, index) in activityTableList.rows" :key="item.ticket_id">
                                                            <v-card :color="getTileColor(index)" v-if="!item.is_private || item.is_private && item.is_private && item.action_by == $session.get('email')">
                                                                <v-row no-gutters class="EDC-Row EDC-RowsSpacing">
                                                                    <v-col cols="3" class="EDC-Col EDC-ColsSpacing" align="left">
                                                                        <v-list-item-title style="padding-left:8px !important;" class="override-font">{{item.activity_name}}</v-list-item-title>
                                                                    </v-col>
                                                                    <v-col cols="4" class="EDC-Col EDC-ColsSpacing" align="left">
                                                                        <v-list-item-title class="override-font">{{item.action_by_fullname}}</v-list-item-title>
                                                                    </v-col>
                                                                    <v-col cols="2" class="EDC-Col EDC-ColsSpacing" align="left">                                
                                                                        <v-list-item-title class="override-font">{{item.tz_action_date_time}}</v-list-item-title>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row no-gutters class="EDC-Row EDC-RowsSpacing">
                                                                    <v-col cols="3" class="EDC-Col EDC-ColsSpacing" align="left">
                                                                        <v-icon large v-if="item.activity_name == 'COMMENT ADDED'" color="primary">mdi-message-text</v-icon>
                                                                        <v-icon large v-if="item.activity_name == 'ATTACHMENT ADDED'" color="primary" @click="preview_file_from_activity(item.related_id, item.content_type)">attachment</v-icon>
                                                                        <v-icon large v-if="item.activity_name == 'TICKET CREATED' || item.activity_name == 'DESCRIPTION ADDED' || item.activity_name == 'DESCRIPTION REMOVED' || item.activity_name == 'DESCRIPTION UPDATED'" color="primary">
                                                                            create
                                                                        </v-icon>
                                                                        <v-icon large v-if="item.activity_name == 'TICKET UPDATED'" color="primary">create</v-icon>
                                                                        <v-icon large v-if="item.activity_name == 'UPDATED TICKET STATUS'" color="primary">update</v-icon>
                                                                    </v-col>
                                                                    <v-col cols="8" class="EDC-Col EDC-ColsSpacing" align="left">
                                                                        <v-content>
                                                                            <v-container fluid style="padding: 0px !important;">
                                                                                <span v-html="item.description" class="override-font" :id="'activity-' + item.log_id"></span> <v-btn v-if="item.activity_name == 'ATTACHMENT ADDED'" @click="preview_file_from_activity(item.related_id, item.content_type)" color="blue" text style="text-decoration: underline;" v-html="item.file_name"></v-btn>
                                                                            </v-container>
                                                                        </v-content>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-card>
                                                        </v-list>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container> 
            </v-card>
        </v-container>
        <v-dialog v-model="show_confirm_delete" width="300">
            <v-col cols="12" class="EDC-Col">
                <v-card elevation-1>
                    <v-row no-gutters class="EDC-Row">
                        <v-toolbar dark dense absolute style="width:100%">
                            <v-toolbar-title class="EDC-ToolbarTitle">Confirmation</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <svgicon  class="svg-icon-grid svg-fill-toolbar" name="cancel_v2"  :original="true" @click="closePanel" title="Close"></svgicon>
                        </v-toolbar>
                    </v-row>
                    <v-row no-gutters class="EDC-Row EDC-RowsSpacing" style="padding-top:60px !important;"  justify="center">
                        <span>Are you sure you want to delete attachment</span>
                    </v-row> 
                    <v-row no-gutters class="EDC-Row EDC-RowsSpacing"  justify="center">
                        <vc-button itemText="Delete" @click.native="removeAttachment()"></vc-button>
                    </v-row> 
                </v-card>
            </v-col>
        </v-dialog>
        <loading-panel :loader="loader"></loading-panel>
    </div>
</template>
<script>
var moment = require("moment-timezone")
import {mapActions} from 'vuex';
import config from '../../config.json';
import {COMMENT_TABLE_LIST, ACTIVITY_TABLE_LIST, ATTACHEMENT_TABLE_LIST} from './ticketMetaData.js'
import {post as postToServer} from './../../methods/serverCall.js';
import {get as getToServer} from './../../methods/serverCall.js';
import {SERVER_ERROR} from '../../data/client_message.js';
import {Snackbar_Default_Timeout, GRID_STATUS_COLOR_CODE, Ticket_management} from '@/constants/constants.js';
import ticketDetailHeader from './ticketDetailsHeader.vue'
import workflowStatus from './workFlowStatus.vue'
import edcForm from '../edcForm/edcTabForm.vue'
export default{
    components:{
        // 'ticket-details-header':ticketDetailHeader,
        'edc-worflowStatus':workflowStatus,
        'edc-form':edcForm,
    },
    data: function(){
        return{
            loader:false,
            snackbartext: '',
            is_closed_ticket: false,
            workflowObj:{},
            workflow_request_id:'',
            info_data:{},
            is_active_step:false,
            is_approval:false,
            show_review_info:false,
            show_contact_form:false,
            token_data:{},
            ticket_response:{},
            show_overlay:false,
            show_text_info:false,
            tabIndex:0,
            mainTabIndex:0,
            show_btns:true,
            workflow_id:'',
            workflow_name:'',
            selected_form:{},
            show_form_buttons:false,
            old_description:'',
            show_assignee:false,
            show_status:false,
            show_priority:false,
            show_confirm_delete:false,
            has_wf:false,
            onlyFieldList:[],
            current_step_status:'',
            current_attachment:'',
            current_attachment_name:'',
            SelectedSort:['Latest'],
            ticket_details:'',
            show_comment_buttons:false,
            dv_link:"",
            selected_image:'',
            overlay:false,
            assignee_list:[],
            status_list:[],
            priority_list:[],
            assignee:{},
            old_assignee:'',
            assignee_fullname:'',
            files:[],
            ticket_id:'',
            ticket_title:'',
            ticket_status:{},
            old_ticket_status:{},
            sub_status:'',
            ticket_priority:{},
            old_priority:{},
            ticket_source:'',
            ticket_queue:'',
            activityTableList: _.cloneDeep(ACTIVITY_TABLE_LIST),
            attachementTableList:_.cloneDeep(ATTACHEMENT_TABLE_LIST),
            highlight_txt:'',
            attached_files:[],
            search_text:'',
            comment:'',
            is_private: false,
            old_comment: '',
            ticket_description:'',
            ticket_action: '',
            user_initials: '',
            user_name: this.$session.get('user_name'),
            user_email: this.$session.get('email'),
            user_client_id: this.$session.get('client_id'),
            user_phone: this.$session.get('phone'),
            user_organization:this.$session.get('client_name').toUpperCase(),
            user_time_zone_pref: this.$session.get('time_zone_preference'),
            dateFormat:this.$session.get('UI_date_format'),
            timeZoneFormat:this.$session.get('time_zone_preference'),
            user_list:[],
            private_note:false,
            ticket_action_list:[],
            show_dvLink:false,
            envIpJson : {
                "filter": [],
                "sort": [],
                "page": 1,
                "page_size": 1000
            },
            disable_for_closed_tkt: false,
            attachment_readonly: false
        }
    },
    mounted(){
        this.ticket_details = this.$route.params
        this.setclearSearchText(this.ticket_details)
        this.setclearDate(this.ticket_details)
        if(this.$route.params.form_json){
            // this.workflow_request_id = this.$route.params.workflow_request_id
            // this.current_step_id = this.$route.params.current_step_id
            // this.current_step_status = this.$route.params.current_step_status
            this.selected_form = this.$route.params.form_json              
            this.show_contact_form = true
            this.ticket_response = this.$route.params
            this.ticket_details = this.$route.params
        }
        else if(this.$route.params.ticket_details){
            this.ticket_details = this.$route.params.ticket_details
        }
        var name = this.user_name.split(' ')
        this.user_initials = name[0].charAt(0)+''+name[1].charAt(0)
        this.initialActivity();
        this.attachementTableList.actions=[
            {'text':'attachment','key':"ticket_id",index:1},
            {'text':'delete','key':"ticket_id", selectType:"single", role:true,index:2},
        ];
        if(this.ticket_details.is_closed_ticket){
            this.disable_for_closed_tkt = true;
            this.attachment_readonly = true;
        }
        
    },
    watch:{
        comment:{
            handler(newValue){
                if(newValue != ''){
                    this.show_comment_buttons = true
                }
                if(newValue == ''){
                    this.show_comment_buttons = false
                }
            }
        },
        ticket_description:{
            handler(newValue){
                if(this.old_description != newValue){
                    this.show_form_buttons = true
                }
                if(this.old_description == newValue){
                    this.show_form_buttons = false
                }
            }
        },
        assignee:{
            handler(newValue){
                if(newValue){
                    if(this.old_assignee != newValue.username){
                        this.show_form_buttons = true
                    }
                    if(this.old_assignee == newValue.username){
                        this.show_form_buttons = false
                    }
                }
                else
                    this.show_form_buttons = true
            }
        },
        ticket_priority:{
            handler(newValue){
                if(this.old_priority.id != newValue.id){
                    this.show_form_buttons = true
                }
                if(this.old_priority.id == newValue.id){
                    this.show_form_buttons = false
                }
            }
        },
        mainTabIndex:{
            handler(newValue){
                if(newValue === 1){
                    this.show_btns = false
                }
                else
                    this.show_btns = true
            }
        },
        '$store.state.ticketDetailsObj':{
            handler(newValue){
                this.ticket_details = _.cloneDeep(newValue)
                this.initialActivity();

            }
        }
    },
    methods:{
        ...mapActions(["setclearSearchText"]),
        ...mapActions(["setclearDate"]),
        redirectLink(link){
            window.open(link, '_blank');
        },
        stepEvent(data){
            var _this =this;
            var client_id = _this.$session.get('client_id');
            var step_code = data.step_code
            var data = {"client_id": client_id,
            'product_name': window.sessionStorage.getItem('product'),
            'step_code':data.step_code,
            'node_id':data.node_id,
            'workflow_request_id':this.workflow_request_id,
            'user_name':this.$session.get('email')
            }
            this.info_data = {}
            if(this.workflowObj.running_node_id === data.node_id && data.step_code === "form_review"){
                this.is_active_step = true
                this.show_contact_form = true
            }
            else if(this.workflowObj.running_node_id === data.node_id && data.step_code === "approval"){
                this.is_active_step = true
                this.show_contact_form = false
                this.is_approval = true
            }
            else{
                this.is_active_step = false
                this.show_contact_form = false
                this.is_approval = false
            }
            this.loader = true
            postToServer(_this, config.WORKFLOW_PRODUCT_URL + '/get_step_request_data', data).then(response  => {
                var security_action = response.security_actions
                if(_.isEmpty(_this.token_data))
                _this.token_data={"workflow_request_id":this.workflow_request_id,"node_id": response.step_data.node_id,"workflow_master_id":response.step_data.workflow_master_id,
                                "step_code":step_code,"master_data_id":response.step_data.master_data_id,"workflow_step_master_id":response.step_data.workflow_step_master_id,
                                "call_from":response.step_data.call_from}
                this.ticket_response = _this.token_data
                response = response.step_data
            // SAVING DATA TO DISPLAY FORM DETAILS
                _this.loader = false;
                _this.show_overlay = true
                _this.show_text_info= false
                _this.show_review_info= false
                if(response.form_json)
                    _this.applyReadonly(response,security_action)
                if(response.node_name === "send_form_link"){
                    _this.show_text_info= true
                    _this.tabIndex = 0
                    _this.show_overlay = true
                    _this.info_data = {
                        "Email Step Information": "",
                        "Sent On": response.tz_aware_email_sent_date_time,
                        "Email Sent to": response.email_data?response.email_data.email_sent_to:'',
                        "Email Sent by": response.email_data?response.email_data.email_sent_by:'',
                        "Email Subject": response.email_data?response.email_data.email_subject:'',
                        "Email Content": response.email_data?response.email_data.email_content:'',
                        "Sent Form Id": response.step_input_data.selected_form,
                        "Email Link": response.email_data?response.email_data.email_link:'',

                        "Link Expire Time": response.email_data?response.email_data.expire_time:'',
                    }
                }
            if(response.node_name === "form_review"){
                _this.tabIndex = 0
                if(!_.isEmpty(response.user_group_details)){
                _this.show_review_info= true
                _this.info_data = {
                    "Status":{
                    "content": "New supplier content",
                    "review": "(in-progress)",
                    "initiated": _this.created_date_time,
                    },
                    "Approvals":[],
                    
                }
                _.forEach(response.user_group_details,function(obj,indx){
                        _this.info_data["Approvals"].push({"group": obj.group_name})
                        let group_name = obj.group_name
                        _.forEach(obj.users_list,function(user){
                            let action_time = ''
                            if(user.action === "reviewed")
                            _this.info_data["Approvals"][indx]["action"]= user.user_name + " (" + group_name+")" + " "+ action_time
                            
                    })
                    })
                }
            }
            if(response.node_name === "Form Mapping"){
                _this.show_mapping_info= true
                _this.tabIndex = 1
                _this.show_overlay = false
                _this.info_data = {
                "source_details":{
                    "Form Mapping Step Source Details": "",
                    "Source Form Id": response.input_form_id,
                    "Source Form Header": response.input_form_json.formHeader,
                    "Source Field":_.find(response.input_form_json.fieldList,['fieldId',response.mapping_details[0].input_field_id]).nameInDB,
                },
                "target_details":{
                    "Form Mapping Step Target Details": "",
                    "Target Form Id": response.output_form_id,
                    "target Form Header": response.output_form_json.formHeader,
                    "Target Field":_.find(response.output_form_json.fieldList,['fieldId',response.mapping_details[0].output_field_id]).nameInDB,
                },
                }
            }
            
            }).catch(ticketResponseError => {
              _this.loader = false;
              if(ticketResponseError){
                  _this.colorValue = 'error'
                  _this.snackbartext = ticketResponseError;                    
              }
              else {                    
                  _this.colorValue = 'error'
                  _this.snackbartext = SERVER_ERROR;                    
              }
          });
        },
        getWorkflowStatus(){
            var _this =this;
            var client_id = _this.$session.get('client_id');
            var data = {"client_id": client_id,
            'product_name': window.sessionStorage.getItem('product'),
            'workflow_request_id':this.workflow_request_id}
            postToServer(_this, config.WORKFLOW_PRODUCT_URL + '/get_workflow_status', data).then(response  => {
            // Saving workflow status data for recreating workflow obj
                this.workflowObj = response
                this.workflow_id = response.workflow_id
                this.workflow_name = response.workflow_name
                if(this.$route.params.node_id){
                setTimeout(()=>{
                    _this.stepEvent({"node_id":this.$route.params.node_id,"step_code":this.$route.params.step_code,"workflow_request_id":this.$route.params.workflow_request_id})
                },1000)
                }
                else if(this.$route.params.moreInfo){
                return
                }
                else{
                let step = _.find(response.workflow_json.cells,['id',response.running_node_id]).shape_type
                
                setTimeout(()=>{
                    _this.stepEvent({"node_id":response.running_node_id,"step_code":step,"workflow_request_id":_this.$route.params.workflow_request_id})
                },1000)
                }
            }).catch(ticketResponseError => {
                this.loader = false;
                if(ticketResponseError){
                    this.colorValue = 'error'
                    this.snackbartext = ticketResponseError;                    
                }
                else {                    
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;                    
                }
            });
        },
        applyReadonly(data,security_action){
            var _this = this
            for(let i=0;i<data.form_json.fieldList.length;i++)
						{	let field = data.form_json.fieldList[i]
							if(field.edcType === 'field')
								field.readonly = true
						}
            if(this.is_active_step && this.show_contact_form){
                for(let i=0;i<security_action.length;i++){
                    let fieldId = security_action[i].field_id
                    let actions =  security_action[i].action_list
                    if(actions.indexOf("update_field_data")>-1){
                        _this.enableChilds(data.form_json.fieldList,fieldId)
                    }
                }
            }
            this.addFieldsToFieldList(data.form_json.fieldList)
            this.loadUDCValues(data.form_json)
        },
        enableChilds(fieldList,parent_id){
            var _this = this
            for(let i=0;i<fieldList.length;i++){
                let fieldobj = fieldList[i]
                if(fieldobj.parentId === parent_id){
                    if(fieldobj.edcType === 'field'){
                        fieldobj.readonly = false
                    }
                    else
                        _this.enableChilds(fieldList,fieldobj.id)
                }
            }
		},
        addFieldsToFieldList(fieldList){
            var _this = this 
            for(let i =0;i<fieldList.length;i++){
					if(fieldList[i].groupId)
						_this.addFieldsToFieldList(fieldList[i].fieldList)
					else
						_this.onlyFieldList.push(fieldList[i])
            }
        },
        getFieldById(id,fieldList){
            var _this = this
            if(!id)
                return
            if(!fieldList)
                fieldList = this.selected_form.fieldList
            for(let i=0;i<fieldList.length;i++){
                if(fieldList[i].id === id)
                    return fieldList[i]
            }
        },
        manageResponse(response,form_json){
            var _this = this
            for(let i=0;i<response.length;i++){
                let fieldId = response[i].fieldId
                let field = _this.getFieldById(fieldId,form_json.fieldList)
                if(field){
                    field.prePropValue.itemList = response[i].item_list
                }
            }
		},
        loadUDCValues(form_json){
            var _this = this
            let udcList = []
            for(let i=0;i<this.onlyFieldList.length;i++){
					if(_.isEmpty(this.onlyFieldList[i].prePropValue) || !this.onlyFieldList[i].prePropValue.isFromApi || this.onlyFieldList[i].prePropValue.valueType != 'UDC')
						continue
					udcList.push({"fieldId":this.onlyFieldList[i].fieldId,"valueDetails":this.onlyFieldList[i].prePropValue.valueDetails})
            }
            if(!udcList.length){
                _this.selected_form = form_json
                return
            }
            let data = {"client_id":this.$session.get("client_id"),
                        "udcList":udcList,
                        "workflow_id": this.workflow_id,
                        "return_type": 'all'}
            this.loader = true
			postToServer(_this,config.WORKFLOW_PRODUCT_URL+'/get_udc_values',data, true).then(response=>{
                this.loader = false
                _this.manageResponse(response,form_json)
                _this.selected_form = form_json
            }).catch(error=>{          
                this.loader = false
                _this.selected_form = form_json
            })	
        },
        sendEmail (data) {
            var _this = this
            var client_id = this.$session.get('client_id')
            var UserData = {
                'client_id': client_id,
                'user_id': "Essentio",
                'client_name': "Essentio",            
                'workflow_request_id':this.workflow_request_id,
                'ticket_id': this.ticket_id,
                'receiver_email': _.find(data.fieldList,['nameInDB',"recipent_email"]).value,
                'current_step_id': this.current_step_id,
                'company_name':_.find(data.fieldList,['nameInDB',"company_name"]).value,
                'recipient_first_name':_.find(data.fieldList,['nameInDB',"recipient_first_name"]).value,
                'recipient_last_name':_.find(data.fieldList,['nameInDB',"recipient_last_name"]).value,
                'current_step_status': this.current_step_status,
                'workflow_request_id':this.workflow_request_id,
                'email_subject': _.find(data.fieldList,['nameInDB',"email_subject"]).value,
                'email_content': _.find(data.fieldList,['nameInDB',"email_content"]).value,
            }
            var data_to_send = {}
            data_to_send =  Object.assign({}, this.ticket_response, UserData);
            data_to_send.form_json = data
            postToServer(this, config.EDC_FORM_MANAGEMENT_URL + '/send_form_email', data_to_send).then(Response  => {
                 _this.loader = false
                //delete this params so we can load current running step data
                delete _this.$route.params.moreInfo
                if(Response){   
                    _this.show_contact_form =false
                    this.snackbar = true
                    this.colorValue = 'success'
                    this.snackbartext = "Email sent successfully.";
                    setTimeout(() => { 
                    _this.getTicketDetails()
                    _this.show_edit_page= true
                    }, 1000);
                }
            }).catch(error_response => {            
                this.loader = false
                if(error_response){
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = error_response;
                }
                else {
                    this.snackbar = true
                    this.snackbartext = 'Something went wrong.Try Again';
                    this.colorValue = 'error'
                    
                }
            });
        },
        initialActivity(){
            this.show_dvLink = this.ticket_details.source_type == "dataviewer"?true:false
            if(this.ticket_details){
                this.ticket_title = this.ticket_details.title
                this.ticket_id = this.ticket_details.ticket_id
                this.ticket_source = this.ticket_details.source_name
                this.ticket_queue = {"queue_id":this.ticket_details.queue_id,
                                    "queue_name":this.ticket_details.queue_name}
                this.tz_created_date_time = moment(this.ticket_details.tz_created_date,this.dateFormat).format('ll') +' at '+moment(this.ticket_details.tz_created_date+' '+this.ticket_details.tz_created_time,this.dateFormat+" HH:mm:ss").tz(this.timeZoneFormat).format('LT') +' '+moment().tz(this.timeZoneFormat).format('z')
            }
            this.getTicketDetails();
            this.getUsersInQueue()
            this.getTicketPriority();
            this.getAllTicketActions();
            this.getAttachements();
            this.getActivityLogs(this.envIpJson);
        },
        getTileColor(index){
        if(index % 2 != 0)
            return '#F5F5F5'
        else
            return 'white'
        },
        getColor(value){
            return GRID_STATUS_COLOR_CODE[value.toLowerCase()]
        },
        removeAttachment(){
            var _this =this;
            var client_id = _this.$session.get('client_id');
            var data = {"client_id": client_id,
                        "ticket_id": this.ticket_id,
                        "attachment_id": this.current_attachment,
                        "attachment_name": this.current_attachment_name,
                        'product_name': "TICKET_MANAGEMENT"}
            var get_summary_url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + '/delete_ticket_attachment'
            postToServer(_this, get_summary_url, data).then(response => {
            _this.getActivityLogs(_this.envIpJson)
                    _this.getAttachements()
                    _this.show_confirm_delete = false
                }).catch(error_response => {
                    if(error_response){
                    _this.getAttachements()
                }
                else{
                }
            }); 
        },
        handleMultipleFileUpload(){
            debugger
            this.attached_files = this.$refs.multiple_file_input.files[0];
            this.addMultipleAttachment()
        },
        closePanel(){
            this.show_confirm_delete = false
        },
        addMultipleAttachment(){
        var _this =this
        let formData = new FormData();
        formData.append('ticket_id', this.ticket_id);
        formData.append('client_id', this.$session.get('client_id'));
        formData.append('attachment_data', this.attached_files);
        _this.loader = true;
        postToServer(this, config.EDC_TICKET_MANAGEMENT_SERVICE_URL + '/manage_attachments', formData
        ).then(response  => {
            this.colorValue = 'success'
            this.snackbartext = response
             _this.loader = false;
            this.attached_files = []
            _this.getAttachements()
            _this.getActivityLogs(_this.envIpJson)
        }).catch(ticketResponseError => {
                _this.loader = false;
                if(ticketResponseError){
                    this.snackbar = true
                    this.attached_files = [] 
                    this.colorValue = 'error'
                    this.snackbartext = ticketResponseError                  
                }
                else {     
                    this.snackbar = true 
                    this.attached_files = []            
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;                    
                }
            });
        },
        closePreview(){
            this.selected_image = ""
        },
        onCloseActionAttachment(attachment_action_type){
            if(attachment_action_type == "Add Attachment")
                this.$refs.multiple_file_input.click()
        },
        getTicketDetails(){
            var _this =this;
            var client_id = _this.$session.get('client_id');
            var ticket_id = _this.ticket_id
            var data = {"client_id": client_id,
                        "ticket_id": ticket_id,
                        'product_name': "TICKET_MANAGEMENT",
                        "is_ticket_closed":_this.ticket_details.is_closed_ticket?_this.ticket_details.is_closed_ticket:false}
            var get_ticket_detail_url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + '/get_ticket_details_by_id'
            _this.loader = true;
            postToServer(_this, get_ticket_detail_url, data).then(detailResponse=>{
                if(detailResponse){
                    _this.loader = false;
                    var tkt_detail_resp = detailResponse
                    this.ticket_title = tkt_detail_resp.title
                    this.ticket_queue = {"queue_id":tkt_detail_resp.queue_id,
                                    "queue_name":tkt_detail_resp.queue_name}
                    this.tz_created_date_time = moment(tkt_detail_resp.tz_created_date,this.dateFormat).format('ll') +' at '+moment(tkt_detail_resp.tz_created_date+' '+tkt_detail_resp.tz_created_time,this.dateFormat+" HH:mm:ss").tz(this.timeZoneFormat).format('LT') +' '+moment().tz(this.timeZoneFormat).format('z')
                    _this.old_ticket_status = tkt_detail_resp.status_details
                    // if(_this.show_dvLink){
                    //     let urlObj = _.find(_this.$session.get('applications_configuration'),['product_name','DATAVIEWER'])
                    //     let url = _this.$session.get('protocol')+'://'+urlObj.host_name+':'+urlObj.running_port
                    //     this.dv_link = url+tkt_detail_resp.master_details.url
                    // }
                    _this.ticket_status = tkt_detail_resp.status_details
                    _this.ticket_priority = tkt_detail_resp.priority_details
                    _this.old_priority = tkt_detail_resp.priority_details
                    _this.ticket_description = tkt_detail_resp.description
                    _this.old_description = tkt_detail_resp.description
                    _this.assignee = {'username':tkt_detail_resp.assigned_to,
                                    'full_name_with_user_name':tkt_detail_resp.assigned_to_fullname}
                    _this.old_assignee = tkt_detail_resp.assigned_to
                    this.assignee_fullname = tkt_detail_resp.assigned_to_fullname
                    if(tkt_detail_resp.tkt_wf_mapping.id){
                        this.has_wf = true
                        this.workflow_request_id = tkt_detail_resp.tkt_wf_mapping.workflow_request_id
                        this.getWorkflowStatus()
                    }
                }
            }).catch(error_response => {
                _this.loader = false;
                if(error_response){
                }
                else{
                }
            });
        },
        getUsersInQueue(){
            var queue_id_data = {"queue_id_list": [this.ticket_queue.queue_id]}
            var url_queue_user = config.EDC_QUEUE_MANAGEMENT_URL+"/get_users_from_queue"
            postToServer(this, url_queue_user, queue_id_data).then(response=>{
                if(response){
                _.forEach(response, (element) => {
                        this.assignee_list.push(element)
                    });
                }
            }).catch(queueErrorResponse=>{
            })
        },
        getAllTicketActions(){
            var action_data = {"client_id": this.$session.get('client_id')}
            var url_queue_user = config.EDC_TICKET_MANAGEMENT_SERVICE_URL+"/get_ticket_action"
            postToServer(this, url_queue_user, action_data).then(response=>{
                if(response){
                    _.forEach(response, (element) => {
                        if(element.status != "Open"){
                            this.ticket_action_list.push(element)
                        }
                    });
                }
            }).catch(actionErrorResponse=>{
            })
        },
        getAttachements(){
            var _this =this;
            var client_id = _this.$session.get('client_id');
            var ticket_id = _this.$route.params.ticket_id ? _this.$route.params.ticket_id : _this.ticket_id
            var data = {"client_id": client_id,
                        "ticket_id": ticket_id,
                        'product_name': "TICKET_MANAGEMENT",
                        "is_ticket_closed":_this.ticket_details.is_closed_ticket?_this.ticket_details.is_closed_ticket:false}
            var get_summary_url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + '/get_ticket_attachments'
            _this.loader = true
            postToServer(_this, get_summary_url, data).then(response => {
                    _this.attachementTableList.rows = []
                    this.attachementTableList.rows =response
                    _this.loader = false
                }).catch(error_response => {
                _this.loader = false
                    if(error_response){
                    _this.attachementTableList.rows = []
                }
                else{
                }
            }); 
        },
        preview_file(id,content_type){
            var _this = this
            let client_id = _this.$session.get('client_id')
            var content_type = content_type ? content_type : 'image/jpeg'
            if(content_type.includes('image')){
            this.imgFilePath = ''
            this.imgFileContentType  = ''
            this.imgFileContentType = 'image/jpeg'
            this.imgFilePath = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + "/view_file?attachment_id=" + id+"&view=Y"+"&client_id="+client_id+"&is_closed_ticket="+this.disable_for_closed_tkt
            this.selected_image = this.imgFilePath
            _this.pathUrl = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + "/view_file?attachment_id=" + id+"&is_closed_ticket="+this.disable_for_closed_tkt
            }
            else{
            _this.fileContentType =''
            _this.filePath =  ''
            _this.fileContentType =content_type
            _this.filePath =  config.EDC_TICKET_MANAGEMENT_SERVICE_URL + "/view_file?attachment_id=" + id+"&view=Y"+"&client_id="+client_id+"&is_closed_ticket="+this.disable_for_closed_tkt
            _this.pathUrl = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + "/view_file?attachment_id=" + id+"&is_closed_ticket="+this.disable_for_closed_tkt
                }
        },
        preview_file_from_activity(id,content_type){
            var _this = this
            let client_id = _this.$session.get('client_id')
            var content_type = content_type ? content_type : 'image/jpeg'
            if(content_type.includes('image')){
            this.imgFilePath = ''
            this.imgFileContentType  = ''
            this.imgFileContentType = 'image/jpeg'
            this.imgFilePath = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + "/view_file?attachment_id=" + id+"&view=Y"+"&client_id="+client_id+"&is_closed_ticket="+this.disable_for_closed_tkt
            this.selected_image = this.imgFilePath
            _this.pathUrl = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + "/view_file?attachment_id=" + id+"&is_closed_ticket="+this.disable_for_closed_tkt
            }
            else{
            _this.fileContentType =''
            _this.filePath =  ''
            _this.fileContentType =content_type
            _this.filePath =  config.EDC_TICKET_MANAGEMENT_SERVICE_URL + "/view_file?attachment_id=" + id+"&view=Y"+"&client_id="+client_id+"&is_closed_ticket="+this.disable_for_closed_tkt
            _this.pathUrl = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + "/view_file?attachment_id=" + id+"&is_closed_ticket="+this.disable_for_closed_tkt
            }
            this.overlay=true
        },
        removeAttachmentConfirmation(ticket_id, attachment_id, attachment_name){
            this.current_attachment = attachment_id
            this.current_attachment_name = attachment_name
            this.show_confirm_delete = true
        },
        getActivityLogs(envIpJson){
            var _this =this;
            var client_id = _this.$session.get('client_id');
            var ticket_id = _this.ticket_id
            var data = {"client_id": client_id,
                        "ticket_id": ticket_id,
                        'product_name': "TICKET_MANAGEMENT",
                        "page":envIpJson.page,
                        "page_size":envIpJson.page_size,
                        "sort":envIpJson.sort,
                        "filter":envIpJson.filter,}
            var get_summary_url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + '/get_ticket_logs'
            _this.loader = true
            postToServer(_this, get_summary_url, data).then(response=>{
                _this.loader = false
                if(response){
                    if(this.SelectedSort == 'Oldest')
                        _this.activityTableList.rows = _.sortBy(response.result,"actiontaken_date_time").reverse()
                    else
                        _this.activityTableList.rows = _.sortBy(response.result,"actiontaken_date_time")
                    _.forEach(_this.activityTableList.rows,function(obj){
                        if(obj.activity_name == 'ATTACHMENT ADDED'){
                            var description = obj.description.split(':')
                            obj.description = description[0]
                            obj.file_name = description[1]
                            if(_this.highlight_txt !=''){
                                obj.file_name = obj.file_name.replace(new RegExp(_this.highlight_txt, "gi"), (match) => `<mark  style="padding: 0px !important;background-color: #FEB72B !important; ">${match}</mark>`);
                                obj.description = obj.description.replace(new RegExp(_this.highlight_txt, "gi"), (match) => `<mark style="padding: 0px !important;background-color: #FEB72B !important;">${match}</mark>`);
                            }
                        }
                        else{
                            if(_this.highlight_txt !='')
                                obj.description = obj.description.replace(new RegExp(_this.highlight_txt, "gi"), (match) => `<mark  style="padding: 0px !important;background-color: #FEB72B !important;">${match}</mark>`);
                        } 
                    })
                    _this.highlight_txt = ''
                    _this.activityTableList.total_count = response.total
                }
            }).catch(error_response => {
                _this.loader = false
                if(error_response){
                    _this.activityTableList.rows = []
                    _this.activityTableList.total_count = 0
                }
                else{
                    _this.activityTableList.rows = []
                    _this.activityTableList.total_count = 0
                }
            })
        },
        updateTicket(){
        var _this =this
        let formData = new FormData();
            formData.append('ticket_id', this.ticket_id);
            formData.append('client_id', this.$session.get('client_id'));
            formData.append('title', this.ticket_title);
            formData.append('description', this.ticket_description);
            formData.append('priority_details',this.ticket_priority ? JSON.stringify(this.ticket_priority) : "")
            if(this.assignee){
              formData.append('assigned_to', this.assignee.username?this.assignee.username:'');
              formData.append('assigned_to_fullname', this.assignee_fullname?this.assignee_fullname:'');
            }
            if(this.ticket_queue){
                formData.append("queue_id", this.ticket_queue.queue_id)
                formData.append("queue_name", this.ticket_queue.queue_name)
            }
            formData.append('update_ticket_flag', true);
            _this.loader = true;
            postToServer(this, config.EDC_TICKET_MANAGEMENT_SERVICE_URL + '/update_ticket', formData
            ).then(response  => {
                _this.loader = false;
                this.$router.push({ name: 'TicketStatus'});
                // this.snackbar = true 
                // this.colorValue = 'success'
                // this.snackbartext = response
            }).catch(ticketResponseError => {
                _this.loader = false;
                if(ticketResponseError){
                    this.snackbar = true 
                    this.colorValue = 'error'
                    this.snackbartext = ticketResponseError                  
                }
                else {     
                    this.snackbar = true             
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;                    
                }
            });
        },
        addComment(){
            var _this = this
            var comment_data = {"client_id": this.$session.get('client_id'),
            "ticket_id": this.ticket_id,
            "comment": this.comment,
            "is_private": this.is_private}
            var add_comment_url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL+"/manage_comment"
            postToServer(_this, add_comment_url, comment_data).then(response=>{
                this.$router.push({ name: 'TicketStatus'});
            }).catch(error_response => {
            });
        },
        saveAttachment(){
            let _this = this

        },
        handleFileUpload(){
            debugger
            var new_file = new FileReader()
            new_file.onload = ()=>{
                this.files = new_file.result
            }
            this.files
        },
        onCloseAction(tkt_action){
            var _this = this
            var client_id = _this.$session.get('client_id');
            var data = {"client_id": client_id,
                    "ticket_id": this.ticket_id,
                    "status": tkt_action,
                    'product_name': "TICKET_MANAGEMENT"}
            var update_status_url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL+"/update_ticket_status"
            postToServer(_this, update_status_url, data).then(response => {
                    this.$router.push({ name: 'TicketStatus'});
                  }).catch(error_response => {
                    });
        },
        getTicketPriority(){
            var tkt_priority_data = {"client_id": this.$session.get('client_id')}
            var url_queue_user = config.EDC_TICKET_MANAGEMENT_SERVICE_URL+"/get_ticket_priority"
            postToServer(this, url_queue_user, tkt_priority_data).then(response=>{
                if(response){
                _.forEach(response, (element) => {
                        this.priority_list.push(element)
                    });
                }
            }).catch(statusErrorResponse=>{
            })
        },
        showHideControl(type,name){
            var _this = this
            if(name == "assignee"){
                this.show_assignee = type =="text"? true : false
                setTimeout(function () {
                    _this.$refs.assignee.focus();
                    }, 100);
            }  
            else if(name == "ticket_priority"){
                this.show_priority = type =="text"? true : false
                setTimeout(function () {
                    _this.$refs.ticket_priority.focus();
                    }, 100);
            }
        },
        dv_redirect_page(){
            window.open(this.dv_link, '_blank');
        },
        sortActivities(sort){
            if(sort){
                if(sort == "Latest"){
                    this.SelectedSort = sort
                    this.getActivityLogs(this.envIpJson)
                }
                if(sort == "Oldest"){
                    this.SelectedSort = sort
                    this.getActivityLogs(this.envIpJson)
                }
                if(sort == "User"){
                    var sortObj = this.envIpJson
                    sortObj.sort = [{"column_name":"action_by"}]
                    this.getActivityLogs(sortObj)
                }
                if(sort == "Action"){
                    var sortObj = this.envIpJson
                    sortObj.sort = [{"column_name":"activity_name"}]
                    this.getActivityLogs(sortObj)
                }
            }
            else{
                this.envIpJson.sort = []
                this.getActivityLogs(this.envIpJson)
            }
        },
        applySearch(){
            if(this.search_text){
                this.envIpJson.filter = []
                var filterObj1 = {column_name: "action_by",
                    e_parentheses: ")",
                    is_drv_table: false,
                    operator: "_cl_",
                    ref_row_id: "",
                    ref_step_id: "",
                    s_parentheses: "(",
                    table_name: "",
                    v_type1: "Value",
                    v_type2: "Value",
                    value1: this.search_text ? this.search_text : '' ,
                    operation:"or",
                    value2: ""
                }
                this.envIpJson.filter.push(filterObj1)
                var filterObj2 = {column_name: "activity_name",
                    e_parentheses: ")",
                    is_drv_table: false,
                    operator: "_cl_",
                    ref_row_id: "",
                    ref_step_id: "",
                    s_parentheses: "(",
                    table_name: "",
                    v_type1: "Value",
                    v_type2: "Value",
                    operation:"or",
                    value1: this.search_text ? this.search_text : '',
                    value2: ""
                }
                this.envIpJson.filter.push(filterObj2)
                var filterObj3 = {column_name: "description",
                    e_parentheses: ")",
                    is_drv_table: false,
                    operator: "_cl_",
                    ref_row_id: "",
                    ref_step_id: "",
                    s_parentheses: "(",
                    table_name: "",
                    v_type1: "Value",
                    v_type2: "Value",
                    operation:"or",
                    value1: this.search_text ? this.search_text : '',
                    value2: ""
                }
                this.envIpJson.filter.push(filterObj3)
                this.highlight_txt = this.search_text
            }
            else{
                this.envIpJson.filter = []
            }
            this.getActivityLogs(this.envIpJson)
        },
        raiseAlert(){
            this.envIpJson.filter = []
            this.getActivityLogs(this.envIpJson)
        },
        removeComment(){
            this.comment=''
        },
    }
}
</script>

<style scoped>
.border-change{
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 1px 0 rgba(0, 0, 0, 0.4);
}
.mycustomcls >>> textarea {
  padding-left: 6px !important;
  font-size: 14px !important;
}
.commentbox-alignment{
  padding-top:8px !important;
  padding-right:2% !important;
  padding-left:0px !important
}
.override-font{
  font-size: 12px !important;
}
.text {
  /* display: block !important;
  width: 100px !important; */
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}
</style>