<template>
    <div>
        <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow" align="center">
                    <v-col cols="4" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li>WorkSpaces</li>
                        </ul>
                    </v-col>
                    <v-col cols="8"></v-col>
                </v-row>
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>

        <edc-data-grid :dataList="tableList" @sortBy="sortBy" @onpagination="onPagination" @filterData="filterData" @onNew="onNew" @onDelete="onDelete" @onUpdate="updateRow" @onCellEvent="onCellEvent" @onDownload="onDownload"  @onEdit="onEdit" @onCopy="onCopy" @ondblClick="onEdit" @onShowResource="onShowResource"></edc-data-grid>
        
        <loading-panel :loader="loader"></loading-panel>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    </div>
</template>
<script>
import moment from 'moment'
import axios from 'axios';
import config from '../../config.json'
import { get as getToServer, deleteFromServer, post as postToServer } from './../../methods/serverCall.js';
import getUserRole from './../../methods/GetUserRole.js'
import {SERVER_ERROR} from '../../data/client_message.js'
import { GET_DATASOURCE_LIST, CHECK_USER_ROLE_PRESENT, EXPORT_DATASOURCE, DELETE_WORKSPACE, GET_ALL_WORKSPACE} from '../../data/url_constants.js';
import {CLIENT_SIDE, SERVER_SIDE} from './../../data/macros.js'
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import {COLOR_CODE,DATASOURCE_SERVICE_ID,CREATE_ACTION,UPDATE_ACTION,DELETE_ACTION,READ_ACTION} from './../../data/macros.js'
import _ from 'lodash';
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
// import dummyData from './tableData.js'
// import data from './metaData.js'
export default {
    name: 'datasourcelist',
    data: function () {
        return {
            userRole:{},
            buttonColor:COLOR_CODE,
            loader:false,
            testConnectionLoader:false,
            dummyData:'test-1',
            tableList: {
                headers: [
                    { text: 'ID', value: 'id', sortable: false, options:[]},
                    { text: 'Workspace', value: 'name', sortable: false, width: '25%', options:[], title: 'Workspace Name' },
                    { text: 'No. of Resources', value: 'resource_count', sortable: false, width: '50%', options:[], title: 'Resource Count'},
                    { text: 'Created By', value: 'created_by', sortable: false, width: '50%', options:[], title: 'Created By' },
                    { text: 'Created Date', value: 'timezone_aware_created_date', sortable: false, width: '50%', dataType:'date', options:[], title: 'Created Date'},
                    { text: 'Created Time', value: 'timezone_aware_created_time', sortable: false, width: '50%', dataType:'time', options:[], title: 'Created Time'},
                    { text: 'Modified By', value: 'modified_by', sortable: false, width: '50%', options:[], title: 'Modified By'},
                    { text: 'Modified Date', value: 'timezone_aware_modified_date', sortable: false, width: '50%', dataType:'date', options:[], title: 'Modified Date'},
                    { text: 'Modified Date', value: 'timezone_aware_modified_time', sortable: false, width: '50%', dataType:'time', options:[], title: 'Modified Time'}
                    
                ],
                // actions: ['edit','execute','publish'],
                actions:[],//if we need conditional action in row then provide key
                sorting_type: CLIENT_SIDE,
                filterType: CLIENT_SIDE,
                paginationType: CLIENT_SIDE,
                total_count: 0,
                rows: [],
                selected_rows: [],
                itemkey:'id'
            },
            //  flag:true,
             snackbar:false,
             snackbartext:'',
             colorValue:'success',
             snackbartimeout: Snackbar_Default_Timeout,
             showEnvType:false,
             valid:true,
             dbTypefltrAry:[],
             nameTypefltrAry:[],
            'envTypefltrAry':[],
            'chip1':true,
            'searchEnv':"",
            'showname':false,
            'showDBType':false,
             chips: [],
             items: [],
            sorting : config.SORTING_TYPE,
            envIpJson : {
                "filter": [],
                "sort": {
                    "column": "",
                    "type": ""
                },
                "page": 1,
                "page_size": 5
            },
            isloading:false,
            menu:false,
            edit:"",
            headers:  [                
                { text: 'Environment Type', value: 'type', sortable: false, width: '20%',options:[] },
                { text: 'Name', value: 'name', sortable: false, width: '30%' },
                { text: 'Database Type', value: 'environment_database_type', sortable: false, width: '20%',options:[] },
                { text: 'Database Location', value: 'databases_locations', sortable: false, width: '20%',options:[] },
            ],
            searchArray: {
                'Environment Type': false,
                'Name': false,
                'Database Type': false,
                'Database Location':false,
                'Action': false
            },
            perPage: 5,
            perPageArray: [5, 10, 50, 'all'],
            page: 1,
            totalPages:1,
            totalRecords:1,
            filterKey: "",
            sortingData: [],
            inputArray: {
                'Environment Type': [],
                'Name': [],
                'Database Type': [],
                'Database Location':[],
                'Action': []
            },
            sortKey: '',
            sortOrders: {
                'type': 1,
                'name': 1,
                'environment_database_type': 1,
                'databases_locations':1,
                'Action': 1
            }
        }
    },

    mounted() {
        // this.tableList.headers =this.headers; 
        /* if(this.tableList.paginationType == CLIENT_SIDE)
            this.envIpJson.page_size="*";
        this.getEnvironmentDetails();    //you are not authorised  
        this.get_environment_by_client_id(this.envIpJson); */
        // console.log("access_token"+this.$session.get('access_token'));
        this.userRole = this.$session.get('user_role_mapping');
        this.tableList.actions=[
            {'text':'new','key':"name", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,DATASOURCE_SERVICE_ID,CREATE_ACTION),index:1},
            {'text':'edit','key':"name", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,DATASOURCE_SERVICE_ID,UPDATE_ACTION),index:2},
            {'text':'copy','key':"name", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,DATASOURCE_SERVICE_ID,CREATE_ACTION),index:3},
            {'text':'delete','key':"name", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,DATASOURCE_SERVICE_ID,DELETE_ACTION),index:4},
            // {'text':'PingAgent', 'displayText':'Test Connection', 'key':'name', selectType:"single", role:true, index:5},
            
            {'text':'resource','displayText':'Show Resources', 'key':"name", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,DATASOURCE_SERVICE_ID,DELETE_ACTION),index:6}];
     this.getDataSourceList();
    },
    
    methods: {    
     onDownload(downloadParams, filterArray){
        let _this = this; 
        _this.loader=true;
        let filter=[];
        filterArray.map((obj, index)=>{
            obj.stringArray.map((stringObj, stringIndex)=>{
                let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
                    filter.push(cloneDeep(temp));
            })
        });
        let inputJson={
            "recordType":downloadParams.recordType,
            "row_option":downloadParams.recordType,
            "client_id": _this.$session.get('client_id'),
            "env_id": "",
            "file_format": downloadParams.fileType,
            "min_range": downloadParams.minRange|| null,
            "max_range": downloadParams.maxRange||null,
            "filter": filter
        }
        var url = config.DATA_SOURCE_URL+EXPORT_DATASOURCE;    
        postToServer(_this, url, inputJson).then(envirnentResponse => {
            var url = config.DATA_SOURCE_URL + "/static/" + envirnentResponse;
            window.open(url, '_blank');
            _this.loader=false;
        }).catch(objError => {
            _this.loader=false;
            if(objError){
                  this.loader = null
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = objError;
                }
                else {
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = SERVER_ERROR;
                }
          })
    },
    onNew(){
        // this.addDataSource();
        let _this = this;
        _this.$router.push('/ManageWorkSpace');

    },
     onCellEvent(record){ 
         this.onEdit(record);
     },
        getDataSourceList () {
        let _this = this;
        _this.loader = true;
        var data ={ client_id: this.$session.get('client_id') }
            postToServer(this, config.WORKSPACE_URL + GET_ALL_WORKSPACE , data
            ).then(response => {
            _this.loader = false;
            if(response){
                _this.tableList.rows = response;
                _this.tableList.total_count =response.length; 
            }else{
                _this.tableList.rows = [];
                _this.tableList.total_count = 0; 
            }
            }).catch(error_response => {
            _this.loader = false;
            if(error_response){
                this.loader = null 
                _this.tableList.rows = [];
                _this.tableList.total_count = 0;
                }
                else {
                _this.tableList.rows = [];
                _this.tableList.total_count = 0;
                }
            });
        },
        addDataSource(){
            let _this = this;
            _this.$router.push('/managedatasource');
        },
        
        submitDbType(){
            let _this = this;
            _this.dbTypefltrAry.push({text:cloneDeep(_this.searchEnv)});
            _this.searchEnv="";
        },
          remove (array, item) {
            array.splice(array.indexOf(item), 1)
            array = [...array]
          },
        getRecordPerPage(){
            let inputJson = this.envIpJson;
            inputJson.page_size = this.perPage;
        },
        removeBubbling(event) {
            event && event.stopPropagation();
        },
        numPages() {
            let _this = this;
            if(_this.totalRecords)
            _this.totalPages = Math.ceil(_this.totalRecords / _this.perPage);
        },
        setRecordPerPage() {
            let _this = this;
            // _this.page = 1;
            _this.numPages();
            // _this.changePage();
        },
        cancelRowData() {
            let _this = this;
            _this.edit = "";
        },
        saveRowData(index) {
            let _this = this;
            _this.edit = "";
            // _this.tableList.map(function (obj, index) {
            //     if (obj.edit == true)
            //         obj.edit = false;
            // });
        },
        deleteRowData(obj) {
            let _this = this;
            // let editObject = JSON.stringify(obj);
            // _this.tableList.map(function (currentObject, index) {
            //     let temp = JSON.stringify(currentObject);
            //     if (temp === editObject)
            //         _this.tableList.splice(index, 1);
            // });
            // 
            _this.deleteDataSource(obj.datasource_id);
            obj.menu = false;
        },
        changePage() {
            let _this = this,
                dataFrom = 0,
                dataTo = 5;
            _this.showDataAsPerPage(_this.sortingData);
        },
        showDataAsPerPage(data) {
            let _this = this,
                dataFrom = 0,
                dataTo = 5;
            if (_this.page < 1) _this.page = 1;
            if (_this.page > _this.numPages()) _this.page = numPages();
            if (_this.page <= 1) {
                if (data.length >= _this.perPage)
                    _this.tableList = data.slice(0, _this.perPage);
                else
                    _this.tableList = data.slice(0, data.length);
            } else {
                dataFrom = (_this.page - 1) * _this.perPage;
                dataTo = _this.perPage * _this.page;
                if (dataTo <= data.length)
                    _this.tableList = data.slice(dataFrom, dataTo);
                else
                    _this.tableList = data.slice(dataFrom, data.length);
            }
        },
        editrowData(id) {
            let _this = this;
            _this.edit = id;
        },
        columnFilter(elem, key) {
            let _this = this;
            // debugger;
            if (!elem)
                _this.setRecordPerPage()
            _this.tableList = _this.sortingData.filter(function (el) {
                return String(el[key]).toLowerCase().indexOf(String(elem).toLowerCase()) > -1;
            })
            // _this.showDataAsPerPage(_this.tableList);
        },
        onDelete:function(record){
            let _this = this;
            _this.deleteDataSource(record);
        },
        onCopy:function(record){
            let _this = this;
            this.$router.push({ name: 'ManageWorkSpace', params: { workspaceid: record.id, type:'copy' }})
        },
        onEdit:function(record){
            let _this = this;
            this.$router.push({ name: 'ManageWorkSpace', params: { workspaceid: record.id, type:'edit' }})
        },
        onShowResource(record){
            let _this = this;
            this.$router.push({ name: 'ResourceList', params: { workspaceid: record.id }})
        },
        sortBy: function (key, sortingType) {
            let _this = this;
            // debugger;
            if(!key || !sortingType)
                return 

            let inputJson = _this.envIpJson;
            inputJson.sort.column = key;
            inputJson.sort.type = sortingType;
            // this.get_environment_by_client_id(inputJson);
        },
        onPagination(page, perPage) {
            let _this = this;
            // debugger;
            let inputJson = _this.envIpJson;
            inputJson.page = page;
            inputJson.page_size = perPage;
            // this.get_environment_by_client_id(inputJson);
            //  _this.changePage();
        },
        filterData(filterArray){
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.filter=[];
            // debugger;
            filterArray.map((obj, index)=>{
                obj.stringArray.map((stringObj, stringIndex)=>{
                    let temp = {"column":obj.value,"value": stringObj};
                     inputJson.filter.push(cloneDeep(temp));
                })
            });
        },
        updateRow(record){
            console.log("Need to integrate update Service");
            var url = config.ENVIRONMENT_API_URL+UPDATE_ENVIRONMENT+record.id;
            let inputJson = record;
            debugger;
            postToServer(this, url, inputJson).then(envirnentResponse => {

            });
        },
        deleteDataSource(record) {
            // var id = _.find(this.tableList,['datasource_id',record.datasource_id]).id
            var data ={
                id:record.id
            }
            let url =  config.WORKSPACE_URL + DELETE_WORKSPACE;
             this.loader=true; 
            postToServer(this, url, data).then(response  => {
                    this.loader=false;
                    this.snackbar = true
                    this.colorValue = 'success'
                    this.snackbartext = response;
                    setTimeout(() => { this.getDataSourceList(); }, 2000);
                    
                }).catch(DatasourceError => {
                     this.loader=false;
                if(DatasourceError){
                    this.snackbar = true;
                    this.colorValue = 'error';
                    this.snackbartext = DatasourceError;
                    }
                    else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                    }
            }); 
            // postToServer(this, config.WORKSPACE_URL + DELETE_WORKSPACE, data).then(response => {
			// // var data = JSON.parse(response.bodyText)
            //     this.snackbar = true
            //     this.colorValue = 'success'
            //     this.snackbartext = response
            //     this.getDataSourceList()
            // }).catch(e => {
            //     this.snackbar = true
            //     this.colorValue = 'error'
            //     this.snackbartext = e;
            // })
		},
    }
}
</script>
<style scoped>
.smaller{
    width:20px;
    height:20px;
    font: 10px
}
/* tr:nth-child(even) {background-color: #E8E8E8;}
.blurOpacity{
    opacity: 0;
}
.showInput {
    visibility: inherit !important;
    width: 50% !important;
}

.srch-ip {
    width: 0px;
    background: white;
    color: black;
    float: right;
    transition: all 1s;
    visibility: hidden;
}

table {
    border: 2px solid grey;
    border-radius: 3px;
    background-color: #fff;
    cursor: pointer;
}

thead {
    line-height: 30px;
    transition: all 10s;
}

th {
    background-color: grey;
    color: rgba(255, 255, 255, 0.66);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

th.active {
    color: #fff;
}

th.active .arrow {
    opacity: 1;
}

.arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
}

.arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #fff;
}

.arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
}

.srch-icon {
    font-size: 16px;
    color: white;
    float: right;
    margin-top: 4%;
} */
</style>