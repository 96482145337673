/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Object_Unpublish': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<g _fill="none" _stroke="#000" stroke-linecap="round"><path pid="0" d="M11.24 43.244l9.526-5.617 9.523-5.617.063 8.206a17.202 17.271 0 008.305-4.276 17.202 17.271 0 004.604-17.887 17.202 17.271 0 01-1.32 23.814 17.202 17.271 0 01-11.544 4.64l.058 7.685-9.607-5.474z" stroke-width="3.25"/><path pid="1" d="M36.352 52.216H54.73c1.452 0 2.622-.892 2.622-2v-43c0-1.108-1.17-2-2.622-2H21.973c-1.452 0-2.621.892-2.621 2v24" stroke-width="2" stroke-linejoin="round"/><path pid="2" d="M50.352 13.216h-24M37.352 21.216h-11" stroke-width="2"/></g>'
  }
})
