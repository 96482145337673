/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'approval_reject_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" d="M2.814 5.8c.056.006.118 0 .188-.025.14-.052.242-.223.252-.36.02-.287.083-.816.21-1.108.013-.028.205-.281.38-.464.174-.183.336-.336.336-.336l.084-.077L4.276.6 4.028.583S3.488.551 2.87.595s-1.32.117-1.732.53l-.05.046-.019.066s-.173.61-.322 1.223c-.074.306-.143.612-.182.854-.019.12-.031.22-.03.316 0 .047-.002.092.02.16a.296.296 0 00.075.123.32.32 0 00.205.084h1.058c-.003.186-.013.37-.001.588.017.311.084.656.34.92l.007.008.008.006c.098.086.236.186.416.246.045.015.095.03.15.036zm-.058-.584c-.056-.032-.116-.063-.145-.086-.123-.13-.175-.32-.189-.573-.014-.255.014-.55 0-.837l-.012-.252H1.082c.004-.029.003-.038.008-.07.034-.214.099-.514.172-.813.133-.548.266-1.01.29-1.1.2-.17.796-.323 1.356-.363.445-.031.674-.016.836-.01l-.01 2.092c-.053.052-.13.123-.273.273-.186.195-.381.385-.482.617-.164.375-.197.806-.223 1.122zM5.76 3.734c.1 0 .179-.08.18-.178L5.95.75a.178.178 0 00-.18-.177H4.698c-.1 0-.179.08-.18.177l-.011 2.806c0 .099.08.178.179.178zm-.714-.268a.268.268 0 01-.269-.267c0-.147.12-.267.269-.267.148 0 .268.12.268.267 0 .147-.12.267-.268.267z"/>'
  }
})
