/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Menu_Download': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M-38.03 2.973A2 2 0 00-40 5v15h-10l6 8 6 8 6-8 6-8h-10V5a2 2 0 00-2.03-2.027zM30 8c-1.108 0-2 .892-2 2v11.973h-4.338c-1.56 0-2.538 1.645-1.758 2.96l4.17 7.026 4.17 7.027c.78 1.316 2.731 1.316 3.512 0l4.17-7.027 4.17-7.025c.78-1.316-.197-2.961-1.758-2.961H36V10c0-1.108-.892-2-2-2h-4zM12 36c-2.216 0-4 1.487-4 3.334v13.332C8 54.513 9.784 56 12 56h40c2.216 0 4-1.487 4-3.334V39.334C56 37.487 54.216 36 52 36h-8.766A12 12 0 0132 43.82 12 12 0 0120.76 36H12zm36.027 10.037a2 2 0 012 2 2 2 0 01-2 2 2 2 0 01-2-2 2 2 0 012-2zm-7.982.08a2 2 0 012 2 2 2 0 01-2 2 2 2 0 01-2-2 2 2 0 012-2z"/>'
  }
})
