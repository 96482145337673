/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Forget': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M8 56h8M36 56h20" _fill="none" _stroke="#000" stroke-width="2"/><path pid="1" d="M22.256 56.116a3.991 3.991 0 005.651-.256L36 47 18.28 30.814l-8.094 8.86a3.991 3.991 0 00.256 5.652zm15.029-12.005a1 1 0 001.413-.064l20.45-22.39c1.735-1.9 1.606-4.867-.291-6.6l-10.83-9.892c-1.898-1.733-4.865-1.593-6.6.306l-20.45 22.39a1 1 0 00.064 1.413zm.61-2.152L23.128 28.471 42.904 6.82a2.643 2.643 0 013.773-.177l10.83 9.892a2.643 2.643 0 01.165 3.773z"/>'
  }
})
