import {get as getToServer,post as postToServer } from './serverCall.js';
import config from '../config.json'
import {GET_ALL_ENV, GET_ALL_ENV_FOR_DV, ENVIRONMENTLIST_BY_CLIENT} from '../data/url_constants'
export default function(_this){
	return new Promise(function(resolve, reject) {
		getToServer(_this, config.ENVIRONMENT_API_URL + '/get_all_environment'+'/' + _this.$session.get('client_id'), true).then(response => {
			return resolve(response);
		},response => {
			return reject(response);
		})    
	})
}

export function getEnvironmentList(_this,is_from_obj_mgmnt_page,is_from_env_page, is_from_approval,use_service_token) {
	if(_this.$session.get('env_list') && !is_from_env_page)
    {
        _this.EnvironmentList = _this.$session.get('env_list')
        if(is_from_obj_mgmnt_page){
            // _this.EnvironmentList.push({'id': 'all_env', 'name': 'All Environments'})
            _this.EnvironmentList.push({'id': '0', 'name': 'Repository'})
        }
        if(is_from_approval){
            _this.EnvironmentList.push({'id': 'all_env', 'name': 'All Environments'})
        }
        return
    }
    getToServer(_this, config.ENVIRONMENT_API_URL + GET_ALL_ENV + _this.$session.get('client_id'),use_service_token).then(response => {
	if(response){
		_this.$session.set('env_list',response)
        if(is_from_env_page)
            return true
        _this.EnvironmentList = response
        if(is_from_obj_mgmnt_page){
            // _this.EnvironmentList.push({'id': 'all_env', 'name': 'All Environments'})
            _this.EnvironmentList.push({'id': '0', 'name': 'Repository'})
        }
        if(is_from_approval){
            _this.EnvironmentList.push({'id': 'all_env', 'name': 'All Environments'})
        }
        
            // console.log(this.EnvironmentList)
        } else {
        	_this.errorMsg = response
        }
    },response => {
        _this.ShowErrorMessage=true
        _this.ErrorMessage=response
    }).catch(EnvironmentError => {
    	if(EnvironmentError){
            // this[l]  = false
            _this.loader = null 
            _this.snackbar = true
            _this.colorValue = 'error'
            _this.snackbartext = EnvironmentError;
        }
        else {
        	_this.snackbar = true
        	_this.colorValue = 'error'
        	_this.snackbartext = SERVER_ERROR;
        }
    })
 }

 export function EnviromentChanged(that){
    try {
        if(!that.$session || !that.$session.get('selected_env') || !that.$session.get('selected_env').id)
            return false
        if(that.$session.get('selected_env').id === that.$session.get('last_selected_env_id'))
            return false
        if(!Number.isInteger(that.$session.get('selected_env').id) || parseInt(that.$session.get('selected_env').id) <= 0)
            return false
        
        if(config.DOMAIN_NAME){
          that.$cookies.set('last_selected_env_id',that.$session.get('selected_env').id,null,null,config.DOMAIN_NAME)
        }

        that.$session.set('last_selected_env_id',that.$session.get('selected_env').id)
        var data_to_send = {"env_id":that.$session.get('selected_env').id,
        "client_id":that.$session.get('client_id'),"user_id":that.$session.get('user_id'),
        "product_name": window.sessionStorage.getItem('product')}
        var url = config.USER_PROVISION_URL + '/update_user_env_details'
        postToServer(that,url,data_to_send,false,true).then(response=>{

        }).catch(Error=>{

        })
    }
    catch(err){

    }
 }

 export function getEnvListForDataviewer(_this,is_from_obj_mgmnt_page,is_from_env_page, is_from_approval,use_service_token) {
	if(_this.$session.get('env_list_dv') && !is_from_env_page)
    {
        _this.EnvironmentList = _this.$session.get('env_list_dv')
        if(is_from_obj_mgmnt_page){
            // _this.EnvironmentList.push({'id': 'all_env', 'name': 'All Environments'})
            _this.EnvironmentList.push({'id': '0', 'env_name': 'Repository'})
        }
        if(is_from_approval){
            _this.EnvironmentList.push({'id': 'all_env', 'env_name': 'All Environments'})
        }
        return
    }
    let data = {"client_id": _this.$session.get('client_id')}
    postToServer(_this, config.ENVIRONMENT_API_URL + GET_ALL_ENV_FOR_DV , data, use_service_token).then(response => {
	if(response){
		_this.$session.set('env_list_dv',response.result)
        if(is_from_env_page)
            return true
        _this.EnvironmentList = response.result
        if(is_from_obj_mgmnt_page){
            // _this.EnvironmentList.push({'id': 'all_env', 'name': 'All Environments'})
            _this.EnvironmentList.push({'id': '0', 'env_name': 'Repository'})
        }
        if(is_from_approval){
            _this.EnvironmentList.push({'id': 'all_env', 'env_name': 'All Environments'})
        }
        
            // console.log(this.EnvironmentList)
        } else {
        	_this.errorMsg = response
        }
    },response => {
        _this.ShowErrorMessage=true
        _this.ErrorMessage=response
    }).catch(EnvironmentError => {
    	if(EnvironmentError){
            // this[l]  = false
            _this.loader = null 
            _this.snackbar = true
            _this.colorValue = 'error'
            _this.snackbartext = EnvironmentError;
        }
        else {
        	_this.snackbar = true
        	_this.colorValue = 'error'
        	_this.snackbartext = SERVER_ERROR;
        }
    })
 }