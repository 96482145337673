/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'History': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M32 16v16l12 12" _fill="none" _stroke="#000" stroke-width="2" stroke-linecap="round"/><path pid="1" d="M20.707 24.113l-8.353 3.944L4 32l.762-9.206.761-9.206 7.592 5.262z"/><path pid="2" d="M16.611 49A22.957 22.957 0 0032 54.957c12.679 0 22.957-10.278 22.957-22.957S44.679 9.043 32 9.043c-11.127.011-20.644 8-22.582 18.957" _fill="none" _stroke="#000" stroke-width="2.087" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
