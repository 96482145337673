<template>
    <v-container>
        <loading-panel :loader="loader"></loading-panel>
    </v-container>
</template>
<script>
import { get as getToServer } from '../../methods/serverCall.js';
import config from '../../config.json'

export default {
    name:'TicketTijebVerification',
    data(){
        return {
            loader:false
        }
    },
    mounted(){
        this.validateToken()
    },
    methods:{
        validateToken(){
				var _this = this
				var token = this.$route.query.token
                this.loader = true
				getToServer(this, config.WORKFLOW_PRODUCT_URL + '/reviewform/' + token).then(response => {
                        this.loader =false
                        this.$router.push({ name: 'TicketDetailsForDv', params: {ticket_id: response.ticket_id,'is_closed_ticket':false,
                            'node_id':response.node_id,'ticket_id':response.ticket_id,'workflow_step_master_id':response.workflow_step_master_id,
                            'workflow_step_master_id':response.workflow_step_master_id,'workflow_master_id':response.workflow_master_id,'workflow_request_id':response.workflow_request_id,
                            'step_code':response.step_code,'token_data':response}});
					}).catch(error_response => {
                        alert('failed to load token details')
					})
				},
    }
}

</script>