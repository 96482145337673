<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>Ticket Configuration</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <v-container>
      <v-form ref="form" v-model="valid">
        <v-card elevation-1 min-width="auto">
          <v-container class="EDC-Container">
            <v-col cols="12" class="EDC-Col EDC-ColsSpacing">
              <v-row class="EDC-Row EDC-RowsSpacing" style="padding-top:8px !important">
               <!-- <v-col cols="1" class="EDC-Col EDC-ColsSpacing">
               </v-col> -->
            <v-col cols="2" class="EDC-Col EDC-ColsSpacing">
              <v-row class="EDC-Row" style="padding-top:20px !important; padding-right:75px !important">
                <!-- <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field label="Attachments Folder Name" v-model="ATTACHMENTS_FOLDER_NAME" :rules="attachmentFolderNameRules" dense></v-text-field>
                </v-col> -->
                  <v-text-field  label="Auto Closed Time (in Hours)" class="Align-Integer" :readonly="readonly" v-model="TICKET_CLOSED_TIME" :rules="ticketCloseTimeRule" autocomplete="off" dense></v-text-field>
                  
              </v-row>

              <v-row class="EDC-Row EDC-RowsSpacing" style="padding-top:20px !important;padding-right:75px !important">  
                <v-text-field label="Inactive Reminder (in Hours)"  class="Align-Integer" :readonly="readonly" v-model="TICKET_INACTIVE_REMAINDER_TIME " :rules="TicketReminderRule" autocomplete="off" dense></v-text-field>              
                   
                <!-- <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <input type="file" ref="folder_select" id="folder_select_2" webkitdirectory  directory multiple/>
                </v-col> -->
              </v-row>

              <v-row class="EDC-Row EDC-RowsSpacing" style="padding-top:20px !important;padding-right:75px !important">
                  <v-autocomplete  style="float:left" v-model="priority" hide-details clearable :items="priority_list" item-text="name" dense  return-object  label="Select Default Priority"></v-autocomplete>
                  
              </v-row>

              <v-row class="EDC-Row EDC-RowsSpacing" style="padding-top:40px !important; padding-right:75px !important">
                  <v-autocomplete  style="float:left" v-model="action" hide-details clearable :items="action_list" item-text="name" dense  return-object  label="Select Default Action"></v-autocomplete>
                  
              </v-row>

              <v-row class="EDC-Row EDC-RowsSpacing" style="padding-top:20px !important;padding-top:30px !important">
                <v-col cols="11" class="EDC-Col">
                 <v-text-field label="File Path" :readonly="readonly" v-model="MASTER_FILE_PATH"  :rules="masterFilePathRule" autocomplete="off" dense></v-text-field>
                </v-col>
                 <v-col cols="1" class="EDC-Col EDC-ColsSpacing">
                   <v-icon title="validate"  @click="validatePath">check_circle_outline</v-icon>
                 </v-col>
              </v-row>
              <v-row class="EDC-Row EDC-RowsSpacing" style="padding-top:20px !important;padding-right:75px !important">  
                    <v-text-field label="Name Schema" v-model="nameSchema" :disabled="readonly" autocomplete="off" dense></v-text-field>
              </v-row>
             <v-row class="EDC-Row EDC-RowsSpacing" style="padding-top:20px !important;padding-right:75px !important">  
                    <v-text-field label="Ticket ID Size" v-model="numberSize" :disabled="readonly"  autocomplete="off" dense></v-text-field>
             </v-row>
              <v-row class="EDC-Row EDC-RowsSpacing" style="padding-top:20px !important;padding-right:75px !important">  
                    <v-text-field label="Example" v-model="createIDExample" readonly  autocomplete="off" dense></v-text-field>
              </v-row>
            </v-col>
            <v-col cols="12" class="EDC-Col EDC-ColsSpacing">
            <v-row no-gutters class="EDC-Row EDC-RowsSpacing" style="padding-top:20px !important" justify="start">
                <vc-button itemText="Save" :disabled="readonly"  @click.native="submit"></vc-button>
                <vc-button itemText="Cancel" @click.native="$router.go(-1)"></vc-button>
              </v-row>
            </v-col>
              </v-row>
              
            </v-col>
          </v-container>
        </v-card>
      </v-form>
    </v-container>
    <loading-panel :loader="loader"></loading-panel>
    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    </div>
</template>

<script>
import config from '../../config.json'
import Breadcrumb from "../Breadcrumbs.vue"
import {
  post as postToServer
} from './../../methods/serverCall.js';
import {
  get as getToServer
} from './../../methods/serverCall.js';
import {
  GET_USER, UPDATE_PROFILE,UPDATE_TOKEN_DETAILS, GET_USER_ROLE, GET_TICKET_PRIORITY,GET_TICKET_MASTER_SEQ_DATA
} from '../../data/url_constants.js'
import {
  SERVER_ERROR
} from '../../data/client_message.js'
import {
  COLOR_CODE,
  BTN_COLOR,
  UPDATE_ACTION,
  TICKET_MANAGEMENT_SERVICE,
  UPDATE_CONFIGURATION,
  READ_CONFIGURATION,
} from '../../data/macros.js'
import {
  DATE_TIME_FORMAT,GetUIDateFormat
} from '@/data/manage_account_constants.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import {getUserRole, getTicketRole} from './../../methods/GetUserRole.js'
import {mapActions} from 'vuex'
import {getActionByGroup} from './../../methods/TicketActionsAndQueueList.js'

var moment = require("moment-timezone");
export default {
  name: 'UserProfile',
  mounted() {
    // var fileuploader = document.getElementById('folder_select_2')
    // fileuploader.addEventListener('onchange',handlefileupload)
    // function handlefileupload(e){
    //     e.preventDefault()
    // }
    this.getPriorityList()
    this.getTicketActionList()
    getActionByGroup(this) 
    // this.getTicketMasterSeqData()
    this.userRole = this.$session.get('user_role_mapping');
    this.getConfigurationData()
    this.applySecurity()
    if(!this.userRole.is_superadmin && !getUserRole(this.userRole,TICKET_MANAGEMENT_SERVICE,UPDATE_ACTION))
      this.readonly = true

  },
  computed:{
        createIDExample(){
            if(!this.nameSchema  || !this.numberSize)
                return ''
            // if (!this.seqNumber)
            //     this.seqNumber = "0"
            let numberId = this.nameSchema + this.seqNumber.toString().padStart(this.numberSize, '0')
            return numberId
        }
    },
  data() {
    return {
      theme_name: true,
      userRole:{},
      outlineColor: BTN_COLOR,
      colorCode: COLOR_CODE,
      testConnectionLoader: false,
      nameSchema: "",
      seqNumber: 0,
      numberSize: "",
      valid: false,
      snackbar: false,
      snackbartext: '',
      readonly:false,
      snackbartimeout: Snackbar_Default_Timeout,
      colorValue: 'error',
      loader: false,
      loading: false,
      TICKET_INACTIVE_REMAINDER_TIME:'',
      ATTACHMENTS_FOLDER_NAME:'',
      TICKET_CLOSED_TIME:'',
      MASTER_FILE_PATH:'',
      EXPIRY_TYPE_LIST:['minutes','hours','days'],
      EXPIRY_TYPE:'hours',
      priority_list:[],
      priority:{},
      action_list:[],
      actions_by_group:[],
      action:{},
      attachmentFolderNameRules: [v => !!v || 'Attachments Folder Name is required.'],
      TicketReminderRule: [v => /^((\d+(\.\d *)?)|((\d*\.)?\d+))$/.test(v) || 'Ticket Inactive Reminder should be integer.'],
      ticketCloseTimeRule: [v => /^((\d+(\.\d *)?)|((\d*\.)?\d+))$/.test(v) ||  'Ticket Close Time should be integer.'],
      masterFilePathRule: [v => !!v || 'Master File Path is required.'],
    }
  },
  components: {
    
  },
  props: {
    msg: String
  },
  methods: {
    ...mapActions(['setthemeInfo']),
    themeChanged(item){
      if(item.value)
      this.setthemeInfo('light')
      else
        this.setthemeInfo('dark')
    },
    getConfigurationData() {
      var _this = this
      let url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + '/get_configuration_data';
      var data = {'client_id': this.$session.get('client_id'), 
                  'product_name': "TICKET_MANAGEMENT" }
      
        postToServer(this, url, data).then(response  => {
        _this.TICKET_CLOSED_TIME = response.ticket_configuration.auto_closed_time
        _this.TICKET_INACTIVE_REMAINDER_TIME = response.ticket_configuration.inactive_remainder_time
        _this.MASTER_FILE_PATH = response.ticket_configuration.master_file_path
        let priority_name = response.ticket_configuration.default_priority
          _this.priority = _.find(_this.priority_list, function(obj) {
            if (obj.name == priority_name) {
                return true;
            }
        });
         let action_name = response.ticket_configuration.default_action
          _this.action = _.find(_this.action_list, function(obj) {
            if (obj.name == action_name) {
                return true;
            }
        });
        _this.nameSchema = response.ticket_id_conf_response.naming_schema
        _this.seqNumber = response.ticket_id_conf_response.sequence_number
        _this.numberSize = response.ticket_id_conf_response.number_size
      }).catch(e => {
        this.snackbar = true
                this.colorValue = "error"
                this.snackbartext = e
      })
    },
    validatePath() {
      var _this = this
      let url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + '/validate_master_file_path';
      var data = { 'master_file_path': this.MASTER_FILE_PATH,
                  'product_name': "TICKET_MANAGEMENT" }
        postToServer(this, url, data).then(response  => {
        this.snackbar = true
                this.colorValue = "success"
                this.snackbartext = response
      }).catch(e => {
        this.snackbar = true
                this.colorValue = "error"
                this.snackbartext = e
      })
    },
    onCancel() {
      this.$router.push("/dashboard")
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.updateConfiguration();
      }
    },
    updateConfiguration() {
      var _this = this
        var ticket_configuration_data = {'client_id': this.$session.get('client_id'), 
                  'auto_closed_time':this.TICKET_CLOSED_TIME,
                  'inactive_remainder_time':this.TICKET_INACTIVE_REMAINDER_TIME,
                  'default_priority':this.priority ? this.priority.name : '',
                  'default_action': this.action ? this.action.name : '',
                  'master_file_path':this.MASTER_FILE_PATH,}
        if(!this.numberSize || !this.seqNumber || !this.nameSchema){
                alert('Please input all data')
                return
            }
        var ticket_id_conf_data = {"table_name": "edc_tkt_master",
                        "client_id": _this.$session.get('client_id'),
                        "naming_schema": _this.nameSchema,
                        "sequence_number": _this.seqNumber,
                        "number_size": _this.numberSize}
        var data = {
          "ticket_configuration_data":ticket_configuration_data,
          "ticket_id_conf_data":ticket_id_conf_data
        }
        let url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + "/update_configuration_data";
        postToServer(this, url, data
        ).then(response  => {
            var _this =this
            this.snackbar = true
            this.colorValue = "success"
            this.snackbartext = "Configuration saved successfully"
            console.log('success')
            setTimeout(() => {
              _this.$router.push("/dashboard")
            }, 1200);
      }).catch(e => {
            this.snackbar = true
            this.colorValue = "error"
            this.snackbartext = e
      })
    },
    handlerFolder(event){
        event.preventDefault()
      alert('Hi')      
              this.attached_files = this.$refs.folder_select.files[0];
            //   this.addMultipleAttachment()
          },
    getPriorityList(){
        var _this =this;
        var client_id = _this.$session.get('client_id');
        var data = {"client_id": client_id,
        'product_name': window.sessionStorage.getItem('product')}
        postToServer(_this, config.EDC_TICKET_MANAGEMENT_SERVICE_URL + GET_TICKET_PRIORITY, data
        ).then(response  => {
            var data =response
            this.priority_list = data
        }).catch(ticketResponseError => {
                this.loader = false;
                if(ticketResponseError){
                    this.colorValue = 'error'
                    this.snackbartext = ticketResponseError;                    
                }
                else {                    
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;                    
                }
            });
        },
    getTicketActionList(){
        var _this =this;
        var client_id = _this.$session.get('client_id');
        var data = {"client_id": client_id,
        'product_name': window.sessionStorage.getItem('product')}
        postToServer(_this, config.EDC_TICKET_MANAGEMENT_SERVICE_URL + "/get_ticket_action", data
        ).then(response  => {
            var data =response
            this.action_list = data
        }).catch(ticketResponseError => {
                this.loader = false;
                if(ticketResponseError){
                    this.colorValue = 'error'
                    this.snackbartext = ticketResponseError;                    
                }
                else {                    
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;                    
                }
            });
        },
  applySecurity(){
    var _this = this
    if(!_this.userRole.is_superadmin && !getTicketRole(_this.actions_by_group,TICKET_MANAGEMENT_SERVICE,UPDATE_CONFIGURATION)){
        this.readonly = true
    }
    
  },
  // getTicketMasterSeqData(){
  //           var _this = this
  //           _this.loader = true
  //           postToServer(_this, config.EDC_TICKET_MANAGEMENT_SERVICE_URL+GET_TICKET_MASTER_SEQ_DATA).then(response =>{
  //               if(response){
  //                   _this.loader = false
  //                   _this.nameSchema = response.naming_schema
  //                   _this.seqNumber = response.sequence_number
  //                   _this.numberSize = response.number_size
  //               }
  //           }).catch(error_response =>{
  //               alert(error_response)
  //           })
  //       },
  }
}
</script>

<style scoped>
  .label-margin label{
    margin-top:5%;
  }
  .Align-Integer>>> input{
    text-align: right;
  }
</style>
