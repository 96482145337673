/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Database_View': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M8 8c-2.216 0-4 1.823-4 4.086v.363c-.01.123-.021.246-.021.371v36.05C2.764 50.093 2 51 2 51s8.266 10.19 17.207 10.19c6.177 0 10.52-1.95 14.182-5.169h21.123c2.931 0 5.467-2.066 5.467-4.804V24H60V12.086C60 9.823 58.216 8 56 8H8zM5.977 24H18v9H5.977v-9zM20 24h11v9H20v-9zm13 0h11v9H33v-9zm13 0h11.98v9H46v-9zM5.977 35H18v5.879c-2.244.223-4.42 1.04-6.41 2.121H5.977v-8zM20 35h11v8h-1.98c-2.542-1.3-5.477-2.064-9.02-2.166V35zm13 0h11v8H33v-8zm13 0h11.98v8H46v-8zm-26 9.547a6.135 6.453 0 01.613.033 6.135 6.453 0 011.627.42 6.135 6.453 0 013.895 6 6.135 6.453 0 01-.719 3.023 6.135 6.453 0 01-.309.543 6.135 6.453 0 01-.005.012 6.135 6.453 0 01-.002.002 6.135 6.453 0 01-.327.463 6.135 6.453 0 01-.037.053 6.135 6.453 0 01-.006.008 6.135 6.453 0 01-.316.365 6.135 6.453 0 01-.105.119 6.135 6.453 0 01-.3.285 6.135 6.453 0 01-.16.148A6.135 6.453 0 0120 57.453a6.135 6.453 0 01-3.846-1.432 6.135 6.453 0 01-.457-.43 6.135 6.453 0 01-.402-.458 6.135 6.453 0 01-.018-.02 6.135 6.453 0 01-.328-.46 6.135 6.453 0 01-.045-.069 6.135 6.453 0 01-.258-.451 6.135 6.453 0 01-.78-3.133 6.135 6.453 0 013.882-6 6.135 6.453 0 01.58-.207 6.135 6.453 0 01.598-.145 6.135 6.453 0 01.594-.08 6.135 6.453 0 01.015-.002 6.135 6.453 0 01.002 0 6.135 6.453 0 01.463-.02zM5.977 45h2.46a35.727 35.727 0 00-2.46 1.977V45zM33 45h11v9.023h-8.564A43.903 43.903 0 0038 51c-1.584-2.013-3.21-3.81-5-5.318V45zm13 0h11.98v6.217c0 1.479-1.45 2.806-3.468 2.806H46V45zm-24.83 2.127a2 2.182 0 00-2 2.182 2 2.182 0 002 2.181 2 2.182 0 002-2.181 2 2.182 0 00-2-2.182z"/>'
  }
})
