/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Download_CSV': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M54.797 43.964c0 6.083-4.931 11.014-11.014 11.014-6.083 0-11.014-4.93-11.014-11.014 0-6.082 4.931-11.014 11.014-11.014 6.083 0 11.014 4.932 11.014 11.014zM44 55H12.782a3.902 3.902 0 01-3.902-3.919V11.896c0-2.17 1.74-3.918 3.902-3.918H44a3.902 3.902 0 013.902 3.918V33.64" _fill="none" _stroke="#000" stroke-width="2"/><path pid="1" d="M44 52l3-4 3-4H38l3 4z"/><path pid="2" d="M45 48V36h-2v12z"/><text style="line-height:1.25" x="14.026" y="25.145" font-weight="400" font-size="11.721" font-family="sans-serif" letter-spacing="1.5" word-spacing="0" _stroke="#000"><tspan x="14.026" y="25.145">CSV</tspan></text>'
  }
})
