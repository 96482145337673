/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Business Object Sync': {
    width: 201.063,
    height: 201.19,
    viewBox: '0 0 53.198 53.232',
    data: '<g transform="translate(-34.264 -16.787)"><rect pid="0" width="23.652" height="9.556" x="46.305" y="24.718" ry="1.559" _stroke="#000" stroke-width=".265"/><rect pid="1" width="7.627" height="5.446" x="42.356" y="56.553" ry=".787" _stroke="#000" stroke-width=".31"/><rect pid="2" y="56.459" x="55.179" height="5.446" width="7.627" ry=".787" _stroke="#000" stroke-width=".31"/><rect pid="3" width="7.627" height="5.446" x="71.886" y="40.353" ry=".787" _stroke="#000" stroke-width=".31"/><path pid="4" d="M51.75 34.275l-.062 12.077M58.994 56.324l.05-9.998-12.56-.056v10.303" _fill="none" _stroke="#000" stroke-width="1.323"/><path pid="5" d="M69.902 32.7v-4.725a2.362 2.362 0 00-2.362 2.363 2.362 2.362 0 002.362 2.362zM51.783 31.803a2.362 2.362 0 00-2.363 2.362h4.725a2.362 2.362 0 00-2.362-2.362z" _fill="#fff"/><path pid="6" d="M75.656 40.245l.011-10.201-5.765.016" _fill="none" _stroke="#000" stroke-width="1.201" stroke-linejoin="round"/></g><path pid="7" d="M41.571 43.413a4.85 4.85 0 01-7.158-1.75 4.774 4.774 0 01-.457-2.807" _fill="none" _stroke="#000" stroke-width="1.323" stroke-linejoin="round"/><path pid="8" d="M31.734 40.533l1.344-1.668 1.344-1.668.773 1.998.773 1.997-2.117-.33z"/><g><path pid="9" d="M35.775 35.775a4.85 4.85 0 013.076-1.1 4.85 4.85 0 014.849 4.849h0a4.85 4.85 0 01-.071.826" _fill="none" _stroke="#000" stroke-width="1.323" stroke-linejoin="round"/><path pid="10" d="M41.672 37.882l.68 2.052.68 2.052 1.436-1.615 1.437-1.614-2.116-.437z"/></g>'
  }
})
