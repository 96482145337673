<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="4" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
                <li>
              <router-link to="/usergrouplist">User Gorup List</router-link>
              </li>
              <li>
              User Group Management
              </li>
            </ul>
          </v-col>
          <v-col cols="8"></v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <v-container>
      <v-card elevation-1>
        <v-col cols="12" class="EDC-Col">
          <v-row no-gutters align="start" class="EDC-Row EDC-TopRow">
            <v-col cols="6" class="EDC-Col EDC-ColsSpacing">
              <v-text-field label="User Group NAme" v-model="group_name" class="EDC-UIComponent" hide-details autocomplete="off" dense></v-text-field>
            </v-col>
            <!-- <v-col cols="4" class="EDC-Col EDC-ColsSpacing EDC-ColDescription">
              <v-textarea v-model="description" label="Description" value rows="1" class="EDC-UIComponent" hide-details dense/>
            </v-col> -->
            <!-- <v-col cols="2" class="EDC-Col EDC-ColsSpacing EDC-ColDescription">
              <vc-button itemText="Save" @click.native="saveUserGroup"></vc-button>
            <vc-button itemText="Cancel" @click.native="cancel"></vc-button>
            </v-col> -->
          </v-row>

          <v-divider class="EDC-UIComponent"/>

          <v-row no-gutters class="EDC-Row EDC-GridRow">
            <v-col class="EDC-Col" cols="12">
              <edc-data-grid :dataList="tableList" @onDelete="onDelete" @onEdit="onEdit" @onNew="onNew" @onSave="onSave" @onCancel="onCancel" @onValueUpdate="onValueUpdate" @performTableSearch="performTableSearch"></edc-data-grid>
            </v-col>
            
          </v-row>

          <v-row no-gutters class="EDC-Row EDC-ButtonRow" justify="end">
            <vc-button itemText="Save" @click.native="saveUserGroup"></vc-button>
            <vc-button itemText="Cancel" @click.native="cancel"></vc-button>
          </v-row>

        </v-col>
      </v-card>
    </v-container>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
    <loading-panel :loader="loader"></loading-panel>
  </div>
</template>
<script>
import { post as postToServer } from "./../../methods/serverCall.js";
import { get as getToServer } from "./../../methods/serverCall.js";
import config from "../../config.json";
import { SERVER_ERROR } from "../../data/client_message.js";
import {
  SERVICE_ACTION_DETAILS,
  SET_ROLE_ACTION
} from "../../data/url_constants.js";
import {
		getTableList,
		getTableColumns,
    getTablesByDVEnv,
	} from "../../methods/agent_api_call.js";
import { COLOR_CODE, BTN_COLOR ,CLIENT_SIDE} from "@/data/macros.js";
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import getUserRole from '../../methods/GetUserRole.js'
import { USER_PROVISIONING_SERVICE_ID } from '../../data/macros.js';

export default {
  data: function () {
    return {
      outlineColor: BTN_COLOR,
      colorCode: COLOR_CODE,
      description:'',
      administratorServiceList:[],
      service_ids_to_exculde:['scheduler','policy_service','engine_service','job_calendar','job_plan_service','process_approval_service','coordinator_service'],
      snackbar: false,
      snackbartext: "",
      colorValue: "error",
      snackbartimeout: Snackbar_Default_Timeout,
      loader: null,
      loading: false,
      selectAll: false,
      action_type: "",
      isDisabled:false,
      connection_str: "",
			schema: "",
			db_type: "",
      datasource_error: "",
			datasource_timer: "",
			datasource_timer_sec: 10000,
      group_name: "Untitled User Group",
      // object_type_list:[{name:"BUSINESS VIEW",value:"BV"},{name:"BUSINESS OBJECT",value:"BO"},{name:"DATAMAP",value:"DM"},{name:"DATABASE OBJECTS",value:"T"}],
      object_type_list:[],
      action_type_list:[],
      envIpJson :{
          "filter": [],
          "sort": [{
              "column_name": "",
              "type": ""
          }],
          "page": 1,
          "page_size": 10000
      },
      userRole:{},
      tableList: {
                headers: [
                { text: 'User', value: 'full_name_with_user_name', width:"100%", option:[], 'item_text':'full_name_with_user_name', 'item_value':'full_name_with_user_name',useAutoComplete:true},
                    // { text: 'Created By', value: 'created_by', width:"20%"},
                    // { text: 'Created Date', value: 'created_date', width:"20%",}, 
                    // { text: 'Created Time', value: 'created_time', width:"60%"}
                    ], 
                actions: [],//if we need conditional action in row then provide key
                sorting_type: CLIENT_SIDE,
                filterType: CLIENT_SIDE,
                paginationType: CLIENT_SIDE,
                total_count: 0,
                is_row_edit:true,
                rows: [],
                itemkey:'id',
                selected_rows: [],
                syncHeaderScroll:false,
                hideExport:true,
                hideFilter:true,
                hideShowColumns:true,
                tableLoading:false,
                disableDefaltSorting:true,
                is_grid_inline_edit:true
            },
    };
  },
  computed:{
    isForcopy(){
      if(this.$route.params && this.$route.params.copy_env)
				return true
      else
        return false
			},
  },
  mounted() {
    // this.tableList =_.cloneDeep(adminList)
    this.GetUserList()
    this.userRole = this.$session.get('user_role_mapping');
    this.tableList.headers[0].option = []
     this.tableList.actions = [
            {'text':'edit','key':"id", selectType:"single",role:this.userRole.is_superadmin ||getUserRole(this.userRole,USER_PROVISIONING_SERVICE_ID,UPDATE_ACTION), index: 1},
            {'text':'new','key':"id", role:this.userRole.is_superadmin || getUserRole(this.userRole,USER_PROVISIONING_SERVICE_ID,CREATE_ACTION), index: 2},
            {'text':"save","key":"is_row_editable", selectType:"single", index: 3},
            {'text':"cancel","key":"is_row_editable", selectType:"single", index: 4},
            {'text':'delete','key':"id", selectType:"single",role:this.userRole.is_superadmin || getUserRole(this.userRole,USER_PROVISIONING_SERVICE_ID,UPDATE_ACTION), index: 5}]
    if(this.$route.params && this.$route.params.group_id){
      this.getUserGrouDetails(this.$route.params.group_id)
    }
    if(this.$route.params && this.$route.params.group_id){
      this.group_name = "Copy of "+ this.group_name
    }
   
  },
  methods: {
    cancel () {
     this.$router.push('/usergrouplist');
  
    },
    onSave(record){
          var _this = this
           let obj = _.find(this.tableList.rows,["is_row_editable",true])
            if(!record.full_name_with_user_name)
            {
              this.snackbar = true;
              this.colorValue = "error";
              this.snackbartext = "Fill all the columns first"
              return
            }
            else{
                if(obj){
                    let user_details = _.find(this.tableList.headers[0].option,["full_name_with_user_name",record.full_name_with_user_name])
                    obj.user_id = user_details.user_id ? user_details.user_id : ''
                    obj.username = user_details.username ? user_details.username : ''
                    if (_.find(_this.tableList.rows, function(o) { return o.user_id == obj.user_id && typeof o.is_row_editable == "undefined"})){
                      _this.snackbar = true;
                      _this.colorValue = "error";
                      _this.snackbartext = "User "+record.full_name_with_user_name +" exists in group"
                      return
 
                    }
                    else
                      delete obj.is_row_editable;
                }
            }
        },
      onDelete(record){
        let index =   _.findIndex(this.tableList.rows,["full_name_with_user_name",record.full_name_with_user_name])
        this.tableList.rows.splice(index,1)
      },
      getUserGrouDetails(group_id){
            var _this= this
            var client_id = this.$session.get('client_id');
              var data = {"group_id": group_id,
                "client_id": client_id,
                "product_name" :window.sessionStorage.getItem('product')}
              var url = config.USER_PROVISION_URL + "/get_group_details_by_id";
            postToServer(this, url, data)
                .then(response => {
                  this.group_id = response.id
                  this.group_name = response.group_name
                  this.tableList.rows = response.user_list
                  //As we are saving only id in user list recreating user list wih display name
                //    _.forEach(this.tableList.rows,function(obj){
                //    let user = _.find(_this.tableList.headers[0].option,["user_id",obj.user_id])
                //    obj.full_name_with_user_name = user.full_name_with_user_name
                // })
                 
                })
                .catch(DatasourceError => {
                  if (DatasourceError) {
                    this.snackbar = true;
                    this.colorValue = "error";
                    this.snackbartext = DatasourceError;
                  } else {
                    this.snackbar = true;
                    this.colorValue = "error";
                    this.snackbartext = SERVER_ERROR;
                  }
                });
        },
     onNew(record){
            let obj = _.find(this.tableList.rows,["is_row_editable",true])
            if(obj)
            {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = 'First fillup the open row';
                return
            }
            this.tableList.rows.unshift({
                'full_name_with_user_name':'',
                'is_row_editable' : true
            });            
        },
    onEdit(record){
        },
    onCancel(record){
      let obj = _.find(this.tableList.rows,["is_row_editable",true])
      if(record.id){
        delete obj.is_row_editable
        // this.getUserGrouDetails(this.role_id)
        }
      else 
        this.tableList.rows.splice(0,1)
        },
    GetUserList() {
      var _this =this;
      var client_id = _this.$session.get('client_id');
      var data = {"client_id": client_id,
      'product_name': "TICKET_MANAGEMENT"}
      var get_user_url = config.USER_PROVISION_URL + "/get_allusers/" + client_id
     getToServer(_this, get_user_url).then(response => {
      this.tableList.headers[0].option = response;
      }).catch(error_response => {
          this.tableList.headers[0].option = []
      }); 
    },
    saveUserGroup() {
      var _this = this
      var client_id = this.$session.get('client_id');
      var user_list = []
       _.forEach(this.tableList.rows,function(obj){
                    user_list.push({"id":obj.user_id,
                                   "username":obj.username})
                })
      var data = {"group_name": this.group_name,
        "client_id": client_id,
        "product_name" :window.sessionStorage.getItem('product'),
        "user_list" :user_list,
        }
      if(!this.group_id || this.isForcopy)
        var url = config.USER_PROVISION_URL + "/create_group";

      else{
        var url = config.USER_PROVISION_URL + "/update_group";
        data["group_id"] = this.group_id
      }
      postToServer(this, url, data)
        .then(response => {
          this.snackbar = true;
          this.colorValue = "success";
          this.group_id = response.id
          this.snackbartext = "User Group save Successfully";
        })
        .catch(DatasourceError => {
          if (DatasourceError) {
            this.snackbar = true;
            this.colorValue = "error";
            this.snackbartext = DatasourceError;
          } else {
            this.snackbar = true;
            this.colorValue = "error";
            this.snackbartext = SERVER_ERROR;
          }
        });
    },
    performTableSearch(event){
				var _this = this
        let obj = _.find(this.tableList.rows,["is_row_editable",true])
        //search only for tables
        if(obj.object_type_name != "Table")
          return
        var data = {}
				data['search_text'] = event
				data['description_needed'] = true
				data['check_in_description'] = true
        data['client_id'] = this.$session.get('client_id')
        _this.tableList.tableLoading = true
				getTablesByDVEnv(
						_this,data
				).then(tableResponse => {
					 _this.tableList.tableLoading = false
        var tableList = []
         _.forEach(tableResponse.available_table_list,function(obj){
                   _.forEach(obj.table_list,function(el){
                    tableList.push({"object_name":el.title,
                              "object_description":el.description,
                              "display_name":el.description?el.title + " "+ el.description:el.title})
                })
                    // tableList.push(list)
                })
         tableList.unshift({
                    'object_name':'All',
                    'object_description':'All',
                    "display_name":'All'
                });
				_this.tableList.headers[3].option = tableList
				})
				.catch(errorResponse => {
          _this.tableList.tableLoading = false
						this.indeterminate = false
						alert('error in server serach')
					});
			}
  }
};
</script>
<style scoped>
.customCheckbox >>> label {
  top: 5px !important;
}
.v-expansion-panel >>> .v-expansion-panel__header {
  padding-bottom: 0px !important;
}

.EDC-UIComponent {
  margin: 0px !important;
  padding: 0px !important;
}

.radioClass >>> label {
  top: 3px !important;
}

.EDC-TopRow {
  padding-top: 20px !important;
  padding-bottom: 10px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.EDC-ColDescription {
  margin-top: -7px !important;
}

.EDC-GridRow {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.EDC-ButtonRow {
  padding-right: 10px !important;
  padding-bottom: 10px !important;
}
</style>