'use strict'
import config from '../config.json'
import { SERVER_ERROR } from './../data/client_message.js'
import { sessioncheck, destroy_session } from './../methods/user_session.js';
import {EnviromentChanged} from './../methods/EnvironmentList.js';

export function deleteFromServer(that, endPoint, is_ui_token = false) {
    // console.log(that.$session.get('access_token'))
    if(that && that.snackbar && !that.snackbarButton)
        that.snackbar = false
    let authorization = config.APPLICATION_TOKEN;
    let headerData ={}
    if (!is_ui_token) {
        if(!sessioncheck(that))
            {
                window.location.href = "/login"
                return
            }
        headerData['From-ui'] = 'Y'
    }
    if(that.$session && that.$session.get('access_token'))
        authorization = that.$session.get('access_token')

    // let product = window.sessionStorage.getItem('product')
    headerData['Authorization'] = authorization
    // headerData['Product-Name'] = product
    headerData['product-token'] = config.APPLICATION_TOKEN
    headerData['Content-Type'] = 'application/json'
    return new Promise(function(resolve, reject) {
        that.$http.delete(endPoint, {
            headers: headerData
        }).then(response => {
            return resolve(response.body)
        }, response => {
            if (!is_ui_token && response.status === 401) {
                destroy_session(that)
            } else if (response.status < 500) {
                return reject(response.body.message)
            } else {
                return reject(SERVER_ERROR)
            }
        })
    })
}

export function get(that, endPoint, is_ui_token = false) {
    if(that && that.snackbar && !that.snackbarButton)
        that.snackbar = false
    let authorization = config.APPLICATION_TOKEN;
    let headerData ={}
    if (!is_ui_token) {
        if(!sessioncheck(that))
            {
                window.location.href = "/login"
                return
            }
        headerData['From-ui'] = 'Y'
    }
    if(that.$session && that.$session.get('access_token'))
        authorization = that.$session.get('access_token')


    // let product = window.sessionStorage.getItem('product')
    
    headerData['Authorization'] = authorization
    // headerData['Product-Name'] = product
    headerData['product-token'] = config.APPLICATION_TOKEN
    headerData['Content-Type'] = 'application/json'
    return new Promise(function(resolve, reject) {
        that.$http.get(endPoint, {
            headers: headerData
        }).then(response => {
            return resolve(response.body)
        }, response => {
            if (!is_ui_token && response.status === 401) {
                destroy_session(that)
            } else if (response.status < 500) {
                return reject(response.body.message)
            } else {
                return reject(SERVER_ERROR)
            }
        })
        if(!is_ui_token)
        EnviromentChanged(that)
    })
}

export function post(that, endPoint, InputJson, is_ui_token = false,is_from_env_change=false) {
    if(that && that.snackbar && !that.snackbarButton)
        that.snackbar = false
    let authorization = config.APPLICATION_TOKEN;
    let headerData ={}
    if (!is_ui_token) {
        if(!sessioncheck(that))
            {
                window.location.href = "/login"
                return
            }
        headerData['From-ui'] = 'Y'
    }
    if(that.$session && that.$session.get('access_token'))
        authorization = that.$session.get('access_token')
    
    let mapped_user_groups = ''
    if(that.$session && that.$session.get('mapped_user_groups'))
        mapped_user_groups = that.$session.get('mapped_user_groups')
    if (mapped_user_groups)
        mapped_user_groups = mapped_user_groups.toString()
    // let product = window.sessionStorage.getItem('product')
    // headerData['Product-Name'] = product
    headerData['Authorization'] = authorization
    headerData['product-token'] = config.APPLICATION_TOKEN
    headerData['Content-Type'] = 'application/json'
    headerData['mapped_user_groups'] = mapped_user_groups

    return new Promise(function(resolve, reject) {
        that.$http.post(endPoint, InputJson, {
            headers: headerData
        }, ).then(response => {
            return resolve(response.body)
        }, response => {
            if (!is_ui_token && response.status === 401) {
                destroy_session(that)
            } else if (response.status <= 500 || response.status === 503) {
                return reject(response.body.message)
            } else {
                return reject(SERVER_ERROR)
            }

        })
        if(!is_ui_token && !is_from_env_change)
            EnviromentChanged(that)
    })
}
export function fileUpload(that, endPoint, InputJson, is_ui_token = false) {
    let authorization = config.APPLICATION_TOKEN;
    let headerData ={}
    if (!is_ui_token) {
        if(!sessioncheck(that))
            {
                window.location.href = "/login"
                return
            }
        headerData['From-ui'] = 'Y'
    }
    if(that.$session && that.$session.get('access_token'))
        authorization = that.$session.get('access_token')
    headerData['Authorization'] = authorization
    headerData['Content-Type'] = 'application/json'
    return new Promise(function(resolve, reject) {
        that.$http.post(endPoint, InputJson, {
            headers: headerData
        }, ).then(response => {
            return resolve(response.body)
        }, response => {
            if (!is_ui_token && response.status === 401) {
                destroy_session(that)
            } else if (response.status <= 500) {
                return reject(response.body.message)
            } else {
                return reject(SERVER_ERROR)
            }

        })
    })
}