/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Information': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<circle pid="0" cx="32.11" cy="32.016" r="26.976" _fill="none" _stroke="#000" stroke-width="2" stroke-linejoin="round"/><text style="line-height:1.25;-inkscape-font-specification:Elephant" x="21.43" y="53.043" transform="scale(1.11669 .8955)" font-weight="400" font-size="48.012" font-family="Elephant" letter-spacing="0" word-spacing="0" stroke-width="1.2"><tspan x="21.43" y="53.043" style="-inkscape-font-specification:Elephant">i</tspan></text>'
  }
})
