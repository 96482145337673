/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'save_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M1.24.53a.715.715 0 00-.71.71v3.87c0 .39.32.71.71.71h3.87c.389 0 .71-.32.71-.71V2.877a.265.265 0 00-.052-.16L4.18.633A.265.265 0 003.969.53H1.24zm0 .529h.348v.66c0 .074.06.133.133.133h1.586c.073 0 .133-.06.133-.133v-.66h.398l1.453 1.908V5.11a.176.176 0 01-.182.182H1.24a.176.176 0 01-.181-.182V1.24c0-.105.076-.181.181-.181zM3.176 2.38a.797.797 0 00-.795.793c0 .437.358.795.795.795a.794.794 0 000-1.588zm0 .264c.294 0 .53.236.53.53 0 .293-.236.529-.53.529a.528.528 0 01-.53-.53c0-.293.236-.53.53-.53z" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
