/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'email_send_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M1.322 1.059a.797.797 0 00-.793.793v2.91c0 .435.358.795.793.795h3.706c.435 0 .792-.36.792-.795v-.264h-.529v.264a.258.258 0 01-.263.266H1.322a.258.258 0 01-.263-.266v-2.91c0-.036.007-.069.018-.1l2.01 1.787c.05.045.127.045.177 0l2.008-1.786c.012.03.02.064.02.1v.528h.529v-.529a.797.797 0 00-.793-.793H1.322zm0 .53h3.706c.009 0 .016.003.025.004l-1.877 1.67-1.878-1.67c.008 0 .015-.004.024-.004zm3.705.925v.66h-.795v.53h.795v.662l.53-.463.528-.463-.529-.463-.529-.463z" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
