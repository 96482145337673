<template>
    <div>
        <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow">
                    <v-col cols="4" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li>User Group List</li>
                        </ul>
                    </v-col>
                    <v-col cols="8"></v-col>
                </v-row>
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>

        <edc-data-grid :dataList="tableList" @onNew="addUserGroup" @onEdit="onEdit" @onDelete="onDelete" @ondblClick="onEdit"></edc-data-grid>

        <loading-panel :loader="loader"></loading-panel>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    </div>
</template>

<script>
import moment from 'moment'
import config from './../../config.json'
import {
    get as getToServer, post as postToServer
} from '../../methods/serverCall.js';
import {
    SERVER_ERROR
} from '../../data/client_message.js'
import {
    CLIENT_SIDE,
    SERVER_SIDE
} from '../../data/macros.js'
import _ from 'lodash'
// import dummyData from './tableData.js'
// import data from './metaData.js'
import {
    USER_PROVISIONING_SERVICE_ID,
    CREATE_ACTION, UPDATE_ACTION, DELETE_ACTION,
} from '../../data/macros.js'
import getUserRole from '../../methods/GetUserRole.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

export default {
    name: 'UserGroupList',
    
    data: function () {
        return {
            userRole:{},
            loader: false,
            snackbar: false,
            snackbartext: '',
            colorValue: 'success',
            snackbartimeout: Snackbar_Default_Timeout,
            tableList: {
                headers: [{
                        text: 'User Group Name',
                        value: 'group_name',
                        width: "30%",
                        title: 'User Group Name'
                    },
                    {
                        text: 'Created By',
                        value: 'created_by',
                        width: "30%",
                        title: 'Group Created By'
                    },
                    {
                        text: 'Created Date',
                        value: 'timezone_aware_created_date',
                        width: "20%",
                        title: 'Group Created Date',
                        dataType:'date'
                    },
                    {
                        text: 'Created Time',
                        value: 'timezone_aware_created_time',
                        width: "20%",
                        title: 'Group Created Time',
                        dataType:'time',
                        hideInlineFilter: true
                    }
                    // ,
                    // {
                    //     text: 'Users',
                    //     value: 'manage_users',
                    //     width: "10%",
                    //     title: 'Double click to Add/Remove users',
                    // },
                ],
                actions:[],
                sorting_type: CLIENT_SIDE,
                filterType: CLIENT_SIDE,
                paginationType: CLIENT_SIDE,
                total_count: 0,
                rows: [],
                selected_rows: [],
                hideExport:true,
                hideShowColumns:true,
                hideFilter:true,
                syncHeaderScroll:false,
            },
            valid: true,
        }
    },
    mounted() {
        this.userRole = this.$session.get('user_role_mapping');
        this.getAllUserGroups();
        this.tableList.actions = [{
                            'text': 'new',
                            'key': "id",
                            selectType:"single",
                            role:this.userRole.is_superadmin || getUserRole(this.userRole,USER_PROVISIONING_SERVICE_ID, CREATE_ACTION),
                            index:1,
                        },{
                            'text': 'edit',
                            'key': "id",
                            selectType:"single",
                            role:this.userRole.is_superadmin || getUserRole(this.userRole,USER_PROVISIONING_SERVICE_ID, UPDATE_ACTION),
                            index:2
                        },
                        {
                            'text': 'delete',
                            'key': "id",
                            selectType:"multiple",
                            role:this.userRole.is_superadmin || getUserRole(this.userRole,USER_PROVISIONING_SERVICE_ID, DELETE_ACTION),
                            index:3
                        }
                ]//if we need conditional action in row then provide key
    },
    methods: {
        addUserGroup(){
            let _this = this;
            _this.$router.push('/usergroupmanagement');
        
        },
        onDelete:function(record){
            let _this = this;
            var group_id_list = []
            _.forEach(record,function(obj){
                            group_id_list.push(obj.id)
                        })
            _this.deleteUserGroup(group_id_list);
        },
        onEdit:function(record){
            let _this = this;   
            this.$router.push({ name: 'userGroupManagement', params: { group_id: record.id, type:'edit' }})
        },
        getAllUserGroups() {
            let _this = this;
            _this.loader = true;
            _this.tableList.rows = []
            var request_data = {
                'client_id': this.$session.get('client_id'),
                'product_name': window.sessionStorage.getItem('product')}
            postToServer(_this, config.USER_PROVISION_URL + '/get_all_group_details', request_data).then(response => {
                _this.loader = false;
                if (response) {
                    _this.tableList.rows = response;
                } else {
                    _this.tableList.rows = [];
                }
               
            }).catch(error_response => {
                _this.loader = false;
                 _this.tableList.rows = [];
                if (error_response) {
                    this.loader = null
                    /* this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = error_response; */
                } else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                }
            });
        },
        deleteUserGroup(group_id_list){
            let _this = this;
            var Authorization = this.$session.get('access_token')
            var deleteData = {"group_id_list": group_id_list, 'client_id': this.$session.get('client_id')}
            var url = config.USER_PROVISION_URL + '/delete_group_details'
            postToServer(_this, url, deleteData).then(response => {
                _this.getAllUserGroups()
          }, response => {
            this.loader = null
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = response;
          }).catch(e => {
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = SERVER_ERROR;
          })
        }
    }
}
</script>
<style scoped>

</style>