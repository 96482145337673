/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Document_Export': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M27 9v16c0 2.216 2.784 4.072 5 4.063L47 29" _fill="none" _stroke="#000" stroke-width="2" stroke-linejoin="round"/><path pid="1" d="M37 25l-6-6v4.8c0 .665.535 1.2 1.2 1.2z" _stroke="#000" stroke-width=".03"/><path pid="2" d="M55.154 44.028c0 6.117-4.96 11.077-11.077 11.077-6.118 0-11.077-4.96-11.077-11.077 0-6.118 4.96-11.077 11.077-11.077s11.077 4.96 11.077 11.077zm-7.875-10.655v-5.17L28.235 9H13c-2.11 0-3.809 1.713-3.809 3.84v38.407c0 2.128 1.699 3.84 3.809 3.84h30" _fill="none" _stroke="#000" stroke-width="1.846"/><path pid="3" d="M52 44l-4-3-4-3v12l4-3z"/><path pid="4" d="M48 43H36v2h12z"/><path pid="5" d="M17 31h6M17 39h12M17 47h11" _fill="none" _stroke="#000" stroke-width="2" stroke-linecap="round"/>'
  }
})
