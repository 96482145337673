<template>
	<v-container class="EDC-Row">
		<v-row class="EDC-Row">
			<v-col class="EDC-Col">
				<v-radio-group v-model="radioModel"  :hide-details="hideDetails" :dense="propDense"  @change="radioSelectionChange">
					<template v-for="(item,index) in itemList">
                		<v-radio class="radioClass" :color="colorCode" :value="item[itemValue]" :hide-details="hideDetails" :dense="propDense" :label="item[itemText]" :key="fieldUniqueId+index">
                		</v-radio>
					</template>
                		
            	</v-radio-group>
			</v-col>
		</v-row>
	</v-container>
</template>
<style scoped>
	.radioClass >>> label{
    	top: 4px !important;
	}
</style>
<script>
	export default{
		props:{
			hideDetails:{
				type:Boolean,
				default:true
			},
			propDense:{
				type:Boolean,
				default:true
			},
			itemList:{
				type:Array,
				default:()=>{
					return []
				}
			},
			colorCode:{
				type:String
			},
			showInColumn:{
				type:Boolean,
				default:true
			},
			showInRow:{
				type:Boolean,
				default:false
			},
			vModel:{
				
			},
			fieldUniqueId:{
				type:String,
				default:''
			},
			radioModel:{

			},
			itemText:{
				type:String,
				default:'text'
			},
			itemValue:{
				type:String,
				default:'value'
			},
		},
		data(){
			return{

			}
		},
		watch:{
			vModel:{
				handler(newvalue){
					// avoid infinite looping
					if(newvalue!=this.radioModel)
						this.radioModel = newvalue
				}
			},
			itemList:{

			}
		},
		mounted(){
			this.radioModel = this.vModel
		},
		methods:{
			radioSelectionChange(event){
				this.$emit('change',event,this.fieldUniqueId)
			}
		}
	}
	
</script>