<template>
 	<div>
	<v-container class="EDC-Container ">
		<v-row class="EDC-Row ">
			<v-col class="EDC-Col" cols="8">
				<v-card  class="EDC-VCards overflow-x-auto style-2scroll" elevation="1">
					<v-card-title class="cardHeader" @click="redirectToStatus">
					<!-- {{getGraphHeader("Open Ticket Grid")}}	 -->
					Unassigned Tickets (Top 10)
					</v-card-title>
					<edc-data-grid key="grid-A" style="max-height: 30vh; overflow: auto;" :dataList="tableList" @ondblClick="onEdit" @onCellEvent="onCellEvent"
					></edc-data-grid>
				</v-card>
			</v-col>
			<v-col class="EDC-Col" cols="4">
				<v-card class="EDC-VCards" elevation="1" v-if="showRow1Col3Graph">
					<v-chart id="row1col3chart"  @dblclick="dblClickOnGraph($event,'r1c3')" :loading="loadingRow1Col3" class="pieChart" :option="row1Col3Object">
					</v-chart>
				</v-card>
				<no-graph-data v-else name="row1col3graph" key="row1col3graph" :headerText="row1col3Header" @showFilter="filterClickedFor = row1col3Identifier"></no-graph-data>
			</v-col>
		</v-row>
		<v-row class="EDC-Row">
			<v-col class="EDC-Col" cols="4">
				<v-card  class="EDC-VCards" elevation="1" v-if="showRow2Col1Graph">
					<v-chart id="row2col1chart"  @dblclick="dblClickOnGraph($event,'r2c1')" :loading="loadingRow2Col1" class="pieChart" :option="row2Col1Object">
					</v-chart>
				</v-card>
				<no-graph-data v-else name="row2col1graph" key="row2col1graph" :headerText="row2col1Header" @showFilter="filterClickedFor = row2col1Identifier"></no-graph-data>
			</v-col>
			<v-col class="EDC-Col" cols="4">
				<v-card  class="EDC-VCards" elevation="1" v-if="showRow2Col2Graph">
					<v-chart id="row2col3chart"  @dblclick="dblClickOnGraph($event,'r2c2')" :loading="loadingRow2Col2" class="barChart" :option="row2Col2Object" v-if="showRow2Col2Graph">
					
					</v-chart>
				</v-card>
					<no-graph-data v-else name="row2col2graph" key="row2col2graph" :headerText="row2col2Header" @showFilter="filterClickedFor = row2col2Identifier"></no-graph-data>
			</v-col>
			<v-col class="EDC-Col" cols="4">
				<v-card  class="EDC-VCards" elevation="1" v-if="showRow2Col3Graph">
					<v-chart id="row3col3chart" @dblclick="dblClickOnGraph($event,'r2c3')" :loading="loadingRow2Col3" class="pieChart" :option="row2Col3Object">
					
					</v-chart>
				</v-card>
				<no-graph-data v-else name="row2col3graph" key="row2col3graph" :headerText="row2col3Header" @showFilter="filterClickedFor = row2col3Identifier"></no-graph-data>
			</v-col>

		</v-row>
	</v-container>
    <v-dialog v-model="filterDialog" width="300px" persistent>
    	<v-container class="EDC-Container">
	    	<v-card>
					<v-card-title class="cardHeader cardHeaderWithBackGround">
						{{filterHeader}}
						<v-spacer></v-spacer><svgicon  class="svg-icon-grid svg-fill-toolbar" name="cancel_v2"  :original="true" @click="closeFilterPopUp" title="Close"></svgicon>
					</v-card-title>
					<v-card-text>
						<v-row class="EDC-Row" style="padding-top:16px !important;">
							<v-col class="EDC-Col" cols="12" align="left">
								<v-combobox v-model="graphFilter" :items="availableFiltersForStatus"  style="padding-top: 1% !important" :search-input.sync="graphFilterSearch" hide-selected label="Select Status For Filter" multiple small-chips>
									<template v-slot:no-data>
										<v-list-item>
											<v-list-item-content>
												<v-list-item-title v-if="graphFilterSearch">
													No results matching "<strong>{{ graphFilterSearch }}</strong>"
												</v-list-item-title>
											</v-list-item-content>
										</v-list-item>
									</template>
									<template v-slot:selection="{ attrs, item, parent, selected }">
										<v-chip
										v-if="item === String(item)"
										v-bind="attrs"
										:input-value="selected"
										label
										small
										>
										<span class="pr-2">
											{{item}}
										</span>
										<v-icon
											small
											@click="parent.selectItem(item)"
										>
											$delete
										</v-icon>
										</v-chip>
									</template>
								</v-combobox>
							</v-col>
						</v-row>
						<v-row class="EDC-Row">
							<v-col class="EDC-Col" cols="12" align="center">
								<vc-button itemText="Apply" @click.native="applyGraphFilters()"></vc-button>
              	<vc-button itemText="Cancel" @click.native="closeFilterPopUp"></vc-button>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
	    </v-container>
    </v-dialog>
	 </div>
</template>
<style scoped>
.pieChart {
  height: 40vh;
  padding:4px;
}

.barChart {
  height: 40vh;
  padding:4px;
}

.EDC-VCards {
	margin: 4px !important;
	height: 43vh;
	padding: 0px !important;
	/* overflow: scroll; */
}

.cardHeader{
	 	font-family: "IBM ", "Plex" , "Sans";
		font-weight: bold;
	}
.cardHeaderWithBackGround{
	color: white;
	background-color: black;
}

	.v-card__subtitle, .v-card__text, .v-card__title {
		padding-top: 0px !important;
		padding-bottom: 0px !important;
		padding-left: 8px !important;
		padding-right: 8px !important;
	}
</style>
<script>
	import noGraphData from './noGraphData.vue';
	import {echartGraphMixin} from './../../mixins/echartGraph.js'

	import { use } from "echarts/core";
	import { CanvasRenderer } from "echarts/renderers";
	import { PieChart,BarChart } from "echarts/charts";
	import { ToolboxComponent,GridComponent } from 'echarts/components';
	import {
	  TitleComponent,
	  TooltipComponent,
	  LegendComponent
	} from "echarts/components";
	import VChart,{ THEME_KEY } from "vue-echarts";
	import {
	post as postToServer
	} from './../../methods/serverCall.js';
	import config from '../../config.json'
	import {DASHBOARD_TABLE} from '../ticketmanagement/ticketMetaData.js' 	
	import {getUserRole, getTicketRole} from './../../methods/GetUserRole.js'
	import {UPDATE_ACTION, TICKET_MANAGEMENT_SERVICE, CREATE_ACTION, DELETE_ACTION, READ_ACTION } from '../../data/macros';
	// import {ECHART_THEMES} from '../../../data/echartConstants.js'
	use([
	  CanvasRenderer,
	  PieChart,
	  TitleComponent,
	  TooltipComponent,
	  LegendComponent,
	  ToolboxComponent,
	  GridComponent,
	  BarChart
	]);
	export default {
		name:'dashboard',
		mixins:[echartGraphMixin],
		components:{
			'no-graph-data':noGraphData,
			'VChart':VChart,
		},
		data(){
			return{
				loadingRow1Col3:true,
				loadingRow2Col1:true,
				loadingRow2Col2:true,
				loadingRow2Col3:true,
				row1Col3Object:{},
		    row2Col1Object:{},
		    row2Col2Object:{},
		    row2Col3Object:{},
		    actualTimeVsEstimateTime:{},
				statusAvailableForPieFilter:["Open",'In progress','Closed'],
				statusAvailableForBarFilter:['In progress','Closed'],
		    row1col3Header:'Open Tickets By Priority',
		    row2col1Header:"Open Tickets By Queue",
		    row2col2Header:"Open Tickets By Resources",
		    row2col3Header:"Open Tickets By Tag",
		    row1col3Url:'/get_ticket_by_priority',
		    row2col1Url:'/get_ticket_by_queue',
		    row2col2Url:'/get_ticket_by_assignee',
		    row2col3Url:'/get_ticket_by_tag',
		    row1col3Filter:["Open",'In progress'],
		    row2col1Filter:["Open",'In progress'],
		    row2col2Filter:['In progress'],
		    row2col3Filter:["Open",'In progress'],
		    row1col3Identifier:'r1c3',
		    row2col1Identifier:'r2c1',
		    row2col2Identifier:'r2c2',
		    row2col3Identifier:'r2c3',
				userRole:{},
				tableList:_.cloneDeep(DASHBOARD_TABLE),
				envIpJson : {
					"filter": [],
					"sort": [{column_name: "priority_sequence_number",
							type: "asc"},
							{column_name: "tz_created_date_time",
							type: "desc"}],
					"page": 1,
					"page_size": 10
				},
				reason:"",
				action_type:'Resolved',
				dialog:false,
				ticket_action_list:[{name:"In progress",
									id:"In progress"},
									{name:"Closed",
									id:"Resolved"},
									{name:"Closed",
									id:"Duplicate"},
									{name:"Closed",
									id:"Invalid"},
									{name:"Closed",
									id:"Other"}],
				close_action_list:[{name:"Open",
	                          id:"Open"},],
				reasonRules:[
					v => !!v || 'User comments are required'
	            	],
				task_status:'',
				task_name:'',
				colorCode:'',
				client_id:this.$session.get('client_id'),
				filterClickedFor:'',	
				filterDialog:false,
				availableFiltersForStatus:["Open",'In progress','Closed'],
				graphFilter:'',
				graphFilterSearch:'',
				filterHeader:'',
			}
		},
		watch:{
			'filterClickedFor':{
				handler(newValue){
					if(!newValue)
						return
					this.manageGraphFilterOptions()
				}
			}
		},
		computed:{
			showRow1Col3Graph(){
				return !_.isEmpty(this.row1Col3Object) || this.loadingRow1Col3
			},
			showRow2Col1Graph(){
				return !_.isEmpty(this.row2Col1Object) || this.loadingRow2Col1
			},
			showRow2Col2Graph(){
				return !_.isEmpty(this.row2Col2Object) || this.loadingRow2Col2
			},
			showRow2Col3Graph(){
				return !_.isEmpty(this.row2Col3Object) || this.loadingRow2Col3
			},
			isFilterClickedForRow1Col3(){
				return this.filterClickedFor === this.row1col3Identifier
			},
			isFilterClickedForRow2Col1(){
				return this.filterClickedFor === this.row2col1Identifier
			},
			isFilterClickedForRow2Col2(){
				return this.filterClickedFor === this.row2col2Identifier
			},
			isFilterClickedForRow2Col3(){
				return this.filterClickedFor === this.row2col3Identifier
			}
		},
		mounted(){
			this.userRole = this.$session.get('user_role_mapping'); 
      		this.tableList.actions=[
            {'text':'new','key':"ticket_id", selectType:"single", role:this.userRole.is_superadmin || getTicketRole(this.userRole,TICKET_MANAGEMENT_SERVICE,CREATE_ACTION), index:1},
            {'text':'edit','key':"ticket_id", selectType:"single",role:this.userRole.is_superadmin || getTicketRole(this.userRole,TICKET_MANAGEMENT_SERVICE,READ_ACTION), index:2},
            {'text':'delete','key':"ticket_id", selectType:"single", role:this.userRole.is_superadmin || getTicketRole(this.userRole,TICKET_MANAGEMENT_SERVICE,DELETE_ACTION),index:3},
            {'text':'closeAction','key':"ticket_id", selectType:"single", role:this.userRole.is_superadmin || getTicketRole(this.userRole,TICKET_MANAGEMENT_SERVICE,UPDATE_ACTION),index:4}
            ];
			this.getRow1Col3Chart()
			this.getRow2Col1Chart()
			this.getRow2Col3Chart()
			this.getRow2Col2Chart()
			// this.getAcutalTimeVsEstimateTime()
			this.getOpenTicketList(this.envIpJson)
			
		},
		methods:{
			getRow1Col3Chart(){
				var _this = this
				_this.loadingRow1Col3 = true

				_this.row1Col3Object = {}
				let data = {"client_id":this.client_id,'status_list':_this.row1col3Filter}
				postToServer(_this,config.EDC_TICKET_MANAGEMENT_SERVICE_URL + _this.row1col3Url,data).then(
				response=>{
					_this.loadingRow1Col3 = false
					let graphResponse = []
					_.forEach(response,(obj)=>{
						graphResponse.push({"value":obj.count,"name":obj.priority_name})
					})
					_this.row1Col3Object =  {
		        toolbox: _this.getToolbox({"filterFor":_this.row1col3Identifier}),
		        title: _this.getGraphHeader(_this.row1col3Header),
		        tooltip:_this.getPieChartTooltip(),
		        series: [
		          {
		            name: "Priority",
		            type: "pie",
		            radius: ['35%', '60%'],
		            center: ["50%", "60%"],
		            data: graphResponse,
		            emphasis: {
		              itemStyle: {
		                shadowBlur: 10,
		                shadowOffsetX: 0,
		                shadowColor: "rgba(0, 0, 0, 0.5)"
		              }
		            },
		            label:_this.getPieChartLabels()
		          }
		        ]
		    	}
				}).catch(error=>{
					_this.loadingRow1Col3 = false
				})
			},
			getRow2Col1Chart(){
				var _this = this
				_this.loadingRow2Col1 = true
				_this.row2Col1Object = {}
				let data = {"client_id":this.client_id,'status_list':_this.row2col1Filter}
				postToServer(_this,config.EDC_TICKET_MANAGEMENT_SERVICE_URL + _this.row2col1Url,data).then(
				response=>{
					_this.loadingRow2Col1 = false
					let graphResponse = []
					_.forEach(response,(obj)=>{
						graphResponse.push({"value":obj.count,"name":obj.queue_name})
					})
					_this.row2Col1Object = {
		        toolbox: _this.getToolbox({"filterFor":_this.row2col1Identifier}),
		        title:_this.getGraphHeader(_this.row2col1Header),
		        tooltip: _this.getPieChartTooltip(),
		        series: [
		          {
		            name: "Queue",
		            type: "pie",
		            radius: '60%',
		            center: ["50%", "60%"],
		            data: graphResponse,
		            emphasis: {
		              itemStyle: {
		                shadowBlur: 10,
		                shadowOffsetX: 0,
		                shadowColor: "rgba(0, 0, 0, 0.5)"
		              }
		            },
		            label:_this.getPieChartLabels()
		          }
		        ]
			    }
				}).catch(error=>{
					_this.loadingRow2Col1 = false

				})
			},
			getRow2Col3Chart(){
				var _this = this
				_this.loadingRow2Col3 = true
				_this.row2Col3Object = {}
				let data = {"client_id":this.client_id,'status_list':_this.row2col3Filter}
				postToServer(_this,config.EDC_TICKET_MANAGEMENT_SERVICE_URL+_this.row2col3Url,data).then(
				response=>{
					_this.loadingRow2Col3 = false
					let graphResponse = []
					_.forEach(response,(obj)=>{
						graphResponse.push({"value":obj.count,"name":obj.tag_name})
					})
					_this.row2Col3Object = {
						toolbox: _this.getToolbox({"filterFor":_this.row2col3Identifier}),
						title: _this.getGraphHeader(_this.row2col3Header),
						tooltip: _this.getPieChartTooltip(),
						series: [
						{
							name: "Tag",
							type: "pie",
							radius: '60%',
							center: ["50%", "60%"],
							data: graphResponse,
							emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: "rgba(0, 0, 0, 0.5)"
							}
							},
							label:_this.getPieChartLabels()
						}
						]
			    	}
				}).catch(error=>{
					_this.loadingRow2Col3 = false
				
				})
			},
			getRow2Col2Chart(){
				var _this = this
				_this.loadingRow2Col2 = true
				_this.row2Col2Object = {}
				let data = {"client_id":this.client_id,'status_list':_this.row2col2Filter}
				postToServer(_this,config.EDC_TICKET_MANAGEMENT_SERVICE_URL + _this.row2col2Url,data).then(
				response=>{
					_this.loadingRow2Col2 = false
					let graphResponse = []
					_.forEach(response,(obj)=>{
						graphResponse.push([obj.count,obj.assigned_to_fullname])
					})
					_this.row2Col2Object = {
	        	title: _this.getGraphHeader(_this.row2col2Header),
		        tooltip: {
		            trigger: 'axis',
		            axisPointer: {
		                type: 'shadow'
		            }
		        },
		        toolbox: _this.getToolbox({"filterFor":_this.row2col2Identifier}),
		        grid:{
		        	bottom:'20'
		        },
		        xAxis: [
		            {
		                type: 'value'
		            }
		        ],
		        yAxis: [
		            {
		              type: 'category',
		              axisTick: {show: false},
		              axisLabel: {
										interval: 0,
										rotate: 63,
										overflow:'truncate',
										ellipsis:'...',
										width:80,
									},	 
		            }
		        ],
		        series: [
	            {
                name: 'User',
                type: 'bar',
                barGap: 0,
                data: graphResponse
	            },
		        ]
		    	}
				}).catch(error=>{
					_this.loadingRow2Col2 = false
				})
			},
			dblClickOnGraph(event,whichGraph){
				let _this = this
				let filterJson = {'column_name':_this.seriesActualNameMapping[event.seriesName],'operator':_this.operatorMapping[event.seriesName],value1:event.name,'isInlineFilter':true}
				this.$router.push({ name: 'TicketStatus',params:{"graphFilter":filterJson}});
				
			},
		getOpenTicketList(envIpJson){
          var _this = this
          var inputJson = {"type": "Open",
                            "client_id": this.$session.get('client_id'),
                             "page":envIpJson.page,
                            "page_size":envIpJson.page_size,
                            "sort":envIpJson.sort,
                            "filter":[{"column_name":"status","operator":"_eq_","value1":"open","value":"open"}]}
          this.tableList.rows = []
          this.tableList.total_count = 0
         postToServer(this, config.EDC_TICKET_MANAGEMENT_SERVICE_URL + "/get_all_tickets" , inputJson
        ).then(response  => {
            this.tableList.rows =response.result
            this.tableList.total_count = response.total
        }).catch(ticketResponseError => {
                this.loader = false;
                _this.tableList.rows = []
            });
        },
        manageGraphFilterOptions(){
        	if(this.isFilterClickedForRow1Col3){
						this.graphFilter = this.row1col3Filter
						this.availableFiltersForStatus = this.statusAvailableForPieFilter
						this.filterHeader = this.row1col3Header
					}
					else if(this.isFilterClickedForRow2Col1){
						this.graphFilter = this.row2col1Filter
						this.availableFiltersForStatus = this.statusAvailableForPieFilter
						this.filterHeader = this.row2col1Header
					} else if(this.isFilterClickedForRow2Col2){
						this.graphFilter = this.row2col2Filter
						this.availableFiltersForStatus = this.statusAvailableForBarFilter
						this.filterHeader = this.row2col2Header
					} else if(this.isFilterClickedForRow2Col3){
						this.graphFilter = this.row2col3Filter
						this.availableFiltersForStatus = this.statusAvailableForPieFilter
						this.filterHeader = this.row2col3Header
					}
					else{
						alert('Invalid Filter choice')
						return
					}
					this.filterDialog = true
        },
        applyGraphFilters(){
        	if(this.isFilterClickedForRow1Col3){
						this.row1col3Filter = this.graphFilter
						this.getRow1Col3Chart()
					}
					else if(this.isFilterClickedForRow2Col1){
						this.row2col1Filter = this.graphFilter
						this.getRow2Col1Chart()
					} else if(this.isFilterClickedForRow2Col2){
						this.row2col2Filter = this.graphFilter
						this.getRow2Col2Chart()
					} else {
						this.row2col3Filter = this.graphFilter
						this.getRow2Col3Chart()
					}
					this.closeFilterPopUp()
        },
        closeFilterPopUp(){
        	this.graphFilter = []
					this.filterClickedFor = ''
					this.filterDialog = false
        },
		redirectToStatus(){
			let _this = this
			let filterJson = {'column_name':'status','operator':'_eq_',value1:'open','isInlineFilter':true}
			this.$router.push({ name: 'TicketStatus',params:{"graphFilter":filterJson}});
		},
		onCellEvent(record){
            this.onEdit(record);
        },
		onEdit:function(record){
            let is_closed_ticket = record.status === 'Closed'?true:false

          	this.$router.push({ name: 'TicketDetailsForDv', params: {ticket_id: record.ticket_id,'is_closed_ticket':is_closed_ticket}});
        }, 
			}
	}
</script>