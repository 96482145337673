<template>
    <h1></h1>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import config from '../../config.json'
import {
  post as postToServer
} from './../../methods/serverCall.js';

export default{
    data: function(){
        return{
            users_list: [],
            ticket_id: '',
            email_title: '',
            queue: '',
            ticketObj: '',
            title: '',
            description: '',
            resource_type: '',
        }
    },
    mounted(){
        if(this.$route.params.edcurl && this.$route.params.postData){
            this.ticketObj = {
                'url':this.$route.params.edcurl,
                'postData':JSON.parse(this.$route.params.postData),
                'url_type':this.$route.params.url_type
            }
        this.resource_type = this.$route.params.resource_type
        this.title = this.$route.params.title
        this.description = this.$route.params.description
        this.getMappedWorkflow();
    }

    },
    methods:{
        getMappedWorkflow(){
            var data_to_send = {"client_id":this.$session.get('client_id'),
                                "request_for": "Dataviewer"}
            var url = config.EDC_QUEUE_MANAGEMENT_URL+"/fetch_mapped_wf_dv"
            postToServer(this, url, data_to_send).then(response=>{
            if(response){
                if(response.queue_id){
                    this.queue={"queue_id": response.queue_id,
                    "queue_name": response.queue_name}
                }
                this.createTicket();
            }
            }).catch(error_response=>{
            if(error_response){
                this.createTicket();
            }
            else{
                this.snackbar = true
                this.colorValue = "error"
                this.snackbartext = SERVER_ERROR
            }
            })
        },
        createTicket(){
        var _this =this
        let formData = new FormData();
        formData.append('client_id', this.$session.get('client_id'));
        formData.append('title', this.title);
        formData.append('description', this.description);
        formData.append('resource_type', this.resource_type);
        formData.append('resource_code', "DAV")
        if(this.queue){
            formData.append('queue_id',this.queue.queue_id ? this.queue.queue_id : '');
            formData.append('queue_name',this.queue.queue_name ? this.queue.queue_name : '');
        }
        if(this.ticketObj){
            formData.append('ticket_details', JSON.stringify({
            "url": this.ticketObj.url,
            "url_type": this.ticketObj.url_type,
            "content_type": "json",
            "request_data": this.ticketObj.postData,
            "description": "Ticket for looking at the table data"
            }));
        }        
        _this.loader = true;
        postToServer(this, config.EDC_TICKET_MANAGEMENT_SERVICE_URL + "/create_ticket", formData
        ).then(response  => {
            this.ticket_id=response.ticket_id
            this.email_title = response.title+" "+response.ticket_id
            this.colorValue = 'success'
            this.snackbartext = "Success"
            if(response.queue_id){
                var queue_id_data = {"queue_id_list": [response.queue_id]}
                this.getUsersInQueue(queue_id_data);
            }
             _this.loader = false;
            // this.$router.push("/ticketstatus")
            // this.$emit('onCreated')
        }).catch(ticketResponseError => {
                _this.loader = false;
                if(ticketResponseError){
                    this.snackbar = true 
                    this.colorValue = 'error'
                    this.snackbartext = ticketResponseError                  
                }
                else {     
                    this.snackbar = true             
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;                    
                }
            });
        },
        getUsersInQueue(queue_id_data){
            var url_queue_user = config.EDC_QUEUE_MANAGEMENT_URL+"/get_users_from_queue"
            postToServer(this, url_queue_user, queue_id_data).then(response=>{
                if(response){
                _.forEach(response, (element) => {
                        this.users_list.push(element.username)
                    });
                this.SendMail(this.users_list);
                }
            }).catch(queueErrorResponse=>{
                this.users_list = [this.$session.get('email')] 
                this.SendMail(this.users_list);
            })
        },
        SendMail(user_list){
            var data_for_mail = {"recipient_email_list": user_list,
            "email_title": this.email_title,
            "from_email": this.$session.get('email'),
            "client_id": this.$session.get('client_id')}
            var send_mail_url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL+"/send_email_to_users"
            postToServer(this, send_mail_url, data_for_mail).then(response=>{
                if(response){
                    alert("Email Sent Successfully")
                    // let params = {"ticket_id": this.users_list}
                    // this.$router.push({"name":"TicketDetails", 'params':params})
                // this.$emit('onCreated')
                }
            }).catch(mailErrorResponse=>{
                alert("Email Not Sent")
            })
        }
    }
}
</script>
