/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'document_edit_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M1.067.523c-.289 0-.53.241-.53.53L.538 5.3c0 .289.24.53.53.53h.842V5.3h-.843V1.053h3.968l-.008 1.064h.53l.008-1.064a.532.532 0 00-.53-.53zm3.43 1.065H1.853v.53l2.117-.001zm.551.897a.263.263 0 00-.183.086l-.444.49.784.71.444-.489a.264.264 0 00-.018-.374l-.392-.355a.264.264 0 00-.19-.068zm-3.196.16v.53h1.323l.53-.53zm2.391.613L2.555 5.12c-.034.214-.123.933-.123.933s.6-.133.907-.222l1.688-1.862zm-2.391.446v.529h.53l.528-.53z" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
