<template>
	<v-container class="EDC-Container">
		<v-row class="EDC-Row">
			<v-dialog v-model="showTicket" persistent width="400" style="overflow:hidden !important;">
				<v-col cols="12" class="EDC-Col">
	        <v-card elevation-1>
	        	<v-row no-gutters class="EDC-Row">
	            <v-toolbar dark dense absolute class="EDC-Toolbar" style="width:100%; padding-bottom:10px;border-radius:0px !important;">
                <v-col cols="6" class="EDC-Col">
                  <v-row no-gutters class="EDC-Row" justify="start">
                    <v-toolbar-title class="EDC-ToolbarTitle">New Ticket</v-toolbar-title>
                  </v-row>
                </v-col>
                <v-col cols="6" class="EDC-Col">
                  <v-row no-gutters class="EDC-Row" justify="end">
                    <svgicon  class="svg-icon-grid svg-fill-toolbar" name="cancel_v2"  :original="true" @click="closePopUp" title="Close"></svgicon>
                  </v-row>
                </v-col>
              </v-toolbar>
            </v-row>
            <v-row class="EDC-Row">
            	<v-col cols="12" class="EDC-Col">
            		<edc-ticket :isForDV="false" :ticketDetails="tkt_data" @onSuccess="manageCreateTicket"></edc-ticket>
            	</v-col>
            </v-row>
	        </v-card>
	      </v-col>
			</v-dialog>
      <v-dialog v-model="showEdcForm" persistent width="400" style="overflow:hidden !important;">
        <v-col cols="12" class="EDC-Col">
          <v-card elevation-1>
            <v-row no-gutters class="EDC-Row">
              <edc-form :formJson="selected_form" key="testform" :formObj="ticket_response" 
              :showContactForm="show_contact_form" @contact_form_event="sendEmail(...arguments)" :isApproval="is_approval"></edc-form>
            </v-row>
          </v-card>
        </v-col>
      </v-dialog>

		</v-row>
		<loading-panel :loader="loader"></loading-panel>
		<v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
	</v-container>
</template>
<script>
import config from '../../config.json'
import {
 fileUpload, post as postToServer
} from './../../methods/serverCall.js';
import {
  get as getToServer
} from './../../methods/serverCall.js';
import {
  SERVER_ERROR
} from '../../data/client_message.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import ticketComponent from './revisedAddNewTicket.vue'
import edcForm from '../edcForm/edcTabForm.vue'

export default{
  name:"createNewTicket",
  components:{
    'edc-ticket':ticketComponent,
    'edc-form': edcForm
	},
  data(){
    return{
      selected_form:{},
      ticket_response:{},
      show_contact_form:false,
      showEdcForm:false,
      is_approval:false,
      ticket_title: '',
      ticket_description: '',
      priority_list: [],
      tkt_data:'',
      colorValue:'error',
      loader:false,
      snackbar:false,
      snackbartimeout: Snackbar_Default_Timeout,
      snackbartext:'',
      showTicket: true,
      ticket_priority: {},
      queue_list: [],
      option_name:'',
      queue_selected: false,
      ticket_queue: {},
      ticket_assignee: {},
      assignee_list: [],
      attached_files:[],
      selected_image:'',
      files: [],
    }
  },
  mounted() {
    if(this.$route.params.moreInfo){
      this.tkt_data = this.$route.params.moreInfo
      this.option_name = this.$route.params.moreInfo.option_name
    }
  },
  methods:{
    closePopUp(){
      var _this = this.$router.push({name:"Dashboard"})

    },
    manageCreateTicket(ticketDetails){
      if(ticketDetails.form_json){
            this.showTicket = false
            this.showEdcForm=true
            this.show_contact_form=true
            this.selected_form = ticketDetails.form_json
            this.ticket_response =ticketDetails
            return;
					}
      this.$router.push({"name":"TicketStatus"})
    },
    sendEmail (data) {
      var _this = this
      var client_id = this.$session.get('client_id')
      var UserData = {
          'client_id': client_id,
          'user_id': "Essentio",
          'client_name': "Essentio",            
          'workflow_request_id':this.ticket_response.workflow_request_id,
          'ticket_id': this.ticket_response.ticket_id,
          'receiver_email': _.find(data.fieldList,['nameInDB',"recipent_email"]).value,
          'current_step_id': this.ticket_response.current_step_id,
          'company_name':_.find(data.fieldList,['nameInDB',"company_name"]).value,
          'recipient_first_name':_.find(data.fieldList,['nameInDB',"recipient_first_name"]).value,
          'recipient_last_name':_.find(data.fieldList,['nameInDB',"recipient_last_name"]).value,
          'current_step_status': this.ticket_response.current_step_status,
          'workflow_request_id':this.ticket_response.workflow_request_id,
          'email_subject': _.find(data.fieldList,['nameInDB',"email_subject"]).value,
          'email_content': _.find(data.fieldList,['nameInDB',"email_content"]).value,
      }
      var data_to_send = {}
      data_to_send =  Object.assign({}, this.ticket_response, UserData);
      data_to_send.form_json = data
      postToServer(this, config.EDC_FORM_MANAGEMENT_URL + '/send_form_email', data_to_send).then(Response  => {
            _this.loader = false
          if(Response){   
              _this.show_contact_form =false
              this.snackbar = true
              this.colorValue = 'success'
              this.snackbartext = "Email sent successfully.";
              setTimeout(() => { 
              this.$router.push({"name":"TicketStatus"})
              }, 1000);
          }
      }).catch(error_response => {            
          this.loader = false
          if(error_response){
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = error_response;
          }
          else {
              this.snackbar = true
              this.snackbartext = 'Something went wrong.Try Again';
              this.colorValue = 'error'
              
          }
      });
    },
  }
}
</script>

<style scoped>
  .label-margin label{
    margin-top:5%;
  }
  .ql-editor{
      min-height: 150px !important;
    }
  .EDC-ColsSpacing {
  padding-left: 10px !important;
  padding-right: 10px !important;
  justify-self: start;
}
</style>
