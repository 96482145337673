<template>
	<v-container class="EDC-Row" >
		<v-row class="EDC-Row EDCHeaderContainerGray"  v-if="showBanner" align="left" dense no-gutters>
			<v-col cols="2" class="EDC-Col EDC-ColsSpacing" style="text-align: left !important; padding-left: 10px !important">
        		<img src="../../assets/images/essentio_logo_white.png" alt="Essentio logo" style="width:70%;">
      		</v-col>
		</v-row>
		<!-- <v-form :id="formJson.domId"> -->
			<v-row class="EDC-Row">
				<v-col class="EDC-Col EDC-Form-Header" cols="12" align="left">
					<b> {{formJson.formHeader}} </b>
				</v-col>
			</v-row>
			<v-row class="EDC-Row" v-for="(level1Row,level1RowIndex) in formJson.gridSystem" :key="getUniqueId+'level1RowIndex'+level1RowIndex">
				<template v-for="(eachFieldInLevel1,eachFieldInLevel1Index) in level1Row.cols">
					<v-col  :key="getUniqueId+'eachFieldInLevel1Index'+eachFieldInLevel1Index" :cols="eachFieldInLevel1.colspan" class="EDC-ColControlPadding" v-if="eachFieldInLevel1.type === 'field'">
						<edc-sub-form :key="getUniqueId" :fieldObj="getFieldById(eachFieldInLevel1.id)" @onAction="mapFieldValue">
						
						</edc-sub-form>
					</v-col>
					<v-col v-else-if="eachFieldInLevel1.type === 'group'" :key="getUniqueId+'eachFieldInLevel1Index'+eachFieldInLevel1Index" class="EDC-ColControlPadding grpCol" align="left" :cols="eachFieldInLevel1.colspan">
						<v-card class="EDC-Group-Card"> 
							<v-row class="EDC-Row">
								<v-col cols="12" class="EDC-Col EDC-Group-Card-Header">
									{{getFieldById(eachFieldInLevel1.id).label}} <span v-if="getFieldById(eachFieldInLevel1.id).AddNewOption"><svgicon name="add_v2" class="svg-icon-mini svg-fill-mini" @click="addNewFieldsToGroup(eachField,level1RowIndex)"></svgicon>  <svgicon name="delete_v2" class="svg-icon-mini svg-fill-mini"></svgicon></span>
								</v-col>

							</v-row>
							<v-row class="EDC-Row" v-for="(level1Group,level1GroupIndex) in eachFieldInLevel1.gridSystem" :key="getUniqueId+'level1GroupIndex'+level1GroupIndex">
								<template v-for="(eachFieldlevel1Group,eachFieldlevel1GroupIndex) in level1Group.cols">
									<v-col  :key="getUniqueId+'eachFieldlevel1GroupIndex'+eachFieldlevel1GroupIndex" :cols="eachFieldlevel1Group.colspan" class="EDC-ColControlPadding" v-if="eachFieldlevel1Group.type === 'field'">
										<edc-sub-form :key="getUniqueId+'field'+eachFieldlevel1GroupIndex" :fieldObj="getFieldById(eachFieldlevel1Group.id)" @onAction="mapFieldValue">
							
										</edc-sub-form>
									</v-col>
								</template>
							</v-row>
						</v-card>
					</v-col>
					<v-col v-else-if="eachFieldInLevel1.type === 'tablist'" :key="getUniqueId+'tablist'+eachFieldInLevel1Index" align="left" :cols="eachFieldInLevel1.colspan" class="EDC-ColControlPadding">
						<v-tabs  non-linear>
							<v-tab v-for="(level1TabHeader,level1TabHeaderIndex) in eachFieldInLevel1.tabs" :key="getUniqueId+'tab'+level1TabHeaderIndex" class="EDC-Tabs">
								{{getFieldById(level1TabHeader.id).label}}
							</v-tab>
							<v-tab-item  v-for="(level1TabDetails,level1TabDetailsIndex) in eachFieldInLevel1.tabs" :key="getUniqueId+'level1TabDetailsIndex'+level1TabDetailsIndex">
								<v-row v-for="(level1TabItem,level1TabItemIndex) in level1TabDetails.gridSystem"  :key="getUniqueId+'level1TabItemIndex'+level1TabItemIndex" class="EDC-Row">
											<template v-for="(eachFieldInlevel1TabItem,eachFieldInlevel1TabItemIndex) in level1TabItem.cols">
												<v-col class="EDC-ColControlPadding" :key="getUniqueId+'eachFieldInlevel1TabItemIndex'+eachFieldInlevel1TabItemIndex" :cols="eachFieldInlevel1TabItem.colspan" v-if="eachFieldInlevel1TabItem.type === 'field'">
													<edc-sub-form :key="getUniqueId+'field'+eachFieldInlevel1TabItemIndex" :fieldObj="getFieldById(eachFieldInlevel1TabItem.id)" @onAction="mapFieldValue">
													
													</edc-sub-form>
												</v-col>
												<v-col class="EDC-ColControlPadding" v-else-if="eachFieldInlevel1TabItem.type === 'tablist'" :key="getUniqueId+'tablist'+eachFieldInlevel1TabItemIndex" align="left" :cols="eachFieldInlevel1TabItem.colspan">
													<v-tabs  non-linear>
														<v-tab v-for="(level2TabHeader,level2TabHeaderIndex) in eachFieldInlevel1TabItem.tabs" :key="getUniqueId+'tabs'+level2TabHeaderIndex" class="EDC-Tabs">
															{{getFieldById(level2TabHeader.id).label}}
														</v-tab>
														<v-tab-item  v-for="(level2TabDetails,level2TabDetailsIndex) in eachFieldInlevel1TabItem.tabs" :key="getUniqueId+'tabitem'+level2TabDetailsIndex">
															<v-row class="EDC-Row"  v-for="(level2TabItem,level2TabItemIndex) in level2TabDetails.gridSystem" :key="getUniqueId+level2TabItemIndex">
																<template v-for="(eachFieldInlevel2TabItem,eachFieldInlevel2TabItemIndex) in level2TabItem.cols">
																	<v-col class="EDC-ColControlPadding" :key="getUniqueId+'cols'+eachFieldInlevel2TabItemIndex" :cols="eachFieldInlevel2TabItem.colspan" v-if="eachFieldInlevel2TabItem.type === 'field'">
																		<edc-sub-form :key="getUniqueId+'field'+eachFieldInlevel2TabItemIndex" :fieldObj="getFieldById(eachFieldInlevel2TabItem.id)" @onAction="mapFieldValue">
												
																		</edc-sub-form>
																	</v-col>
																	<v-col class="EDC-ColControlPadding"  :key="getUniqueId+'blank'+eachFieldInlevel2TabItemIndex" :cols="eachFieldInlevel2TabItem.colspan" v-if="eachFieldInlevel2TabItem.type === 'blank'">
																		
												
																	</v-col>
																</template>
															</v-row>
														</v-tab-item>
													</v-tabs>

												</v-col>
											</template>

								</v-row>									
							</v-tab-item>
						</v-tabs>
					</v-col>
					<v-col class="EDC-ColControlPadding" :key="getUniqueId+eachFieldInLevel1Index" :cols="eachFieldInLevel1.colspan" v-else-if="eachFieldInLevel1.type === 'blank'">
																		
												
					</v-col>
					<!-- <v-col v-else>
						Not implemented
					</v-col> -->
				</template>
			</v-row>
			<v-row class="EDC-Row" v-if="showContactForm">
				<v-col class="EDC-ColControlPadding" align="end">
					<vc-button itemText="Submit" :disabled="!isAllFieldClientSideValid" :hidden="!showContactForm && !isExternalForm" @click.native="submitData"></vc-button>
					<vc-button itemText="Cancel" :hidden="!showContactForm && !isExternalForm" @click.native="redirectToHome"></vc-button>
				</v-col>
			</v-row>
			<v-row class="EDC-Row" v-if="isApproval">
				<v-col class="EDC-ColControlPadding" align="end" cols="3">
					<v-text-field dense label="Remark"   v-model="remark" autocomplete="off"></v-text-field>
				</v-col>
				<v-col class="EDC-ColControlPadding" align="start">
					<vc-button itemText="Approve"  @click.native="approve('accepted')"></vc-button>
					<vc-button itemText="Reject"  @click.native="approve('rejected')"></vc-button>
				</v-col>
			</v-row>
			<v-row class="EDC-Row" v-if="!showContactForm">
				<v-col class="EDC-ColControlPadding" align="end">
					<vc-button itemText="Submit" :hidden="!showContactForm" @click.native="submitEmail"></vc-button>
					<vc-button itemText="Cancel" :hidden="!showContactForm"></vc-button>
				</v-col>
			</v-row>
		<!-- </v-form> -->
		<loading-panel :loader="loader"></loading-panel>
		<v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
	</v-container>
</template>
<style scoped>
	
</style>
<script>
	const uuidv4 = require('uuid/v4')
	import edcSubForm from './edcSubForm.vue'
	import { UPDATE_WORKFLOW} from '../../data/url_constants.js'
	import {Snackbar_Default_Timeout, GRID_STATUS_COLOR_CODE, Ticket_management} from '@/constants/constants.js'
	import {getField,getUDCPrePropValues,getOtherControl,getCustomCheckboxPreProValues,getPaymentCreationValue} from '@/data/fieldData.js'
	import { get as getToServer } from '../../methods/serverCall.js';
	import { post as postToServer } from '../../methods/serverCall.js';
	import config from '../../config.json'
	export default {
		name:'edcForm',
        props:{
    		formJson:Object,
			formObj:Object,
			showContactForm:Boolean,
			isApproval:Boolean
  		},
		components:{
			'edc-sub-form':edcSubForm
		},
        watch:{
		'formJson':{
			handler(newValue){
				this.formJson = newValue
			}
    	},
		'formObj':{
			handler(newValue){
				this.formObj = newValue
			}
    	},
		'showContactForm':{
			handler(newValue){
				this.showContactForm = newValue
			}
    	},
		},
		data(){
			return {
				loader:false,
				keyIndex:1,
				snackbar:false,
				colorValue:'error',
				remark:'',
				snackbartimeout: Snackbar_Default_Timeout,
				snackbartext:'',
				isExternalForm:false,
				// formJson:{},
				onlyFieldList:[],
				workflow_id: null,
				workspace_id: null,
			}
		},
		computed:{
			isAllFieldClientSideValid(){
				for(let i=0;i<this.onlyFieldList.length;i++){
					if(_.isEmpty(this.onlyFieldList[i].validationDetails))
						continue
					 if(this.onlyFieldList[i].validationDetails.type === 'server_side')
					 	continue
					 if(this.onlyFieldList[i].isValid === false)
						return false
				}
				return true
			},
			getUniqueId(){
				return uuidv4()
			},
			showBanner(){
				return !this.$session || !this.$session.get('access_token')
			}
		},
		mounted(){
			var _this = this
			if(this.$route.query.token)
				this.validateToken()	
			// setTimeout(()=>{
			// 	_this.loadUDCValues()
			// },100)
		},
		methods:{
			validateToken(){
				var _this = this
				var token = this.$route.query.token
				this.formJson = {}
				getToServer(this, config.WORKFLOW_PRODUCT_URL + '/manageaform/' + token, true).then(response => {
					if(response.form_json.formHeader != "Supplier Basic Information"){
						for(let i=0;i<response.form_json.fieldList.length;i++)
						{	let field = response.form_json.fieldList[i]
							if(field.edcType === 'field')
								field.readonly = true
						}
						if(!response.security_actions)
							response['security_actions']=[]

						for(let i=0;i<response.security_actions.length;i++){
							let fieldId = response.security_actions[i].field_id
							let actions =  response.security_actions[i].action_list
							if(actions.indexOf("update_field_data")>-1){
								this.enableChilds(response.form_json.fieldList,fieldId)
							}
						}
					}
					debugger
					this.formJson = response.form_json
					this.workflow_id = response.workflow_id
					this.workspace_id = response.workspace_id
					this.addFieldsToFieldList(this.formJson.fieldList)
					if(!this.isApproval)
						this.showContactForm = true
					this.form_response = response
					console.log("Success")
					if(response.form_json.formHeader=="Supplier Detail Information")
						this.loadUDCValues()
					}).catch(error_response => {
					console.log("Failled")
					if(error_response){
						this.loader = null 
						this.snackbar = true
						this.colorValue = 'error'
						this.snackbartext = "Access Denied.";
						setTimeout(function(){
							_this.$router.push("/unauthorizedaccess")
						},1500)
						
						}
						else {
						this.snackbar = true
						this.colorValue = 'error'
						this.snackbartext = 'Something went wrong. Try Again';
						}
					})
				},
			getFieldById(id,fieldList){
				var _this = this
				if(!id)
					return
				if(!fieldList)
					fieldList = this.formJson.fieldList
				for(let i=0;i<fieldList.length;i++){
					if(fieldList[i].id === id)
						return fieldList[i]
					// else if(fieldList[i].groupId){
					// 	let returnList =  _this.getFieldById(fieldId,fieldList[i].fieldList)
					// 	if(returnList)
					// 		return returnList
					// }
				}
			},
			addFieldsToFieldList(fieldList){
				var _this = this 
				for(let i =0;i<fieldList.length;i++){
					if(fieldList[i].groupId)
						_this.addFieldsToFieldList(fieldList[i].fieldList)
					else
						_this.onlyFieldList.push(fieldList[i])
				}
			},
			mapFieldValue(fieldValue,fieldId,successfullyValidated){
				let fieldJson = this.getFieldById(fieldId,this.formJson.fieldList)
				if(!fieldJson)
				{
					alert('Something went wrong')
					return
				}
				fieldJson.value = fieldValue
				fieldJson.isValid = successfullyValidated
			},
			submitData(){
				if(this.formJson.formHeader === "Supplier Detail Information"){
					this.submitDetailFOrm()
				}
				if(this.formJson.formHeader === "Supplier Email Information"){
					this.submitEmail()
				}
				else{
					var _this = this
					this.loader = true
					let dataToPass= this.form_response
						dataToPass.form_json = this.formJson
					postToServer(this,config.EDC_FORM_MANAGEMENT_URL+'/form_execution',dataToPass).then(
					response=>{
						this.loader = false
						this.snackbar = true
						this.colorValue = 'success'
						this.snackbartext = "Form submited successfully.";
						// setTimeout(() => {  _this.$router.push("/successpage") }, 1000);
					}).catch(error=>{
						this.loader = false
						this.snackbar = true
						this.colorValue = 'error'
						this.snackbartext = error;
					})

				}
				
			},
			redirectToHome(){
				this.$router.push({"name":"Dashboard"})
			},
			submitDetailFOrm(){
				var _this = this
				this.loader = true
				var dataToPass = {
					"client_id":this.form_response? this.form_response.client_id :this.$session.get("client_id"),
					"client_name": this.form_response? this.form_response.client_name :this.$session.get("client_name"),
					"user_id":this.form_response? this.form_response.user_id :this.$session.get("user_id"),
					"recipient_email": this.form_response? this.form_response.recipient_email :this.$session.get("email"),
					"user_action": "reviewed",
					"workflow_id": this.form_response? this.form_response.workflow_id :this.formObj.workflow_id,
					"workflow_name": this.form_response? this.form_response.workflow_name :this.formObj.workflow_name,
					"workflow_step_master_id":  this.form_response? this.form_response.workflow_step_master_id :this.formObj.workflow_step_master_id,
					"workflow_master_id": this.form_response? this.form_response.workflow_master_id :this.formObj.workflow_master_id,
					"workflow_request_id":this.form_response? this.form_response.workflow_request_id :this.formObj.workflow_request_id,
					"form_json": this.form_response? this.form_response.form_json :this.formJson,
					"form_request_id": this.form_response? this.form_response.form_request_id :this.formObj.form_request_id,
					"master_data_id": this.form_response? this.form_response.master_data_id :this.formObj.master_data_id,
					"call_from": "Review Form",
					"approver_group_id":this.form_response? this.form_response.approver_group_id :this.formObj.approver_group_id,
					}
				postToServer(this,config.WORKFLOW_PRODUCT_URL+UPDATE_WORKFLOW,dataToPass).then(
				response=>{
					this.loader = false
					this.snackbar = true
					this.colorValue = 'success'
					this.snackbartext = "Form submited successfully.";
					// setTimeout(() => {  _this.$router.push("/successpage") }, 1000);
				}).catch(error=>{
					this.loader = false
					this.snackbar = true
					this.colorValue = 'error'
					this.snackbartext = error;
				})
			},
			addNewFieldsToGroup(groupInGridSystem,rowIndex){
				/*
					Have to check its reached to max limit or not. If it reach to max limit then do not allow to add new row.

					First we need to put field copy into the formJson then need to make changes in the grid system else it will cause an error for the form design.
				*/
				var _this = this
				console.log(groupInGridSystem)
				let grpObj = _this.getFieldById(groupInGridSystem.id)

			},
			removeFieldsFromGroup(groupInGridSystem,rowIndex){
				/* We have to keep atleast one group.*/
			},
			loadUDCValues(){
				var _this = this
				let udcList = []
				for(let i=0;i<this.onlyFieldList.length;i++){
					if(_.isEmpty(this.onlyFieldList[i].prePropValue) || !this.onlyFieldList[i].prePropValue.isFromApi || this.onlyFieldList[i].prePropValue.valueType != 'UDC')
						continue
					udcList.push({"fieldId":this.onlyFieldList[i].fieldId,"valueDetails":this.onlyFieldList[i].prePropValue.valueDetails})
				}
				if(!udcList.length)
					return
				let data = {"udcList":udcList,
							"workflow_id": _this.workflow_id,
							"workspace_id": _this.workspace_id,}
				postToServer(_this,config.WORKFLOW_PRODUCT_URL+'/get_udc_values',data, true).then(response=>{
					_this.manageResponse(response)
				}).catch(error=>{
					// setTimeout(()=>{
					// 	_this.manageResponse()
					// },1000)
				})
				
			},
			manageResponse(response){
				var _this = this

				for(let i=0;i<response.length;i++){
					let fieldId = response[i].fieldId
					let field = _this.getFieldById(fieldId)
					field.prePropValue.itemList = response[i].item_list
				}

			},
			approve(action) {
				var _this = this
				this.loader = true
				var client_id = this.$session.get('client_id')
				var UserData = {
					"client_id":this.form_response? this.form_response.client_id :this.$session.get("client_id"),
					"client_name": this.form_response? this.form_response.client_name :this.$session.get("client_name"),
					"user_id":this.form_response? this.form_response.user_id :this.$session.get("user_id"),
					"recipient_email": this.form_response? this.form_response.recipient_email :this.$session.get("email"),
					"user_action": action,
					"user_remark": this.remark,
					"workflow_id": this.form_response? this.form_response.workflow_id :this.formObj.workflow_id,
					"workflow_name": this.form_response? this.form_response.workflow_name :this.formObj.workflow_name,
					"workflow_step_master_id":  this.form_response? this.form_response.workflow_step_master_id :this.formObj.workflow_step_master_id,
					"workflow_master_id": this.form_response? this.form_response.workflow_master_id :this.formObj.workflow_master_id,
					"workflow_request_id":this.form_response? this.form_response.workflow_request_id :this.formObj.workflow_request_id,
					"form_json": this.form_response? this.form_response.form_json :this.formJson,
					"form_request_id": this.form_response? this.form_response.form_request_id :this.formObj.form_request_id,
					"master_data_id": this.form_response? this.form_response.master_data_id :this.formObj.master_data_id,
					"call_from": this.form_response? this.form_response.call_from :this.formObj.call_from,
					"approver_group_id":this.form_response? this.form_response.approver_group_id :this.formObj.approver_group_id,
					}
				postToServer(this, config.WORKFLOW_PRODUCT_URL + UPDATE_WORKFLOW, UserData).then(Response  => {
				if(Response){   
					this.loader = false
					this.snackbar = true
					this.colorValue = 'success'
					this.snackbartext = "Approval submitted successfully.";
					// setTimeout(() => {  _this.$router.push("/successpage") }, 1000);
					
				}
				}).catch(error_response => {            
				this.loader = false
				if(error_response){
					this.snackbar = true
					this.colorValue = 'error'
					this.snackbartext = error_response;
				}
				else {
					this.snackbar = true
					this.snackbartext = 'Something went wrong.Try Again';
					this.colorValue = 'error'
					
				}
			});
			},
			submitEmail(){
				if(this.showContactForm)
					this.$emit('contact_form_event', this.formJson)
				else{
					
					this.loader = true
					let dataToPass= this.formObj ? this.formObj : this.form_response
					dataToPass.form_json = this.formJson
					postToServer(this,config.EDC_FORM_MANAGEMENT_URL+'/form_execution',dataToPass).then(
					response=>{
						this.loader = false
					}).catch(error=>{
						this.loader = false
					})
				}
			},
			enableChilds(fieldList,parent_id){
				var _this = this
				for(let i=0;i<fieldList.length;i++){
					let fieldobj = fieldList[i]
					if(fieldobj.parentId === parent_id){
						if(fieldobj.edcType === 'field'){
							fieldobj.readonly = false
						}
						else
							_this.enableChilds(fieldList,fieldobj.id)
					}
				}
			}

		}
	}
</script>