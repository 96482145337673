/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ping_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" d="M.965.93a2.626 2.626 0 000 2.903l.247-.103a2.371 2.371 0 01-.42-1.35c0-.5.156-.964.42-1.348L.965.93zm4.42 0l-.247.102a2.374 2.374 0 010 2.698l.247.103a2.633 2.633 0 000-2.904zm-.751.313l-.253.106c.237.277.38.636.38 1.031 0 .396-.144.756-.381 1.034l.253.105a1.844 1.844 0 000-2.276zm-2.917 0a1.839 1.839 0 000 2.276l.253-.106a1.581 1.581 0 010-2.064l-.253-.106zm1.458.08A1.058 1.058 0 002.117 2.38a1.058 1.058 0 00.793 1.023v2.417h.53V3.405a1.058 1.058 0 00.793-1.024 1.058 1.058 0 00-1.058-1.058z"/>'
  }
})
