var highlighter_invalid_cell = {
		highlighter: {
			name: 'stroke',
			options: {
				padding: 10,
				rx: 5,
				ry: 5,
				attrs: {
					'stroke-width': 3,
					stroke: '#FF0000'
				}
			}
		}
	}

export function highlightUnHighlightInvalidCell(graph,paper,error_cell_lists,graph_table_error_message){
	var all_cells = graph.getCells()
	if(typeof error_cell_lists === "object" && graph_table_error_message === "Duplicate hierarchy name"){
		error_cell_lists = Object.keys(error_cell_lists[0])
	}
	for(var i=0;i<all_cells.length;i++){
		//first unhighlight the cell.
		paper.findViewByModel(all_cells[i]).unhighlight(null, highlighter_invalid_cell)
		if(error_cell_lists.indexOf(all_cells[i].id) >=0){
			paper.findViewByModel(all_cells[i]).highlight(null, highlighter_invalid_cell)
				
		}
	}
}

export function highlightUnHighlightInvalidBO(graph,paper,isHeighlight,cell_id,message){

	var all_cells = graph.getCells()
	for(var i=0;i<all_cells.length;i++){
		//first unhighlight the cell.
		if (!cell_id){
			// specific call for unheighlight
			paper.findViewByModel(all_cells[i]).unhighlight(null, highlighter_invalid_cell)
			continue
		}
		if(all_cells[i].id != cell_id)
			continue
		paper.findViewByModel(all_cells[i]).unhighlight(null, highlighter_invalid_cell)
		if(isHeighlight)
			paper.findViewByModel(all_cells[i]).highlight(null, highlighter_invalid_cell)
	}

}

