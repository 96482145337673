/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Email': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<g _fill="none" _stroke="#000" stroke-linejoin="round"><rect pid="0" width="46.047" height="34.047" x="8.953" y="17" rx="1.959" ry="1.702" stroke-width="1.953" stroke-linecap="round"/><path pid="1" d="M9 18l23 15 23-15" stroke-width="2"/></g>'
  }
})
