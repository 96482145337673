import config from '../config.json'
import {Archivist_Product,Workflow_Product,Dataviewer_Product,Develop_Product,Ticket_management,Ticket_Management_Domain} from '../constants/constants.js'

export function getIntialURL(){
	let url = this.$session.get('protocol')
	let domain_name = this.$session.get('erp_cloud_host')
	return url +'://'+domain_name
}

export function getURLByDomainByProduct(_this,product_name,route_path,query_string){
    let url = _this.$session.get('protocol')+'://'
    let lProductName = product_name.toLowerCase()
    let domain_name = window.location.hostname
	if(config.DOMAIN_NAME){
		if(lProductName == Ticket_management.toLowerCase())
			url = url +Ticket_Management_Domain+'.'+config.DOMAIN_NAME+'/'+route_path+query_string // for the server with domain
		else
        	url = url +lProductName+'.'+config.DOMAIN_NAME+'/'+route_path+query_string // for the server with domain
	}
	else  if(lProductName == Archivist_Product.toLowerCase() && !config.ARCHIVIST_PORT)
	    url = url + domain_name+":"+config.ARCHIVIST_PORT+'/'+route_path+query_string // for client DEPLOYMENT.
	else if(lProductName == Archivist_Product.toLowerCase() && config.ARCHIVIST_PORT)
	    url = url + domain_name+":"+config.ARCHIVIST_PORT+'/'+route_path+query_string // for local DEVELOPMENT
	else if(lProductName == Dataviewer_Product.toLowerCase() && !config.DATAVIEWER_PORT)
	    url = url + lProductName+"."+domain_name+":"+'/'+route_path+query_string // for client DEPLOYMENT.
	else if(lProductName == Dataviewer_Product.toLowerCase() && config.DATAVIEWER_PORT)
	    url = url + domain_name+":"+config.DATAVIEWER_PORT+'/'+route_path+query_string // for local DEVELOPMENT
	else if(lProductName == Develop_Product.toLowerCase() && !config.BUSINESSVIEW_PORT)
	    url = url + domain_name+":"+config.BUSINESSVIEW_PORT+'/'+route_path+query_string // for client DEPLOYMENT.
	else if(lProductName == Develop_Product.toLowerCase() && config.BUSINESSVIEW_PORT)
	    url = url + domain_name+":"+config.BUSINESSVIEW_PORT+'/'+route_path+query_string // for local DEVELOPMENT
	else if(lProductName == Ticket_management.toLowerCase() && !config.TICKETMANAGEMENT_PORT)
		url = url + Ticket_Management_Domain+"."+domain_name+'/'+route_path+query_string // for client DEPLOYMENT.
	else if(lProductName == Ticket_management.toLowerCase() && config.TICKETMANAGEMENT_PORT)
		url = url + domain_name+":"+config.TICKETMANAGEMENT_PORT+'/'+route_path+query_string // for local DEVELOPMENT
	else{
	    alert('selected product not configure')
	    return ''
	}
	return url
}

export function getURLByDomainByProductNew(_this,productDetails,route_path,query_string){
    let url = _this.$session.get('protocol')+'://'+productDetails.host_name
    if(productDetails.running_port)
    	url = url+':'+productDetails.running_port
    url = url + '/'+route_path

    url = url+'?product_key='+_this.$session.get('access_token')
    if(query_string)
    	url = url + query_string
    return url
}

export function getObjectRedirectionUrl(_this,additionalQueryString){
	let url = _this.$session.get('protocol')+'://'
	let productName = 'archivist'
    let queryString = '?product_key='+_this.$session.get('access_token')+"&product_name="+productName
    if(additionalQueryString)
    	queryString =queryString+"&"+additionalQueryString
    let domain_name = _this.$session.get('erp_cloud_host') ? _this.$session.get('erp_cloud_host') : "localhost"
    if(config.DOMAIN_NAME)
        url = url +'develop.'+config.DOMAIN_NAME+'/login'+queryString
    else
        url = url + domain_name+":"+config.BUSINESSVIEW_PORT+'/login'+queryString
    return url
}

export function getIframeUrl(_this,additionalQueryString){
	let url = _this.$session.get('protocol')+'://'
	let productName = 'archivist'
    let queryString = '?product_key='+config.APPLICATION_TOKEN+"&product_name="+productName
    if(additionalQueryString)
    	queryString =queryString+"&"+additionalQueryString
	let domain_name = _this.$session.get('erp_cloud_host') ? _this.$session.get('erp_cloud_host') : "localhost"
    if(config.DOMAIN_NAME)
        url = url +'develop.'+config.DOMAIN_NAME+'/workflowstatus'+queryString
    else
        url = url + domain_name+":"+config.BUSINESSVIEW_PORT+'/workflowstatus'+queryString
    return url
}