/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'User_Unlock': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<rect pid="0" width="32.122" height="28.122" x="15.939" y="27.939" rx="2.008" ry="1.758" _fill="none" _stroke="#000" stroke-width="1.878" stroke-linecap="round"/><path pid="1" d="M30 41h4v7h-4z"/><circle pid="2" cx="32" cy="38" r="4"/><path pid="3" d="M25 20v8" _fill="none" _stroke="#000" stroke-width="2"/><path pid="4" d="M25 20c0-5.483-4.477-9.928-10-9.928S5 14.517 5 20M5 20v8" _fill="none" _stroke="#000" stroke-width="2" stroke-linecap="round"/>'
  }
})
