/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add_multiple_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M4.424.53a1.4 1.4 0 00-1.397 1.397l.003.062c.092.024.181.054.266.091-.006-.05-.015-.1-.015-.153a1.142 1.142 0 112.286 0A1.14 1.14 0 014.424 3.07c-.055 0-.108-.01-.16-.016.036.085.064.175.088.266.024.001.048.004.072.004.77 0 1.397-.626 1.397-1.396A1.4 1.4 0 004.424.53zM4.418 1a.254.254 0 00-.25.256v.406h-.405c-.339 0-.339.509 0 .509h.405v.405c0 .339.508.339.508 0V2.17h.405c.34 0 .34-.509 0-.509h-.405v-.406A.254.254 0 004.418 1zM2.381 2.115A1.855 1.855 0 00.53 3.969c0 1.02.831 1.852 1.852 1.852a1.855 1.855 0 001.852-1.852 1.855 1.855 0 00-1.852-1.853zm0 .337c.84 0 1.515.677 1.515 1.516S3.22 5.484 2.381 5.484A1.512 1.512 0 01.866 3.969c0-.84.676-1.516 1.515-1.516zm-.008.286a.337.337 0 00-.331.34v.54h-.537a.337.337 0 000 .673h.537v.537a.337.337 0 00.674 0v-.537h.537a.337.337 0 000-.674h-.537V3.08a.337.337 0 00-.343-.34z" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
