/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'forget_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M3.795.265a.785.785 0 00-.141.007.645.645 0 00-.47.308L1.809 2.973 4.102 4.29 5.477 1.9a.646.646 0 00.029-.563.866.866 0 00-.38-.421L4.212.387a.903.903 0 00-.416-.122zM3.73.797a.358.358 0 01.216.049l.916.528a.355.355 0 01.153.16c.02.052.014.08.002.101l-1.11 1.934-1.377-.791L3.643.844c.012-.022.032-.039.088-.047zM1.545 3.432l-.485.842a.578.578 0 00.002.543.93.93 0 00.38.386l.918.528a.931.931 0 00.525.133.578.578 0 00.47-.272l.483-.841-.228-.133-2.065-1.186zM.53 5.688v.266h1.059v-.266H.529zm3.176 0v.266H5.82v-.266H3.705z" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
