import * as edcAnonymousObj from  "./anonymousFunctions.js"
import * as edcLinkObj from './links.js'
import * as graphObj from './graph.js'


export function mouseOverEvent(that,item,conditionCheck){
	if(conditionCheck && ((conditionCheck === 'selection' && !that.isCellSelected) 
					  ||(conditionCheck === 'copied' && !that.elementCutCopied)))
		return false
	item.target.style.backgroundColor = "grey";
}

export function mouseOutEvent(that,item){
		item.target.style.backgroundColor = "white";
}

export function contextMenuItemClick(that,action_type,graph,graphFor){
	let embeddedCell = edcAnonymousObj.getEmbeddedCell(that.selectedElementForContextMenu)
	let relatedLinks = edcLinkObj.getRelatedLinks(embeddedCell,graph,graphFor,true)
	if((action_type === 'cut' || action_type === 'copy') && that.selectedElementForContextMenu.id){
		that.elementWrapperCutCopied = that.selectedElementForContextMenu.clone()
		that.elementCutCopied = embeddedCell
		if(action_type === 'cut'){
			that.selectedElementForContextMenu.remove()
		}
	}else if(action_type === 'paste'){
		let element = that.elementCutCopied.clone()
		let elmentWrapper = that.elementWrapperCutCopied.clone()
		element.position(50, 50)
		elmentWrapper.position(50,50)
		elmentWrapper.embed(element)
		that.edcGraph.addCells([element,elmentWrapper])
	}else if(action_type === 'delete'){
		// now we are showing magnet on the tranferent border. If user press 
		// delete button there then it only delete child. So we are finding parent and delete it.
		let parentCell = that.selectedElementForContextMenu.getParentCell()
		if(parentCell)
			parentCell.remove()
		else
			that.selectedElementForContextMenu.remove()

		let relatedJoins = []
		if(that.copyJoinJson && that.copyJoinJson.length){
			for(let i=0;i<relatedLinks.length;i++){
				let reletedJoin = _.find(that.copyJoinJson,relatedLinks[i].id)
				if(reletedJoin)
					relatedJoins.push(reletedJoin)
			}
		}
		if(graphFor === 'data_selection' ||graphFor === 'business_object'){
			let zippedElement = graphObj.zipElement(embeddedCell,graphFor) 
			that.$emit('sendNonGraphEvent','remove_table',{"element":zippedElement,'relatedLinks':relatedLinks, 
				'step_id':that.parentdigramobj?that.parentdigramobj.stepId:"",'relatedJoins':relatedJoins})
		}
		else
			that.sendElementEvent('remove_element',{"element":embeddedCell,'relatedLinks':relatedLinks})
		 
	}else {

	}
		// if(action_type==='new'){
		// 	that.AddNewCell()
		// }
		// else if(action_type==='cut'){
		// 	if(that.step_name_list){
		// 		var step_name = that.selected_el.model.prop('step_name')
		// 		that.step_name_list.splice(that.step_name_list.indexOf(step_name),1)
		// 	}
		// 	that.cut_copy_ele = that.selected_el.model.clone()
		// 	that.elementCutCopied = true
		// 	that.selected_el.model.remove()

		// 	// let data = that.graph.toJSON(); 
		// 	// console.log("data"+JSON.stringify(data));
		// }
		// else if(action_type === 'copy'){
		// 	that.cut_copy_ele = that.selected_el.model.clone()
		// 	that.elementCutCopied = true
		// 	if(that.cut_copy_ele.attributes.attrs['.name'].text){
		// 		var copied_text = 'Copy of ' +that.cut_copy_ele.attributes.attrs['.name'].text
		// 		that.cut_copy_ele.attributes.attrs['.name'].text = copied_text
		// 	}
		// }
		// else if(action_type === 'paste'){
		// 	var main_div = that.$refs.main_container

		// 	var copy_el = that.cut_copy_ele
		// 	copy_el.position(main_div.scrollLeft + 50, main_div.scrollTop+50)
		// 	that.graph.addCell(copy_el.clone())	
		// }
		// else if(action_type === 'delete'){
		// 	if(that.step_name_list){
		// 		var step_name = that.selected_el.model.prop('step_name')
		// 		that.step_name_list.splice(that.step_name_list.indexOf(step_name),1)
		// 	}
		// 	that.selected_el.model.remove({disconnectLinks : false})
		// 	that.isSelected = false
		// }
		// else if(action_type === 'rename'){
		// 	that.step_name = that.selected_el.model.attr('text/text')
		// 	that.step_description = that.selected_el.model.attr('desciption')
		// 	that.dialog = true
		// }
	}