export const GRID_SIZE = 5
export const GRID_COLOR = '#FFFFFF'
export const LINK_RELATIONSHIP = [
								{id: "1:1",name: "1:1"},
								{id: "1:M",name: "1:M"},
								{id: "M:1",name: "M:1"},
								{id: "M:M",name: "M:M"}]

export const CONTEXT_MENU_ITEMS = [
									{"id":"cut","name":"Cut"},
									{"id":"copy","name":"Copy"},
									{"id":"paste","name":"Paste"},
									{"id":"delete","name":"Delete"},
									{"id":"rename","name":"Rename"}
								]

export const BUSINESS_OBJECT_TABLE_WIDTH = 120
export const BUSINESS_OBJECT_TABLE_HEIGHT = 60
export const BUSINESS_OBJECT_TABLE_BORDER_RX = 10
export const BUSINESS_OBJECT_TABLE_NAME_REFY = 0

export const BUSINESS_OBJECT_TABLE_DESCRIPTION_REFY = 75

export const BUSINESS_EDC_CELL_TYPE = 'business_table'
export const BUSINESS_EDC_CELL_CONTAINER_TYPE = 'business_table_container'
export const BUSINESS_EDC_CELL_KEY_TYPE = 'business_keys'

export const BUSINESS_OBJECT_KEYS_NAME = 'Business Object Keys'
export const BUSINESS_OBJECT_KEYS_CONTAINER_TYPE = 'business_key_table_container'

export const CENTER_OF_BO_GRAPH_PAPER = ((window.innerWidth-(window.innerWidth*.20))/2-((BUSINESS_OBJECT_TABLE_WIDTH)/2))-25 // 25 for adjusting the scroll and left/right side space

export const GRAPH_EVENTS_FOR_REDESIGN = ['add','remove','change','redesign','cellpropchange']

export const GRAPH_FOR_BUSINESS_OBJECT = 'business_object'
export const GRAPH_FOR_PROCESS_DESIGNER = 'process_designer'
export const GRAPH_FOR_DATA_SELECTION = 'data_selection'
export const GRAPH_FOR_DATA_MAP = 'data_map'
export const GRAPH_FOR_BUSINESS_VIEW = 'business_view'
export const GRAPH_FOR_WORKFLOW = 'edcWorkflow'

export const PROCESS_DESIGNER_SHAPE_BORDER_RX = 12
export const PROCESS_DESIGNER_SHAPE_WIDTH = 80
export const PROCESS_DESIGNER_SHAPE_HEIGHT = 80
export const PROCESS_DESIGNER_EDC_CELL_TYPE = 'process_designer_step'
export const PROCESS_DESIGNER_EDC_CELL_CONTAINER_TYPE = 'process_designer_step_container'
export const SELECTION_STEP = 'Selection'
export const MINUS_STEP = 'Minus'
export const MERGE_STEP = 'Merge'
export const ARCHIVE_STEP = 'Archival'
export const COPY_STEP = "Copy"
export const PURGE_STEP = "Purge"
export const DUPLICATE_STEP = "Duplicate"
export const PROCEDURE_STEP = 'Procedure'
export const PROCESS_DESIGNER_DESCRIPTION_REFY = 100

export const WORKFLOW_DESIGNER_SHAPE_WIDTH = 95
export const WORKFLOW_DESIGNER_SHAPE_HEIGHT = 80
export const WORK_FLOW_EDC_CELL_TYPE = 'workf_flow_step'
export const SEND_EMAIL_STEP = 'email_request'
export const WAIT_FOR_FORM_DATA_STEP = 'Wait For Form Submit'
export const FORM_MAPPING_STEP = 'Form Mapping'
export const REVIEW_STEP = 'Review'
export const APPROVAL_STEP = 'Approval'
export const START_STEP = 'Start'
export const CONDITION_STEP = 'Condition'
export const FORM_SELECTION_STEP = 'Form Selection'
export const END_STEP = 'End'

export const CELL_HIGHLIGHTER =  {
		highlighter: {
			name: 'stroke',
			options: {
				padding: 0,
				rx: 9,
				ry: 9,
				attrs: {
					'stroke-width': 5,
					stroke: '#FFA500'
				}
			}
		}
	}

export const RUNNING_CELL_HIGHLIGHTER =  {
	highlighter: {
		name: 'stroke',
		options: {
			padding: 0,
			rx: 0,
			ry: 0,
			attrs: {
				'stroke-width': 3,
				stroke: '#0000FF'
			}
		}
	}
}

export const COMPLETED_CELL_HIGHLIGHTER =  {
	highlighter: {
		name: 'stroke',
		options: {
			padding: 0,
			rx: 0,
			ry: 0,
			attrs: {
				'stroke-width': 3,
				stroke: '#008000'
			}
		}
	}
}

export const AUTO_VALIDATOR_TIMER = 1000

export const WORKFLOW_SHAPE_WIDTH = 64
export const WORKFLOW_SHAPE_HEIGHT = 64

export const WORKFLOW_DESIGNER_DESCRIPTION_RFEY = 72

export const EDC_BEGIN ={"name":"Begin","value":"begin","is_for_status":false,"withoutWrapper":true}
export const EDC_END ={"name":"End","value":"end","is_for_status":false,"withoutWrapper":true}
export const EDC_CONDITION ={"name":"Condition","value":"condition","is_for_status":false,"withoutWrapper":true}
export const EDC_SEND_EMAIL ={"name":"Send Email","value":"send_email","is_for_status":true,"withoutWrapper":false}
export const EDC_SEND_FORM_LINK = {"name":"Send Form Link","value":"send_form_link","is_for_status":true,"withoutWrapper":false}
export const EDC_FORM_REVIEW = {"name":"Form Review","value":"form_review","is_for_status":true,"withoutWrapper":false}
export const EDC_FORM_RESPONSE_WAIT = {"name":"Form Response Wait","value":"form_response_want","is_for_status":true,"withoutWrapper":false}
export const EDC_FORM_DATA_TRANSFER = {"name":"Form Data Transfer","value":"form_data_transfer","is_for_status":true,"withoutWrapper":false}
export const EDC_APPROVAL = {"name":"Approval","value":"approval","is_for_status":true,"withoutWrapper":false}
export const JDE_SUPPLIER = {"name":"JDE Supplier Master","value":"jde_supplier_master","is_for_status":true,"withoutWrapper":true}
export const JDE_CUSTOMER = {"name":"JDE Customer Master","value":"jde_customer_master","is_for_status":true,"withoutWrapper":true}
export const JDE_ITEM = {"name":"JDE Item Master","value":"jde_item_master","is_for_status":true,"withoutWrapper":true}

export const row0col0Location = {"positionX":10,"positionY":5}
export const row0col1Location = {"positionX":100,"positionY":5}
export const row0col2Location = {"positionX":190,"positionY":5}

export const row1col0Location = {"positionX":10,"positionY":80}
export const row1col1Location = {"positionX":100,"positionY":80}
export const row1col2Location = {"positionX":190,"positionY":80}