<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>Ticket Status</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2" class="EDC-Col" style="float:right">
        <!-- <v-text-field label="Search"  v-model="search_text"  @keydown.enter="search_text ? getActivityLogs(envIpJson) : null" prepend-inner-icon="search"></v-text-field> -->
      </v-col>
    </v-row>

    <v-container>
      <v-card elevation-1  style="padding:10px !important">
        <v-row class="EDC-Row" no-gutters>
          <v-tabs v-model="activeTab" non-linear @change="tabChange">
            <v-tab class="EDC-Tabs"   key="tab1">
              <span >Open Tickets</span>
            </v-tab>
            <v-tab class="EDC-Tabs" key="tab2">
              <span >Closed Tickets</span>
              
            </v-tab>
            <v-tab class="EDC-Tabs" key="tab3" v-if="show_search_results" style="float:right">
              <span >Search Results</span>
              <svgicon  class="svg-icon-toolbar svg-fill-grid icon-spacing" name="cancel_v2"    @click="closeResults" title="Close"></svgicon>
            </v-tab>
            <!-- <v-text-field class="searchbox-fixed" style="padding-top: 5px !important" label="Search" hide-details dense  v-model="search_text"  @keydown.enter="search_text ? getActivityLogs(envIpJson) : null" prepend-inner-icon="search" autocomplete="off"></v-text-field> -->
            <!-- <v-tab class="EDC-Tabs" key="tab2">
              <span >Active Tickets</span>
            </v-tab>
            <v-tab class="EDC-Tabs" key="tab3">
              <span >Waiting Tickets</span>
            </v-tab> -->
            <!-- <v-tab class="EDC-Tabs" key="tab4">
              Closed Tickets
            </v-tab> -->
            <v-tab-item>
              <edc-data-grid key="grid-A" :dataList="tableList" @onHistory="onHistory" @onNew="onNew" @ondblClick="onHistory"  @onSort="sortBy" @onEdit="onHistory" @onDelete="deleteTicket" @onPageChange="onPageChange" @onPerPageChange="onPerPageChange"  @UpdateFilter="filterData" @onCloseAction="onCloseAction" @onInvalid="onInvalid" @onOther="onOther" @onDuplicate="onDuplicate"></edc-data-grid>
            </v-tab-item>
            <v-tab-item>
              <edc-data-grid key="grid-B" :dataList="closedticketList" @onHistory="onHistory" @onNew="onNew" @ondblClick="onHistory"  @onSort="sortBy" @onEdit="onHistory" @onDelete="deleteTicket" @onPageChange="onPageChange" @onPerPageChange="onPerPageChange"  @UpdateFilter="filterData" @onCloseAction="onCloseAction" @onInvalid="onInvalid" @onOther="onOther" @onDuplicate="onDuplicate"></edc-data-grid>
            </v-tab-item>
            <v-tab-item >
             <v-row no-gutters class="EDC-Row" style="padding-top:20px !important">
                  <v-col cols="12" class="EDC-Col EDC-ColsSpacing">
                    <v-list   v-for="(item, index) in activityTableList.rows" :key="item.ticket_id" >
                          <v-card :color="getTileColor(index)" @click="redirectToActivity(item.ticket_id,item.log_id)">
                          <v-row no-gutters class="EDC-Row EDC-RowsSpacing" >
                            <v-col cols="2" class="EDC-Col EDC-ColsSpacing" align="left">
                            <v-list-item-title style="padding-left:8px !important;">{{item.title}}</v-list-item-title>
                            </v-col>

                            <v-col cols="3" class="EDC-Col EDC-ColsSpacing" align="left">
                            <v-list-item-title>{{item.action_by}}</v-list-item-title>
                            </v-col>
                            <v-col cols="2" class="EDC-Col EDC-ColsSpacing" align="left">                                
                              <v-list-item-title>{{item.tz_action_date_time}}</v-list-item-title>
                            </v-col>
                          </v-row>
                            <v-row no-gutters class="EDC-Row EDC-RowsSpacing">
                            <v-col cols="2" class="EDC-Col EDC-ColsSpacing" align="left">                                
                              <v-icon
                                large
                                v-if="item.activity_name == 'COMMENT ADDED'"
                                color="primary"
                                >
                                mdi-message-text
                                </v-icon>
                                <v-icon
                                large
                                v-if="item.activity_name == 'ATTACHMENT ADDED'"
                                color="primary"
                                >
                                attachment
                                </v-icon>
                                <v-icon
                                large
                                v-if="item.activity_name == 'TICKET CREATED' || item.activity_name == 'DESCRIPTION ADDED'
                                || item.activity_name == 'DESCRIPTION REMOVED' || item.activity_name == 'DESCRIPTION UPDATED'"
                                color="primary"
                                >
                                create
                                </v-icon>
                                <v-icon
                                large
                                v-if="item.activity_name == 'TICKET UPDATED'"
                                color="primary"
                                >
                                create
                                </v-icon>
                                <v-icon
                                large
                                v-if="item.activity_name == 'UPDATED TICKET STATUS'"
                                color="primary"
                                >
                                update
                                </v-icon>
                            </v-col>
                            <v-col cols="8" class="EDC-Col EDC-ColsSpacing" align="left">
                              <v-content>
                              <v-container fluid style="padding: 0px !important">
                              <span v-html="item.activity_description"></span> 
                              </v-container>
                              </v-content>
                            </v-col>

                          </v-row>
                          </v-card>
                    </v-list>
                  </v-col>
                </v-row>
            </v-tab-item>
            <!-- <v-tab-item>
              <edc-data-grid key="grid-d" :dataList="closedticketList" @onHistory="onHistory" @onSort="sortBy" @onEdit="onEdit" @onDelete="deleteTicket" @onPageChange="onPageChange" @onPerPageChange="onPerPageChange"  @UpdateFilter="filterData"></edc-data-grid>
            </v-tab-item> -->
          </v-tabs>
        </v-row>
      </v-card>
    </v-container>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
     <loading-panel :loader="loader"></loading-panel>
    <v-dialog v-model="dialog" width="300">
      	<v-form ref="ticket_form">
        	<v-col cols="12" class="EDC-Col">
	          	<v-card elevation-1>
	            	<v-row no-gutters class="EDC-Row">
	             		<v-toolbar dark dense absolute style="width:100%">
	                		<span><b>Ticket ID: {{ task_name }}</b></span>
	              		</v-toolbar>
	            	</v-row>                
                <v-row no-gutters class="EDC-Row EDC-RowsSpacing" style="padding-top:60px !important;">
                  <span style="float:left;padding-bottom:4px;padding-left:4px;font-size:12px !important" class="subheading">Select Action</span>
	             		<v-radio-group v-model="action_type"  required hide-details row class="EDC-UIComponent" dense>
                            <v-radio class="customRadio" :color="colorCode" v-for="(n, index) in ticket_action_list" :key="index" :label="n.sub_status" :value="n.id" dense></v-radio>
                          </v-radio-group>
                  <!-- <v-radio-group v-model="action_type" v-else required hide-details row class="EDC-UIComponent" dense>
                            <v-radio class="customRadio" :color="colorCode" v-for="(n, index) in close_action_list" :key="index" :label="n.id" :value="n.id" dense></v-radio>
                          </v-radio-group> -->
	            	</v-row>
	            	<v-row no-gutters class="EDC-Row EDC-RowsSpacing">
	             		<v-text-field v-if="action_type === 'other'" v-model="reason" label="Enter comment" :rules="reasonRules" required @keypress="reasonKeypress" class="EDC-TextField" hide-details autocomplete="off" dense></v-text-field>
	            		<v-text-field v-else v-model="reason" label="Enter comment" class="EDC-TextField" hide-details autocomplete="off" dense></v-text-field>
	            	</v-row>
	            	<v-row no-gutters class="EDC-Row EDC-RowsSpacing" justify="end">
	              		<vc-button itemText="Submit" @click.native="ticket_action_update"></vc-button>
	            	</v-row> 
	          	</v-card>
	        </v-col>
      	</v-form>
    </v-dialog>
    <v-dialog v-model="show_add_dialog" width="auto">
        	<v-col cols="12" class="EDC-Col">
	          	<v-card elevation-1>
                <v-row no-gutters class="EDC-Row">
	             		<v-toolbar dark dense absolute class="EDC-Toolbar" style="width:100%; padding-bottom:10px;border-radius:0px !important;">
                    <v-toolbar-title class="EDC-ToolbarTitle">Create Ticket</v-toolbar-title>
                    <v-spacer></v-spacer>
                <svgicon  class="svg-icon-grid svg-fill-toolbar" name="cancel_v2"  :original="true" @click="onCancled" title="Close"></svgicon>
	              		</v-toolbar>
	            	</v-row>
                <v-row class="EDC-Row EDC-RowsSidePadding" style="padding-top:28px !important;">
	            	<create-ticket v-if="show_add_dialog" @onCreated="onCreated" callFrom='create_dialog' @onCancled='onCancled'></create-ticket>
                </v-row>
	          	</v-card>
	        </v-col>
    </v-dialog>
    </div>
</template>
<script>
import {mapActions} from 'vuex';
import config from '../../config.json'
import {TABLE_LIST, ACTIVITY_TABLE_LIST} from './ticketMetaData.js'
import {
  post as postToServer
} from './../../methods/serverCall.js';
import {
  get as getToServer
} from './../../methods/serverCall.js';
import { GET_ALL_USERS_LIST,GET_ALL_TICKETS,DELETE_TICKET_URL,UPDATE_TICKET_STATUS,SEARCH_TICKET_LOGS,GET_QUEUE_FROM_TAG,GET_TICKET_STATUS } from '../../data/url_constants.js'
import {
  SERVER_ERROR
} from '../../data/client_message.js'
import {CLIENT_SIDE, COLOR_CODE, TICKET_MANAGEMENT_SERVICE,CREATE_ACTION,READ_ACTION,
 UPDATE_ACTION, DELETE_ACTION, CREATE_TICKET, READ_TICKET, DELETE_TICKET, CHANGE_TICKET_STATUS} from '../../data/macros.js'
import cloneDeep from 'lodash/cloneDeep';
import {Snackbar_Default_Timeout,Ticket_management} from '@/constants/constants.js'
import {getTicketRole} from './../../methods/GetUserRole.js'
import ticketDetails from './ticketDetailsNew.vue'
import {getActionByGroup} from './../../methods/TicketActionsAndQueueList.js'
// import Breadcrumb from "../Breadcrumbs.vue"

export default {
  name: 'TicketStatus',
  
  data: function () {
    return {
      userRole:{},
      loader:false,
      colorCode:COLOR_CODE,
      snackbartext:'',
      activeTab:0,
      dialog:false,
      colorValue: 'error',
      snackbar:false,
      task_name:'',
      task_status:'',
      show_search_results:false,
      show_add_dialog:false,
      reason:"",
      ticket_action_list_: [],
      // ticket_action_list:[{name:"In progress",
      //                     id:"In progress"},
      //                     {name:"Closed",
      //                     id:"Resolved"},
      //                     {name:"Closed",
      //                     id:"Duplicate"},
      //                     {name:"Closed",
      //                     id:"Invalid"},
      //                     {name:"Closed",
      //                     id:"Other"}],
      ticket_action_list: [],
      search_text:'', 
      redirect_search_text:'',                         
      close_action_list:[{name:"Open",
                          id:"Open"},],
      action_type:'Resolved',
      reasonRules:[
				v => !!v || 'User comments are required'
            	],
      snackbartimeout: Snackbar_Default_Timeout,
      tableList:_.cloneDeep(TABLE_LIST),
      activeticketList: _.cloneDeep(TABLE_LIST),
      waitingticketList: _.cloneDeep(TABLE_LIST),
      closedticketList: _.cloneDeep(TABLE_LIST),
      activityTableList: _.cloneDeep(ACTIVITY_TABLE_LIST),
      available_queues:[],
      actions_by_group:[],
      envIpJson : {
          "filter": [],
          "sort": [],
          "page": 1,
          "page_size": 10
      },
    }
  },
  mounted() {
    this.setclearSearchText({})
    this.userRole = this.$session.get('user_role_mapping');
    this.actions_by_group = this.$session.get('user_role_mapping');
    console.log(this.userRole)
    this.getTicketStatus()
    getActionByGroup(this)  
      this.tableList.actions=[
            {'text':'new','key':"ticket_id", selectType:"single", role:this.userRole.is_superadmin || getTicketRole(this.actions_by_group,TICKET_MANAGEMENT_SERVICE,CREATE_TICKET), index:1},
            {'text':'edit','key':"ticket_id", selectType:"single",role:this.userRole.is_superadmin || getTicketRole(this.actions_by_group,TICKET_MANAGEMENT_SERVICE,READ_TICKET), index:2},
            {'text':'delete','key':"ticket_id", selectType:"single", role:this.userRole.is_superadmin ||  getTicketRole(this.actions_by_group,TICKET_MANAGEMENT_SERVICE,DELETE_TICKET),index:3},
            {'text':'closeAction','key':"ticket_id", selectType:"single", role:this.userRole.is_superadmin || getTicketRole(this.actions_by_group,TICKET_MANAGEMENT_SERVICE,CHANGE_TICKET_STATUS),index:4}
            ];
       this.activeticketList.actions=[
             {'text':'new','key':"ticket_id", selectType:"single", role:this.userRole.is_superadmin || getTicketRole(this.actions_by_group,TICKET_MANAGEMENT_SERVICE,CREATE_TICKET), index:1},
            {'text':'edit','key':"ticket_id", selectType:"single",role:this.userRole.is_superadmin || getTicketRole(this.actions_by_group,TICKET_MANAGEMENT_SERVICE,READ_TICKET), index:2},
            {'text':'delete','key':"ticket_id", selectType:"single", role:this.userRole.is_superadmin ||  getTicketRole(this.actions_by_group,TICKET_MANAGEMENT_SERVICE,DELETE_TICKET),index:3},
            {'text':'closeAction','key':"ticket_id", selectType:"single", role:this.userRole.is_superadmin || getTicketRole(this.actions_by_group,TICKET_MANAGEMENT_SERVICE,CHANGE_TICKET_STATUS),index:4}
            ];
       this.waitingticketList.actions=[
            {'text':'new','key':"ticket_id", selectType:"single", role:this.userRole.is_superadmin || getTicketRole(this.actions_by_group,TICKET_MANAGEMENT_SERVICE,CREATE_TICKET), index:1},
            {'text':'edit','key':"ticket_id", selectType:"single",role:this.userRole.is_superadmin || getTicketRole(this.actions_by_group,TICKET_MANAGEMENT_SERVICE,READ_TICKET), index:2},
            {'text':'delete','key':"ticket_id", selectType:"single", role:this.userRole.is_superadmin ||  getTicketRole(this.actions_by_group,TICKET_MANAGEMENT_SERVICE,DELETE_TICKET),index:3},
            {'text':'closeAction','key':"ticket_id", selectType:"single", role:this.userRole.is_superadmin || getTicketRole(this.actions_by_group,TICKET_MANAGEMENT_SERVICE,CHANGE_TICKET_STATUS),index:4}
            ];
       this.closedticketList.actions=[
            {'text':'new','key':"ticket_id", selectType:"single", role:this.userRole.is_superadmin || getTicketRole(this.actions_by_group,TICKET_MANAGEMENT_SERVICE,CREATE_TICKET), index:1},
            {'text':'edit','key':"ticket_id", selectType:"single",role:this.userRole.is_superadmin || getTicketRole(this.actions_by_group,TICKET_MANAGEMENT_SERVICE,READ_TICKET), index:2},
            {'text':'delete','key':"ticket_id", selectType:"single", role:this.userRole.is_superadmin ||  getTicketRole(this.actions_by_group,TICKET_MANAGEMENT_SERVICE,DELETE_TICKET),index:3},
            {'text':'closeAction','key':"ticket_id", selectType:"single", role:this.userRole.is_superadmin || getTicketRole(this.actions_by_group,TICKET_MANAGEMENT_SERVICE,CHANGE_TICKET_STATUS),index:4}
            ];
      if(this.$route && this.$route.params){
        if(this.$route.params.graphFilter)
          this.envIpJson.filter.push(this.$route.params.graphFilter)
        this.envIpJson.sort = [{column_name: "priority_sequence_number",
							type: "asc"},
							{column_name: "tz_created_date_time",
							type: "desc"}],
        this.tableList.updateInlineFilter=this.$route.params.graphFilter ? this.$route.params.graphFilter : []
        // this.envIpJson.filter.push({'column_name': 'priority_name', 'column_actual_name': 'priority_name', 'value1': 'low', 'operator': '_cl_', 'hideKey': true, 'isInlineFilter': true, 'actual_value1': 'low', 's_parentheses': '(', 'e_parentheses': ')'})
      }
      this.getAvailalbleQueues(this.$session.get('mapped_user_groups'))
      
       if(!this.userRole.is_superadmin && !getTicketRole(this.actions_by_group,TICKET_MANAGEMENT_SERVICE,CREATE_TICKET)){
          this.tableList.showNew = false
          this.activeticketList.showNew = false
          this.waitingticketList.showNew = false
          this.closedticketList.showNew = false
       }       
  },
  components: {
    'create-ticket':ticketDetails
  },
  methods: {
    ...mapActions(["setclearSearchText"]),
    getTicketStatus(record){
        var _this = this
         getToServer(this, config.EDC_TICKET_MANAGEMENT_SERVICE_URL + GET_TICKET_STATUS
        ).then(response  => {
            _this.ticket_action_list_ = response
        }).catch(ticketResponseError => {
                // _this.loader = false;
                if(ticketResponseError){
                    _this.loader = null                   
                }
                else { 
                    _this.snackbar = true                   
                    _this.colorValue = 'error'
                    _this.snackbartext = SERVER_ERROR;                    
                }
            });
        },
    onNew(){
      let _this = this;
      this.show_add_dialog = true
      // this.$router.push({ name: 'TicketDetails'});
    },
    onCreated(){
      //Once ticket is created hide create ticket dialog and load open tickets again
      this.show_add_dialog =false
      this.getOpenTicketList(this.envIpJson)
    },
    onCancled(){
      this.show_add_dialog =false
    },
    tabChange(){
      if(this.activeTab === 1)
        this.getClosedTicketList(this.envIpJson)
      // else if(this.activeTab === 2)
      //   this.getWaitingTicketList(this.envIpJson)
      // else if(this.activeTab === 2)
      //   this.getActivityLogs(this.envIpJson)
      },
      getOpenTicketList(envIpJson){
          var _this = this
          var inputJson = {"type": "Open",
                            "client_id": this.$session.get('client_id'),
                            "queue_id_list": this.available_queues,
                             "page":envIpJson.page,
                            "page_size":envIpJson.page_size,
                            "sort":envIpJson.sort,
                            "filter":envIpJson.filter}
          this.tableList.rows = []
          this.tableList.total_count = 0
          this.loader = true;
         postToServer(this, config.EDC_TICKET_MANAGEMENT_SERVICE_URL + GET_ALL_TICKETS , inputJson
        ).then(response  => {
            _this.loader = false;
            this.tableList.rows =response.result
            this.tableList.total_count = response.total
        }).catch(ticketResponseError => {
                _this.loader = false;
                _this.tableList.rows = []
            });
        },
        getActiveTicketList(envIpJson){
          var inputJson = {"type": "In progress",
                            "client_id": this.$session.get('client_id'),
                             "page":envIpJson.page,
                            "page_size":envIpJson.page_size,
                            "sort":envIpJson.sort,
                            "filter":envIpJson.filter}
         postToServer(this, config.EDC_TICKET_MANAGEMENT_SERVICE_URL + GET_ALL_TICKETS , inputJson
        ).then(response  => {
            this.activeticketList.rows =response.result
            this.activeticketList.total_count =response.total
        }).catch(ticketResponseError => {
                this.loader = false;
                if(ticketResponseError){
                    this.colorValue = 'error'
                    this.snackbartext = ticketResponseError;                
                }
                else {                    
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;                    
                }
            });
        },
        getWaitingTicketList(envIpJson){
         var inputJson = {"type": "Waiting",
                            "client_id": this.$session.get('client_id'),
                            "page":envIpJson.page,
                            "page_size":envIpJson.page_size,
                            "sort":envIpJson.sort,
                            "filter":envIpJson.filter}
         postToServer(this, config.EDC_TICKET_MANAGEMENT_SERVICE_URL + GET_ALL_TICKETS , inputJson
        ).then(response  => {
            this.waitingticketList.rows = response.result
            this.waitingticketList.total_count =response.total
        }).catch(ticketResponseError => {
                this.loader = false;
                if(ticketResponseError){
                    this.colorValue = 'error'
                    this.snackbartext = ticketResponseError;                
                }
                else {                    
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;                    
                }
            });
        },
        getClosedTicketList(envIpJson){
          var _this = this
          var inputJson = {"type": "Closed",
                            "client_id": this.$session.get('client_id'),
                            "page":envIpJson.page,
                            "page_size":envIpJson.page_size,
                            "sort":envIpJson.sort,
                            "filter":envIpJson.filter,
                            "is_ticket_closed":true}
          this.closedticketList.rows = []
          this.closedticketList.total_count = 0
          this.loader = true;
         postToServer(this, config.EDC_TICKET_MANAGEMENT_SERVICE_URL + GET_ALL_TICKETS , inputJson
        ).then(response  => {
           _this.loader = false;
            this.closedticketList.rows =response.result
            this.closedticketList.total_count =response.total
        }).catch(ticketResponseError => {
                _this.loader = false;
                if(ticketResponseError){
                    this.colorValue = 'error'
                    this.snackbartext = ticketResponseError;                
                }
                else {                    
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;                    
                }
            });
        },
        sortBy: function (sortJson) {
        let _this = this;
        let inputJson = _this.envIpJson;
        inputJson.sort = sortJson
        if(this.activeTab == 0)
          this.getOpenTicketList(inputJson);
        else if(this.activeTab == 1)
          this.getActiveTicketList(inputJson);
        else if(this.activeTab == 2)
          this.getWaitingTicketList(inputJson);
        else if(this.activeTab == 3)
          this.getClosedTicketList(inputJson);
        },
        onPageChange(page, perPage) {
          let _this = this;
          let inputJson = _this.envIpJson;
          inputJson.page = page;
          inputJson.page_size = perPage;
          if(this.activeTab == 0)
            this.getOpenTicketList(inputJson);
          else if(this.activeTab == 1)
            this.getActiveTicketList(inputJson);
          else if(this.activeTab == 2)
            this.getWaitingTicketList(inputJson);
          else if(this.activeTab == 3)
            this.getClosedTicketList(inputJson);
        },

        onPerPageChange(perPage, page) {
          let _this = this;
          let inputJson = _this.envIpJson;
          inputJson.page = page;
          inputJson.page_size = perPage;
          if(this.activeTab == 0)
            this.getOpenTicketList(inputJson);
          else if(this.activeTab == 1)
            this.getActiveTicketList(inputJson);
          else if(this.activeTab == 2)
            this.getWaitingTicketList(inputJson);
          else if(this.activeTab == 3)
            this.getClosedTicketList(inputJson);
        },
        filterData(filterArray, pageno) {
          let _this = this;
          let inputJson = _this.envIpJson;
          inputJson.filter=filterArray;
          inputJson.page=1
            if(this.activeTab == 0)
              this.getOpenTicketList(inputJson);
            else if(this.activeTab == 1)
              this.getActiveTicketList(inputJson);
            else if(this.activeTab == 2)
              this.getWaitingTicketList(inputJson);
            else if(this.activeTab == 3)
              this.getClosedTicketList(inputJson);
        },
        deleteTicket(record) {
          var _this =this;
          var client_id = _this.$session.get('client_id');
          var data = {"client_id": client_id,
                    "ticket_id": record.ticket_id}
          var delete_url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + DELETE_TICKET_URL
        postToServer(_this, delete_url, data).then(response => {
          if(this.activeTab == 0)
            this.getOpenTicketList(this.envIpJson);
          else if(this.activeTab == 1)
            this.getActiveTicketList(this.envIpJson);
          else if(this.activeTab == 2)
            this.getWaitingTicketList(this.envIpJson);
          else if(this.activeTab == 3)
            this.getClosedTicketList(this.envIpJson);
        }).catch(error_response => {
        }); 
        },      
        onEdit(record){
          let _this = this;
          this.$router.push({ name: 'CreateTicket', params: {ticket_id: record.ticket_id,}});
        },
        onHistory(record){
          let _this = this;
          
          let is_closed_ticket = record.status === 'Closed'?true:false
          record['is_closed_ticket'] = is_closed_ticket
          if(record.source_type == "dataviewer" || record.source_type == "ticket_management"){
            this.$router.push({ name: 'TicketDetailsForDv', params: {ticket_details:record}});
            //this.$router.push({ name: 'TicketDetails', params: {ticket_id: record.ticket_id,'is_closed_ticket':is_closed_ticket}});
            return;
          }

          this.$router.push({ name: 'TicketDetails', params: {ticket_id: record.ticket_id,'is_closed_ticket':is_closed_ticket}});
        },
        onCloseAction(record){
          if(record.status == "Closed"){
            this.ticket_action_list = this.ticket_action_list_.filter(item => item.sub_status == 'Open')
          }else{
              this.ticket_action_list = this.ticket_action_list_.filter(item => item.sub_status != 'Auto-closed' && item.sub_status != record.status)
          }
          this.task_name = record.ticket_id
          this.task_status = record.status
          if(record.status != 'Closed')
            this.action_type = 'Resolved'
          else
            this.action_type = 'Open'
	        this.dialog = true
        },
        onInvalid(record){
          this.task_name = record.ticket_id
	        this.comments_required = false
	        this.dialog = true
        },
        onOther(record){
          this.task_name = record.ticket_id
	        this.comments_required = true
	        this.dialog = true
        },
        onDuplicate(record){
          this.task_name = record.ticket_id
	        this.comments_required = false
	        this.dialog = true
        },
        ticket_action_update(){
          if (this.$refs.ticket_form.validate()) {
            var _this = this
              this.dialog = false
              var _this =this;
              var client_id = _this.$session.get('client_id');
              var data = {"client_id": client_id,
                    "ticket_id": this.task_name,
                    "status": this.action_type}
              var update_status_url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + UPDATE_TICKET_STATUS
              postToServer(_this, update_status_url, data).then(response => {
                    this.getOpenTicketList(this.envIpJson)
                    this.getClosedTicketList(this.envIpJson);
                  }).catch(error_response => {
                      if(error_response){
                        _this.getOpenTicketList(this.envIpJson)
                        _this.getClosedTicketList(this.envIpJson);
                        // this.snackbar = true
                        // this.colorValue = "error"
                        // this.snackbar = error_response
                        }
                      else{
                        _this.getOpenTicketList(this.envIpJson)
                        _this.getClosedTicketList(this.envIpJson);
                          // this.snackbar = true
                          // this.colorValue = "error"
                        // this.snackbar = SERVER_ERROR
                        }
                    }); 
			          }
        },
        getActivityLogs(envIpJson){
        var _this =this;
        var client_id = _this.$session.get('client_id');
        var data = {"client_id": client_id,
                    "search_param": this.search_text,
                    "page":envIpJson.page,
                    "page_size":envIpJson.page_size,
                    "sort":envIpJson.sort,
                    "filter":envIpJson.filter}
        var get_summary_url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + SEARCH_TICKET_LOGS
        _this.activityTableList.rows = []
        _this.activityTableList.total_count = 0
        postToServer(_this, get_summary_url, data).then(response => {
                _.forEach(response.result,function(obj){
                   if(_this.search_text !=''){
                     obj.activity_description = obj.activity_description.replace(new RegExp(_this.search_text, "gi"), (match) => `<mark  style="padding: 0px !important;background-color: #68FF33 !important;">${match}</mark>`);
                   }
                })
                _this.activityTableList.rows = _.sortBy(response.result,"actiontaken_date_time").reverse()
                _this.activityTableList.total_count = response.total
                _this.show_search_results = true
                _this.redirect_search_text = _this.search_text  
                _this.search_text = ''
                _this.activeTab = 2
            }).catch(error_response => {
                if(error_response){
                this.snackbar = true
                this.colorValue = "error"
                this.snackbartext = error_response
            }
            else{
                // this.snackbar = true
                // this.colorValue = "error"
                // this.snackbar = SERVER_ERROR
            }
        }); 
    },
    redirectToActivity(ticket_id,activity_id){
      this.$router.push({ name: 'TicketDetails', params:{'ticket_id':ticket_id, 'activity_id':activity_id , 'search_param': this.redirect_search_text}});
    },
    closeResults(){
      this.activeTab = 0
      this.show_search_results = false
    },
    getTileColor(index){
      if(index % 2 != 0)
        return '#F5F5F5'
      else
       return 'white'
    },
        reasonKeypress(e){
     		if(e.key === "Enter"){
     			// e.preventDefault()
     			this.ticket_action_update()
     		}
     	},
    getAvailalbleQueues(group_list){
        let input_details ={
          'group_id_list': group_list,
          "product_name":Ticket_management
        }
        this.loading = true;
        var tag_end_point = config.EDC_QUEUE_MANAGEMENT_URL + GET_QUEUE_FROM_TAG
        postToServer(this, tag_end_point, input_details).then(tagResponse  => {
          this.loading=false;
              if(tagResponse){
                this.available_queues = tagResponse;
                this.getOpenTicketList(this.envIpJson)
              }
          }).catch(error_response => {
            this.getOpenTicketList(this.envIpJson)
            this.loading=false;
           
        });
      },
    }
}
</script>

<style scoped>
  .label-margin label{
    margin-top:5%;
  }
  .EDC-TextField {
 	padding-top:0px !important;
 	padding-left:5px !important;
 	padding-right:10px !important;
}
.EDC-UIComponent{
    margin: 0px !important;
    padding: 0px !important;
}
.customRadio>>> label{
  top: 5px !important;
}
.EDC-Toolbar >>> .v-sheet {
  border-radius: 0px !important;
}
.ql-toolbar ql-snow{
  width: 413px !important;
    height: 50px !important;
}
.searchbox-fixed{
  right: 2% !important;
  top: 1% !important;
  position:fixed;
}
.icon-spacing{
  padding-left: 3px !important;;
}
</style>
