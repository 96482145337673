/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'document_publish_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M4.763.265v.668a1.588 1.588 0 00-1.454 1.61c.251-.58.822-.955 1.454-.955v.793l.661-.529.661-.53-.661-.528-.661-.53zM1.564.53c-.426 0-.76.367-.76.793v3.705c0 .426.334.793.76.793h3.223c.427 0 .76-.367.76-.793V3.174h-.528v1.854c0 .16-.109.264-.232.264H1.564c-.123 0-.23-.104-.23-.264V1.323c0-.16.107-.264.23-.264H2.91v-.53H1.564zm.288 1.059v.529h.794v-.53h-.794zm0 1.056v.53H2.91v-.53H1.852zm0 1.059v.529h2.38v-.53h-2.38z" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
