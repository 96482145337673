<template>
  <div style="width: 100% !important"> 
    <loading-panel :loader="loader"></loading-panel>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
      </v-col>
      <v-col cols="4">
      </v-col>
    </v-row>
    <v-container style="width: 100% !important;">
      <!-- <v-dialog v-model="show_overlay" width="500" height="500" style="overflow-y:none">
		            <edc-form :formJson="formJson"></edc-form>
                <span @click="redirectEdcForm">Test</span>
		        </v-dialog> -->
      <v-card elevation-1  style="width: 100% !important">
        <!-- Top row with ticket name and info -->
         <v-row class="EDC-Row" v-if="isForEdit || show_edit_page" no-gutters>
           
           <v-col cols="4" style="background-color:white;" class="EDC-Col columns-alignment">
                  <v-row v-if="isForEdit || show_edit_page" class="EDC-Row" justify="start">
                  <v-col cols="6"  class="EDC-Col">
                      <span class="override-font" style="float:left;font-weight: bold;padding-left: 1%">{{title}}</span>
                  </v-col>
                  <v-col cols="2"  class="EDC-Col">
                         <span class="override-font" style="float:left;">{{ticket_id}}</span>
                    </v-col>
                    <v-col cols="2"  style="margin-left:0px !important;" v-if="isForEdit && url" class="EDC-Col EDC-ColsSpacing"> 
                          <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-icon @click="redirectToIssue" class="mr-1" v-on="on">link</v-icon>
                        </template>
                        <span>Click to open</span>
                      </v-tooltip>
                    </v-col>
                </v-row>
            </v-col>
            <v-col v-if="isForEdit || show_edit_page">
            </v-col>
            <v-col cols="7" style="background-color:white;" class="EDC-Col columns-alignment">
                <v-row v-if="isForEdit || show_edit_page" class="EDC-Row EDC-RowsSpacing" justify="start">
                  <v-col cols="2"  class="EDC-Col EDC-ColsSpacing">
                    <v-row class="EDC-Row EDC-RowsSpacing" justify="start">
                      <span style="float:left;font-weight: bold;" class="override-font">Status:</span>
                    </v-row>
                    <v-row class="EDC-Row EDC-RowsSpacing" justify="start">
                      <v-chip
                          :color="getColor(status.status?status.status:'')"
                          :text-color="status.status == 'Closed'? 'black' : 'white'"
                          v-if="status.status != 'Closed'"
                          class="override-font"
                          >
                          {{status.status}}
                      </v-chip>
                      <span v-else>{{status.status}}</span>
                      </v-row>
                    </v-col>
                    <v-col cols="2" class="EDC-Col Top_Line">
                      <v-row class="EDC-Row EDC-RowsSpacing" justify="start">
                      <span class="override-font" style="float:left;font-weight: bold;">Priority</span>
                      </v-row>
                      <v-row class="EDC-Row EDC-RowsSpacing" v-if="!show_priority" justify="start">
                        <span @dblclick="showHideControl('text','priority')" style="padding-top: 0.3em;" class="override-font">{{priority.sequence_number}}-{{priority.name}}</span>
                      </v-row>
                      <v-row class="EDC-Row EDC-RowsSpacing" v-if="show_priority" justify="start">
                        <v-autocomplete ref="prioritytest" class="override-font"  :readonly="priority_readonly" v-model="priority" @blur="showHideControl('control','priority')" :rules="priorityRules" validate-on-blur item-text="name" return-object :items="priority_list" dense></v-autocomplete>
                      </v-row>
                     </v-col>
                    <v-col cols="2"  class="EDC-Col EDC-ColsSpacing">
                      <v-row class="EDC-Row EDC-RowsSpacing" justify="start">
                        <span class="override-font" style="float:left;font-weight: bold;">Assignee</span>
                      </v-row>
                      <v-row class="EDC-Row EDC-RowsSpacing" v-if="!show_assignee" justify="start">
                        <span @dblclick="showHideControl('text','assignee')" class="override-font"  style="padding-top: 0.3em;">{{assignee.username?assignee.username:'Select Assignee '}}</span>
                      </v-row>
                      <v-row class="EDC-Row EDC-RowsSpacing" v-if="show_assignee" justify="start">
                        <v-autocomplete class="customInputClass override-font" :clearable="!assignee_readonly" :rules="assigneeRule" ref="assignee" @blur="showHideControl('control','assignee')" :readonly="assignee_readonly"  @change="assigne_fullname = assignee.full_name_with_user_name" return-object   v-model="assignee" item-text="full_name_with_user_name" :items="user_list" dense></v-autocomplete>
                      </v-row>
                    
                    <input type="file" ref="multiple_file_input" multiple="single" hidden @change="handleMultipleFileUpload()">
                    </v-col>
                    <v-col cols="2" v-if="status.status == 'Closed'" class="EDC-Col EDC-ColsSpacing Span_Line">
                      <v-row class="EDC-Row EDC-RowsSpacing" justify="start">
                        <span class="override-font" style="float:left;font-weight: bold;">Resolution:</span>
                      </v-row>
                      <v-row class="EDC-Row" justify="start">
                        <span class="override-font" style="float:left;padding-top: 0.3em;"> {{status.sub_status}}</span>
                      </v-row>
                    
                     </v-col>
                    <v-col cols="2"  class="EDC-Col EDC-ColsSpacing Span_Line" style="padding-bottom:2px !important">
                      <v-row class="EDC-Row EDC-RowsSpacing" justify="start">
                      <span class="override-font" style="float:left;font-weight: bold;">Ticket Source:</span>
                      </v-row>
                      <v-row class="EDC-Row" justify="start">
                      <span class="override-font" style="float:left;padding-top: 0.4em;">{{resource_type}}</span>
                      </v-row>
                       
                     </v-col>
                  <v-col cols="4"  class="EDC-Col EDC-ColsSpacing Span_Line">
                    <v-row class="EDC-Row EDC-RowsSpacing" justify="start">
                    <span style="float:left;font-weight: bold;" class="override-font">Created Datetime:</span>
                  </v-row>
                    <v-row class="EDC-Row EDC-RowsSpacing" justify="start">
                    <span class="override-font" style="float:left;padding-top: 0.2em;">{{tz_created_date_time}}</span>
                  </v-row>
                    
                  </v-col>
              </v-row>
              </v-col>
          </v-row>
          <!-- Row For workflow object display -->
          <v-row class="EDC-Row" v-if="isForEdit || show_edit_page" no-gutters>
            <v-col class="EDC-Col">
              <v-card elevation-1  style="width: 98% !important">
              <edc-worflowStatus :graphData="workflowObj" @stepEvent="stepEvent( ...arguments)"></edc-worflowStatus>
              </v-card>
            </v-col>
          </v-row>
           <!-- Row For contact info form -->
          <v-row class="EDC-Row" v-if="show_contact_form" no-gutters>
            <v-col class="EDC-Col">
              <v-card elevation-1  style="width: 98% !important">
              <edc-form :formJson="selected_form" :formObj="ticket_response" @contact_form_event="sendEmail(...arguments)" :showContactForm="show_contact_form"></edc-form>
              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="EDC-Row">
                <v-tabs v-model="tabIndex" v-if="!show_contact_form && (!(Object.keys(selected_form).length === 0) || !(Object.keys(info_data).length === 0))" non-linear>
                  <v-tab class="EDC-Tabs" :disabled="Object.keys(selected_form).length === 0">Form</v-tab>
                  <v-tab class="EDC-Tabs" :disabled="Object.keys(info_data).length === 0">Step Info</v-tab>
                  <v-tab-item>
                    <v-row class="EDC-Row" v-if="show_overlay"  style="padding-top: 8px!important" no-gutters>
                    <v-card elevation-1  style="width: 98% !important">
                      <v-col class="EDC-Col">
                      <edc-form :formJson="selected_form" key="testform" :formObj="ticket_response" @contact_form_event="sendEmail(...arguments)" :showContactForm="show_contact_form" :isApproval="is_approval"></edc-form>
                      </v-col>
                    </v-card>
                  </v-row>
                  </v-tab-item>
                  <v-tab-item>
                    <!-- <v-row class="EDC-Row" v-if="show_overlay"  style="padding-top: 8px!important" no-gutters>
                    <v-card elevation-1  style="width: 98% !important">
                      <v-col class="EDC-Col">
                      <edc-form :formJson="selected_form" key="testform" :formObj="ticket_response" @contact_form_event="sendEmail(...arguments)" :showContactForm="show_contact_form"></edc-form>
                      </v-col>
                    </v-card>
                  </v-row> -->
                  <v-row class="EDC-Row" v-if="show_text_info" style="padding-top: 8px!important;padding-left: 8px!important;padding-bottom: 8px!important;" no-gutters>
                    <v-card elevation-1   style="width: 98% !important">
                      <v-col class="EDC-Col">
                        <v-row class="EDC-Row" v-for="(key, index) in Object.keys(info_data)" :key="index+'info'">
                            <v-col class="EDC-Col" cols="2">
                              <span class="override-font" style="float:left;font-weight: bold;">{{key}}</span>
                            </v-col>
                            <v-col class="EDC-Col" v-if="key === 'Email Link'" cols="4">
                              <a class="override-font" style="float:left;font-weight: bold;" @click="redirectLink(info_data[key])">{{info_data[key].split("_")[0]+"..."}}</a>
                            </v-col>
                            <v-col class="EDC-Col" v-else cols="4">
                              <span class="override-font" style="float:left;font-weight: bold;">{{info_data[key]}}</span>
                            </v-col>
                        </v-row>
                      </v-col>
                    </v-card>
                </v-row>
                <v-row class="EDC-Row" v-else-if="show_review_info" style="padding-top: 8px!important;padding-left: 8px!important;padding-bottom: 8px!important;" no-gutters>
                  <v-card elevation-1   style="width: 98% !important">
                    <v-col class="EDC-Col" cols="12">
                      <v-row class="EDC-Row">
                        <v-col class="EDC-Col" cols="4">
                          <v-card elevation-1   style="width: 98% !important">
                          <v-row class="EDC-Row">
                            <span class="override-font" style="float:left;font-weight: bold;">Status</span>
                          </v-row>
                          <v-row class="EDC-Row" v-if="info_data.Status">
                            <v-col class="EDC-Col" cols="12">
                              <span class="override-font" style="float:left;font-weight: bold;">{{info_data.Status.content}}</span>
                            </v-col>
                            <v-col class="EDC-Col" cols="12">
                              <span class="override-font" style="float:left;font-weight: bold;">Form Review{{info_data.Status.review}}</span>
                            </v-col>
                            <v-col class="EDC-Col" cols="12">
                              <span class="override-font" style="float:left;font-weight: bold;">Initiated:{{info_data.Status.time}}</span>
                            </v-col>
                            <v-col class="EDC-Col" cols="12">
                              <span class="override-font" style="float:left;font-weight: bold;">Completed:{{info_data.Status.time}}</span>
                            </v-col>
                          </v-row>
                          </v-card>
                        </v-col>
                        <v-col class="EDC-Col" >
                        </v-col>
                        <v-col class="EDC-Col" cols="4">
                          <v-card elevation-1   style="width: 98% !important">
                          <v-row class="EDC-Row">
                            <span class="override-font" style="float:left;font-weight: bold;">Approvals</span>
                          </v-row>
                          <v-row class="EDC-Row" v-for="(key, index) in info_data.Approvals" :key="index+'info'">
                            <v-col class="EDC-Col" cols="6">
                              <span class="override-font" style="float:left;font-weight: bold;">{{key.action}}</span>
                            </v-col>
                            <v-col class="EDC-Col" cols="6">
                              <span class="override-font" style="float:left;font-weight: bold;">{{key.group}}</span>
                            </v-col>
                          </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-card>
              </v-row>
                <v-row class="EDC-Row" v-else-if="show_mapping_info" style="padding-top: 8px!important;padding-left: 8px!important;padding-bottom: 8px!important;" no-gutters>
                  <v-card elevation-1   style="width: 98% !important">
                    <v-col class="EDC-Col" cols="12">
                      <v-row class="EDC-Row">
                        <v-col class="EDC-Col" cols="4">
                          <v-row class="EDC-Row" v-for="(key, index) in Object.keys(info_data.source_details)" :key="index+'info'">
                            <v-col class="EDC-Col" cols="6">
                              <span class="override-font" style="float:left;font-weight: bold;">{{key}}</span>
                            </v-col>
                            <v-col class="EDC-Col" cols="6">
                              <span class="override-font" style="float:left;font-weight: bold;">{{info_data.source_details[key]}}</span>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col class="EDC-Col" cols="4">
                          <v-row class="EDC-Row" v-for="(key, index) in Object.keys(info_data.target_details)" :key="index+'info'">
                            <v-col class="EDC-Col" cols="6">
                              <span class="override-font" style="float:left;font-weight: bold;">{{key}}</span>
                            </v-col>
                            <v-col class="EDC-Col" cols="6">
                              <span class="override-font" style="float:left;font-weight: bold;">{{info_data.target_details[key]}}</span>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-card>
              </v-row>
                  </v-tab-item>
                  
                </v-tabs>
          </v-row>
          <!-- Row For step info display for forms -->
          <!-- <v-row class="EDC-Row" v-if="show_overlay"  style="padding-top: 8px!important" no-gutters>
            <v-card elevation-1  style="width: 98% !important">
            <v-col class="EDC-Col">
             <edc-form :formJson="selected_form" key="testform" :formObj="ticket_response" @contact_form_event="sendEmail(...arguments)" :showContactForm="show_contact_form"></edc-form>
            </v-col>
            </v-card>
          </v-row> -->
          <!-- Row For step info display for other info -->
          
          
        </v-card>
    </v-container>

      <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
    
    <loading-panel :loader="loader"></loading-panel>
    </div> 
</template>
<script>
var moment = require("moment-timezone")
import config from '../../config.json'
import {COMMENT_TABLE_LIST, ACTIVITY_TABLE_LIST, ATTACHEMENT_TABLE_LIST} from './ticketMetaData.js'
import {
  post as postToServer
} from './../../methods/serverCall.js';
import {
  SERVER_ERROR
} from '../../data/client_message.js'
import {
  get as getToServer
} from './../../methods/serverCall.js';
import cloneDeep from 'lodash/cloneDeep';
import { GET_ALL_USER,DECRYPT_URL_DATA,GET_TICKET_SUMMARY,GET_TICKET_COMMENTS,GET_TICKET_LOGS,GET_RECENT_TICKET_LOGS,GET_TICKET_STATUS,GET_WORKFLOW_STATUS,GET_STEP_REQUEST,SEND_FROM_EMAIL,
          GET_TICKET_PRIORITY,GET_TICKET_DETAILS_BY_ID,MANAGE_COMMENT,UPDATE_TICKET,CREATE_TICKET_URL,GET_TICKET_ATTACHMENTS,MANAGE_ATTACHEMENTS,GET_TICKET_DETAILS,CREATE_SUPPLIER_TICKET,
          DELETE_TICKET_ATTACHMENT,SEARCH_TAGS,CREATE_TAG,GET_ACTIONS_BY_GROUP_ID_LIST,GET_ALL_TAGS,UPDATE_TICKET_STATUS,GET_USERS_FROM_QUEUE, GET_ALL_QUEUES,
          GET_TICKET_ACTION, GET_CONFIG_DATA} from '../../data/url_constants.js'
import RichTextBox from "@/views/richTextEditor/index.vue";
import {Snackbar_Default_Timeout, GRID_STATUS_COLOR_CODE, Ticket_management} from '@/constants/constants.js'
import CommentBox from "@/views/richTextEditor/commentRichText.vue";
import NormalCommentBox from "@/views/richTextEditor/commentNoToolbar.vue";
import {getUserRole, getTicketRole} from './../../methods/GetUserRole.js'
import edcForm from '../edcForm/edcTabForm.vue'
import {UPDATE_ACTION, TICKET_MANAGEMENT_SERVICE, CREATE_ACTION, TAG_SERVICE, DELETE_ACTION, CREATE_TICKET, CHANGE_DESCRIPTION, CREATE_TICKET_ACTION, CREATE_ATTACHMENT, CREATE_COMMENT,
        DELETE_ATTACHMENT, DELETE_TICKET, READ_TICKET_ACTION, READ_ATTACHMENT, READ_COMMENT, READ_TICKET, UPDATE_TICKET_ACTION, CHANGE_TICKET_QUEUE, CHANGE_TICKET_ASSIGNEE} from '../../data/macros';
import {getActionByGroup} from './../../methods/TicketActionsAndQueueList.js'
// import Breadcrumb from "../Breadcrumbs.vue"

import workflowStatus from './workFlowStatus.vue'

export default {
  name: 'TicketDetails',
  components: {
    'edc-worflowStatus':workflowStatus,
    'edc-form':edcForm,
  },
  props:{
    ticketObj:Object,
  },
  data: function () {
    return {
      userRole:{},
      items: [
        { title: 'Click Me 1' },
        { title: 'Click Me 2 ' },
        { title: 'Click Me 3' },
      ],
      loader:false,
      snackbartext:'',
      activetab:0,
      ticket_id:'',
      dialog:false,
      task_name:'',
      task_status:'',
      reason:"",
      showActionList:true,
      show_confirm_delete:false,
      current_attachment:'',
      current_attachment_name:'',
      selected_image:'',
      ticket_action_list:[{name:"Resolved",
                          id:"Resolved"},
                          {name:"Unable to reproduce issue",
                          id:"Invalid"},
                          {name:"Duplicate",
                          id:"Duplicate"},
                          // {name:"On-hold",
                          // id:"On-hold"},
                          // {name:"Closed",
                          // id:"Other"},
                          // {name:"Add Attachment",
                          // id:"Add Attachment"}
                          ],
      action_list:["TICKET CREATED","UPDATED TICKET STATUS","COMMENT ADDED","ATTACHMENT ADDED"],                          
      close_action_list:[{name:"Open",
                          id:"Open"},],
      action_type:'Resolved',
      reasonRules:[
				v => !!v || 'User comments are required'
            	],
      titleRules:[v => !!v || 'Title is required'],
      tagRules:[v => !!v.length >0 || 'Tags are required'],
      priorityRules:[v => !_.isEmpty(v)|| 'Priority is required'],
      assigneeRule: [],
      colorValue: 'error',
      snackbar:false,
      ticketsummary:[],
      priority_id:'',
      filterList:['User','Action',],
      SelectedFilter:{user:'',action:''},
      sortList:['User','Action','Latest', 'Oldest'],
      SelectedSort:['Latest'],
      url:'',
      Summary_txt:'Summary',
      NoDataFound:'No data found',
      commenTabletList: _.cloneDeep(COMMENT_TABLE_LIST),
      attachementTableList:_.cloneDeep(ATTACHEMENT_TABLE_LIST),
      activityTableList: _.cloneDeep(ACTIVITY_TABLE_LIST),
      snackbartimeout: Snackbar_Default_Timeout,
      title:'',
      show_preview:false,
      title_readonly:false,
      comment_readonly:false,
      attachment_readonly:false,
      attachment_delete_readonly:false,
      action_readonly:false,
      description_readonly:false,
      queue_readonly:false,
      priority_readonly:false,
      status_readonly:false,
      assignee_readonly:false,
      create_ticket_readonly:false,
      update_ticket_readonly:false,
      commentEditorObj:{},
      description:'',
      show_form_buttons:false,
      show_comment_buttons:false,
      old_description:'',
      old_priority:{},
      old_tag:[],
      old_queue:[],
      old_assignee:'',
      token_data:{},
      assignee:{},
      assigne_fullname:'',
      status:{},
      status_txt:'',
      sub_status:'',
      show_richcomment:false,
      show_normalcomment:true,
      showrich_description:true,
      resource_type:'',
      assigned_by:'',
      user_list:[],
      imgFilePath:'',
      imgFileContentType:'',
      fileContentType:'',
      filePath:'',
      pathUrl:'',
      ticket_action:'',
      showImageViewer:false,
      showFileViewer:false,
      vueEditorObj:{},
      priority:{},
      notForEdit: true,
      tag:[],
      status_list:[],
      priority_list:[],
      comment:'',
      files:[],
      oldFiles:[],
      attached_files:[],
      tz_assigned_date_time:'',
      created_by: '',
      age:'',
      tz_created_date_time:'',
      recentLogItems:[],
      search_text:'',
      highlight_txt:'',
      tag_list:[],
      search:'',
      private_note:false,
      overlay:false,
      show_priority:false,
      show_assignee:false,
      initials:'',
      queue:'',
      tabIndex:0,
      queue_list:[],
      current_step_status:'',
      current_step_id:'',
      first_name:'',
      email:'',
      last_name:'',
      workflow_request_id:'',
      actions_by_group:[],
      workflow_id:'',
      workflow_name:'',
      workflow_status_data:{},
      envIpJson : {
          "filter": [],
          "sort": [],
          "page": 1,
          "page_size": 1000
      },
      is_closed_ticket:false,
      workflowObj:{},
      is_approval:false,
      show_overlay:false,
      show_contact_form:false,
      show_edit_page:false,
      dark_value:false,
      show_text_info:false,
      show_mapping_info:false,
      show_review_info:false,
      is_active_step:false,
      info_data:{},
      selected_form:{},
      ticket_response:{},
      formJson:{},
      onlyFieldList:[],
    }
  },
  mounted() {
    debugger;
    this.is_closed_ticket = this.$route.params.is_closed_ticket ? this.$route.params.is_closed_ticket : false
    this.userRole = this.$session.get('user_role_mapping');
    if(this.$route.params.token_data)
      this.token_data = this.$route.params.token_data
    if(this.$route.params.moreInfo)
      this.addSupplierTicket(this.$route.params.moreInfo)
    // getActionByGroup(this)
    this.email = this.$session.get('email')
     if(this.$session.get('last_name') && this.$session.get('last_name').length > 0){
          this.initials = this.$session.get('first_name').charAt(0).toUpperCase() + this.$session.get('last_name').charAt(0).toUpperCase()
          this.first_name = this.$session.get('first_name') + ' ' + this.$session.get('last_name')
        }
        else{
          this.initials = this.$session.get('first_name').charAt(0).toUpperCase()
          this.first_name = this.$session.get('first_name')
         
        }
    if(this.$route.params.edcurl && this.$route.params.postData){
      this.ticketObj = {
        'url':this.$route.params.edcurl,
        'postData':JSON.parse(this.$route.params.postData),
        'url_type':this.$route.params.url_type
      }
      this.resource_type = "dataviewer_service"
      this.getMappedWorkflow();
    }
    if(this.$route.params.ticket_id){
      this.ticket_id = this.$route.params.ticket_id
      this.getTicketSummery()
    }
    if(this.$route.query.ticket_id){
      this.notForEdit = true
      window.sessionStorage.setItem('lastUrl', this.$route.fullPath);
    }
    if(this.ticketObj){
      this.url = this.ticketObj.url
    }
     this.attachementTableList.actions=[
            {'text':'attachment','key':"ticket_id",index:1},
            {'text':'delete','key':"ticket_id", selectType:"single", role:true,index:2},
            ];
    if(this.isForEdit && this.notForEdit){
      this.getTicketDetails()
      this.showrich_description = false
    }
    
    if(!this.userRole.is_superadmin && !getUserRole(this.userRole,TICKET_MANAGEMENT_SERVICE,UPDATE_ACTION)){
      this.onReadOnly()
    }
  },
  computed:{
    isForEdit(){
      if(this.$route.params.ticket_id || this.$route.query.ticket_id)
				return true
      else
        return false
			},
  },
  watch:{
    'ticketObj':{
      handler(newValue){
        this.url = newValue.url
      }
    },
    imgFilePath:{
      handler(newValue){
        this.imgFilePath = newValue
      }
    },
    filePath:{
      handler(newValue){
        debugger
        this.filePath = newValue
      }
    },
    fileContentType:{
      handler(newValue){
        debugger
        this.fileContentType = newValue
      }
    },
    description:{
      handler(newValue){
        if(this.old_description != newValue){
          this.show_form_buttons = true
        }
        if(this.old_description == newValue){
          this.show_form_buttons = false
        }
      }
    },
    title:{
      handler(newValue){
         this.show_form_buttons = true
      }
    },
    assignee:{
      handler(newValue){
        if(newValue){
          if( !_.isEmpty(newValue))
            this.assigneeRule = []
        if(this.old_assignee != newValue.username){
          this.show_form_buttons = true
        }
        if(this.old_assignee == newValue.username){
          this.show_form_buttons = false
        }
        }
        else
          this.show_form_buttons = true
      }
    },
    priority:{
      handler(newValue){
        if(this.old_priority.id != newValue.id){
          this.show_form_buttons = true
        }
        if(this.old_priority.id == newValue.id){
          this.show_form_buttons = false
        }
      }
    },
    comment:{
      handler(newValue){
        if(newValue != ''){
          this.show_comment_buttons = true
        }
        if(newValue == ''){
          this.show_comment_buttons = false
        }
      }
    },
    tag:{
      handler(newValue){
        if(this.old_tag.id != newValue){
          this.show_form_buttons = true
        }
        if(this.old_tag.id == newValue){
          this.show_form_buttons = false
        }
      }
    },
    queue:{
      handler(newValue){
        if(this.old_queue.id != newValue.id){
          this.show_form_buttons = true
        }
        if(this.old_queue.id == newValue.id){
          this.show_form_buttons = false
        }
      }
    },
    user_list:{
      handler(newValue){
        this.assigneeRule = [] 
        if( this.assignee.user_name && !_.find(newValue,["user_name",this.assignee.user_name]) ){
          // this.assigneeRule = ['Current assigne has no access in selected queue']
          this.assignee = {}
        }
      }
    },
  },
  methods: {
    getMappedWorkflow(){
      var data_to_send = {"client_id":this.$session.get('client_id'),
                          "request_for": "Dataviewer"}
      var url = config.EDC_QUEUE_MANAGEMENT_URL+"/fetch_mapped_wf_dv"
      postToServer(this, url, data_to_send).then(response=>{
        if(response){
          var data_for_tkt = {"resource_type": this.resource_type,
          "title": "Dataviewer Ticket",
          "option_name": response.option_name,
          "client_id": this.$session.get('client_id')}
          this.addSupplierTicket(data_for_tkt)
          // function that will create ticket and start wf
        }
      }).catch(error_response=>{
        if(error_response){
          this.snackbar = true
          this.colorValue = "error"
          this.snackbartext = error_response
        }
        else{
          this.snackbar = true
          this.colorValue = "error"
          this.snackbartext = SERVER_ERROR
        }
      })
    },
    getColor(value){
        return GRID_STATUS_COLOR_CODE[value.toLowerCase()]
    },
    getTicketSummery(){
        var _this =this;
        var client_id = _this.$session.get('client_id');
        var ticket_id = _this.$route.params.ticket_id ? _this.$route.params.ticket_id : _this.ticket_id
        var data = {"client_id": client_id,
                    "ticket_id": ticket_id,
                    'product_name': "TICKET_MANAGEMENT",
                    "is_ticket_closed":_this.is_closed_ticket}
        var get_summary_url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + GET_TICKET_SUMMARY
        postToServer(_this, get_summary_url, data).then(response => {
            this.loader = false
            if(response){
              var keys  = Object.keys(response)
              // index used for sorting
              var data_list = []
              for (var i = 0; i <keys.length; i++) {

                if(keys[i] === 'ticket_id' && response.ticket_id){
                  data_list.push({"name":"Ticket ID","value":response.ticket_id,"index":1})
                }

                if(keys[i] === "title")
                  data_list.push({"name":"Title","value":response.title,"index":2})
                 if(keys[i] === 'priority_name')
                  data_list.push({"name":"Priority","value":response.priority_name ,"index":4})
                
                if(keys[i] === "status"){
                  this.status_txt = response.status
                  data_list.push({"name":"Status","value":response.status,"index":5})
                }

                if(keys[i] === "sub_status"){
                  this.sub_status = response.sub_status
                  data_list.push({"name":"Sub Status","value":response.sub_status,"index":6})
                }

                if(keys[i] === "resource_type"){
                  this.resource_type = response.resource_type
                  data_list.push({"name":"Request From","value":response.resource_type,"index":7})
                }

                if(keys[i] === "assigned_to")
                  data_list.push({"name":"Assignee","value":response.assigned_to,"index":8})

                if(keys[i] === "assigned_by"){
                  this.assigned_by = response.assigned_by
                  data_list.push({"name":"Assigned By","value":response.assigned_by,"index":9})
                }

                if(keys[i] === "tz_assigned_date"){
                  this.tz_assigned_date_time = response.tz_assigned_date + " "+ response.tz_assigned_time
                  data_list.push({"name":"Assigned Datetime","value":response.tz_assigned_date + " "+ response.tz_assigned_time,"index":10})
                }

                if(keys[i] === "created_by"){
                  this.created_by = response.created_by
                  data_list.push({"name":"Created By","value":response.created_by,"index":11})
                }
                 
                if(keys[i] === "tz_created_date"){
                  let dateFormat = this.$session.get('UI_date_format')
                  let timeZoneFormat = this.$session.get('time_zone_preference')
                  this.tz_created_date_time = moment(response.tz_created_date,dateFormat).format('ll') +' at '+moment(response.tz_created_date+' '+response.tz_created_time,dateFormat+" HH:mm:ss").tz(timeZoneFormat).format('LT') +' '+moment().tz(timeZoneFormat).format('z')

                  data_list.push({"name":"Created Datetime","value":response.tz_created_date + " "+ response.tz_created_time,"index":11})
                }
                
              }
              this.ticketsummary = _.sortBy(data_list,"index")
            }
        }).catch(error_response => {
            if(error_response){
                this.snackbar = true
                this.colorValue = "error"
                this.snackbar = error_response
            }
            else{
                this.snackbar = true
                this.colorValue = "error"
                this.snackbar = SERVER_ERROR
            }
        }); 
    },
    getTicketDetails(){
       var _this =this;
        var client_id = _this.$session.get('client_id');
        var ticket_id = _this.$route.params.ticket_id ? _this.$route.params.ticket_id : _this.ticket_id
        var data = {"client_id": client_id,
                    "ticket_id": ticket_id,
                    'product_name': "TICKET_MANAGEMENT",
                    "is_ticket_closed":_this.is_closed_ticket}
        var get_ticket_detail_url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + GET_TICKET_DETAILS_BY_ID
        _this.loader = true;
        postToServer(_this, get_ticket_detail_url, data).then(response => {
                _this.loader = false;
                var data = response
                this.title = response.title
                this.description = response.description
                this.old_description = response.description
                this.url = response.master_details.url
                this.queue = {"id": response.queue_id,
                              "queue_name": response.queue_name}
                this.old_queue = this.queue
                this.assignee = {'username':response.assigned_to,
                                  'full_name_with_user_name':response.assigned_to_fullname }
                this.assigne_fullname = response.assigned_to_fullname
                this.old_assignee = response.assigned_to
                this.priority = response.priority_details
                this.old_priority = response.priority_details
                this.priority_id = response.priority_details.id
                this.status = response.status_details
                this.files = response.attachment_data
                this.oldFiles = response.attachment_data
                this.created_date_time = response.tz_created_date_time
                this.modified_date_time = response.tz_modified_date_time
                this.workflow_request_id = response.tkt_wf_mapping.workflow_request_id
                var now = moment().tz(_this.$session.get('time_zone_preference')).format( _this.$session.get("UI_date_format")  +" HH:mm:ss")
                now =  moment(now,  _this.$session.get("UI_date_format") + " HH:mm:ss")
                this.getWorkflowStatus()
                _this.created_date_time = moment(_this.created_date_time,  _this.$session.get("UI_date_format") + " HH:mm:ss")
                _this.age = moment.duration(now.diff(_this.created_date_time));
                var lastActivity = this.modified_date_time ?  moment(_this.modified_date_time,  _this.$session.get("UI_date_format") + " HH:mm:ss") : _this.created_date_time
                _this.inactiveTime = moment.duration(now.diff(lastActivity));
                // this.created_by = response.created_by
                // this.sub_status = response.status.sub_status
                this.vueEditorObj = {}
                this.vueEditorObj['description'] = _.cloneDeep(response.description)
                if(this.status.status == "Closed"){
                  this.ticket_action_list =[{name:"Open",
                          id:"Open"}]
                  this.onReadOnly()                        
                          }
            }).catch(error_response => {
              _this.loader = false;
                if(error_response){
            }
            else{
            }
        }); 
    },
    onReadOnly(){
       this.title_readonly = true
        this.queue_readonly = true
        this.priority_readonly = true
        this.status_readonly = true
        this.assignee_readonly = true
        this.description_readonly = true
        this.comment_readonly = true
        this.attachment_readonly = true
        //stoping from making other actions readonly when ticket is closed
        if(!this.userRole.is_superadmin && !getUserRole(this.userRole,TICKET_MANAGEMENT_SERVICE,UPDATE_ACTION)){
          this.action_readonly = true
        }
    },
  redirectToIssue(){
    let _this = this
    let redirect_url = this.$session.get('protocol')+'://'
    let queryString = '?product_key='+this.$session.get('access_token')+'&url1='+encodeURIComponent(this.url)
    let domain_name = this.$session.get('erp_cloud_host') ? this.$session.get('erp_cloud_host') : "localhost"
    if(config.DOMAIN_NAME)
      redirect_url = redirect_url +'dataviewer.'+config.DOMAIN_NAME+'/login'+queryString
    else
      redirect_url = redirect_url + domain_name+":"+config.DATAVIEWER_PORT+'/login'+queryString
    window.open(redirect_url, '_blank')
  },
  showHideControl(type,name){
    var _this = this
    if(name == "priority"){
      this.show_priority = type =="text"? true : false
       setTimeout(function () {
        _this.$refs.prioritytest.focus();
            }, 100);
    }
    else if(name == "assignee"){
      this.show_assignee = type =="text"? true : false
      setTimeout(function () {
        _this.$refs.assignee.focus();
            }, 100);
    }  
  },
  getWorkflowStatus(){
        var _this =this;
        var client_id = _this.$session.get('client_id');
        var data = {"client_id": client_id,
        'product_name': window.sessionStorage.getItem('product'),
        'workflow_request_id':this.workflow_request_id}
        postToServer(_this, config.WORKFLOW_PRODUCT_URL + GET_WORKFLOW_STATUS, data
        ).then(response  => {
            // Saving workflow status data for recreating workflow obj
            this.workflowObj = response
            this.workflow_id = response.workflow_id
            this.workflow_name = response.workflow_name
            if(this.$route.params.node_id){
              setTimeout(()=>{
                _this.stepEvent({"node_id":this.$route.params.node_id,"step_code":this.$route.params.step_code,"workflow_request_id":this.$route.params.workflow_request_id})
              },1000)
            }
            else if(this.$route.params.moreInfo){
              return
            }
            else{
              let step = _.find(response.workflow_json.cells,['id',response.running_node_id]).shape_type
              
              setTimeout(()=>{
                _this.stepEvent({"node_id":response.running_node_id,"step_code":step,"workflow_request_id":_this.$route.params.workflow_request_id})
              },1000)
            }
        }).catch(ticketResponseError => {
                this.loader = false;
                if(ticketResponseError){
                    this.colorValue = 'error'
                    this.snackbartext = ticketResponseError;                    
                }
                else {                    
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;                    
                }
            });
        },
    stepEvent(data){
      var _this =this;
      var client_id = _this.$session.get('client_id');
      var step_code = data.step_code
      var data = {"client_id": client_id,
      'product_name': window.sessionStorage.getItem('product'),
      'step_code':data.step_code,
      'node_id':data.node_id,
      'workflow_request_id':this.workflow_request_id,
      'user_name':this.$session.get('email')
      }
      // this.selected_form={}
      this.info_data = {}
      if(this.workflowObj.running_node_id === data.node_id && data.step_code === "form_review"){
        this.is_active_step = true
         this.show_contact_form = true
      }
      else if(this.workflowObj.running_node_id === data.node_id && data.step_code === "approval"){
        this.is_active_step = true
        this.show_contact_form = false
        this.is_approval = true
      }
      else{
        this.is_active_step = false
         this.show_contact_form = false
         this.is_approval = false
      }
      this.loader = true
      postToServer(_this, config.WORKFLOW_PRODUCT_URL + GET_STEP_REQUEST, data
      ).then(response  => {
        var security_action = response.security_actions
        if(_.isEmpty(_this.token_data))
          _this.token_data={"workflow_request_id":this.workflow_request_id,"node_id": response.step_data.node_id,"workflow_master_id":response.step_data.workflow_master_id,
                            "step_code":step_code,"master_data_id":response.step_data.master_data_id,"workflow_step_master_id":response.step_data.workflow_step_master_id,
                            "call_from":response.step_data.call_from}
        this.ticket_response = _this.token_data
        response = response.step_data
          // SAVING DATA TO DISPLAY FORM DETAILS
          _this.loader = false;
          _this.show_overlay = true
          _this.show_text_info= false
          _this.show_review_info= false
          if(response.form_json)
            _this.applyReadonly(response,security_action)
          if(response.node_name === "send_form_link"){
            _this.show_text_info= true
            _this.tabIndex = 0
            _this.show_overlay = true
            _this.info_data = {
              "Email Step Information": "",
              "Sent On": response.tz_aware_email_sent_date_time,
              "Email Sent to": response.email_data?response.email_data.email_sent_to:'',
              "Email Sent by": response.email_data?response.email_data.email_sent_by:'',
              "Email Subject": response.email_data?response.email_data.email_subject:'',
              "Email Content": response.email_data?response.email_data.email_content:'',
              "Sent Form Id": response.step_input_data.selected_form,
              "Email Link": response.email_data?response.email_data.email_link:'',

              "Link Expire Time": response.email_data?response.email_data.expire_time:'',
            }
          }
          // if(response.node_name === "form_review" || response.node_name === "approval"){
          if(response.node_name === "form_review"){
            
             _this.tabIndex = 0
            //  _this.show_overlay = false
            if(!_.isEmpty(response.user_group_details)){
              _this.show_review_info= true
              _this.info_data = {
                "Status":{
                  "content": "New supplier content",
                  "review": "(in-progress)",
                  "initiated": _this.created_date_time,
                  // "Source Form Id": response.input_form_id,
                  // "Source Form Header": response.input_form_json.formHeader,
                  // "Source Field":_.find(response.input_form_json.fieldList,['fieldId',response.mapping_details[0].input_field_id]).nameInDB,
                },
                "Approvals":[],
                
              }
              _.forEach(response.user_group_details,function(obj,indx){
                      _this.info_data["Approvals"].push({"group": obj.group_name})
                      let group_name = obj.group_name
                      _.forEach(obj.users_list,function(user){
                        let action_time = ''
                        if(user.action === "reviewed")
                          _this.info_data["Approvals"][indx]["action"]= user.user_name + " (" + group_name+")" + " "+ action_time
                        
                  })
                  })
            }
          }
          if(response.node_name === "Form Mapping"){
            _this.show_mapping_info= true
            _this.tabIndex = 1
            _this.show_overlay = false
            _this.info_data = {
              "source_details":{
                "Form Mapping Step Source Details": "",
                "Source Form Id": response.input_form_id,
                "Source Form Header": response.input_form_json.formHeader,
                "Source Field":_.find(response.input_form_json.fieldList,['fieldId',response.mapping_details[0].input_field_id]).nameInDB,
              },
              "target_details":{
                "Form Mapping Step Target Details": "",
                "Target Form Id": response.output_form_id,
                "target Form Header": response.output_form_json.formHeader,
                "Target Field":_.find(response.output_form_json.fieldList,['fieldId',response.mapping_details[0].output_field_id]).nameInDB,
              },
            }
          }
          
      }).catch(ticketResponseError => {
              _this.loader = false;
              if(ticketResponseError){
                  _this.colorValue = 'error'
                  _this.snackbartext = ticketResponseError;                    
              }
              else {                    
                  _this.colorValue = 'error'
                  _this.snackbartext = SERVER_ERROR;                    
              }
          });
    },
    redirectEdcForm(){
      this.$router.push({ name: 'edcForm', params: { formJson: this.formJson,}})
    },
    addSupplierTicket(data){
        var _this =this
        if (data.resource_type == "dataviewer_service"){
          if(this.ticketObj){
            data['ticket_details'] ={
            "url": this.ticketObj.url,
            "url_type": this.ticketObj.url_type,
            "content_type": "json",
            "request_data": this.ticketObj.postData,
            "description": ""
            };
          }
          var formData = data
          formData["resource_code"] = "DAV"
        }
        else{
          var formData = {
              'title':"Supplier Ticket",
              "option_name": data.option_name,
              "resource_type": "form_management",
              "resource_code": "FRM_MGMT",
              'client_id': this.$session.get('client_id')
          }
        }
            
        _this.loader = true;
        postToServer(this, config.EDC_TICKET_MANAGEMENT_SERVICE_URL + CREATE_SUPPLIER_TICKET, formData
        ).then(response  => {
            this.colorValue = 'success'
            this.ticket_id = response.ticket_id
            this.workflow_request_id = response.workflow_request_id
            this.current_step_id = response.current_step_id
            this.current_step_status = response.current_step_status
            this.input_data = response.input_data
            this.ticket_response = response
            
            if(response.form_json){
              this.selected_form = response.form_json              
              this.show_contact_form = true
            }
            else{
              this.show_edit_page= true
              delete _this.$route.params.moreInfo              
              this.show_contact_form = false
            }
            this.getTicketDetails()
            this.getTicketSummery()
            _this.loader = false;
        }).catch(ticketResponseError => {
                _this.loader = false;
                if(ticketResponseError){
                    this.snackbar = true 
                    this.colorValue = 'error'
                    this.snackbartext = ticketResponseError                  
                }
                else {     
                    this.snackbar = true             
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;                    
                }
            });
        },
      sendEmail (data) {
        var _this = this
        var client_id = this.$session.get('client_id')
        var UserData = {
            'client_id': client_id,
            'user_id': "Essentio",
            'client_name': "Essentio",            
            'workflow_request_id':this.workflow_request_id,
            'ticket_id': this.ticket_id,
            'receiver_email': _.find(data.fieldList,['nameInDB',"recipent_email"]).value,
            'current_step_id': this.current_step_id,
            'company_name':_.find(data.fieldList,['nameInDB',"company_name"]).value,
            'recipient_first_name':_.find(data.fieldList,['nameInDB',"recipient_first_name"]).value,
            'recipient_last_name':_.find(data.fieldList,['nameInDB',"recipient_last_name"]).value,
            'current_step_status': this.current_step_status,
            'workflow_request_id':this.workflow_request_id,
            'email_subject': _.find(data.fieldList,['nameInDB',"email_subject"]).value,
            'email_content': _.find(data.fieldList,['nameInDB',"email_content"]).value,

          }
        var data_to_send = {}
        data_to_send =  Object.assign({}, this.ticket_response, UserData);
        data_to_send.form_json = data
        postToServer(this, config.EDC_FORM_MANAGEMENT_URL + SEND_FROM_EMAIL, data_to_send).then(Response  => {
          _this.loader = false
          //delete this params so we can load current running step data
          delete _this.$route.params.moreInfo
          if(Response){   
            _this.show_contact_form =false
            
            
            this.snackbar = true
            this.colorValue = 'success'
            this.snackbartext = "Email sent successfully.";
            setTimeout(() => { 
              _this.getTicketDetails()
              _this.getTicketSummery()
              _this.show_edit_page= true
             }, 1000);
            
          }
          }).catch(error_response => {            
        this.loader = false
        if(error_response){
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = error_response;
          }
          else {
            this.snackbar = true
            this.snackbartext = 'Something went wrong.Try Again';
            this.colorValue = 'error'
            
          }
      });
    },
    applyReadonly(data,security_action){
      var _this = this
      for(let i=0;i<data.form_json.fieldList.length;i++)
						{	let field = data.form_json.fieldList[i]
							if(field.edcType === 'field')
								field.readonly = true
						}
      if(this.is_active_step && this.show_contact_form){
						for(let i=0;i<security_action.length;i++){
							let fieldId = security_action[i].field_id
							let actions =  security_action[i].action_list
							if(actions.indexOf("update_field_data")>-1){
								_this.enableChilds(data.form_json.fieldList,fieldId)
							}
						}
      }
      //  this.selected_form = data.form_json
       this.addFieldsToFieldList(data.form_json.fieldList)
       this.loadUDCValues(data.form_json)
    },
    redirectLink(link){
      window.open(link, '_blank');
    },
    enableChilds(fieldList,parent_id){
				var _this = this
				for(let i=0;i<fieldList.length;i++){
					let fieldobj = fieldList[i]
					if(fieldobj.parentId === parent_id){
						if(fieldobj.edcType === 'field'){
							fieldobj.readonly = false
						}
						else
							_this.enableChilds(fieldList,fieldobj.id)
					}
				}
			},
      addFieldsToFieldList(fieldList){
				var _this = this 
				for(let i =0;i<fieldList.length;i++){
					if(fieldList[i].groupId)
						_this.addFieldsToFieldList(fieldList[i].fieldList)
					else
						_this.onlyFieldList.push(fieldList[i])
				}
			},
      getFieldById(id,fieldList){
				var _this = this
				if(!id)
					return
				if(!fieldList)
					fieldList = this.selected_form.fieldList
				for(let i=0;i<fieldList.length;i++){
					if(fieldList[i].id === id)
						return fieldList[i]
				}
			},
      manageResponse(response,form_json){
				var _this = this

				for(let i=0;i<response.length;i++){
					let fieldId = response[i].fieldId
          let field = _this.getFieldById(fieldId,form_json.fieldList)
          if(field){
					  field.prePropValue.itemList = response[i].item_list
          }
				}

			},
      loadUDCValues(form_json){
				var _this = this
				let udcList = []
				for(let i=0;i<this.onlyFieldList.length;i++){
					if(_.isEmpty(this.onlyFieldList[i].prePropValue) || !this.onlyFieldList[i].prePropValue.isFromApi || this.onlyFieldList[i].prePropValue.valueType != 'UDC')
						continue
					udcList.push({"fieldId":this.onlyFieldList[i].fieldId,"valueDetails":this.onlyFieldList[i].prePropValue.valueDetails})
				}
				if(!udcList.length){
          _this.selected_form = form_json
					return
        }
				let data = {"client_id":this.$session.get("client_id"),
							"udcList":udcList,
							"workflow_id": this.workflow_id,
              "return_type": 'all'}
        this.loader = true
				postToServer(_this,config.WORKFLOW_PRODUCT_URL+'/get_udc_values',data, true).then(response=>{
          this.loader = false
					_this.manageResponse(response,form_json)
          _this.selected_form = form_json
				}).catch(error=>{          
          this.loader = false
          _this.selected_form = form_json
				})
				
			},
  },
  
}
</script>

<style scoped>

.mycustomcls >>> textarea {
  padding-left: 6px !important;
  font-size: 14px !important;
}

.mycustomcls >>> input {
  padding-left: 6px !important;
  font-size: 14px !important;
}

  .label-margin label{
    margin-top:5%;
  }
  .EDC-VCards {
  margin: 0px !important;
  height: 165px !important;
  width: 875px !important;
  overflow:none !important;
}
.EDC-Scroll {
  height: 525px !important;
  overflow:auto !important;
}
.Tile_White {
  background: white !important;
}
.Top_Line {
  padding-left: 1% !important;
}
.Span_Line {
  padding-top: 0.3% !important;
}
.EDC-Container {
  margin: 0px !important;
  padding: 0px !important;
  padding-left: 8px !important;
  padding-right: 0px !important;
  object-fit:fill !important;
}
.Tile_Dark {
  background: #636363 !important;
}
.Image-Resize {
object-fit:contain !important;
}
.EDC-VListItemContent {
  margin: 0px !important;
  padding: 0px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  color:var(--v-apptext-base) !important;
}
.Chip-Active {
  color:white;
}
.v-chip.v-size--default {
    border-radius: 11px !important;
    font-size: 10px !important;
    height: 24px !important;
}
.GroupCard {
    padding-left: 5px !important;
    padding-bottom: 5px !important;
    padding-top: 5px !important;
    padding-right: 0px !important;
    margin-top: 6px !important;
    margin-bottom: 6px !important;
    margin-right: 25px !important;
}
.EDC-TextField {
 	padding-top:0px !important;
 	padding-left:5px !important;
 	padding-right:10px !important;
}
.customInputClass >>> label{
    font-weight: bold !important;
}
.columns-alignment{
  padding-top:8px !important;
  padding-right:24px !important;
  padding-left:6px !important
}
.commentbox-alignment{
  padding-top:8px !important;
  padding-right:2% !important;
  padding-left:0px !important
}
.v-card__title{
  padding:0px !important;
  margin:0px !important;
}
.title-fixed{
  right: 2% !important;
  left: 2% !important;
  top: 1% !important;
  margin-top: -3% !important;
  position:relative;
}
.border-change{
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 1px 0 rgba(0, 0, 0, 0.4);
}
.v-input >>> label {
  padding-left: 6px !important;
  font-size: 14px !important;;
}

.override-font{
  font-size: 14px !important;
  
}

.v-input {
  padding-left: 6px !important;
  font-size: 14px !important;;
}
</style>
<style scoped>
.v-input__slot{
  font-size: 14px;
  margin-bottom: -8px;
  margin-top: -5px;
}
</style>
