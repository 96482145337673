<template>
  <v-container :class="{EDCHeaderContainer:!isGrayTheme,EDCHeaderContainerGray:isGrayTheme}">
    <v-row class="EDC-Row topLines" justify="start" align="center" dense no-gutters>
      <v-col v-if="!isGrayTheme" :cols="$route.meta.showSearchBar?2:3" class="EDC-PageHeader primary--text" @click="routeUrl('/dashboard')" style="text-align: left !important">
        <!-- <img src="../assets/images/essentio_logo_normal.png" alt="Essentio logo" style="width:70%;"> -->
        Task Former
      
      </v-col>
       <v-col v-else :cols="$route.meta.showSearchBar?2:3" @click="routeUrl('/dashboard')" class="EDC-PageHeader graythmprimary--text" style="text-align: left !important;">
        <!-- <img src="../assets/images/essentio_logo_white.png" alt="Essentio logo" style="width:70%;"> -->
        Task Former
      </v-col>
      <v-col v-show="$route.meta.showSearchBar">
            <ticket-details-header key="onHeader" :isFromHeader="true"></ticket-details-header>
      </v-col>
      <v-col :cols="$route.meta.showSearchBar?6:8" style="max-width: 65% !important;">
       <v-row class="EDC-Row" dense no-gutters v-if="!hideHeader" justify="start">
        <v-col cols="3" :class="{normal:isSelected !== 'Home',focus:isSelected == 'Home'}" @click="routeUrl('/dashboard')" style="max-width: 20% !important;">
          <v-row class="EDC-Row" dense no-gutters justify="start">
            <v-menu offset-y class="menuCss">
              <template v-slot:activator="{ on }">
              <v-btn text small :class="{normal:isSelected !== 'Home',focus:isSelected == 'Home','primary--text':isSelected==='Home' && !isGrayTheme,'secondary--text':isSelected!='Home'  && !isGrayTheme,'graythmprimary--text':isSelected==='Home' && isGrayTheme,'graythmsecondary--text':isSelected!='Home' && isGrayTheme }" slot="activator" @click="isSelected='Home',routeUrl('/dashboard')" v-on="on">
                <svgicon name="Menu_Home" :original="true" style="margin-bottom:5%"></svgicon>
                <span> Home</span>
              </v-btn>
            </template>
            </v-menu>
          </v-row>
        </v-col>
    
        <v-col cols="3" :class="{normal:isSelected !== 'NewTicket',focus:isSelected == 'NewTicket'}" @click="routeUrl('/ticketdetails')" style="max-width: 25% !important;">
          <v-row class="EDC-Row" dense no-gutters justify="start">
            <v-menu offset-y class="menuCss">
              <template v-slot:activator="{ on }">
              <v-btn text small :class="{normal:isSelected !== 'NewTicket',focus:isSelected == 'NewTicket','primary--text':isSelected==='NewTicket' && !isGrayTheme,'secondary--text':isSelected!='NewTicket'  && !isGrayTheme,'graythmprimary--text':isSelected==='NewTicket' && isGrayTheme,'graythmsecondary--text':isSelected!='NewTicket' && isGrayTheme }" slot="activator" @click="isSelected='NewTicket'" v-on="on">
                <svgicon name="Menu_Create_Ticket" :original="true" style="margin-bottom:5%;margin-top:5%"></svgicon>
                <span> New Ticket</span>
              </v-btn>
            </template>
            <v-list style="cursor:pointer" class="EDC-MenuList">
                    <v-list-item v-for="(tile, index2) in topNavigation.NewTicketOption" :key="index2" v-if="tile.role" id="menulistitemhover" @click="routeUrl(tile.url,tile.moreInfo)">
                      <v-list-tile-title  :id="tile.name" @click="routeUrl(tile.url,tile.moreInfo);">{{tile.name}}</v-list-tile-title>
                    </v-list-item>
                  </v-list>
            </v-menu>
          </v-row>
        </v-col>

        <v-col cols="3" :class="{normal:isSelected !== 'Archive',focus:isSelected == 'Archive'}" @click="routeUrl('/ticketstatus')" style="max-width: 35% !important;">
          <v-row class="EDC-Row" dense no-gutters justify="start">
            <v-menu offset-y class="menuCss">
              <template v-slot:activator="{ on }">
                <v-btn  text small :class="{normal:isSelected !== 'Archive',focus:isSelected == 'Archive','primary--text':isSelected==='Archive' && !isGrayTheme,'secondary--text':isSelected!='Archive'  && !isGrayTheme,'graythmprimary--text':isSelected==='Archive' && isGrayTheme,'graythmsecondary--text':isSelected!='Archive' && isGrayTheme}" slot="activator" @click="isSelected='Archive',routeUrl('/ticketstatus')"  v-on="on">
                  <svgicon name="Menu_Manage_Tickets" :original="true" style="margin-bottom:3%"></svgicon>
                  <span> Ticket Status</span>
                </v-btn>
              </template>
            </v-menu>
          </v-row>
       </v-col>
        <v-col cols="3" v-show="!$route.meta.showSearchBar" :class="{normal:isSelected !== 'Workflow',focus:isSelected == 'Workflow'}" @click="routeUrl('/workflowRouteList')">
          <v-menu offset-y class="menuCss">
            <template v-slot:activator="{ on }">
              <v-btn  text small :class="{normal:isSelected !== 'Workflow',focus:isSelected == 'Workflow','primary--text':isSelected==='Workflow' && !isGrayTheme,'secondary--text':isSelected!='Archive'  && !isGrayTheme,'graythmprimary--text':isSelected==='Workflow' && isGrayTheme,'graythmsecondary--text':isSelected!='Workflow' && isGrayTheme}" slot="activator" @click="isSelected='Workflow',routeUrl('/workflowRouteList')"  v-on="on">
                <svgicon name="Menu_Manage_Tickets" :original="true" style="margin-bottom:3%"></svgicon>
                <span>Workflow</span>
              </v-btn>
            </template>
            </v-menu>
       </v-col>
      </v-row>
    </v-col>
    <v-col cols="1">
      <v-row class="EDC-Row" justify="start" dense no-gutters>
          <v-col cols="5" align="left">
            <v-row class="EDC-Row">
              <v-menu offset-y class="menuCss">
                <template v-slot:activator="{ on }">
                  <v-btn text x-small id="smallbtn" :class="{normal:isSelected !== 'Configure',focus:isSelected == 'Configure','primary--text':isSelected==='Configure' && !isGrayTheme,'secondary--text':isSelected!='Configure'  && !isGrayTheme,'graythmprimary--text':isSelected==='Configure' && isGrayTheme,'graythmsecondary--text':isSelected!='Configure' && isGrayTheme}" slot="activator"  
                  @click="isSelected='Configure'" v-on="on">
                    <svgicon name="Menu_Configure" :original="true" title="Configure"></svgicon>
                    <!-- <span> Configurations</span> -->
                  </v-btn>
                </template>
                <v-list style="cursor:pointer" v-show="showConfigure" class="EDC-MenuList">
                  <v-list-item v-for="(tile, index) in topNavigation.configure" :key="index" v-if="tile.role" id="menulistitemhover" @click="routeUrl(tile.url)">
                    <v-list-tile-title  :id="tile.name" @click="routeUrl(tile.url)">{{tile.name}}</v-list-tile-title>
                  </v-list-item>
                  
                  <v-menu offset-x class="menuCss" open-on-hover style="width:100%" v-show="showConfigure">
                    <template v-slot:activator="{ on }">
                      <v-list-item v-on="on">
                          <v-list-tile-title>Notification Group</v-list-tile-title>
                          <v-list-tile-action class="justify-end">
                            <v-icon>play_arrow</v-icon>
                          </v-list-tile-action>
                      </v-list-item>
                    </template>
                    <v-list style="cursor:pointer" class="EDC-MenuList">
                      <v-list-item v-for="(tile, index2) in topNavigation.notificationGroup" :key="index2" v-if="tile.role" id="menulistitemhover" @click="routeUrl(tile.url)">
                        <v-list-tile-title  :id="tile.name" @click="routeUrl(tile.url);">{{tile.name}}</v-list-tile-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu offset-x class="menuCss" open-on-hover style="width:100%" v-show="showRuleMenu">
                    <template v-slot:activator="{ on }">
                      <v-list-item v-on="on">
                          <v-list-tile-title>Rules</v-list-tile-title>
                          <v-list-tile-action class="justify-end">
                            <v-icon>play_arrow</v-icon>
                          </v-list-tile-action>
                      </v-list-item>
                    </template>
                    <v-list style="cursor:pointer" class="EDC-MenuList">
                      <v-list-item v-for="(tile, index2) in topNavigation.rule" :key="index2" v-if="tile.role" id="menulistitemhover" @click="routeUrl(tile.url)">
                        <v-list-tile-title  :id="tile.name" @click="routeUrl(tile.url);">{{tile.name}}</v-list-tile-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu offset-x class="menuCss" open-on-hover style="width:100%" v-show="showRuleMenu">
                    <template v-slot:activator="{ on }">
                    <v-list-item v-on="on">
                      <v-list-tile-title>Agent</v-list-tile-title>
                      <v-list-tile-action class="justify-end">
                        <v-icon>play_arrow</v-icon>
                      </v-list-tile-action>
                    </v-list-item>
                  </template>
                    <v-list style="cursor:pointer" class="EDC-MenuList">
                      <v-list-item v-for="(tile, index3) in topNavigation.agent" :key="index3" v-if="tile.role" id="menulistitemhover" @click="routeUrl(tile.url)">
                        <v-list-tile-title  :id="tile.name" @click="routeUrl(tile.url);">{{tile.name}}</v-list-tile-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list>
              </v-menu>
            </v-row>
          </v-col>
          <v-col cols="3">
          <v-row class="EDC-Row">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn fab text x-small v-on="on" id="smallbtn" :title="getUserTitle()">
                  <!-- <i class="material-icons" :class="{'graythmprimary--text':isGrayTheme,'primary--text':!isGrayTheme}" title="Profile Settings" id="smallicon">person</i> -->
                  <span id="profile" :class="{'graythmprimary--text':isGrayTheme,'primary--text':!isGrayTheme}">{{initials}}</span>
                </v-btn>
              </template>
              <v-list style="cursor:pointer" class="EDC-MenuList">
                <!-- <v-list-item v-if="!hideHeader">
                  <v-list-tile-title id="My Downloads" @click="routeUrl('/fileexport')">My Downloads</v-list-tile-title>
                </v-list-item> -->
                <v-list-item v-if="!hideHeader">
                  <v-list-tile-title id="Edit Profile" @click="routeUrl('/userprofile')">Edit Profile</v-list-tile-title>
                </v-list-item>
                <v-list-item v-if="!hideHeader">
                  <v-list-tile-title id="Change Password" @click="routeUrl('/changepassword')">Change Password</v-list-tile-title>
                </v-list-item>
                <v-list-item>
                  <v-list-tile-title id="Sign out" @click="logout()">Sign out</v-list-tile-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>
        </v-col>

          <v-col cols="4">
          <v-row class="EDC-Row">
              <v-menu offset-x z-index="1000" v-if="!hideHeader" :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                  <v-btn fab text x-small v-on="on" id="smallbtn" style="border:0px !important">
                    <i  class="material-icons" :class="{'graythmprimary--text':isGrayTheme,'primary--text':!isGrayTheme}" title="Applications" id="smallicon">apps</i>
                  </v-btn>
                </template>
                    <v-list style="cursor:pointer" class="EDC-MenuList" v-if="applicationList.length">
                  <v-list-item v-for="(item,idx) in applicationList" :key="item.product_name">
                    <v-list-tile-title :id="item.product_name" @click="redirectProduct(item)">{{item.product_name}}</v-list-tile-title>
                  </v-list-item>
                  <v-list-item>
                    <v-menu offset-y class="menuCss">
                  <template v-slot:activator="{ on }">
                  <svgicon name="User_Admin" :original="true" style="margin-bottom:3%" v-on="on"></svgicon>
                  <v-list-tile-title>Admin</v-list-tile-title>
                  
                </template>
                  <v-list style="cursor:pointer" v-show="showAdmin" class="EDC-MenuList">
                    <v-list-item v-for="(tile, index) in topNavigation.admin" :key="index" v-if="tile.role" id="menulistitemhover" @click="routeUrl(tile.url)">
                      <v-list-tile-title  :id="tile.name" @click="routeUrl(tile.url)">{{tile.name}}</v-list-tile-title>
                    </v-list-item>
                  </v-list>
              </v-menu>
                  </v-list-item>
                </v-list>
                
          </v-menu>
            </v-row>
          </v-col>
        <!-- </v-col> -->
      </v-row>
    </v-col>

    <v-snackbar v-model="snackbar" :right=true :top=true :timeout=snackbartimeout :color='colorValue'>
      {{ snackbartext }}
    </v-snackbar>
  </v-row>
</v-container>
</template>

<script>
var moment = require("moment-timezone")
import {
  post as postToServer
} from './../methods/serverCall.js';
import config from '../config.json'
import {
  SERVER_ERROR
} from '../data/client_message.js'
import {
  LOGOUT, UPDATE_LAST_USED_PRODUCT
} from '../data/url_constants.js'
import {
  DATASOURCE_SERVICE_ID,PUBLISH_SERVICE_ID,ENVIRONMENT_SERVICE_ID,REPOSITORY_SERVICE_ID,USER_PROVISIONING_SERVICE_ID,POLICY_SERVICE,SCHEDULER_SERVICE,ACCOUNT_MANAGEMENT_SERVICE,SECURITY_SERVICE,JOB_MANAGEMENT_SERVICE,JOB_CALENDAR_SERVICE, JOB_PLAN_SERVICE_ID, TICKET_MANAGEMENT_SERVICE, TAG_SERVICE
} from "../data/macros.js"
import {mapActions} from 'vuex'
import '../compiled-icons';
import {Archivist_Product,Workflow_Product,Dataviewer_Product} from '../constants/constants.js'
import {getURLByDomainByProductNew} from './../methods/domain_management.js'
import {getActionByGroup} from './../methods/TicketActionsAndQueueList.js'
import ticketDetailHeader from './ticketmanagement/ticketDetailsHeader.vue'


export default {
  name: "customHeader",
  components:{
        'ticket-details-header':ticketDetailHeader
  },
  watch:{
      '$store.state.themeType':{
      handler(newValue){
        this.isGrayTheme = false
          if(newValue === 'dark')
            this.isGrayTheme = true
        }
      },
      '$store.state.generalDownloadNotification':{
        handler(newVal){
          ++this.notificationCount
          if(newVal.notification_bar_message)
            this.notificationBarList.unshift(newVal.notification_bar_message)
          this.showDownloadNotification(newVal)
        }
      },
      '$store.state.pendingDownloadStatusUpdate':{
            handler(newVal){
              if(!this.clearNotificationBadge){
                ++this.notificationCount
                if(newVal.notification_bar_message)
                  this.notificationBarList.unshift(newVal.notification_bar_message)
              }
            }
        },
        '$store.state.workflowQueueMappingChange':{
            handler(newVal){
              this.GetWorkflowMappingList()
            }
          },
        '$route':{
          handler(newVal){
            if(this.clearNotificationBadge){
              this.notificationCount = 0
              this.notificationBarList = []
            }
          }
        },
        'notificationBarList':{
          handler(newVal){
            this.notificationDisplayList = newVal.slice(0,5)
          }
        },
    },
    data() {
      return {
        product_name:Archivist_Product,
        archivist_main_menu:['run','data management','develop','configurations','admin', 'configure'],
        workflow_main_menu:['approve','develop','admin', 'configurations'],
        isGrayTheme:false,
        snackbar:false,
        snackbartext:'',
        snackbartimeout:5000,
        colorValue:'green',
        userRole:this.$session.get('user_role_mapping'),
        have_account_right:this.getUserMenu(ACCOUNT_MANAGEMENT_SERVICE),
        configureMenu:false,
        isSelected:'Home',
        applicationList:[],
        topNavigation: {},
          currenttime: '',
          interval: '',
          sinout_endpoint: '',
          user_name: this.$session.get('user_name'),
          time_zone:'',
          showRunMenu:this.getUserMenu(REPOSITORY_SERVICE_ID) || this.getUserMenu(JOB_MANAGEMENT_SERVICE) ||this.getUserMenu(SCHEDULER_SERVICE)|| this.getUserMenu(PUBLISH_SERVICE_ID) || this.getUserMenu(JOB_PLAN_SERVICE_ID),
          showDataManagementMenu:this.getUserMenu(JOB_MANAGEMENT_SERVICE),
          showDevlop: this.getUserMenu(REPOSITORY_SERVICE_ID) || this.getUserMenu(PUBLISH_SERVICE_ID) || this.getUserMenu(TICKET_MANAGEMENT_SERVICE) || this.getUserMenu(TAG_SERVICE),
          showConfigure: this.getUserMenu(DATASOURCE_SERVICE_ID) || this.getUserMenu(POLICY_SERVICE) ||this.getUserMenu(ENVIRONMENT_SERVICE_ID) ||  this.getUserMenu(JOB_MANAGEMENT_SERVICE) || this.getUserMenu(PUBLISH_SERVICE_ID) || this.getUserMenu(JOB_CALENDAR_SERVICE)||this.getUserMenu(USER_PROVISIONING_SERVICE_ID) || this.getUserMenu(SECURITY_SERVICE) || this.getUserMenu(ACCOUNT_MANAGEMENT_SERVICE) || this.getUserMenu(TICKET_MANAGEMENT_SERVICE),
          showRuleMenu:this.getUserMenu(POLICY_SERVICE) ||  this.getUserMenu(JOB_MANAGEMENT_SERVICE) || this.getUserMenu(PUBLISH_SERVICE_ID)|| this.getUserMenu(JOB_CALENDAR_SERVICE),
          showAdmin:this.getUserMenu(USER_PROVISIONING_SERVICE_ID) || this.getUserMenu(SECURITY_SERVICE) || this.getUserMenu(ACCOUNT_MANAGEMENT_SERVICE),
          initials:'',
          fullName:'',
          notificationCount:0,
          notificationBarList:[],
          notificationDisplayList:[],
        }
      },
      computed:{
        hideHeader() {
          return this.$route.path === '/businessobjectdesigner' || this.$route.path === '/Flowchart' || this.$route.path === '/workflowide'
        },
        clearNotificationBadge(){
          return this.$route.path === '/fileexport'
        },
        showArchivistProduct(){
          let product_list = this.$session.get('product_list')
          return product_list && product_list.includes(Archivist_Product)
        },
        showDataviewerProduct(){
          let product_list = this.$session.get('product_list')
          return product_list && product_list.includes(Dataviewer_Product)
        },
      },
      filters: {
        initCap: function (value) {
          if (!value) return ''
            let arr = value.split(' ');
          arr.map((string, index) => {
            string = string.toString()
            arr[index] = string.charAt(0).toUpperCase() + string.slice(1)
          })
          return arr.join(' ');
        }
      },
      beforeCreate(){
        this.userRole = this.$session.get('user_role_mapping');
      },
      mounted() {
        this.interval = setInterval(this.gettime, 1000);
        this.setthemeInfo(this.$session.get('theme_name'))
        this.product_name = window.sessionStorage.getItem('product') 
        this.topNavigation = this.getTopNavigation()       
        if(this.$session.get('last_name') && this.$session.get('last_name').length > 0){
          this.initials = this.$session.get('first_name').charAt(0).toUpperCase() + this.$session.get('last_name').charAt(0).toUpperCase()
        }
        else{
          this.initials = this.$session.get('first_name').charAt(0).toUpperCase()
         
        }
        this.GetWorkflowMappingList()
        this.getApplicationList()
        // this.getUnreadNotifications()
      },
      methods: {
        ...mapActions(['setthemeInfo','setworkflowQueueMappingChange']),
        getUnreadNotifications(){
          let data = {
            'client_id':this.$session.get('client_id'),
            'username':this.$session.get('email')
          }
          postToServer(this,config.DATA_VIEWER_URL+'/get_unread_notification',data).then(response=>{
            // console.log('unread notification',response)
            this.notificationCount = response.unread_notification
          }).catch(error=>{

          })
        },
        getTopNavigation(){
          return {
          'run':[
          {
            'name': "Job Status",
            'url': '/currentjoblist',
            role:this.getUserMenu(JOB_MANAGEMENT_SERVICE)
          },
          {
            'name': "Job List",
            'url': '/process_def_list_for_execution',
            role:this.getUserMenu(PUBLISH_SERVICE_ID)
          },
          {
            'name': "Job Plan Status",
            'url': '/jobplanstatus',
            role: this.getUserMenu(JOB_PLAN_SERVICE_ID)
          },
          {
            'name': "Job Plan",
            'url': '/jobplanlist',
            role: this.getUserMenu(JOB_PLAN_SERVICE_ID)
          },
          {
            'name': 'Scheduled Jobs',
            'url': '/scheduledJobList',
            role:this.getUserMenu(SCHEDULER_SERVICE)
          },
          // {
          //   'name': "Job Plan History",
          //   'url': '/jobplanhistory',
          //   role:this.getUserMenu(JOB_PLAN_SERVICE_ID)
          // },
          {
            'name': 'Scheduler History',
            'url': '/schedulejobhistory',
            role:this.getUserMenu(SCHEDULER_SERVICE)
          },
          ],
          'approve':[
          {
            name: 'My Approvals',
            url: '/managetaskforapprovallist',
            role:this.getUserMenu('default')
          },
          {
            name: 'My Requests',
            url: '/manageapprovalrequestlist',
            role:this.getUserMenu('default')
          },
          {
            name: 'Approval History',
            url: '/approvalhistorylist',
            role:this.getUserMenu('default')
          },
          ],
          'archive': [
          {
            'name': "Ticket Status",
            'url': '/ticketstatus',
            role:this.getUserMenu(TICKET_MANAGEMENT_SERVICE)
          },
          ],
          'dataManagement':[
          {
            'name': 'Manage Archives',
            'url': '/archives',
            role:this.getUserMenu(JOB_MANAGEMENT_SERVICE)
          },
          {
            'name':'Business Object Activity',
            'url': '/allactivity',
            role:this.getUserMenu(JOB_MANAGEMENT_SERVICE)
          },
          {
            'name':'Activity By Table',
            'url': '/boactivitygraph',
            role:this.getUserMenu(JOB_MANAGEMENT_SERVICE)
          },
          ],
          'NewTicketOption':[],
          'configure': [
          // {
          //     name: 'Datasources',
          //     url: '/datasourcelist',
          //     role:this.getUserMenu(DATASOURCE_SERVICE_ID)  
          //     this.userRole.is_superadmin || getUserMenu('datasource')
          //   },
            {
              'name': 'User Group List',
              'url': '/usergrouplist',
              role:this.getUserMenu(ENVIRONMENT_SERVICE_ID) 
            },
            {
              'name': 'List Management',
              'url': '/listmanagement',
              role:this.getUserMenu(TICKET_MANAGEMENT_SERVICE) 
            },
          {
            'name': "Tag Management",
            'url': '/tagmanagement',
            role:this.getUserMenu(TAG_SERVICE)
          },
           {
              name: "Ticket Queue Management",
              url: "/ticketqueuelist",
              role:this.getUserMenu(TICKET_MANAGEMENT_SERVICE)
          },
          {
              name: "Ticket Configuration",
              url: "/configureticketmanagement",
              role:this.getUserMenu(TICKET_MANAGEMENT_SERVICE)
          },
          // {
          //     name: "Ticket ID Configuration",
          //     url: "/ticketIDGeneration",
          //     role:this.getUserMenu(TICKET_MANAGEMENT_SERVICE)
          // },
          {
            'name': "Workflow Mapping",
            'url': '/workflowmapping',
            role:this.getUserMenu(REPOSITORY_SERVICE_ID)
          },
          // {
          //   'name': "Workspace Mapping",
          //   'url': '/workspacemapping',
          //   role:this.getUserMenu(REPOSITORY_SERVICE_ID)
          // },
           {
            'name': "Workspace List",
            'url': '/WorkSpacesList',
            role:this.getUserMenu(REPOSITORY_SERVICE_ID)
          },
           {
            'name': "Resource Mapping",
            'url': '/ResourceWorkpsaceManagement',
            role:this.getUserMenu(REPOSITORY_SERVICE_ID)
          },
          // {
          //     name: "Erp Accelerator",
          //     url: "/erpacceleratorlist",
          //     role:this.getUserMenu(USER_PROVISIONING_SERVICE_ID)
          // },           
          // {
          //     name: "Users",
          //     url: "/userlist",
          //     role:this.getUserMenu(USER_PROVISIONING_SERVICE_ID)
          // },
          // {
          //     name: "Ticket Management",
          //     url: "/configureticketmanagement",
          //     role:this.getUserMenu(TICKET_MANAGEMENT_SERVICE)
          // },
          // {
          //     name: "Ticket Queue Management",
          //     url: "/ticketqueuelist",
          //     role:this.getUserMenu(TICKET_MANAGEMENT_SERVICE)
          // },
            // {
            //   'name': 'Policies',
            //   'url': '/policyList',
            //   role:this.getUserMenu(POLICY_SERVICE) 
            // },
            // {
            //   name: 'Job Calendar',
            //   url: '/calendar',
            //   role:this.getUserMenu(JOB_CALENDAR_SERVICE)   //this.userRole.is_superadmin || getUserMenu('datasource')
            // }
            // ,
            // {
            //   'name': 'Notification Group',
            //   'url': '/get_all_notification_group',
            //   role:this.getUserMenu(JOB_CALENDAR_SERVICE)
            // }
            ],
            'notificationGroup':[
              {
                'name': 'Notification Group',
                'url': '/get_all_notification_group',
                role:this.getUserMenu(JOB_CALENDAR_SERVICE)
              },
              // {
              //   'name': 'Map User & Notification Group',
              //   'url': '/get_all_notification_group_mapping',
              //   role:this.getUserMenu(JOB_CALENDAR_SERVICE)
              // },
              {
                'name': 'Map User Group & Notification Group',
                'url': '/get_all_notificationgroup_usergroup_mapping',
                role:this.getUserMenu(JOB_CALENDAR_SERVICE)
              }
            ],

            // 'configure2':[
            // {
            //   'name': 'Business Object Policy',
            //   'url': '/bopolicymapping',
            //   role:this.getUserMenu(POLICY_SERVICE)
            // }
            // ],
            'rule':[
            // {
            //   name: 'Run Job',
            //   url: '/jobrulelist',
            //   role:this.getUserMenu(JOB_MANAGEMENT_SERVICE)
            // },
            // {
            //   name: 'Object Publishing',
            //   url: '/publishrulelist',
            //   role:this.getUserMenu(PUBLISH_SERVICE_ID)
            // },
            // {
            //   name: 'Object Publishing Using Datasources',
            //   url: '/publishrulelistfords',
            //   role:this.getUserMenu(PUBLISH_SERVICE_ID)
            // },
            // {
            //   'name': 'Policy Change',
            //   'url': '/policyrulelist',
            //   role:this.getUserMenu(POLICY_SERVICE)
            // },
            // {
            //   name: 'Calendar Rule',
            //   url: '/processDefinitionrule',
            //   role:this.getUserMenu(JOB_CALENDAR_SERVICE)
            // },
            {
              name: 'Notification Group Rule',
              url: '/get_all_notification_group_rule',
              role:this.getUserMenu(JOB_CALENDAR_SERVICE)
            },
            // {
            //   name: 'Job Limit Rule',
            //   url: '/joblimitrule',
            //   role:this.getUserMenu(JOB_MANAGEMENT_SERVICE)
            // },
            ],
            'agent':[
            // {
            //   name:'Download Agent',
            //   url: '/download_agent',
            //   role: false
            // },
            {
              name:'Agent List',
              url: '/agent_list',
              role: true
            },
            {
              name:'Agent Queue List',
              url: '/agent_queue_list',
              role: true
            }
            ],

            'admin': [
            {
              name: "Users",
              url: "/userlist",
              role:this.getUserMenu(USER_PROVISIONING_SERVICE_ID)
          },
          {
              name: 'User Roles',
              url: '/userroleset',
              role:this.getUserMenu(SECURITY_SERVICE)
            },
            {
              name: 'User Group Roles',
              url: '/usergrouproleset',
              role:this.getUserMenu(SECURITY_SERVICE)
            },            
            {
              name: 'Role Setup',
              url: '/rolelist',
              role:this.getUserMenu(SECURITY_SERVICE)
            },
            {
              name: 'User Group Form',
              url: '/usergroupformmappingnew',
              role:this.getUserMenu(SECURITY_SERVICE)
            },
            // {
            //   name: 'Package Builder',
            //   url: '/package_builder',
            //   role:((this.getUserMenu(PUBLISH_SERVICE_ID)||this.getUserMenu(REPOSITORY_SERVICE_ID)))
            // },
            // {
            //   name: 'Package List',
            //   url: '/PackageList',
            //   role:((this.getUserMenu(PUBLISH_SERVICE_ID)||this.getUserMenu(REPOSITORY_SERVICE_ID)))
            // },
            // {
            //   name: 'Import Package',
            //   url: '/import_package',
            //   role:this.getUserMenu('joblist')
            // },
            ]
          }
        },
        isArchivistProduct(){
          return true
          // return this.product_name === Archivist_Product
        },
        isWorkflowProduct(){
          return true
          // return this.product_name === Workflow_Product
        },
        isMenuBelongToCurrentProduct(menu){
          return true
          let menu_list = []
          if(this.product_name === Archivist_Product)
            menu_list = this.archivist_main_menu
          else if(this.product_name === Workflow_Product)
            menu_list = this.workflow_main_menu
          if(menu_list.indexOf(menu)>-1)
            return true
          return false
        },
        getUserMenu(serviceName){
          if(this.userRole.is_superadmin)
            return true
          if(this.userRole[serviceName])
            return true
          if (serviceName === 'default')
            return true
          return false;
        },
        routeUrl(path,moreInfo) {
          this.setthemeInfo(this.$session.get('theme_name'))
          if(moreInfo){
            if(moreInfo.source_type == "ticket_management"){
              this.$router.push({ name: 'CreateTicket',
                params:{"moreInfo": moreInfo}})
            }
          }
          else
            this.$router.push(path);
        },
        gettime() {
          this.currenttime = moment().tz(this.$session.get('time_zone_preference')).format('HH:mm:ss')
          this.time_zone = moment().tz(this.$session.get('time_zone_preference')).format('z')
        },
        logout() {
          var logout_endpoint = config.AUTHENTICATION_URL + LOGOUT
          var input_token = {
            'access_token': this.$session.get('access_token')
          }

          if(config.DOMAIN_NAME){
            this.$cookies.remove('access_token',null,config.DOMAIN_NAME)
            this.$cookies.remove('last_selected_env_id',null,config.DOMAIN_NAME)
          }
          
          postToServer(this, logout_endpoint, input_token).then(logout_response => {
            if (this.$cookies.isKey('Timeout')) {
              this.$cookies.remove('Timeout')
            }
            this.$session.destroy()
            window.location.href = "/login"
          }).catch(error_response => {
            if (this.$cookies.isKey('Timeout')) {
              this.$cookies.remove('Timeout')
            }
            this.$session.destroy()
          });
        },
        ReportRedirect(){
          this.$router.push({ name: 'ReportDownloadStatus',
            params:{"frompage": true}})
        },
        RedirectToApp(){
          return
          let routeData = this.$router.resolve({ name: 'approvalHistoryList'});
          // update last product_name as workflow
          var last_used_product_endpoint = config.USER_PROVISION_URL + UPDATE_LAST_USED_PRODUCT
          var inpute_data = {
            "user_id": this.$session.get('user_id'),
            "client_id": this.$session.get('client_id'),
            'product_name': Workflow_Product
          }
          postToServer(this, last_used_product_endpoint, inpute_data).then(last_used_product_response => {
            if (last_used_product_response) {
            }
          }).catch(error_response => {
          });
          window.open(routeData.href, '_blank');
        },
        redirectProduct(productDetails){
          let _this = this
          // let url = this.$session.get('protocol')+'://'
          let queryString = "&last_env="+this.$session.get('last_selected_env_id')
          let url = getURLByDomainByProductNew(_this,productDetails,'login',queryString)
          if(url)
              window.open(url, '_blank');

          setTimeout(function(){
            _this.selectedproduct = ''
          },100)
        },
        getUserTitle(){
          if(this.$session.get('last_name') && this.$session.get('last_name').length > 0){
          this.fullName = this.$session.get('first_name') + " "+ this.$session.get('last_name') + ' | '+this.currenttime +' ('+this.time_zone+')'
          }
          else{
            this.fullName = this.$session.get('first_name') + ' | '+this.currenttime +' ('+this.time_zone+')'
          }
          return this.fullName
        },
        showDownloadNotification(data){
          // this.colorValue = data.message_type
          // this.snackbartext = data.body.message
          // this.snackbar = true
        },
        GetWorkflowMappingList(){
            var _this = this
            // _this.loader = true
            _this.topNavigation.NewTicketOption = []
            var data_to_send = {"client_id": _this.$session.get('client_id'),
                                "source_type":"ticket_management",
                                "product_name": window.sessionStorage.getItem('product')}
            postToServer(_this, config.EDC_QUEUE_MANAGEMENT_URL+"/get_all_queue_workflow_form_rule", data_to_send).then(response =>{
                if(response){
                  let menuList = []
                  for(let i=0;i<response.length;i++){
                    if(response[i].source_type == "ticket_management"){
                      menuList.push({"name":response[i].option_name,'url':'/createticket','role':true,'moreInfo':response[i]})
                    }
                  }
                  // menuList.push({"name":"General Ticket",'url':'/createticket', 'role':true, 'option_name': "General Ticket"})
                  this.topNavigation.NewTicketOption =menuList

                }
                else{
                    _this.loader = false
                }
            }).catch(error_response => {
            });
        },
        getApplicationList(){
          var _this = this
          let data = {"client_id":this.$session.get('client_id')}
          this.applicationList = []
          postToServer(_this,config.ACCOUNT_MANAGEMENT_URL+'/get_product_configuration',data).then(response=>{
            this.$session.set('applications_configuration',response)
            _.forEach(response,(obj)=>{
              if(obj.product_name === 'OBJECT_DESIGNER' || obj.product_name === 'TASKFORMER')
                return
              this.applicationList.push(obj)
            })
          }).catch(error=>{
            alert('error in application fetching')
          })
        }
      }
    }
</script>

<style>

    .normal{
      color:rgb(125, 125, 115) !important;
    }
    .focus{
      color:rgb(50,120,150) !important;
      font-weight: bold !important;
    }
    .width-60{
      width:60%
    }
    .menuCss{
      height:100%;
      padding-top: 0px;
      width:120px;
      font-size: 90%;

    }
    .topLine{
     background-color: white;
     color: #A0A0A0;
     line-height: 35px;
     position: fixed;
     top: 0px;
     width: 100%;
     margin: 0px !important;
     z-index: 20;
   }

   .main-header {
    background-color: RGB(51,51,51);
    width: 100%;
    z-index: 20;
    padding-top: 5px;
    margin: 0px;
    position: fixed;
    top: 30px;
    width: 100%;
    height: 66px;
  }

  .cust-logo {
    width: 90%;
    height: 80%;
  }

  .lightgray {
    color: rgb(125, 125, 115) ;
  }

  .v-list__tile:hover{
    background: rgb(51,51,51);
    color: white;
  }
  .activeLink {
    color: rgb(50,120,150) !important;
    font-weight: bold;
  }
  
  .ht-70 {
    height: 70px;
  }

  #menulistitemhover:hover {
    background-color:black;
    color:white !important;
  }

  .tabTitle {
    font-size: 90%;
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }

  .v-tabs__container {
    height: 30px !important;
  }

  .v-btn{
    color:#A0A0A0 !important;
    padding:0 px;
    font-size:14px !important;
    text-transform: none;
  }

  .v-application .mb-12 {
    margin-bottom: 0px !important;
}

#smallbtn {
    height: 25px !important;
    width: 25px !important;
    color: #A0A0A0 !important;
    border: 1px solid #A0A0A0 !important;
    margin: 6px !important;
}

#smallicon {
    font-size: 16px;
}

</style>