<template>
	<v-container class="EDC-Row">
		<v-form id="edcTextform" ref="form">
			<v-row class="EDC-Row">
				<v-col class="EDC-Col" cols="12">
					<v-text-field :autocomplete="autoComplete" :hide-details="hideDetails" :dense="propDense" v-model="textModel" @blur="onBlur" :type="fieldType" :label="labelText" :rules='rules' :title="titleText || labelText" :disabled="readOnly" v-mask="maskFormat">
						<template #label>
							{{labelText}}
    						<span class="red--text" v-if="isRequired"><strong> *</strong></span>
  						</template>
					</v-text-field>
				</v-col>
			</v-row>
		</v-form>
	</v-container>
</template>
<style scoped>
	div[aria-required=true].v-input .v-label::after {
	  content: " *";
	  color: red;
	}
</style>
<script>
	export default {
		name:'EdcTextField',
		props:{
			fieldType:{
				type:String,
				default:"text"
			},
			autoComplete:{
				type:String,
				default:"off"
			},
			hideDetails:{
				type:Boolean,
				default:false
			},
			propDense:{
				type:Boolean,
				default:true
			},
			vModel:{
				type:String,
				default:'',
			},
			labelText:{
				type:String,
				default:'Text'
			},
			fieldUniqueId:{
				type:String,
				default:''
			},
			isRequired:{
				type:Boolean,
				default:true
			},
			formValidate:{
				type:Boolean,
				default:true
			},
			isSpaceAllowed:{
				type:Boolean,
				default:true
			},
			lengthValidation:{
				type:Object,
				default:()=>{
					return {}
				}
			},
			titleText:{
				type:String,
				default:''
			},
			isEmail:{
				type:Boolean,
				default:false
			},
			readOnly:{
				type:Boolean,
				default:false
			},
			maskFormat:{
				type:String,
				default:""
			}
		},
		data(){
			return{
				textModel:'',

			}
		},
		computed:{
			rules(){
				let rules = []
				if(!this.formValidate || this.readOnly)
					return
				if(this.isRequired){
					let rule = v => !!v || this.labelText +' field is required'
					rules.push(rule)
				}
				if(this.isEmail){
					let rule = v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v) || this.labelText+ ' must be valid'
					rules.push(rule)
				}
				if(!this.isSpaceAllowed){
					let rule = v => (v || '').indexOf(' ') < 0 ||
              		'No spaces are allowed'

          			rules.push(rule)
				}
				if(!_.isEmpty(this.lengthValidation)){
					let rule = ''
					if(this.lengthValidation.minimum && this.lengthValidation.maximum === this.lengthValidation.minimum)
						rule = v=>(v||'').length === this.lengthValidation.minimum || `Exact ${this.lengthValidation.minimum} characters are required.`
					
					else if(this.lengthValidation.maximum)
						rule = v=>(v||'').length <= this.lengthValidation.maximum || `A maximum of ${this.lengthValidation.maximum} characters are allowed.`
					else if(this.lengthValidation.minimum)
						rule = v=>(v||'').length >= this.lengthValidation.minimum || `A minimum of ${this.lengthValidation.minimum} characters are required.`
					
					if(rule)
						rules.push(rule)
				}
				return rules
			}
		},
		watch:{
			vModel:{
				handler(newvalue){
					// avoid infinite looping
					if(newvalue!=this.textModel)
						this.textModel = newvalue
				}
			},
			isRequired:{
				handler(newvalue){

				}
			},
			readOnly:{
				handler(newvalue){

				}
			}
		},
		mounted(){
			this.textModel = this.vModel
		},
		methods:{
			onBlur(){
				this.$emit('onBlur',this.textModel,this.fieldUniqueId,this.$refs.form.validate())
			}
		}
	}
</script>