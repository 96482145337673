<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>User Group Role Mapping</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <edc-data-grid :dataList="tableList" @onNew="onNew" @onDelete="onDelete" @onEdit="onEdit" @onSave="onSave" @onCancel="onCancel" @onValueUpdate="onValueUpdate"></edc-data-grid>
    
    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>

<script>
import { post as postToServer } from './../../methods/serverCall.js';
import { get as getToServer } from './../../methods/serverCall.js';
import config from '../../config.json'
import { SERVER_ERROR } from '../../data/client_message.js'
import { GET_ALL_USERS_LIST, GET_USER_ROLE_MAPPING, GET_USER_ENV_ROLE_MAPPING, GET_ALL_ROLES, GET_ENVIRONMENTAL_ROLES, MANAGE_USER_ROLE_MAPPING, DELETE_USER_ROLE_MAPPING, GET_ROLES_BY_TYPE, MANAGE_ENV_USER_ROLE_MAPPING, ADD_ENV_USER_ROLE_MAPPING } from '../../data/url_constants.js'
import {CLIENT_SIDE, SERVER_SIDE} from '../../data/macros.js'
import {SECURITY_SERVICE,CREATE_ACTION,READ_ACTION, CREATE_ROLE, READ_ROLE, DELETE_ROLE, UPDATE_ROLE} from "./../../data/macros.js"
import _ from 'lodash'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import {getActionByGroup} from './../../methods/TicketActionsAndQueueList.js'
import {getUserRole, getTicketRole}  from './../../methods/GetUserRole.js'

export default {
  data() {
    return {
      snackbar:false,
      snackbartext:'',
      colorValue:'error',
      loader: "loading",
      snackbartimeout: Snackbar_Default_Timeout,
      loading: false,
      userGroupList:[],
      administratorRoleList:[],
      environmentalRoleList: [],
      actions_by_group:[],
      userRole:'',
      envIpJson: {
        "filter": [],
        "sort": [{
            "column_name": "",
            "type": ""
        }],
        "page": 1,
        "page_size": 5
      },
      tableList:{
        headers: [
          {text: 'User Group', value: 'group_name', width:"40%", option:[], 'item_text':'group_name', 'item_value':'group_name', useAutoComplete:true},
          { text: 'Role Type', value: 'role_type', width: '30%', title: 'Security Role Type', option:[], 'item_text':'role_type', 'item_value':'role_type', useAutoComplete:true, isRoleMapping:true},
          {text: 'Role', value: 'role', width:"30%", option:[], 'item_text':'role', 'item_value':'role', useAutoComplete:true},
        ], 
        actions: [],//if we need conditional action in row then provide key
        sorting_type: CLIENT_SIDE,
        filterType: CLIENT_SIDE,
        paginationType: CLIENT_SIDE,
        total_count: 0,
        is_row_editable:true,
        rows: [],
        selected_rows: [],
        syncHeaderScroll:false,
        disableDefaltSorting:true,
        itemkey:'unique_id',
        is_grid_inline_edit:true,
        hideFilter:true,
        hideExport:true,
        hideShowColumns:true
      },
    }
  },

  mounted() {
    this.userRole = this.$session.get('user_role_mapping');
    this.tableList.headers[1].option = [{"role_type":"Administrator"},{"role_type":"Queue Based"}]
    getActionByGroup(this) 
    this.tableList.actions = [
      {'text':'edit','key':"id", selectType:"single", role:this.userRole.is_superadmin || getTicketRole(this.actions_by_group,SECURITY_SERVICE,UPDATE_ROLE), index:1},
      {'text':'new','key':"id", 'enabled':this.Environment, role:this.userRole.is_superadmin || getTicketRole(this.actions_by_group,SECURITY_SERVICE,CREATE_ROLE), index:2},    
      {'text':"save","key":"is_row_editable",selectType:"single", index:3},
      {'text':"cancel","key":"is_row_editable",selectType:"single", index:4},
      {'text':'delete','key':"id", selectType:"single", role:this.userRole.is_superadmin || getTicketRole(this.actions_by_group,SECURITY_SERVICE,DELETE_ROLE), index:5}];
    if(this.tableList.paginationType == CLIENT_SIDE)
      this.envIpJson.page_size="*";
    this.GetAllRoleList();
    this.GetUserGroupList();
    // this.GetAdministratorRoleList();
    // this.GetEnvironmentalRoleList();
  },

  methods: {
    GetAllRoleList() {
      let data = {
        'client_id':this.$session.get('client_id'),
        'product_name': "TICKET_MANAGEMENT"}
      var url = config.Security_URL + '/get_group_env_role_mapping'
      postToServer(this, url, data).then(Response => {
        if(Response){
              Response.forEach(function(obj,idx){
                obj.role_type = obj.role_type.toLowerCase() == "environmental"? "Queue Based": "Administrator"
                obj['unique_id'] = idx+1
              })
              this.tableList.rows = Response;
        }
        else{
          this.tableList.rows = [];
        }
      }).catch(error_response => {
        this.tableList.rows = [];
      });
    },

    GetUserGroupList() {
      var _this =this;
      var client_id = _this.$session.get('client_id');
      var data = {"client_id": client_id,
                 'product_name': window.sessionStorage.getItem('product')}
      var get_user_url = config.USER_PROVISION_URL + '/get_all_group_details'
      postToServer(_this, get_user_url, data).then(response => {
      _this.userGroupList = response;
      _this.tableList.headers[0].option = response;
      }).catch(error_response => {
      }); 
    },

    onValueUpdate(record) {
      if(record.role_type === "Administrator"){
        this.GetAdministratorRoleList();
      }
      else if(record.role_type === "Queue Based"){
        this.GetEnvironmentalRoleList();
      }
      else{
        this.tableList.headers[2].option = []
      }
    },

    GetAdministratorRoleList() {
      var _this =this;
      var client_id = _this.$session.get('client_id');
      var data = {"client_id":client_id, "role_type":'administrator',
      'product_name': "TICKET_MANAGEMENT"}
      var url = config.Security_URL + GET_ROLES_BY_TYPE
      postToServer(_this, url, data).then(response => {
        this.administratorRoleList = response;
        this.tableList.headers[2].option = response;
        administratorRoleList.map(function(obj){
        obj.isselected = false
      })
      }).catch(error_response => {
      }); 
    },

    GetEnvironmentalRoleList() {
      var _this =this;
      var client_id = _this.$session.get('client_id');
      var data = {"client_id":client_id, "role_type":'environmental',
      'product_name': "TICKET_MANAGEMENT"}
      var url = config.Security_URL + GET_ROLES_BY_TYPE
      postToServer(_this, url, data).then(response => {
        this.environmentalRoleList = response;
        this.tableList.headers[2].option=response;
        environmentalRoleList.map(function(obj){
        obj.isselected = false
      })
      }).catch(error_response => {
      }); 
    },

    onNew() {
      let obj = _.find(this.tableList.rows,["is_row_editable",true])
      if(obj)
      {
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = 'First fiilup the open row';
        return
      }
      this.tableList.rows.unshift({
        'group_name':'',
        'role_type':'',
        'role':'',
        'is_row_editable': true,
      });
    },

    onCancel() {
      this.GetAllRoleList();
    },

    onSave(record) {
      if(!record.group_name || !record.role_type || !record.role){
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = 'All fields are required';
        return false
      }
      
      if(record.role_type === "Administrator"){
        var role_list = this.administratorRoleList
      }
      else if(record.role_type === "Queue Based"){
        var role_list = this.environmentalRoleList
      }
      let userData = {
        'id':record.id,
        'client_id':this.$session.get('client_id'),
        'role_id':_.find(role_list,['role',record.role]).role_id,
        'role_name':record.role,
        'group_id':_.find(this.userGroupList,['group_name',record.group_name]).id,
        'group_name':record.group_name,
        'user_role_id':record.id,
        'product_name': "TICKET_MANAGEMENT"
      }

      record.id ? this.updateUserGroup(userData) : this.saveUserGroup(userData);            
    },

    saveUserGroup(userData) {
      var url = config.Security_URL + '/add_group_env_role_mapping'
      postToServer(this, url, userData).then(Response => {
        this.snackbar = true
        this.colorValue = 'success'
        this.snackbartext = "Role added successfully";
        this.GetAllRoleList();
      }).catch(error_response => {
        if (error_response) {
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = error_response;
        }else{
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = error_response;
        }
      });
    },

    updateUserGroup(userData) {  
      var url = config.Security_URL + '/update_group_env_role_mapping'
      postToServer(this, url, userData).then(Response => {
        this.snackbar = true
        this.colorValue = 'success'
        this.snackbartext = "User updated successfully";
        this.GetAllRoleList();
      }).catch(error_response => {
        if (error_response) {
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = error_response;
        }else{
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = error_response;
        }
      });
    },

    onDelete(record) {
      let inputJson = {
        'client_id':this.$session.get('client_id'),
        'group_name':record.group_name,
        'group_id':_.find(this.userGroupList,['group_name',record.group_name]).id,
        'role_name':record.role,
        'role_id':record.role_id,
        'user_role_id':record.id,
        'product_name': "TICKET_MANAGEMENT"}
      let url = config.Security_URL + '/delete_group_env_role_mapping'              
      postToServer(this, url, inputJson).then(response  => {
        this.snackbar = true
        this.colorValue = 'success'
        this.snackbartext = 'User Role deleted successfully';
        this.GetAllRoleList();
      }).catch(error_response => {
        this.GetAllRoleList();
        if(error_response){
          this.snackbar = true;
          this.colorValue = 'error';
          this.snackbartext = error_response;
        } else {
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = SERVER_ERROR;
        }
      });               
    },
  }
}
</script>
<style scoped>

</style>