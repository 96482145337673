/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Save': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<g color="#000" font-weight="400" font-family="sans-serif"><path pid="0" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M13 8c-2.753 0-5 2.247-5 5v38c0 2.753 2.247 5 5 5h38c2.753 0 5-2.247 5-5V23.586l-.293-.293L40.414 8H13zm0 2h5v9a1 1 0 001 1h20a1 1 0 001-1v-8.586l14 14V51c0 1.68-1.32 3-3 3H13c-1.68 0-3-1.32-3-3V13c0-1.68 1.32-3 3-3zm7 0h18v8H20v-8z" overflow="visible"/><path pid="1" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M31.904 27.836c-4.406 0-8 3.594-8 8 0 4.406 3.594 8 8 8 4.407 0 8-3.594 8-8 0-4.406-3.593-8-8-8zm0 2c3.326 0 6 2.674 6 6 0 3.325-2.674 6-6 6-3.325 0-6-2.675-6-6 0-3.326 2.675-6 6-6z" overflow="visible"/></g>'
  }
})
