/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Approve': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M6 28c-1.108 0-2 .892-2 2v24c0 1.108.892 2 2 2h12c1.108 0 2-.892 2-2V30c0-1.108-.892-2-2-2zm7.975 3.025a3 3 0 013 3 3 3 0 01-3 3 3 3 0 01-3-3 3 3 0 013-3z"/><path pid="1" d="M57 26H44m10 16a3 3 0 012.967 3C57 49 51 48 55 48h-3c-.669 0 2 .156 2 .156 1 .844 1.644 1.342 1.336 2.825C54.92 52.985 53 53 52 53H25V31h4s3.453-7.018 4.823-10.785c1.674-3.563.235-9.641 1-10 .945-.321 3.568-.524 5.662.76C42.665 12.312 42.87 15.361 43 16v11m13 7a4 4 0 013.314 3.94c0 2.21-2.314 4.06-4 4.094C54.976 42.041 53 42 54 42m3-16c2 0 4 1.986 4 4 .173 3.362-3.564 3.958-6 4-.047 0 .906 0 .859-.002" _fill="none" _stroke="#000" stroke-width="2"/>'
  }
})
