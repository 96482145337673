/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Menu_Approve': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M32 3.5a28 28 0 00-28 28 28 28 0 0028 28 28 28 0 0028-28 28 28 0 00-28-28zm11.314 15.525c1.023 0 2.045.393 2.829 1.176a3.99 3.99 0 010 5.656L29.172 42.828a3.992 3.992 0 01-5.656 0l-8.487-8.484a3.993 3.993 0 010-5.658 3.99 3.99 0 015.657 0l5.658 5.658L40.484 20.2a3.992 3.992 0 012.83-1.176z"/>'
  }
})
