<template lang="html">
  <!-- <v-flex xs12 style="padding:0px;"> -->
    <v-menu ref="menu2" :close-on-content-click="false" :disabled="disabled" v-model="menu2" 
      :return-value.sync="date" :absolute="true"  :nudge-left="100"
      lazy transition="scale-transition" offset-y full-width min-width="290px">
      <template v-slot:activator="{ on }">
      <v-text-field clearable :required="isdateRequired" autocomplete="off" slot="activator" :disabled="disabled" v-model="calenderDate" :label="label || dateLabel" append-icon="event" @focus="OnDateFieldFocus" @keypress="OnDateKeyPress" @blur="DateInputEvent" hide-details="auto" :dense="dense" v-on="on" v-show="!format"></v-text-field>
      <v-text-field clearable :required="isdateRequired" autocomplete="off" slot="activator" :disabled="disabled" v-model="displayDate" :label="label || dateLabel" append-icon="event" @focus="OnDateFieldFocus" @keypress="OnDateKeyPress" @blur="DateInputEvent" hide-details="auto" :dense="dense" v-on="on" v-show="format"></v-text-field>
    </template>
      <v-date-picker v-model="date" :disabled="disabled" @input="emitChange" :min="mindate" no-title></v-date-picker>
    </v-menu>
  <!-- </v-flex> -->
</template>

<script>
import moment from 'moment'

export default {
  name: 'Calender',
  data() {
    return {
        menu2: false,
        date: null,
        isdateRequired:false,
        calenderDate:this.input,
        customFormat:null,
        dateLabel:'Date',
        date_format:'(YYYY-MM-DD)',
        anyKeyPressed:false, // this variable used to handle blur event properly. If blur event is active and user going to select date from date picker then we no need to fire blur. blur event close date popup.
        isFocused:false, // this variable to select date in date picker if date already there. Focus event get called continuesly. So this variable will get used to fire event only once,
        mindate:'',
        displayDate:'', // this variable is used to show custom date to user. To backend we are passing YYYY-MM-DD format only. So we are not changing that code thats why used v-show in the html code to make sure user can see the formated date but same time we have make sure expected format get passed to the backend.
    }
  },
  props: ['input','format','disabled','label','isMinDate','dense', 'dateRequired'],
  computed:{
    isKeyPressDenied(){
      // in some format , user has to select the date he cannot type it.
      return this.format === 'dddd, MMMM Do YYYY'
    }
  },
  watch:{
    dense(newValue){

    },
    dateRequired(newValue){
      this.isdateRequired=newValue
    },
    input(newValue) {  
      this.calenderDate = newValue;
    },
   date (val) {
      this.calenderDate = this.parseDate(this.date)
    },
    format(newValue) {  

    },
    label(newValue){
      // if(newValue)
      //   this.label = newValue + date_format
    },
    isMinDate(newValue){
      if(newValue)
        this.mindate = moment().format("YYYY-MM-DD")
    },
    calenderDate(newValue){
      // alert(newValue)
      this.manageDisplayDate()
    }
  },
  mounted(){
    if(this.isMinDate)
      this.mindate = moment().format("YYYY-MM-DD")
    // if(this.format)
    //   this.customFormat = this.format
  },
  methods: {
    emitChange: function () {
      this.calenderDate = this.parseDate(this.date);
      this.$refs.menu2.save(this.calenderDate);
      this.anyKeyPressed = false;
      this.isFocused = false;
      this.$emit('update', this.calenderDate);
    },
    parseDate (date) {
       if (!date) return null

       if(!this.customFormat || this.customFormat == 'yyyy-mm-dd') return date

        const [year, month, day] = date.split('-')
        return `${month}-${day}-${year}`
    },
    OnDateKeyPress(e){
      e.preventDefault()

      if(this.anyKeyPressed){
        e.preventDefault()
        return
      }
      this.anyKeyPressed = true
      if(e.keyCode === 13){
        this.DateInputEvent()
      }
    },
    DateInputEvent(){
      if(this.anyKeyPressed){
      this.date = this.parseDate(this.calenderDate)
      this.emitChange()
      }
    },
    OnDateFieldFocus(){
      if(!this.isFocused){
        this.isFocused = true
        this.date = this.parseDate(this.calenderDate)
      }
    },
    manageDisplayDate(){
      let parsedDate = this.parseDate(this.calenderDate)
      if(parsedDate && this.format === 'dddd, MMMM Do YYYY'){
        this.displayDate = moment(parsedDate).format('dddd, MMMM Do YYYY')
      }
      else
        this.displayDate = parsedDate
    }
  }
}
</script>