<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="4" class="EDC-Col">
             <ul class="breadcrumb breadcrumbUL">
              <li>
                <router-link to="/WorkSpacesList">Workspace List</router-link>
              </li>
              <li>Manage Workspace</li>
            </ul>
          </v-col>
          <v-col cols="8"></v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>
    <v-container>
      <v-card elevation-1>
        <v-col cols="12" class="EDC-Col">
          <v-row class="EDC-Row" align="center">
            <v-col cols="3" class="EDC-Col EDC-ColsSpacing">
              <v-autocomplete clearable  :items="WorkspaceList" v-model="selectedWorkspace" item-text="name" return-object label="Workspace Name" :rules="workspaceRules" @input="onWorkSpaceSelect()" required></v-autocomplete>
            </v-col>
            <v-col cols="3" class="EDC-Col">
              <!-- <vc-button type="button" item-text="Save" @click.native="savePlan"/> -->
              <vc-button type="button" item-text="Cancel" @click.native="OnCancelBtn()"/>
            </v-col>
            <v-col cols="3" class="EDC-EDC-ColsSpacing ">
              <!-- <v-text-field v-model="description" label="Description" :rules="descRules" required/> -->
            </v-col>
            <v-col cols="3" class="EDC-Col">
              <!-- <v-text-field v-model="paralleljobcount" type='number' label="Maximum Running Jobs"  @blur='validateParellelJobCount' required/> -->
            </v-col>
          </v-row>
          <v-row class="EDC-Row">
            <v-col cols="12" class="EDC-Col EDC-ColsSpacing">
             <edc-data-grid :dataList="tableList" @onDelete="onDelete" @onEdit="onEdit" @onNew="onNew" @onSave="onSave" @onCancel="onCancel" @onRecourceTypeChange="onRecourceTypeChange" @onAgentPing="TestDBConnection" @onValueUpdate="onValueUpdate"></edc-data-grid>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
      
    </v-container>
    
    
    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>
<script>
import moment from 'moment'
import config from '../../config.json'
import { get as getToServer, post as postToServer } from './../../methods/serverCall.js';
import environmentList from '../../methods/EnvironmentList.js'
import {SERVER_ERROR} from '../../data/client_message.js'
import {GET_ALL_ENV, GET_ALL_WORKFLOW_LIST, UPDATE_ENV_RULE, DELETE_ENV_RULE,
            ENVIRONMENT_RULES, ADD_ENV_RULE, ADD_WORKSPACE_RULE,MANAGE_WORKSPACE_RESOURCE, DELETE_WORKSPACE_RULE, UPDATE_WORKSPACE_RULE, GET_ALL_WORKSPACE_RULE} from './../../data/url_constants.js';
import {CLIENT_SIDE, SERVER_SIDE} from './../../data/macros.js'
import _ from 'lodash'
import { ADD_USER, GET_USER, ADD_WORKSPACE_DATA, DELETE_WORKSPACE_RESOURCE, UPDATE_USER,GET_DATASOURCE_LIST, GET_ALL_WORKSPACE, UPDATE_WORKSPACE_DATA,GET_ALL_WORKSPACE_RESOURCE, GET_WORKSPACE_DATA, GET_ALL_SMTP_SERVER, GET_ALL_DATABASE_SERVER,GET_ALL_APPLICATION_SERVER, GET_ALL_CLOUD_SERVER} from './../../data/url_constants.js'
import getUserRole from './../../methods/GetUserRole.js'
import {PUBLISH_SERVICE_ID,CREATE_ACTION,UPDATE_ACTION,DELETE_ACTION} from "../../data/macros.js"
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

export default {
    name: 'ClientJobListNew',
    
    data: function () {
        return {
            loader:false,            
            tableList: {
                headers: [
                    { text: 'Resource Name', value: 'resource_name',option:[], 'item_text':'resource_name', 'item_value':'resource_name', width:"25%", label:"Resource Name", useTextField:true},
                    { text: 'Resource Type', isResourceType:true, value: 'resource_type', width:"30%",option:[], 'item_text':'resource_type', 'item_value':'resource_type', useAutoComplete:true}, 
                    { text: 'Resource', value: 'datasource_name', width:"25%", option:[], 'item_text':'datasource_name', 'item_value':'datasource_name', useAutoComplete:true, returnObject:true},
                    // { text: 'Enabled', visible:"!is_row_editable" ,value: 'enabled', displayValue:true?"enabled":"disabled", width:"25%", option:[], 'item_text':'is_active', 'item_value':'is_active', useToggle:true},
                    { text: 'Enabled', value: 'is_active', width:"25%", option:[], 'item_text':'is_active', 
                    booleanTextMapping:{"true":"Enabled","false":"Disabled"}, isBooleanToText:true,'item_value':'is_active', useCheckbox:true}], 
                actions: [],//if we need conditional action in row then provide key
                sorting_type: CLIENT_SIDE,
                filterType: CLIENT_SIDE,
                paginationType: CLIENT_SIDE,
                total_count: 0,
                is_row_edit:true,
                rows: [],
                itemkey:'id',
                selected_rows: [],
                syncHeaderScroll:false,
                disableDefaltSorting:true,
                hideExport:true,
                caption:"addResource",
                hideShowColumns:true,
                is_grid_inline_edit:true
            },
            EnvironmentList:[],
            WorkspaceList: [],
            allServerList:[],
            allDatasourceList:[],
            selectedWorkspace:{},
            changedResourceType:"database",
            workspace_name:'',
            snackbar:false,
            snackbartext:'',
            colorValue:'success',
            addResource:true, 
            snackbartimeout: Snackbar_Default_Timeout,           
            valid:true,
            userRole:{},
        }
    },
    mounted() {
        this.userRole = this.$session.get('user_role_mapping');
        this.tableList.actions = [{"text":'new', 'key':'id',selectType:"single", role:this.userRole.is_superadmin ||getUserRole(this.userRole,PUBLISH_SERVICE_ID,UPDATE_ACTION), index: 1},
            {'text':'edit','key':"id", selectType:"single",role:this.userRole.is_superadmin ||getUserRole(this.userRole,PUBLISH_SERVICE_ID,UPDATE_ACTION), index: 2},
            {'text':'AddResource','key':"id", 'enabled':this.Environment, role:this.userRole.is_superadmin || getUserRole(this.userRole,PUBLISH_SERVICE_ID,CREATE_ACTION), index: 3},
            {'text':"save","key":"is_row_editable", selectType:"single", index: 4},
            {'text':"cancel","key":"is_row_editable", selectType:"single", index: 5},
            {'text':'delete','key':"id", selectType:"single",role:this.userRole.is_superadmin || getUserRole(this.userRole,PUBLISH_SERVICE_ID,UPDATE_ACTION), index: 6},
            {'text':'PingAgent', 'displayText':'Test Connection', 'key':'id', selectType:"multiple", role:true, index:7}]

       this.getResourceTypes();
      this.getWorkspaceList();
      // getWorkspaceList(this);
        this.GetAllServer("application")
        this.GetAllDatasource()  
      //  this.getRuleList();
    },
    methods: {
        onSave(record){
            record.id ? this.updateResource(record): this.saveResource(record);         
        },
        OnCancelBtn(){
          this.$router.push("/WorkSpacesList")
        },
        onCancel(){
            // debugger;
            if(this.selectedWorkspace.id)
              this.GetReSource(this.selectedWorkspace.id);
        },
        getResourceTypes() {
            let _this = this;
             _this.tableList.headers[1].option = ['application','cloud','smtp','database','cloud']
        },
        onWorkSpaceSelect(){
          var ws_list = this.WorkspaceList
          this.workspace_name = this.selectedWorkspace.name
          let id = this.selectedWorkspace.id
          this.GetReSource(id);
        },
        getWorkspaceList () {
        let _this = this;
        _this.loader = true;
        var data ={ client_id: this.$session.get('client_id') }
            postToServer(this, config.WORKSPACE_URL + GET_ALL_WORKSPACE , data
            ).then(response => {
            _this.loader = false;
            if(response){
                _this.WorkspaceList = response; 
            }else{
                _this.WorkspaceList = [];
            }
            }).catch(error_response => {
            _this.loader = false;
            if(error_response){
                this.loader = null 
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
                }
                else {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = SERVER_ERROR;
                }
            });
        },
         onRecourceTypeChange(item){
           let type = item.resource_type
      // this.changedResourceType = type
      
      // this.resourceNameList[idx] =[]
      if(type === "database"){
          // this.GetAllDatasource()
          this.tableList.headers[2].option = this.allDatasourceList
          
        }
      else{
      // this.GetAllServer(type)
       this.tableList.headers[2].option = this.allServerList
      }
      },
      // getWorkspaceList(){
      //   var data = {"client_id": this.$session.get('client_id')}
      //   let _this = this
      //   _this.loader = true;
      //   postToServer(this, config.WORKSPACE_URL + GET_ALL_WORKSPACE, data).then(response => {
                
      //           var data = response
      //            _this.WorkspaceList = data
      //          _this.loader = false;

      //      }).catch(error_response => {
      //       if(error_response){
      //         _this.loader = false;
      //       }
      //       else {
      //         _this.snackbar = true
      //         _this.colorValue = 'error'
      //         _this.loader = false;
      //         _this.snackbartext = SERVER_ERROR;
      //       }
      //   })
      // },
      GetAllDatasource(){
        var client_id = this.$session.get('client_id')
         let _this = this
        _this.loader = true;
        // postToServer(this, securityUrl, security_json).then(checkResponse => {
            getToServer(this, config.DATA_SOURCE_URL + GET_DATASOURCE_LIST + client_id
            ).then(response => {
                var data = response
                let _this= this
                _this.loader = false;
            let ds_list = []
              _.forEach(data, function(obj) {
                 let id = obj.datasource_id
                  let name = obj.datasource_info.datasource_name
                  obj={datasource_name:name,
                  datasource_type:"database",
                  id:id}
                  ds_list.push(obj)
                });
                //  _this.tableList.headers[2].option = ds_list
                 _this.allDatasourceList=ds_list

           }).catch(error_response => {
            if(error_response){
              _this.loader = false;
            }
            else {
              _this.snackbar = true
              _this.colorValue = 'error'
              _this.loader = false;
              _this.snackbartext = SERVER_ERROR;
            }
        })
      },
      GetReSource(id){
      if(id){
         let _this = this
        var data ={
          client_id:this.$session.get('client_id'),
          workspace_master_id: id
        }
         _this.loader = true;
        postToServer(this, config.WORKSPACE_URL + GET_ALL_WORKSPACE_RESOURCE, data).then(response => {
                var data = response
                data = _.sortBy(data,'resource_name')
                let _this =this
                if(id)
                  {
                    //   _.forEach(data, function(obj) {
                    //  let value = obj.is_active
                    //  if(value==true)
                    //  obj.enabled = "Enabled"
                    //  else
                    //  obj.enabled = "Disabled"
                    //   });
                     _this.tableList.rows = data
                      _this.loader = false;
                    //  _this.workspace_name=data.name
                   
                    // for(var i=0; i<data.resource_list.length; i++){
                    //   if(data.resource_list[i].workspace_master_id){
                    //   name_list[i]={id:data.resource_list[i].id,
                    //                 resource_name:data.resource_list[i].resource_name,
                    //                 datasource_name:data.resource_list[i].datasource_name,
                    //                 resource_type:data.resource_list[i].resource_type,
                    //                 datasource_id:data.resource_list[i].datasource_id}
                    //   }
                    //   else{
                    //      name_list[i]={
                    //                 resource_name:data.resource_list[i].resource_name,
                    //                 datasource_name:data.resource_list[i].datasource_name,
                    //                 resource_type:data.resource_list[i].resource_type,
                    //                 datasource_id:data.resource_list[i].datasource_id}
                    //   }
                    //   this.showEdit[i]="true"
                    // } 
                  }  

           }).catch(error_response => {
            if(error_response){
              _this.snackbar = true
              _this.colorValue = 'error'
              _this.snackbartext = error_response;
              _this.loader = false 
            }
            else {
              _this.snackbar = true
              _this.colorValue = 'error'
              _this.snackbartext = SERVER_ERROR;
               _this.loader = false 
            }
        })
      }
      else{
        this.addResource()
      }
      },
      GetAllServer(type){
        var data ={
          client_id: this.$session.get('client_id'),
          resource_type: type
        }
      let _this= this
      _this.loader = true;
        postToServer(this, config.DATA_SOURCE_URL + '/get_workflow_datasource_details_by_type', data).then(response => {
                
                var data = response
                //  _this.tableList.headers[2].option = data
                _this.allServerList = data
                _this.loader = false;
           }).catch(error_response => {
            if(error_response){
              _this.loader = false;
            }
            else {
              _this.snackbar = true
              _this.colorValue = 'error'
              _this.snackbartext = SERVER_ERROR;
              _this.loader = false;
            }
        })
      },
        // getWorkflowList(){
        //     let _this = this;
        //     workflowList(this).then(response=>{
        //         if(response){
        //                 _this.tableList.headers[2].option = response;
        //                  _this.tableList.headers[3].option = response;
        //         }
        //     },response=>{
        //         this.ShowErrorMessage=true
        //         this.ErrorMessage=response
        //     })
        // },
        saveResource(record){
            // debugger
                    let _this =this
                    let datasource_list = []
                    if(record.resource_type === "database")
                        datasource_list = this.allDatasourceList
                    if(record.resource_type === "application")
                        datasource_list = this.allServerList
                    let datasourcObj = _.find(datasource_list,['datasource_name',record.datasource_name]);
                    let  inputJson={
	                  client_id:this.$session.get('client_id'),
	                  workspace_master_id: this.selectedWorkspace.id,
	                  resource_name: record.resource_name,
	                  resource_type: record.resource_type,
	                  datasource_id: datasourcObj.id,
	                  datasource_name: datasourcObj.datasource_name,
	                  is_active: record.is_active
                      }
            // let inputJson = {
            //                source_workspace_id:sourceObj ? sourceObj.id+'':null,
            //                source_workspace_name:rule.source_workspace_name || '',
            //                target_workspace_id:targetObj ? targetObj.id+'':null,
            //                target_workspace_name:rule.target_workspace_name || '',
            //                client_id:this.$session.get('client_id')}
            let url =  config.WORKSPACE_URL + MANAGE_WORKSPACE_RESOURCE; 
            _this.loader = true;             
            postToServer(this, url, inputJson).then(response  => {
                               this.snackbar = true
                this.colorValue = 'success'
                this.snackbartext = response
                this.loader = false
                setTimeout(() => {  this.GetReSource(this.selectedWorkspace.id); }, 2000);
            }, response => {
                if (response){
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = response;
                    this.loader = false
                }
		    }).catch(e => {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = e
                this.loader = false
                
            })                
        },
        onDelete(record){
          this.deleteResource(record)
          this.GetReSource(this.selectedWorkspace.id);
        },
        deleteResource(record){
          let _this =this
            let inputJson = {id:record.id,
                              client_id:this.$session.get('client_id'),
                              workspace_master_id: this.selectedWorkspace.id
                           }
            _this.loader = true;
            let url =  config.WORKSPACE_URL + DELETE_WORKSPACE_RESOURCE;              
            postToServer(this, url, inputJson).then(response  => {
                          this.snackbar = true
                this.colorValue = 'success'
                this.snackbartext = response
                this.loader = false
            }, response => {
                if (response){
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = response;
                    this.loader = false
                    
                }
		    }).catch(e => {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = e
                this.loader = false
            })              
        },
        updateResource(record){
            // debugger
            let _this =this
             let datasource_list = []
             if(record.resource_type === "database")
                        datasource_list = this.allDatasourceList
                    if(record.resource_type === "application")
                        datasource_list = this.allServerList
                    let datasourcObj = _.find(datasource_list,['datasource_name',record.datasource_name]);
           let  inputJson={
	                  client_id:this.$session.get('client_id'),
                    workspace_master_id: this.selectedWorkspace.id,
                    id:record.id,
	                  resource_name: record.resource_name,
	                  resource_type: record.resource_type,
	                  datasource_id: datasourcObj.id,
	                  datasource_name: datasourcObj.datasource_name,
	                  is_active: record.is_active
                      }
            let url =  config.WORKSPACE_URL + MANAGE_WORKSPACE_RESOURCE; 
            _this.loader = true;             
            postToServer(this, url, inputJson).then(response  => {
                    _this.snackbar = true
                    _this.colorValue = 'success'
                    _this.snackbartext = "Resource updated Successfully"
                   setTimeout(() => {  _this.GetReSource(_this.selectedWorkspace.id); }, 2000);
                   _this.loader = false;
                }).catch(DatasourceError => {
                if(DatasourceError){
                    _this.snackbar = true;
                    _this.colorValue = 'error';
                    _this.snackbartext = DatasourceError;
                    _this.loader = false;
                    }
                    else {
                    _this.snackbar = true
                    _this.colorValue = 'error'
                    _this.snackbartext = SERVER_ERROR;
                    _this.loader = false;
                    }
            });               
        },
       onNew(record){
            let obj = _.find(this.tableList.rows,["is_row_editable",true])
            if(obj)
            {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = 'First fillup the open row';
                return
            }
            this.tableList.rows.unshift({
                'resource_name':'',
                'resource_type':'',
                'datasource_name':'',
                'is_active':true,
                'is_row_editable' : true
            });              
        },
        onEdit(record){
          this.onRecourceTypeChange(record)
        },
      onValueUpdate(record) {
      if(record.resource_type === "database"){
          this.tableList.headers[2].option = this.allDatasourceList
      }
      else{
        this.tableList.headers[2].option = this.allServerList
      }

      },
        TestDBConnection(record){
            this.loader = true
            let _this =this
            let resource_list= []
            _.forEach(record, function(obj) {
                  let name = obj.datasource_name
                  var resource = {
                    id:obj.datasource_id,
                    resource_name:obj.resource_name,
                    datasource_type:obj.resource_type
                  }
                  resource_list.push(resource)
                });
              
            _this.loader = true;
           postToServer(this, config.DATA_SOURCE_URL + "/test_connection", resource_list).then(response => {
			// var data = JSON.parse(response.bodyText)
                _this.snackbar = true
                _this.colorValue = 'success'
                _this.snackbartext = response
                 _this.loader = false
		    }).catch(e => {
                _this.snackbar = true
                _this.colorValue = 'error'
                _this.snackbartext = e
                 _this.loader = false
            })
        },
        focus_env(){
        //   if(this.$refs.envname.$el)
        //     this.$refs.envname.$el.focus()
        //   else this.$refs.envname.focus()
        },
    }
}
</script>
<style>
/* tr:nth-child(even) {background-color: #E8E8E8;} */
/* tr:hover td {background:#A9A9A9;} */
/* .blurOpacity{
    opacity: 0;
}
.showInput {
    visibility: inherit !important;
    width: 50% !important;
}

.srch-ip {
    width: 0px;
    background: white;
    color: black;
    float: right;
    transition: all 1s;
    visibility: hidden;
}

table {
    border: 2px solid grey;
    border-radius: 3px;
    background-color: #fff;
    cursor: pointer;
}

thead {
    line-height: 30px;
    transition: all 10s;
}

th {
    background-color: grey;
    color: rgba(255, 255, 255, 0.66);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

th.active {
    color: #fff;
}

th.active .arrow {
    opacity: 1;
}

.arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
}

.arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #fff;
}

.arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
}

.srch-icon {
    font-size: 16px;
    color: white;
    float: right;
    margin-top: 4%;
} */
</style>