/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'move_down': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M3.153 5.82a.398.398 0 01-.322-.197l-.423-.726-.424-.728a.396.396 0 01.346-.592h.316V1.722h1.058v1.855h.316c.306 0 .498.328.346.592l-.424.728-.423.726a.399.399 0 01-.366.197zM1.852 1.193v-.53h2.646v.53z" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
