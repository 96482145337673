/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'archive_synchronize_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" d="M4.71.265v.528H.794a.265.265 0 00-.264.266V2.91h.53V1.323H4.71v.53l.687-.398.687-.397-.687-.397-.687-.396zM1.853 2.278v.368h2.646v-.368H1.852zm.261.642v1.186a.132.132 0 00.133.131h1.863a.132.132 0 00.133-.131V2.92H3.98v1.053h-1.6V2.92h-.266zm.797.123v.264h.53v-.264h-.53zm2.381.397v1.588H1.64v-.53l-.687.397-.687.397.687.397.687.396v-.528h3.918a.265.265 0 00.263-.265V3.44h-.529z"/>'
  }
})
