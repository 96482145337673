/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'archive_solid': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" d="M.794 0A.792.792 0 000 .794v1.852h6.35V.794A.792.792 0 005.556 0H.794zm0 3.44v2.286c0 .346.265.624.595.624h3.572c.33 0 .595-.278.595-.624V3.44H.794zm1.323.529h2.116v.529H2.117v-.53z"/>'
  }
})
