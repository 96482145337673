/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Cancel': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<g _fill="none" _stroke="#000"><circle pid="0" cx="32.149" cy="32.626" r="26.976" stroke-width="2" stroke-linejoin="round"/><path pid="1" d="M20 44l24-24M44 44L20 20" stroke-width="4" stroke-linecap="round"/></g>'
  }
})
