import _ from 'lodash';
export default function getUserRole(userRole ,service, name){
    if(userRole && userRole[service] && _.indexOf(userRole[service], name) !== -1)
            return true;
        else
            return false;    
}

export function getTicketRole(userRole ,service, name){
    if(service in userRole){
        let rule = userRole[service]
        if(rule.includes(name)){
            return true
        }
    }
    return false
    // let rule = _.find(userRole,["service_id",service])
    // if(rule  && rule.actions_list.includes(name))
    //         return true;
    //     else
    //         return false;    
}