/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'move_up': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M3.153.664a.399.399 0 00-.322.197l-.423.726-.424.728a.396.396 0 00.346.592h.316v1.856h1.058V2.907h.316a.396.396 0 00.346-.592l-.424-.728L3.52.861a.399.399 0 00-.366-.197zM1.852 5.292v.529h2.646v-.53H1.852z" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
