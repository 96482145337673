/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'filter_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" style="-inkscape-stroke:none" d="M1.058.53a.265.265 0 00-.265.263V2.66a.795.795 0 000 1.497v1.354a.265.265 0 00.265.263.265.265 0 00.264-.263V4.158a.794.794 0 01-.01.003.796.796 0 00.54-.75.799.799 0 00-.53-.75V.793a.265.265 0 00-.264-.264zm2.118 0a.265.265 0 00-.266.263V3.75a.799.799 0 00-.53.748c0 .344.223.638.53.748v.266a.265.265 0 00.266.264.265.265 0 00.264-.264v-.266a.795.795 0 000-1.497V.793A.265.265 0 003.176.53zm2.115.043a.265.265 0 00-.263.265v.53a.795.795 0 000 1.498v2.691a.265.265 0 00.263.264.265.265 0 00.266-.264V2.865a.796.796 0 00.529-.747.8.8 0 00-.53-.75v-.53a.265.265 0 00-.265-.265zm-.014 1.016h.014a.527.527 0 110 1.056.527.527 0 01-.014-1.056zM1.043 2.88h.014c.294 0 .529.236.529.53 0 .293-.235.529-.53.529a.528.528 0 01-.529-.53c0-.289.228-.521.516-.529zm2.12 1.088h.013c.294 0 .53.236.53.53 0 .293-.236.529-.53.529a.528.528 0 01-.53-.53c0-.289.229-.522.516-.529zm.15 1.31a.794.794 0 01-.007.002l.007-.002z" color="#000"/>'
  }
})
