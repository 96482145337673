<template>
  <div>
      <loading-panel :loader="loader"></loading-panel>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>User Group Form Mapping</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <edc-data-grid :dataList="tableList" @onNew="onNew" @onDelete="onDelete" @onSave="onSave" @onCancel="onCancel"  @onValueUpdate="onValueUpdate"></edc-data-grid>
    
    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>
<script>
import { post as postToServer } from '../../methods/serverCall.js';
import config from '../../config.json'
import {GET_ROLES_BY_TYPE, GET_ALL_PROCESS_DEF_ENV,GET_PROCESS_GRAPH_DATA} from '../../data/url_constants.js'
import { SERVER_ERROR } from '../../data/client_message.js'
import {CLIENT_SIDE, SERVER_SIDE} from '../../data/macros.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
export default{
    data(){
        return{
            snackbar:false,
            snackbartext:'',
            colorValue:'error',
            loader: false,
            snackbartimeout: Snackbar_Default_Timeout,
            loading: false,
            userGroupList:[],
            frmDataList: [],
            fieldsList: [],
            envIpJson: {
                "filter": [],
                "sort": [{
                    "column_name": "",
                    "type": ""
                }],
                "page": 1,
                "page_size": 5
            },
            tableList:{
                headers: [
                {text: 'User Group', value: 'user_group_name', width:"20%", option:[], 'item_text':'user_group_name', 'item_value':'user_group_name', useAutoComplete:true},
                { text: 'Role Type', value: 'role_type', width: '20%', title: 'Security Role Type', option:[], 'item_text':'role_type', 'item_value':'role_type', useAutoComplete:true, isRoleMapping:true},
                {text: 'Role', value: 'role_name', width:"20%", option:[], 'item_text':'role_name', 'item_value':'role_name', useAutoComplete:true},
                {text: 'Form', value: 'form_name', width:"20%", option:[], 'item_text':'form_name', 'item_value':'form_name', useAutoComplete:true, isRoleMapping:true},
                {text: 'Fields', value: 'field_name', width:"20%", option:[], 'item_text':'field_name', 'item_value':'field_name', useAutoComplete:true},

                ], 
                actions: [],//if we need conditional action in row then provide key
                sorting_type: CLIENT_SIDE,
                filterType: CLIENT_SIDE,
                paginationType: CLIENT_SIDE,
                total_count: 0,
                is_row_editable:true,
                rows: [],
                selected_rows: [],
                syncHeaderScroll:false,
                disableDefaltSorting:true,
                itemkey:'id',
                is_grid_inline_edit:true,
                hideFilter:true,
                hideExport:true,
                hideShowColumns:true
            },
        }
    },
    mounted(){
        this.tableList.headers[1].option = [{"role_type":"Administrator"},{"role_type":"Form Based"}]
        // this.tableList.headers[3].option = [{"form_name":"Supplier"},{"form_name":"Employee"},{"form_name":"Item"}]
        this.tableList.actions = [
        {'text':'edit','key':"id", selectType:"single", role:true, index:1},
        {'text':'new','key':"id", 'enabled':this.Environment, role:true, index:2},    
        {'text':"save","key":"is_row_editable",selectType:"single", index:3},
        {'text':"cancel","key":"is_row_editable",selectType:"single", index:4},
        {'text':'delete','key':"id", selectType:"single", role:true, index:5}];
        this.GetUserGroupList();
        this.GetAllUserGroupFormMapping();
        this.GetFormList();
    },
    methods: {
        
        GetUserGroupList() {
            var _this =this;
            var client_id = _this.$session.get('client_id');
            var data = {"client_id": client_id,
                        'product_name': window.sessionStorage.getItem('product')}
            var get_user_url = config.USER_PROVISION_URL + '/get_all_group_details'
            postToServer(_this, get_user_url, data).then(response => {
            response.forEach(function(obj){
                obj.user_group_name = obj.group_name
            })
            _this.userGroupList = response;
            _this.tableList.headers[0].option = response;
            }).catch(error_response => {
            }); 
        },
        GetFormList(){
            var _this = this;
            var client_id = _this.$session.get('client_id');
            var filter = [{'s_parentheses': '(', 'value1': 'Form', 'actual_value1': 'Form', 'operator': '_cl_', 'column_actual_name': 'object_type_desc', 'hideKey': true, 'e_parentheses': ')', 'column_name': 'object_type_desc', 'isInlineFilter': true}]
            var search_data = {"client_id": client_id,
            "filter": filter}
            var get_all_forms_url = config.REPOSITORY_API_URL + GET_ALL_PROCESS_DEF_ENV
            postToServer(_this, get_all_forms_url, search_data).then(response =>{
                if (response){
                    var frm_data = response.result
                    _this.frmDataList = response.result
                    frm_data.forEach(function(obj){
                        obj.form_name = obj.object_name
                    })
                    _this.tableList.headers[3].option = frm_data;
                }
            }).catch(error_response => {
                 _this.tableList.headers[3].option = []
            });
        },
        GetAllUserGroupFormMapping(){
            var _this = this
            var data = {"client_id": _this.$session.get('client_id')}
            postToServer(_this, config.Security_URL+'/get_all_group_form_mapping', data).then(response=>{
                if(response){
                    this.tableList.rows = response;
                }
                else{
                    this.tableList.rows = [];
                }
            }).catch(error_response => {
                this.tableList.rows = [];
            });
        },
        onNew() {
            let obj = _.find(this.tableList.rows,["is_row_editable",true])
            if(obj)
            {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = 'First fiilup the open row';
                return
            }
            this.tableList.rows.unshift({
                'user_group_name':'',
                'role_name':'',
                'form_name':'',
                'field_name':'',
                'is_row_editable': true,
            });
        },
        onSave(record) {
            if(!record.user_group_name || !record.role_type || !record.role_name || !record.form_name || !record.field_name){
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = 'All fields are required';
                return false
            }
            
            if(record.role_type === "Administrator"){
                var role_list = this.administratorRoleList
            }
            else if(record.role_type === "Form Based"){
                var role_list = this.environmentalRoleList
            }
            let userData = {
                'id':record.id,
                'client_id':this.$session.get('client_id'),
                'role_id':_.find(role_list,['role_name',record.role_name]).role_id,
                'role_name':record.role_name,
                'role_type': record.role_type,
                'user_group_id':_.find(this.userGroupList,['user_group_name',record.user_group_name]).id,
                'user_group_name':record.user_group_name,
                'form_name': record.form_name,
                "form_id": _.find(this.frmDataList,["form_name",record.form_name]).object_id,
                'field_name': record.field_name,
                "field_id": _.find(this.fieldsList,["field_name",record.field_name]).id
            }

            record.id ? this.updateUserGroupForm(userData) : this.saveUserGroupForm(userData);         
        },

        saveUserGroupForm(userData) {
            var url = config.Security_URL + '/add_group_form_mapping'
            postToServer(this, url, userData).then(Response => {
                this.snackbar = true
                this.colorValue = 'success'
                this.snackbartext = "Role added successfully";
                this.GetAllUserGroupFormMapping();
            }).catch(error_response => {
                if (error_response) {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
                }else{
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
                }
            });
        },

        updateUserGroupForm(userData) {  
            var url = config.Security_URL + '/update_group_form_mapping'
            postToServer(this, url, userData).then(Response => {
                this.snackbar = true
                this.colorValue = 'success'
                this.snackbartext = "User updated successfully";
                this.GetAllUserGroupFormMapping();
            }).catch(error_response => {
                if (error_response) {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
                }else{
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
                }
            });
        },

        onDelete(record) {
            let inputJson = {
                'record_id':record.id,
            }
            let url = config.Security_URL + '/delete_group_form_mapping'              
            postToServer(this, url, inputJson).then(response  => {
                this.snackbar = true
                this.colorValue = 'success'
                this.snackbartext = 'User Role deleted successfully';
                this.GetAllUserGroupFormMapping();
            }).catch(error_response => {
                this.GetAllUserGroupFormMapping();
                if(error_response){
                this.snackbar = true;
                this.colorValue = 'error';
                this.snackbartext = error_response;
                } else {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = SERVER_ERROR;
                }
            });              
        },

        onCancel() {
            this.GetAllUserGroupFormMapping();
        },

        onValueUpdate(record) {
            if(record.role_type === "Administrator"){
                this.GetAdministratorRoleList();
            }
            else if(record.role_type === "Form Based"){
                this.GetEnvironmentalRoleList();
            }
            else{
                this.tableList.headers[2].option = []
            }
            if(record.form_name != ""){
                this.GetFieldsForForm(record)
            }
            else{
                this.tableList.headers[4].option = []
            }
        },

        GetFieldsForForm(record) {
            var object_id = _.find(this.frmDataList,['form_name',record.form_name]).object_id
            var data_to_send = {"object_id": object_id,
            "object_revision": "1"}
            var object_details_url = config.REPOSITORY_API_URL+GET_PROCESS_GRAPH_DATA
            postToServer(this, object_details_url, data_to_send).then(response =>{
                if(response){
                    var field_json = response.object_json.fieldList
                    var fields = []
                    field_json.forEach(function(obj){
                        if(obj.label != ''){
                            fields.push(obj)
                        }
                    })
                    fields.forEach(function(obj){
                        obj.field_name = obj.label
                    })
                    this.fieldsList = fields.slice()
                    this.tableList.headers[4].option = fields
                }
            })
        },

        GetEnvironmentalRoleList() {
            var _this =this;
            var client_id = _this.$session.get('client_id');
            var data = {"client_id":client_id, "role_type":'environmental',
            'product_name': "TICKET_MANAGEMENT"}
            var url = config.Security_URL + GET_ROLES_BY_TYPE
            postToServer(_this, url, data).then(response => {
                response.forEach(function(obj){
                    obj.role_name = obj.role
                })
                this.environmentalRoleList = response;
                this.tableList.headers[2].option=response;
                environmentalRoleList.map(function(obj){
                obj.isselected = false
            })
            }).catch(error_response => {
            }); 
        },

        GetAdministratorRoleList() {
            var _this =this;
            var client_id = _this.$session.get('client_id');
            var data = {"client_id":client_id, "role_type":'administrator',
            'product_name': "TICKET_MANAGEMENT"}
            var url = config.Security_URL + GET_ROLES_BY_TYPE
            postToServer(_this, url, data).then(response => {
                response.forEach(function(obj){
                    obj.role_name = obj.role
                })
                this.administratorRoleList = response;
                this.tableList.headers[2].option = response;
                administratorRoleList.map(function(obj){
                obj.isselected = false
            })
            }).catch(error_response => {
            }); 
        },
    }
}
</script>