/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shortcut_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" d="M.53.53v5.29h5.29V3.727c-.264.243-.264.243-.528.507v1.059H1.058V1.059h1.058c.264-.265.264-.265.502-.53H.529zm2.646 0a.265.265 0 00-.188.45l.598.599c-.24.18-.55.347-.777.7-.235.364-.39.891-.506 1.345-.116.453-.182.827-.182.827a.265.265 0 00.492.172s.142-.258.338-.564c.196-.306.456-.658.633-.809.193-.164.456-.394.683-.536a1.05 1.05 0 01.314-.14l.788.788a.265.265 0 00.451-.188V.794A.265.265 0 005.557.53H3.176zm.638.529h1.477v1.477l-.342-.342c-.143-.143-.357-.164-.517-.129-.16.035-.304.112-.446.201-.283.178-.557.42-.746.58-.062.054-.108.146-.168.211.064-.167.117-.389.182-.49.169-.261.584-.475.902-.793a.265.265 0 000-.373l-.342-.342z" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-variant-east-asian:normal;font-feature-settings:normal;font-variation-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;shape-margin:0;inline-size:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
