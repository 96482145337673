<template>
  <div
    id="app"
    style="background:white"
  >
    <!-- <button @click.prevent="focusEditor">Focus Editor</button> -->
    <vue-editor id="editor3" ref="editor3"
      v-model="obj_comment"
      placeholder="Comment"
      @blur="onBlur"
      :editor-toolbar="customToolbar"
    />
  </div>
</template>

 <script>
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor
  },
  props:['commentEditorObj'],

  data() {
    return {
      obj_comment: "",
      // in_focus:false,
      editorOptions : {
          modules: {
          toolbar: false,
        }
      },
      // customToolbar:  [
      //           [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
      //           [{ 'size': ['small', false, 'large', 'huge'] }],
      //           ['bold', 'italic', 'underline', 'strike'],
      //           [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
      //           // [{ 'header': 1 }, { 'header': 2 }],
      //           ['blockquote', 'code-block'],
      //           [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
      //           [{ 'script': 'sub'}, { 'script': 'super' }],
      //           [{ 'indent': '-1'}, { 'indent': '+1' }],
      //           [{ 'color': [] }, { 'background': [] }],
      //           ['link', 'image', 'video', 'formula'],
      //           [{ 'direction': 'rtl' }],
      //           ['clean'], 
      //         ]
      customToolbar:  [
                [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
                // [{ 'size': ['small', false, 'large', 'huge'] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
                // [{ 'header': 1 }, { 'header': 2 }],
                ['blockquote'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
                // [{ 'script': 'sub'}, { 'script': 'super' }],
                [{ 'indent': '-1'}, { 'indent': '+1' }],
                [{ 'color': [] }, { 'background': [] }],
                ['link', 'image'],
                // [{ 'direction': 'rtl' }],
                // ['clean'],
              ]
    };
  },
  mounted() {
    this.$refs.editor1.quill.focus();
  },
  methods: {
    focusEditor() {
      this.$refs.editor1.quill.focus();
    },
    onBlur(){
        this.$emit('onBlur');
    },
  },
  watch: {
    commentEditorObj(newvalue,oldvalue){
      this.obj_comment = ''
      debugger;
      if(newvalue && newvalue.comment)
        this.obj_comment = this.commentEditorObj.comment
    },
    obj_comment(newvalue){
        this.$emit('CommentChange',newvalue);
    },
  }
};

</script>
<style scoped>
  .ql-editor{
      min-height: 150px !important;
    }
</style>